import React from 'react';
import { ModelSelect } from '../../fields/ModelSelect';
import { SerialNumberInput } from '../../fields/SerialNumberInput';
import { DataProvider } from './DataProvider';
import styles from './default.module.scss';
import { DirectConnection } from './DirectConnection';
import { MeterRequest } from './MeterRequest';

export const DefaultIt = () => {
  return (
    <div>
      <div className="p-3 pt-0 d-flex gap-3">
        <ModelSelect />
        <SerialNumberInput />
      </div>

      <div className={styles.container}>
        <DataProvider />
        <DirectConnection />
      </div>
      <MeterRequest />
    </div>
  );
};
