import React from 'react';
import { Input } from '@GDM/forms';
import { Controller, useFormContext } from 'react-hook-form';
import { MandateFiltersType } from './MandatesFilter';

export const MandateNameFilter: React.FC<{
  onChange: (params: MandateFiltersType) => void;
}> = ({ onChange }) => {
  const formContext = useFormContext<MandateFiltersType>();

  return (
    <Controller
      name="representative"
      control={formContext.control}
      render={({ field }) => (
        <Input
          value={field.value}
          placeholder="common.representative"
          full
          icon="Search"
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            onChange({ ...formContext.getValues(), representative: e.currentTarget.value });
            field.onChange(e.currentTarget.value);
          }}
        />
      )}
    />
  );
};
