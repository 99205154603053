import React from 'react';
import { NumberCell } from '@GDM/Table';
import { Tooltip } from '@GDM/Tooltip/Tooltip';
import useTranslation from '@hooks/useTranslation';
import { CellContext } from '@tanstack/react-table';
import { getNumberColor } from '@utils/getNumberColor';
import { MeterInvoice } from '@utils/types/meter-invoice';

export const LoadcurveCell: React.FC<CellContext<MeterInvoice, unknown>> = ({ row }) => {
  const { t } = useTranslation();
  const value = row.original.volume_produced_at_neg_prices;

  const productionLoadCurve = (
    (row?.original?.volume_production_load_curve || 0) - (row.original.volume_produced_at_neg_prices || 0)
  )?.toLocaleString();
  const tooltip = `${t('monitoring.invoicing.prod_from_raw_load_curve')}:<br /> ${productionLoadCurve} kWh`;

  return (
    <Tooltip tooltip={tooltip}>
      <div>
        <NumberCell color={getNumberColor(value)} value={value} />
        {row.original.volume_produced_at_neg_prices && row.original?.volume_produced_at_neg_prices !== 0 ? (
          <div>
            <span style={{ fontWeight: 400 }}>{t('monitoring.invoicing.negative_price')} : </span>
            <NumberCell
              color={getNumberColor(row.original.volume_produced_at_neg_prices)}
              value={row.original.volume_produced_at_neg_prices}
            />
          </div>
        ) : null}

        {row.original.volume_clipped && row.original.volume_clipped !== 0 ? (
          <div>
            <span style={{ fontWeight: 400 }}>{t('monitoring.invoicing.clipping')} : </span>
            <NumberCell color={getNumberColor(row.original.volume_clipped)} value={row.original.volume_clipped} />
          </div>
        ) : null}
      </div>
    </Tooltip>
  );
};
