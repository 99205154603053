import axiosInstance from '@utils/axiosInstance';
import { v2_owner_path, v2_owners_path } from '@utils/routes';
import type { CountryCode } from '@utils/types/countries';
import type Installation from '@utils/types/installation';
import type MarketPlayer from '@utils/types/market_player';

export type MarketPlayerBody = Partial<MarketPlayer> & {
  country?: CountryCode;
  installation_uuid?: Installation['uuid'];
};

export const getOwners = async (options?: { withMandatesOwners?: boolean }) => {
  const { withMandatesOwners } = options || {};

  return (
    await axiosInstance(false).get<MarketPlayer[]>(
      v2_owners_path({
        with_mandates_owners: withMandatesOwners,
      }),
    )
  ).data;
};

export const getOwnerById = async (id?: MarketPlayer['id']) => {
  return (await axiosInstance(false).get<MarketPlayer>(v2_owner_path(id || ''))).data;
};

export const createOwner = async (body: MarketPlayerBody) => {
  return (await axiosInstance(false).post<MarketPlayer>(v2_owners_path(), body)).data;
};

export const updateOwner = async (id: MarketPlayer['id'], body: MarketPlayerBody) => {
  return (await axiosInstance(false).patch<MarketPlayer>(v2_owner_path(id), body)).data;
};

export const deleteOwner = async (id: MarketPlayer['id']) => {
  return axiosInstance(false).delete(v2_owner_path(id));
};
