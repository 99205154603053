import React from 'react';
import classnames from 'classnames';

type Props = {
  color?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'muted' | 'white';
  error?: string;
  className?: string;
  bold?: boolean;
};

const Text: React.FC<React.PropsWithChildren<Props>> = ({ color, error, children, className, bold }) => {
  const internalClassName = classnames(`text-${color}`, className, { 'font-weight-bold': bold });

  return <p className={internalClassName}>{error || children}</p>;
};

Text.defaultProps = { color: 'primary' };

export default Text;
