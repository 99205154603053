import React from 'react';
import useTranslation from '@hooks/useTranslation';
import { CellContext } from '@tanstack/react-table';
import { Unavailability } from '@utils/types/unavailability';

export const EventTypeCell: React.FC<React.PropsWithChildren<CellContext<Unavailability, unknown>>> = ({
  row: { original },
}) => {
  const { t } = useTranslation();
  const event_type = original.event_type;

  return <>{t(`monitoring.unavailabilities.${event_type}`)}</>;
};
