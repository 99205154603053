import React, { useMemo, useState } from 'react';
import { Spinner } from '@GDM/Spinner';
import { useInvoicesByIdentifier } from '@hooks/requests/meter-invoices/useInvoicesByIdentifier';
import { useDashboardMeter } from '@pages/DashboardMeter/useDashboardMeter';
import dayjs from 'dayjs';
import { AnnualCapChart } from './charts/AnnualCapChart';
import { CreditNoteChart } from './charts/CreditNoteChart';
import { contractCapsContext } from './contract-caps.context';
import styles from './contract-caps.module.scss';
import { ContractCapsContextType } from './contract-caps.types';
import { CapTypeSelector } from './header/CapTypeSelector';
import { DateSelector } from './header/DateSelector';

export const ContractCaps: React.FC = () => {
  const { meter } = useDashboardMeter();

  const [startDate, setStartDate] = useState<Date | null>(dayjs().startOf('month').subtract(25, 'month').toDate());
  const [endDate, setEndDate] = useState<Date | null>(dayjs().startOf('month').subtract(1, 'month').toDate());
  const [capType, setCapType] = useState<ContractCapsContextType['capType']>('annual_prod');

  const {
    data: invoices,
    loaded,
    loading,
  } = useInvoicesByIdentifier({
    identifier: meter?.name || '',
    source_type: 'meter',
    startDate,
    endDate,
  });

  const contractCapsContextValue = useMemo<ContractCapsContextType>(
    () => ({
      invoices,
      startDate,
      endDate,
      capType,
      setEndDate,
      setStartDate,
      setCapType,
    }),
    [capType, endDate, invoices, startDate],
  );

  return (
    <contractCapsContext.Provider value={contractCapsContextValue}>
      <div className={styles['filter-container']}>
        <div className={styles.datepicker}>
          <DateSelector />
        </div>

        <CapTypeSelector />

        {loading && <Spinner size="sm" />}
      </div>

      {loaded && capType === 'annual_prod' && <AnnualCapChart />}
      {loaded && capType === 'credit_note' && <CreditNoteChart />}
    </contractCapsContext.Provider>
  );
};
