import React, { useContext } from 'react';
import { Filters, FilterContainer } from '@GDM/Filters';
import { Toggle } from '@GDM/Toggle';
import { BookFilter } from './BookFilter';
import { unavailabilityFiltersContext } from './context';
import { DatesFilter } from './DatesFilter';
import { EnergyFilter } from './EnergyFilter';
import { EventFilter } from './EventFilter';
import { InstallationFilter } from './InstallationFilter';
import { StatusFilter } from './StatusFilter';

export const UnavailabilityFilters: React.FC = () => {
  const { form, chartVisible, toggleChart } = useContext(unavailabilityFiltersContext);

  if (!form) return null;

  return (
    <Filters form={form} className="p-0">
      <FilterContainer size="datepicker">
        <DatesFilter />
      </FilterContainer>
      <FilterContainer>
        <InstallationFilter />
      </FilterContainer>
      <FilterContainer>
        <BookFilter />
      </FilterContainer>
      <FilterContainer size="fit">
        <EnergyFilter />
      </FilterContainer>
      <FilterContainer>
        <EventFilter />
      </FilterContainer>
      <FilterContainer>
        <StatusFilter />
      </FilterContainer>
      <FilterContainer>
        <Toggle
          value={chartVisible}
          onChange={(e) => toggleChart?.(e ?? false)}
          icon="Zap"
          label="monitoring.unavailabilities.chart"
        />
      </FilterContainer>
    </Filters>
  );
};
