import React, { useContext } from 'react';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import { datePickerContext } from './date-picker.context';
import styles from './date-picker.module.scss';
import { PreDefinedPeriods } from './date-picker.types';
import { getPeriods, PERIOD_MAP } from './periods';

export const DatePickerContainer: React.FC<React.PropsWithChildren> = ({ children }) => {
  const {
    hasPreDefinedPeriods,
    periods,
    customPeriods,
    selectedPeriod,
    onChange,
    onClickPeriod,
    setSelectedPeriod,
    maxDate,
    minDate,
    selectsRange,
  } = useContext(datePickerContext);
  const { t } = useTranslation();

  const onSelectPeriod = (period: PreDefinedPeriods | string): void => {
    setSelectedPeriod?.(period);

    const dates = customPeriods?.find((p) => p?.name === period)?.dates || PERIOD_MAP.get(period as PreDefinedPeriods);

    if (!selectsRange && dates) {
      dates[0] = minDate ? (dates[0] >= minDate ? dates[0] : minDate) : dates[0];

      onChange?.(dates[0] as Date & [Date, Date]); // TODO: can be removed when we make ts strict
      onClickPeriod?.(period);

      return;
    }

    if (dates && selectsRange) {
      dates[0] = minDate ? (dates?.[0] >= minDate ? dates?.[0] : minDate) : dates?.[0];
      dates[1] = maxDate ? (dates?.[1] <= maxDate ? dates?.[1] : maxDate) : dates?.[1];

      onChange?.(dates);
      onClickPeriod?.(period);
    }
  };

  const periodList = getPeriods({ periods, customPeriods });

  return (
    <div className={classNames('react-datepicker', styles['calendar-wrapper'])}>
      {(hasPreDefinedPeriods || !!periods || !!customPeriods) && (
        <div className={styles['pre-defined-periods']}>
          <div className={styles['select-range-title']}>{t('common.select_range')}</div>
          <ul>
            {periodList.map((period) => (
              <li key={period}>
                <button
                  type="button"
                  data-cy={period}
                  onClick={() => onSelectPeriod(period)}
                  className={classNames(period === selectedPeriod && styles.selected)}
                >
                  {t(period)}
                </button>
              </li>
            ))}
            <li>
              <button
                type="button"
                onClick={() => setSelectedPeriod?.(PreDefinedPeriods.Custom)}
                className={classNames(selectedPeriod === 'custom' && styles.selected)}
              >
                {t(PreDefinedPeriods.Custom)}
              </button>
            </li>
          </ul>
        </div>
      )}
      <div className={styles['calendar-container']}>{children}</div>
    </div>
  );
};
