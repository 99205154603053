import React, { useState } from 'react';
import { Badge } from '@GDM/Badge';
import { Collapse } from '@GDM/Collapse';
import { Icon } from '@GDM/Icon';
import { Text } from '@GDM/Text';
import getContractTypeLabel from '@utils/contract/getLabel';
import { MeterInvoice } from '@utils/types/meter-invoice';
import classNames from 'classnames';
import styles from './email-modal.module.scss';
import { InstallationInfoExternalService } from './InstallationInfoExternalService';
import { InstallationInfoRecipientCc } from './InstallationInfoRecipientCc';

export const InstallationInfo: React.FC<{
  invoice: MeterInvoice;
  last: boolean;
  source?: 'cegedim' | 'edf_oa';
}> = ({ invoice, last, source }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contractTypeLabel = invoice.contract_type
    ? getContractTypeLabel(invoice.contract_type, invoice.installation_country)
    : '';

  return (
    <div
      key={invoice.uuid}
      className={classNames(styles['installation-info'], { 'mb-2': !last })}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="mb-1 d-flex align-items-center">
        <Text text={invoice.installation_name} className="fw:600" />

        <Badge className="ml-2" label={contractTypeLabel} />

        <Icon
          size={14}
          name="ChevronLeft"
          className={classNames('ml-auto', styles['chevron-icon'], isOpen && styles['open'])}
        />
      </div>

      <Collapse isOpen={isOpen} className="mb-3">
        {!source && <InstallationInfoRecipientCc invoice={invoice} />}
        {source && <InstallationInfoExternalService source={source} invoice={invoice} />}
      </Collapse>
    </div>
  );
};
