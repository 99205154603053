import React, { useMemo } from 'react';
import { AltContent } from '@GDM/AltContent';
import { NumberCell, Table, TableBody, TableHead } from '@GDM/Table';
import { useReactTable, getCoreRowModel, ColumnDef } from '@tanstack/react-table';

type TableProps = {
  dateList?: string[];
  dataPoints?: { name: string; data: [number, number][]; data1d: [string, number][] }[];
};

type Comparison = {
  date: string;
  [installationName: string]: number | string | null;
};

export const ComparisonTable: React.FC<TableProps> = ({ dateList, dataPoints }) => {
  const data: Comparison[] =
    dateList?.map((date) => {
      const base: Comparison = { date };

      dataPoints?.forEach((point) => {
        const normalizedDailyProduction = point.data1d.find((dataPoint) => dataPoint[0] === date)?.[1];

        base[point.name] = normalizedDailyProduction ? normalizedDailyProduction * 100 : null;
      });

      return base;
    }) || [];

  const columns = useMemo<ColumnDef<Comparison>[]>(
    () =>
      [
        { header: 'common.date', accessorKey: 'date' },
        ...(dataPoints
          ? dataPoints
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(
                (point) =>
                  ({
                    header: point.name,
                    accessorKey: point.name,
                    cell: ({ getValue }) => (
                      <NumberCell
                        value={getValue<number>()}
                        unit="percentage"
                        numberOptions={{ maximumFractionDigits: 1, minimumFractionDigits: 1 }}
                        color={
                          getValue<number>() / 100 > 5 / 24
                            ? 'green'
                            : getValue<number>() / 100 > 3 / 24
                            ? 'orange'
                            : 'red'
                        }
                      />
                    ),
                  } satisfies ColumnDef<Comparison>),
              )
          : []),
      ] satisfies ColumnDef<Comparison>[],
    [dataPoints],
  );

  const table = useReactTable({ columns, enableSorting: false, data, getCoreRowModel: getCoreRowModel() });

  if (!data?.length) return <AltContent />;

  return (
    <>
      <Table data-cy="comparison-table">
        <TableHead table={table} small />
        <TableBody table={table} />
      </Table>
    </>
  );
};
