import React from 'react';
import { Select } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { Controller } from 'react-hook-form';
import { ReactiveForm } from '../types';
import { useReactiveForm } from '../useReactiveForm';

export const TypePicker: React.FC = () => {
  const { control, resetInstructions } = useReactiveForm();

  const typeOptions: Option<NonNullable<ReactiveForm['type']> | null>[] = [
    { value: 'tan_phi', label: 'common.tg_phi' },
    { value: 'qfu', label: 'Q=f(U)' },
  ];

  return (
    <Controller
      name="type"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
        <Select
          {...field}
          label="common.type"
          options={typeOptions}
          selectedOption={field.value}
          classNamePrefix="reactive-type"
          onChange={async (option) => {
            field.onChange(option?.value);
            resetInstructions();
          }}
        />
      )}
    />
  );
};
