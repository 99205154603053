import React from 'react';
import { Input } from '@GDM/forms';
import { Controller } from 'react-hook-form';
import styles from '../subscription-form.module.scss';
import { useSubscriptionForm } from '../useSubscriptionForm';

export const UsageLimits: React.FC = () => {
  const { control, readonly } = useSubscriptionForm();

  return (
    <div className={styles.section}>
      <Controller
        control={control}
        name="max_nb_meters"
        render={({ field, fieldState }) => (
          <Input
            {...field}
            className={styles.input}
            value={field.value ?? ''}
            label="subscriptions.max_nb_meters"
            tooltip="subscriptions.info.max_nb_meters"
            size="lg"
            hasError={!!fieldState.error}
            type="number"
            readOnly={readonly}
            full
          />
        )}
      />
      <Controller
        control={control}
        name="max_nb_meters_2"
        render={({ field, fieldState }) => (
          <Input
            {...field}
            className={styles.input}
            value={field.value ?? ''}
            label="subscriptions.max_nb_meters_2"
            tooltip="subscriptions.info.max_nb_meters_2"
            size="lg"
            hasError={!!fieldState.error}
            type="number"
            readOnly={readonly}
            full
          />
        )}
      />
      <Controller
        control={control}
        name="max_nb_forecast_meters"
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <Input
            {...field}
            className={styles.input}
            value={field.value ?? ''}
            label="subscriptions.max_nb_forecast_meters"
            size="lg"
            hasError={!!fieldState.error}
            type="number"
            readOnly={readonly}
            full
          />
        )}
      />
      <Controller
        control={control}
        name="max_mw_power"
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <Input
            {...field}
            className={styles.input}
            value={field.value ?? ''}
            label="subscriptions.max_mw_power"
            tooltip="subscriptions.info.max_mw_power"
            size="lg"
            hasError={!!fieldState.error}
            type="number"
            suffix="MW"
            readOnly={readonly}
            full
          />
        )}
      />
    </div>
  );
};
