import React, { useState } from 'react';
import { DefinitionModalBody, DefinitionModalFooter } from '@GDM/DefinitionList';
import { Input } from '@GDM/forms';
import { useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import { getErrorMessage } from '@utils/parsers';
import { change_pmax_v2_dashboard_meter_index_path } from '@utils/routes';
import { Meter } from '@utils/types/meter';
import { useMeterInfo } from '../useMeterInfo';

export const PowerModal: React.FC<{ meter?: Meter }> = ({ meter }) => {
  const { nominal_power, setNominalPower } = useMeterInfo();
  const [localNominalPower, setLocalNominalPower] = useState(nominal_power);
  const { t } = useTranslation();

  const {
    loading: isLoading,
    execute: updatePower,
    error,
  } = useRequest(
    change_pmax_v2_dashboard_meter_index_path({
      meter: meter?.name,
      pmax: localNominalPower,
    }),
    'GET',
    true,
    undefined,
    false,
  );

  const saveUpdate = async () => {
    try {
      const response = await updatePower?.();
      setNominalPower?.(localNominalPower);

      if (typeof response === 'string') return response;
    } catch (e) {
      return getErrorMessage(e);
    }
  };

  return (
    <>
      <DefinitionModalBody>
        <Input
          suffix="kW"
          value={localNominalPower}
          type="number"
          data-cy="nominal-power-input"
          step="1"
          placeholder="1000"
          onChange={(e) => setLocalNominalPower(e.target.value)}
          full
        />
      </DefinitionModalBody>
      <DefinitionModalFooter
        save={saveUpdate}
        isLoading={isLoading}
        error={error?.field ? t(`errors.installation.${error?.code}`, { field: error?.field }) : error?.code || null}
      />
    </>
  );
};
