import { nextYear } from '@utils/date';
import { ContractSubPeriodForm, ContractType, PriceType } from '@utils/types/contract';
import { v4 as uuid } from 'uuid';

export const makeSubPeriod = (
  contract_type: ContractType,
  {
    dates,
    phase_type = null,
    period_type,
    price_rule,
    price_model_attributes,
    market_future,
  }: Partial<ContractSubPeriodForm>,
): ContractSubPeriodForm => {
  let price_type: PriceType | null = contract_type === 'ContractCr' ? 'm0' : 'fixed';
  if (phase_type === 'testing') price_type = 'imbalance_pos';
  if (phase_type === 'intermediate') price_type = 'spot';

  return {
    id: uuid(),
    dates: [dates?.[0] || new Date(), dates?.[1] || nextYear(new Date())],
    price_type,
    phase_type,
    period_type: period_type || 'full',
    price_model_attributes: price_model_attributes ?? {},
    price_rule: price_rule ?? null,
    market_future,
    market_future_id: market_future?.id,
    display_price: null,
    multiplier: null,
    price_model: null,
    price_unit: null,
  };
};
