import React from 'react';
import { useUser } from '@context/User.context';
import { Currency } from '@utils/types/currency';
import classnames from 'classnames';
import styles from './currency-cell.module.scss';

type Props = {
  compact?: boolean;
  showUnit?: boolean;
  value: number | null;
  color?: 'red' | 'green' | 'default';
  currency: Currency;
};

export const CurrencyCell: React.FC<Props> = ({ compact, showUnit, value, color, currency }) => {
  const { locale } = useUser();
  const className = classnames(`text-${color}`, styles['number-cell']);
  const options: Intl.NumberFormatOptions = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    ...(showUnit ? { style: 'currency', currency } : null),
    ...(compact ? { notation: 'compact' } : null),
  };

  return (
    <div className={className}>
      {(value !== null && !isNaN(value) && new Intl.NumberFormat(locale, options).format(value)) || '-'}
    </div>
  );
};
