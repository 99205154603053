import React from 'react';
import { Input } from '@GDM/forms';
import { Col } from '@GDM/layout';

const SignatoryEmailInput: React.FC<{
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
}> = ({ value, onChange, disabled }) => {
  return (
    <Col md={6}>
      <Input
        full
        label="admin.installations.signatory_email"
        value={value}
        onChange={onChange}
        disabled={disabled}
        data-cy="signatory-email-input"
      />
    </Col>
  );
};

export default SignatoryEmailInput;
