import React, { useCallback } from 'react';
import { Restricted } from '@components/Restricted';
import { useUnavailabilitiesContext } from '@context/UnavailabilitiesContext';
import { Button, DropDownButton } from '@GDM/Button';
import { useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import { formatSystemDateTime } from '@utils/formatters/formatSystemDateTime';
import { export_csv_v2_unavailabilities_path } from '@utils/routes';
import dayjs from 'dayjs';
import { useFormContext } from 'react-hook-form';
import { useCreateModalContext } from './CreateModal/context';
import { CreateModalForm } from './CreateModal/types';
import { useUnavailabilityFiltersContext } from './Filters/context';
import styles from './unavailabilities.module.scss';
import { fetchCsv } from './utils/fetchCsv';

export const Actions: React.FC = () => {
  const csvRequest = useRequest(export_csv_v2_unavailabilities_path(), fetchCsv, true);
  const { setSelectedUnavailability } = useUnavailabilitiesContext();
  const { form, filteredUnavailabilities } = useUnavailabilityFiltersContext();
  const { reset } = useFormContext<CreateModalForm>();
  const { toggle } = useCreateModalContext();
  const { t } = useTranslation();

  const startDate = form?.watch('startDate');
  const endDate = form?.watch('endDate');

  const onAddClick = useCallback(() => {
    setSelectedUnavailability(null);
    reset();
    toggle?.();
  }, [setSelectedUnavailability, reset, toggle]);

  const handleCsvDownload = (range: boolean) => {
    return () => {
      csvRequest.execute?.({
        params: {
          start_date: formatSystemDateTime(dayjs(startDate).startOf('day').toDate()),
          end_date: formatSystemDateTime(dayjs(endDate).add(1, 'day').startOf('day').toDate()),
          installations_name: Array.from(new Set(filteredUnavailabilities?.map((u) => u.installation.name) || [])),
          range,
        },
      });
    };
  };

  return (
    <div className={styles.actions}>
      <Restricted permissions={['display:edit_monitoring']}>
        <DropDownButton
          icon="Download"
          size="xxs"
          variant="primary-2"
          label={t('common.download')}
          isLoading={csvRequest.loading}
        >
          <Button variant="sub-button" text="common.csv_range" onClick={handleCsvDownload(true)} />
          <Button variant="sub-button" text="common.csv_overview" onClick={handleCsvDownload(false)} />
        </DropDownButton>
        <Button
          variant="primary-2"
          size="xxs"
          data-cy="add-new-unavailability"
          onClick={onAddClick}
          icon="Plus"
          className="ml-2"
        >
          {t('common.add_new')}
        </Button>
      </Restricted>
    </div>
  );
};
