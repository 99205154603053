import React, { useState } from 'react';
import { useMemo } from 'react';
import { Table, TableActions, TableBody, TableHead, TablePageSizeSelect, TablePagination } from '@GDM/Table';
import {
  ColumnDef,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Subscription } from '@utils/types/subscription';
import { SubscriptionColumns } from './Columns';

export const SubscriptionsTable: React.FC<{ data: Subscription[] }> = ({ data }) => {
  const columns: ColumnDef<Subscription>[] = useMemo(() => SubscriptionColumns(), []);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const table = useReactTable({
    data,
    columns,
    state: { pagination },
    sortDescFirst: false,
    initialState: { sorting: [{ id: 'start_date', desc: false }] },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const pageSize = pagination.pageSize;

  return (
    <>
      <Table hasActions>
        <TableHead table={table} />
        <TableBody table={table} data-cy="subscriptions-table" />
      </Table>

      {data.length > pageSize && (
        <TableActions className="p-0">
          <TablePageSizeSelect pageSize={pageSize} setPageSize={table.setPageSize} totalEntries={data.length} />
          <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />
        </TableActions>
      )}
    </>
  );
};
