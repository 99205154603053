import React from 'react';
import { useProgressStepper } from '../context/useProgressStepper';
import { Step } from '../Step';

export const Steps: React.FC = () => {
  const { steps, singleStep } = useProgressStepper();

  if (!steps) return null;

  if (singleStep) return <Step stepId={singleStep} step={steps[singleStep]} />;

  return Object.entries(steps).map(([key, step]) => <Step stepId={key} step={step} key={key} />);
};
