import React, { AnchorHTMLAttributes, HTMLAttributes } from 'react';
import { Icon } from '@GDM/Icon';
import { CellContext } from '@tanstack/react-table';
import { Contract } from '@utils/types/contract';
import styles from './counterpart-cell.module.scss';

export const CounterpartCell: React.FC<CellContext<Contract, unknown>> = ({ row: { original } }) => {
  const href = original.buyer?.id ? `/v2/market_players/${encodeURIComponent(original.buyer.id)}` : null;
  const Element = href ? 'a' : 'div';

  const elementProps: HTMLAttributes<HTMLDivElement> & AnchorHTMLAttributes<HTMLAnchorElement> = {};
  if (href) elementProps.href = href;

  return (
    <Element className={styles['counterpart-cell']} {...elementProps} data-cy="buyer-link">
      <Icon name="ArrowRightCircle" size={14} /> {original?.buyer?.short_name}
    </Element>
  );
};
