import React, { useContext } from 'react';
import { Button } from '@GDM/Button';
import { ModalFooter } from '@GDM/Modal';
import { Spinner } from '@GDM/Spinner';
import { Text } from '@GDM/Text';
import useTranslation from '@hooks/useTranslation';
import styles from '../definition-list.module.scss';
import { definitionContext } from '../definition.context';

export const DefinitionModalFooter: React.FC<{
  closeOnSave?: boolean;
  error?: unknown;
  isLoading?: boolean;
  isDisabled?: boolean;
  save?: () => void | Promise<void | string>;
}> = ({ closeOnSave = true, error, isLoading, isDisabled, save }) => {
  const { t } = useTranslation();
  const { toggle } = useContext(definitionContext);

  /** If the save function returns a string, it will display it as an error. */
  const submitAction = async () => {
    const request = await save?.();
    if (closeOnSave && !error && !request && toggle) toggle?.();
  };

  return (
    <ModalFooter>
      {!!error && (
        <Text
          type="danger"
          size="sm"
          icon="AlertCircle"
          text={typeof error === 'string' ? error : null}
          className={styles['error-message']}
        />
      )}
      <div>
        <Button size="sm" type="button" variant="link" onClick={toggle} className="mr-2">
          {t('common.cancel')}
        </Button>
        <Button size="sm" variant="primary-2" type="submit" disabled={isDisabled} onClick={submitAction}>
          {t('common.save')} {!!isLoading && <Spinner size="sm" className="ml-1" />}
        </Button>
      </div>
    </ModalFooter>
  );
};
