import React, { useMemo, useState } from 'react';
import { useUser } from '@context/User.context';
import { Table, TableActions, TableBody, TableHead, TablePageSizeSelect, TablePagination } from '@GDM/Table';
import { getPaginationRowModel, getSortedRowModel, useReactTable, getCoreRowModel } from '@tanstack/react-table';
import absoluteSortTable from '@utils/absoluteSortTable';
import { MeterInvoice } from '@utils/types/meter-invoice';
import { TabId } from '../invoices.types';
import { getColumns } from './getColumns';

export const MonitoringInvoicesTable: React.FC<{
  invoicings: MeterInvoice[] | null;
  loading: boolean;
  onOpenModal?: (invoicing: MeterInvoice) => void;
  inModal?: boolean;
  type?: TabId;
}> = ({ invoicings, loading, onOpenModal, inModal, type }) => {
  const data = invoicings || [];
  const { locale } = useUser();
  const columns = useMemo(
    () => (type ? getColumns(type, { inModal, locale, onOpenModal }) : []),
    [type, inModal, locale, onOpenModal],
  );

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 25 });

  const table = useReactTable({
    columns,
    sortingFns: { absoluteSortTable },
    data,
    state: { pagination },
    sortDescFirst: false,
    onPaginationChange: setPagination,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  const pageSize = pagination.pageSize;

  return (
    <>
      <Table>
        <TableHead table={table} />
        <TableBody table={table} isLoading={loading} />
      </Table>

      <TableActions>
        <TablePageSizeSelect pageSize={pageSize} setPageSize={table.setPageSize} totalEntries={data.length} />
        {data.length > pageSize && <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />}
      </TableActions>
    </>
  );
};
