import React, { useContext, useEffect } from 'react';
import { installationsContext } from '@context/installations.context';
import { Button } from '@GDM/Button';
import { DatePicker } from '@GDM/DatePicker';
import { Select } from '@GDM/forms';
import { Text } from '@GDM/Text';
import { useRequest } from '@hooks/useRequest';
import { formatSystemDate } from '@utils/formatters';
import {
  find_closest_meters_v2_comparaison_solaire_index_path,
  get_meters_data_v2_comparaison_solaire_index_path,
} from '@utils/routes';
import { Option } from '@utils/types/common-types';
import { useForm } from 'react-hook-form';
import styles from '../comparison.module.scss';
import { ComparisonData } from '../comparison.types';
import { comparisonContext } from '../context';

type ComparisonFormValues = {
  installations: string[];
  startDate: Date | null;
  endDate: Date | null;
};

const formDefaultValues: ComparisonFormValues = {
  installations: [],
  startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
  endDate: new Date(),
};

export const ComparisonForm: React.FC = () => {
  const { watch, setValue, getValues } = useForm<ComparisonFormValues>({ defaultValues: formDefaultValues });
  const [startDate, endDate, installations] = watch(['startDate', 'endDate', 'installations']);
  const { installations: installationData, isLoading } = useContext(installationsContext);
  const { setComparisonData } = useContext(comparisonContext);

  const options = installationData?.map((m) => ({ label: m.name, value: m.name })) || [];
  const selectedOptions = options?.filter(({ value }) => installations.includes(value)) || [];

  const comparisonParamsString = () =>
    new URLSearchParams({
      meters: getValues('installations').join(),
      first_date: formatSystemDate(startDate),
      last_date: formatSystemDate(endDate),
    }).toString();

  const {
    data: closestMeters,
    loading: closestMetersLoading,
    loaded: closestMetersLoaded,
    error: closestMetersError,
    execute: requestClosestMeters,
  } = useRequest<{
    meters: string[];
  }>(`${find_closest_meters_v2_comparaison_solaire_index_path()}?meters=${getValues('installations')}`, 'GET', true);

  const {
    data: comparisonData,
    loading: comparisonDataLoading,
    error: comparisonError,
    execute: requestComparisonData,
  } = useRequest<ComparisonData>(
    `${get_meters_data_v2_comparaison_solaire_index_path()}?${comparisonParamsString()}`,
    'GET',
    true,
  );

  useEffect(() => {
    setComparisonData?.(comparisonData);
  }, [comparisonData, setComparisonData]);

  useEffect(() => {
    if (closestMeters?.meters.length && closestMetersLoaded) {
      setValue('installations', Array.from(new Set([...getValues('installations'), ...closestMeters.meters])));
    }
  }, [closestMeters, setValue, getValues, closestMetersLoaded]);

  const handleOptionChange = (options: Option[] | null): void => {
    const installations = options?.map(({ value }) => value);
    setValue('installations', installations ?? []);
  };

  const handleDatesChange = (dateRange: [Date | null, Date | null]): void => {
    if (Array.isArray(dateRange)) {
      const [startDate, endDate] = dateRange;
      setValue('startDate', startDate);
      setValue('endDate', endDate);
    }
  };

  const isDisabled = selectedOptions.length === 0 || !startDate || !endDate || comparisonDataLoading;

  return (
    <>
      <div className={styles.container}>
        <div className={styles['date-picker']}>
          <DatePicker
            onChange={handleDatesChange}
            maxDate={new Date()}
            startDate={startDate}
            endDate={endDate}
            selectsRange={true}
            data-cy="comparison-dates"
          />
        </div>

        <Select
          className={styles.installations}
          options={options}
          isLoading={isLoading}
          isDisabled={isLoading}
          selectedOptions={selectedOptions.map(({ value }) => value)}
          onChange={handleOptionChange}
          label="common.installations"
          classNamePrefix="comparison-installations"
          hasSelectAll
          isMulti
        />

        <Button
          className={styles.button}
          size="xs"
          onClick={() => requestComparisonData?.()}
          variant="primary-2"
          disabled={isDisabled}
          isLoading={comparisonDataLoading}
          text="common.show"
          data-cy="submit-comparison"
        />
        <Button
          size="xs"
          variant="link"
          icon={closestMetersError ? 'AlertCircle' : undefined}
          onClick={() => requestClosestMeters?.()}
          disabled={selectedOptions.length === 0}
          isLoading={closestMetersLoading}
          text="comparison.find_closest_meters"
          data-cy="closest-meters"
        />
      </div>
      {(closestMetersError || comparisonError) && (
        <Text type="danger" text={(closestMetersError || comparisonError)?.code} icon="AlertCircle" />
      )}
    </>
  );
};
