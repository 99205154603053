import React from 'react';
import { Input, Select } from '@GDM/forms';
import { useRequest } from '@hooks/useRequest';
import { all_v2_users_path } from '@utils/routes';
import { Option } from '@utils/types/common-types';
import { Subscription } from '@utils/types/subscription';
import { Controller } from 'react-hook-form';
import styles from '../subscription-form.module.scss';
import { useSubscriptionForm } from '../useSubscriptionForm';

export const GeneralInfo: React.FC = () => {
  const { control, readonly, watch } = useSubscriptionForm();
  const { data: users, loading } = useRequest<{ id: string; email: string }[]>(all_v2_users_path(), 'GET');

  const productOptions: Option<Subscription['product']>[] = [
    { value: 'monitoring', label: 'Monitoring' },
    { value: 'salesfocus', label: 'Salesfocus' },
    { value: 'monitoring_salesfocus', label: 'Monitoring + Salesfocus' },
    { value: 'pricing', label: 'Pricing' },
    { value: 'generation_forecasting', label: 'Generation Forecasting' },
    { value: 'load_forecasting', label: 'Load Forecasting' },
    { value: 'third_party_access', label: 'Third Party Access' },
    { value: 'other', label: 'Other' },
  ];

  const userOptions: Option[] =
    users?.map((user) => ({
      value: user.id,
      label: user.email,
    })) ?? [];

  return (
    <>
      {!watch('company_billing_detail.user.id') && (
        <div className={styles.section}>
          <Controller
            control={control}
            name="company_billing_detail.user_id"
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <Select
                isLoading={loading}
                options={userOptions}
                className={styles.input}
                selectedOption={field.value}
                label="common.username"
                onChange={(option) => field.onChange(option?.value)}
                size="lg"
                hasError={!!fieldState.error}
                full
              />
            )}
          />
        </div>
      )}
      <div className={styles.section}>
        <Controller
          control={control}
          name="product"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <Select
              options={productOptions}
              className={styles.input}
              selectedOption={field.value}
              label="subscriptions.module_name"
              onChange={(option) => field.onChange(option?.value)}
              size="lg"
              hasError={!!fieldState.error}
              readOnly={readonly}
              full
            />
          )}
        />
        <Controller
          control={control}
          name="deal_id"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              className={styles.input}
              value={field.value ?? ''}
              label="subscriptions.deal_id"
              size="lg"
              hasError={!!fieldState.error}
              readOnly={readonly}
              full
            />
          )}
        />
      </div>
    </>
  );
};
