import React from 'react';
import { Toggle } from '@GDM/Toggle';
import { setQueryParams } from '@utils/queryParams';
import { usePortfolio } from '../usePortfolio';

export const MapToggle: React.FC = () => {
  const { dataType, displayMode, loading, setDisplayMode } = usePortfolio();

  const handleMapToggle = (value?: boolean) => {
    setDisplayMode?.(value ? 'map' : 'table');
    setQueryParams({ show_map: value });
  };

  return (
    <Toggle
      disabled={loading || dataType === 'books'}
      label="common.map"
      onChange={handleMapToggle}
      value={displayMode === 'map'}
      tooltip={dataType === 'books' ? 'monitoring.portfolio.maps_books_not_available' : null}
      icon="Map"
      data-cy="toggle-map"
    />
  );
};
