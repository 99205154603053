import React, { MouseEventHandler, useMemo } from 'react';
import { Restricted } from '@components/Restricted';
import { Button } from '@GDM/Button';
import { Table, TableBody, TableHead, NoWrapHeadCell, NumberCell } from '@GDM/Table';
import { getSortedRowModel, useReactTable, getCoreRowModel, ColumnDef } from '@tanstack/react-table';
import Panel from '@utils/types/panel';
import { TrackerCell } from './TrackerCell';

type PanelTableProps = {
  panels: Panel[];
  handleEditClick: (panel: Panel) => MouseEventHandler;
  openDeleteModal: (panel: Panel) => MouseEventHandler;
};

export const PanelTable: React.FC<PanelTableProps> = ({ panels, handleEditClick, openDeleteModal }) => {
  const sumRatio = panels.reduce((acc, curr) => acc + parseFloat(curr.power), 0);

  const columns = useMemo<ColumnDef<Panel>[]>(
    () => [
      {
        header: () => <NoWrapHeadCell label="monitoring.panels.tilt" tooltip="(°)" />,
        accessorKey: 'tilt',
        cell: ({ row: { original } }) =>
          original.tracker ? <TrackerCell /> : <NumberCell value={original.tilt} unit="degree" />,
      },
      {
        header: () => <NoWrapHeadCell label="monitoring.panels.azimut" tooltip="(°)" />,
        accessorKey: 'corrected_azimuth',
        cell: ({ row: { original } }) =>
          original.tracker ? <TrackerCell /> : <NumberCell value={original.corrected_azimuth} unit="degree" />,
      },
      {
        header: () => <NoWrapHeadCell label="common.power" tooltip="(%)" />,
        accessorKey: 'power',
        cell: ({ getValue }) => (
          <NumberCell
            value={(+getValue<string>() * 100) / sumRatio}
            unit="percentage"
            numberOptions={{ maximumFractionDigits: 1 }}
          />
        ),
      },
      {
        id: 'actions',
        disableSortBy: true,
        cell: ({ row: { original } }) => {
          return (
            <Restricted permissions={['display:edit_monitoring']}>
              <div className="ta:right">
                <Button icon="Edit3" className="ml-1" onClick={handleEditClick(original)} floating />
                <Button icon="Trash2" variant="secondary" onClick={openDeleteModal(original)} floating />
              </div>
            </Restricted>
          );
        },
      },
    ],
    [handleEditClick, openDeleteModal, sumRatio],
  );

  const table = useReactTable({
    columns,
    data: panels,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table hasActions>
      <TableHead table={table} />
      <TableBody table={table} />
    </Table>
  );
};
