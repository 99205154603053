import React from 'react';
import { Icon } from '@GDM/Icon';
import { iconDictionary } from '@utils/constants';

export const BookNameCell: React.FC<{ name: unknown; isPricing?: boolean }> = ({ name, isPricing }) => {
  if (typeof name !== 'string') return null;

  const href = isPricing
    ? `/v2/ppa_projects/${encodeURIComponent(name)}`
    : `/v2/dashboard_meter/?book=${encodeURIComponent(name)}`;

  return (
    <div>
      <a href={href}>
        <span className="type-icon mr-2">
          <Icon name={iconDictionary.book} size={16} />
        </span>
        <span>{name}</span>
      </a>
    </div>
  );
};
