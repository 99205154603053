import React from 'react';
import { Badge, BadgeProps } from '@GDM/Badge';
import { Simulation } from '@utils/types/simulation';

type StatusMap = { [status in Simulation['status']]: { label: string; variant: BadgeProps['variant'] } };

const SimulationStatusCell: React.FC<{
  value?: Simulation['status'] | null;
}> = ({ value }) => {
  const status: StatusMap = {
    Processing: { variant: 'default', label: 'common.status.in_progress' },
    'En attente': { variant: 'default', label: 'common.status.in_progress' },
    Terminée: { variant: 'primary-1', label: 'common.status.done2' },
    Échec: { variant: 'danger', label: 'common.status.failed' },
  };

  if (!value || !status[value]) return null;

  const { variant, label } = status[value];

  return <Badge variant={variant} label={label} />;
};

export default SimulationStatusCell;
