import React, { createContext, Dispatch, SetStateAction, useCallback, useContext } from 'react';
import { MeterInvoice } from '@utils/types/meter-invoice';

type SelectInvoicesContext = {
  selectedInvoicesUuids: string[];
  setSelectedInvoicesUuids: Dispatch<SetStateAction<string[]>>;
  addInvoice: (invoice: MeterInvoice) => void;
  unselectInvoice: (invoice: MeterInvoice) => void;
  selectMultipleInvoices: (invoices: MeterInvoice[]) => void;
  unselectAllInvoices: () => void;
};

/**
 * Context for handling invoices select.
 */
export const selectInvoicesContext = createContext<SelectInvoicesContext>({
  selectedInvoicesUuids: [],
  setSelectedInvoicesUuids: () => {},
  addInvoice: () => {},
  unselectInvoice: () => {},
  selectMultipleInvoices: () => {},
  unselectAllInvoices: () => {},
});

/**
 * Hook to consume the select invoice context.
 */
export const useSelectedInvoicesContext = (): SelectInvoicesContext => {
  const context = useContext(selectInvoicesContext);

  if (!context) {
    throw new Error('useSelectedInvoicesContext must be used within a SelectInvoicesContext');
  }

  return context;
};

/**
 * Hook To handle select invoices state
 */
const useSelectInvoices = () => {
  const [selectedInvoicesUuids, setSelectedInvoicesUuids] = React.useState<string[]>([]);

  const addInvoice = useCallback((d: MeterInvoice) => setSelectedInvoicesUuids((s) => [...s, d.uuid]), []);

  const unselectInvoice = useCallback((d: MeterInvoice) => {
    setSelectedInvoicesUuids((uuids) => {
      if (uuids.includes(d.uuid)) {
        return uuids.filter((uuid) => uuid !== d.uuid);
      }

      return uuids;
    });
  }, []);

  const selectMultipleInvoices = useCallback((invoices: MeterInvoice[]) => {
    setSelectedInvoicesUuids(invoices.map((d: MeterInvoice) => d.uuid));
  }, []);

  const unselectAllInvoices = useCallback(() => {
    setSelectedInvoicesUuids([]);
  }, []);

  return {
    selectedInvoicesUuids,
    setSelectedInvoicesUuids,
    addInvoice,
    unselectInvoice,
    selectMultipleInvoices,
    unselectAllInvoices,
  };
};

/**
 * Component to wrap the context.
 */
export const SelectInvoicesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const selectInvoices = useSelectInvoices();

  return <selectInvoicesContext.Provider value={selectInvoices}>{children}</selectInvoicesContext.Provider>;
};
