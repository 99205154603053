import React, { useMemo } from 'react';
import booksContext from '@context/BooksContext';
import useBooks from '@hooks/requests/useBooks';

export const BookProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { data: books, isPending } = useBooks();

  const contextValue = useMemo(() => ({ books: books ?? [], isLoading: isPending }), [books, isPending]);

  return <booksContext.Provider value={contextValue}>{children}</booksContext.Provider>;
};
