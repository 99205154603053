import React from 'react';
import { InvoiceTableDataWrapper } from '@components/invoices/InvoiceTableDataWrapper';
import { useInvoicesByIdentifier } from '@hooks/requests/meter-invoices/useInvoicesByIdentifier';
import MarketPlayer from '@utils/types/market_player';
import dayjs from 'dayjs';

export const InvoicesTab: React.FC<{ marketPlayer: MarketPlayer }> = ({ marketPlayer }) => {
  const { data: invoices, loading } = useInvoicesByIdentifier({
    identifier: marketPlayer.id,
    source_type: 'market_player',
    endDate: dayjs().startOf('day').toDate(),
  });

  return <InvoiceTableDataWrapper isLoading={loading} invoices={invoices} sortByDefault="end_date" isLight />;
};
