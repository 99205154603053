import React from 'react';
import { DatePicker } from '@GDM/DatePicker';
import dayjs from 'dayjs';
import { Controller, useFormContext } from 'react-hook-form';
import styles from '../../new-simulation.module.scss';
import { useFieldRules } from '../../utils/getFieldRules';
import { SimulationForm } from '../../utils/simulations.types';

export const EndDateInput: React.FC = () => {
  const { control, watch } = useFormContext<SimulationForm>();
  const { fieldRules } = useFieldRules();
  const rules = fieldRules('end_date');
  const startDate = watch('start_date');

  return (
    <Controller
      control={control}
      name="end_date"
      rules={rules}
      render={({ field, fieldState }) => (
        <div className={styles['datepicker-container']}>
          <DatePicker
            label="common.end_date"
            size="lg"
            selected={field.value}
            onChange={(endDate) => field.onChange(dayjs(endDate).startOf('d').toDate())}
            errorMessage={fieldState?.error?.message || null}
            maxDate={dayjs().toDate()}
            minDate={startDate}
          />
        </div>
      )}
    />
  );
};
