import React from 'react';
import { Select } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { CapType } from '../contract-caps.types';
import { useContractCaps } from '../useContractCaps';

export const CapTypeSelector: React.FC = () => {
  const { setCapType, capType } = useContractCaps();

  const options: Option<CapType>[] = [
    { label: 'common.annual_cap', value: 'annual_prod' },
    { label: 'common.credit_note_cap', value: 'credit_note' },
  ];

  return (
    <Select
      options={options}
      selectedOption={capType}
      onChange={(option) => setCapType?.(option?.value)}
      isSearchable
    />
  );
};
