import React, { useEffect, useMemo } from 'react';
import { InstallationsContext, installationsContext } from '@context/installations.context';
import { Button } from '@GDM/Button';
import { FormErrors } from '@GDM/FormErrors';
import { ERRORS_TO_REMAP } from '@GDM/FormErrors/errors-to-remap';
import { ProgressStepper } from '@GDM/forms';
import { useInstallations } from '@hooks/requests/useInstallations';
import useTranslation from '@hooks/useTranslation';
import Page from '@pages/Page/Page';
import { v2_ppa_simulations_path } from '@utils/routes';
import { User } from '@utils/types/user';
import classNames from 'classnames';
import { FieldPath, FormProvider, useForm } from 'react-hook-form';
import { SIMULATION_FORM_DEFAULT_VALUES } from './config/default-values';
import { selectedInstallationContext } from './context/selectedInstallation.context';
import { useSimulationRequest } from './hooks/useSimulationRequest';
import styles from './new-simulation.module.scss';
import { serializeSimulationBody } from './utils/serializeSimulationBody';
import type { SimulationForm as SimulationFormType } from './utils/simulations.types';
import { useBuilder } from './utils/useBuilder';

export const NewSimulation: React.FC<{
  user: User;
}> = ({ user }) => {
  const { data, loading } = useInstallations({ type: 'all' });
  const { t } = useTranslation(user.locale);
  const form = useForm<SimulationFormType>({
    defaultValues: SIMULATION_FORM_DEFAULT_VALUES,
    reValidateMode: 'onChange',
    mode: 'onChange',
  });
  const [installations, setInstallations] = React.useState(data || []);
  const { steps, selectedInstallation } = useBuilder(form, installations);
  const installationsContextValue: InstallationsContext = useMemo(
    () => ({ installations, isLoading: loading }),
    [installations, loading],
  );
  const selectedInstallationContextValue = useMemo(() => ({ selectedInstallation }), [selectedInstallation]);
  const request = useSimulationRequest();

  const handleSubmit = (data: SimulationFormType) => {
    request.execute?.(serializeSimulationBody(data));
  };

  useEffect(() => {
    if (request.loaded && request.data) {
      window.location.href = v2_ppa_simulations_path();
    }

    if (request.errors) {
      request.errors.forEach((error) => {
        const errorI18nString = error?.code ? ERRORS_TO_REMAP[error?.code] : error.code;

        const fieldName =
          error.field === 'name' ? 'installation_attributes.name' : (error.field as FieldPath<SimulationFormType>);

        form.setError(fieldName, {
          type: 'manual',
          message: t(errorI18nString, { prop: error.field, date: error.date && error.date, count: error.count }),
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request.loaded, request.data, request.errors]);

  useEffect(() => {
    if (data) {
      setInstallations(data);
    }
  }, [data]);

  return (
    <installationsContext.Provider value={installationsContextValue}>
      <selectedInstallationContext.Provider value={selectedInstallationContextValue}>
        <FormProvider {...form}>
          <div className={styles['page-container']}>
            <form onSubmit={form.handleSubmit(handleSubmit)} className={styles.container}>
              <Page
                user={user}
                title="pricing.new_simulation_title"
                layout="no-background"
                pageActions={
                  <>
                    <Button
                      type="button"
                      size="sm"
                      variant="link"
                      text="common.back"
                      href="/v2/ppa_simulations"
                      className="mr-3"
                    />
                    <Button
                      type="submit"
                      size="sm"
                      variant="primary-2"
                      text="common.save"
                      isLoading={request.loading}
                      disabled={!form.formState.isValid}
                      data-cy="create-simulation"
                    />
                  </>
                }
                error={request.error?.code}
              >
                <FormErrors errors={request.errors} title="sales_management.form_request_has_errors" />

                <ProgressStepper steps={steps} className={classNames('mt-4', styles.stepper)} />
              </Page>
            </form>
          </div>
        </FormProvider>
      </selectedInstallationContext.Provider>
    </installationsContext.Provider>
  );
};
