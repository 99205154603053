import React from 'react';
import { Input } from '@GDM/forms';
import { Controller, useFormContext } from 'react-hook-form';
import { useFieldRules } from '../../utils/getFieldRules';
import { SimulationForm } from '../../utils/simulations.types';

export const FixedStopStrikeInput: React.FC = () => {
  const form = useFormContext<SimulationForm>();
  const { fieldRules } = useFieldRules();
  const rules = fieldRules('fixed_stop_strike');

  return (
    <Controller
      name="fixed_stop_strike"
      control={form.control}
      rules={rules}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          hasError={!!fieldState.error}
          errorMessage={fieldState.error?.message}
          type="number"
          step="1"
          full
          size="lg"
          label="pricing.project.fixed_stop_strike"
          value={field.value ?? ''}
          tooltip="pricing.project.fixed_stop_strike_tooltip"
        />
      )}
    />
  );
};
