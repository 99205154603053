import React, { useMemo, useState } from 'react';
import {
  DateCell,
  HeadCell,
  NumberCell,
  Table,
  TableActions,
  TableBody,
  TableHead,
  TablePageSizeSelect,
  TablePagination,
} from '@GDM/Table';
import useTranslation from '@hooks/useTranslation';
import {
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  getCoreRowModel,
  ColumnDef,
} from '@tanstack/react-table';
import { useCurrency } from '@utils/string';
import { M0Aggregated, M0Row } from '@utils/types/m0';

export const M0Table: React.FC<{ m0Aggregated: M0Aggregated; isGermany?: boolean }> = ({ m0Aggregated, isGermany }) => {
  const { t } = useTranslation();
  const currency = useCurrency();

  const columns = useMemo<ColumnDef<M0Row>[]>(() => {
    const cols = [
      {
        header: 'Date',
        accessorKey: 'date',
        cell: ({ getValue }) => (
          <>
            {getValue() === 'TOTAL' ? t('common.total') : <DateCell value={getValue<string>()} format="YYYY-MM-DD" />}
          </>
        ),
      },
      {
        id: 'm0',
        header: () => <HeadCell tooltip={`${currency}/MWh`} label="pricing.market_value" />,
        accessorKey: 'm0',
        cell: ({ getValue }) => <NumberCell value={getValue<number>()} />,
      },
      {
        id: 'capture_price',
        header: () => <HeadCell tooltip={`${currency}/MWh`} label="monitoring.installation.capture_price" />,
        accessorKey: 'capture_price',
        cell: ({ getValue }) => <NumberCell value={getValue<number>()} />,
      },
      {
        id: `${t('pricing.market_value_deviation')}`,
        header: () => (
          <HeadCell
            tooltip={`${currency}/MWh`}
            label={isGermany ? 'common.deviation_m0_german' : 'common.deviation_m0'}
          />
        ),
        accessorKey: 'm0_deviation',
        cell: ({ getValue }) => <NumberCell value={getValue<number>()} />,
      },
      {
        id: 'm0_deviation_no_prod_neg_price',
        header: () => (
          <HeadCell tooltip={`${currency}/MWh`} label="monitoring.installation.m0_deviation_no_prod_neg_price" />
        ),
        accessorKey: 'm0_deviation_no_prod_neg_price',
        cell: ({ getValue }) => <NumberCell value={getValue<number>()} />,
      },
    ] satisfies ColumnDef<M0Row>[];

    return cols;
  }, [currency, isGermany, t]);

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const data = Object.values(m0Aggregated);

  const table = useReactTable({
    columns,
    data,
    state: { pagination },
    initialState: {
      columnVisibility: { m0_deviation_no_prod_neg_price: !isGermany, epex_gain: !isGermany },
    },
    enableSorting: false,
    onPaginationChange: setPagination,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  const pageSize = pagination.pageSize;

  return (
    <div className="mt-3">
      <Table>
        <TableHead table={table} />
        <TableBody table={table} />
      </Table>

      {Array.isArray(data) && data.length > pageSize && (
        <TableActions>
          <TablePageSizeSelect pageSize={pageSize} setPageSize={table.setPageSize} totalEntries={data.length} />
          <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />
        </TableActions>
      )}
    </div>
  );
};
