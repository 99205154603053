import React from 'react';
import { Input } from '@GDM/forms';
import { Controller, useFormContext } from 'react-hook-form';
import { UserFormType } from '../../types';

export const NameInput: React.FC<{ disabled: boolean }> = ({ disabled }) => {
  const { control } = useFormContext<UserFormType>();

  return (
    <Controller
      name="name"
      control={control}
      rules={{ required: true }}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          disabled={disabled}
          hasError={!!fieldState.error}
          data-cy="add-user-name"
          label="common.name"
          full
        />
      )}
    />
  );
};
