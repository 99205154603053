import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useForm, FormProvider } from 'react-hook-form';
import { BSMContext, FilterForm } from '../bsm.types';
import { bsmContext } from './subscriptions.context';

export const BSMProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [subscriptions, setSubscriptions] = useState<BSMContext['subscriptions']>(null);
  const [activityTracks, setActivityTracks] = useState<BSMContext['activityTracks']>(null);

  const filterForm = useForm<FilterForm>({ mode: 'onChange', defaultValues: { email: null, dates: null } });

  const selectedEmail = filterForm.watch('email');
  const selectedDates = filterForm.watch('dates');

  const filteredSubscriptions = useMemo<BSMContext['subscriptions']>(() => {
    if (!subscriptions || !selectedEmail) return subscriptions;

    return subscriptions.filter((s) => s.email === selectedEmail);
  }, [subscriptions, selectedEmail]);

  const filteredActivityTracks = useMemo<BSMContext['activityTracks']>(() => {
    if (!activityTracks) return activityTracks;

    return activityTracks.filter((s) => {
      const emailFilter = s.email === selectedEmail || !selectedEmail;
      const dateFilter =
        ((dayjs(s.start_date).isAfter(selectedDates?.[0], 'month') ||
          dayjs(s.start_date).isSame(selectedDates?.[0], 'month')) &&
          (dayjs(s.end_date).isBefore(selectedDates?.[1], 'month') ||
            dayjs(s.end_date).isSame(selectedDates?.[1], 'month'))) ||
        selectedDates?.some((d) => !d) ||
        !selectedDates;

      return emailFilter && dateFilter;
    });
  }, [activityTracks, selectedDates, selectedEmail]);

  const BSMContextValue = useMemo<BSMContext>(
    () => ({
      subscriptions: filteredSubscriptions,
      setSubscriptions,
      activityTracks: filteredActivityTracks,
      setActivityTracks,
    }),
    [filteredActivityTracks, filteredSubscriptions],
  );

  return (
    <bsmContext.Provider value={BSMContextValue}>
      <FormProvider {...filterForm}>{children}</FormProvider>
    </bsmContext.Provider>
  );
};
