import React from 'react';
import { useUser } from '@context/User.context';
import { Card } from '@GDM/Card';
import { Chart } from '@GDM/Chart';
import useTranslation from '@hooks/useTranslation';
import { useRevenueReportingContext } from '@pages/Reporting/utils/hooks/useRevenueReportingContext';
import { getSymbol } from '@utils/currency/getSymbol';
import { Currency } from '@utils/types/currency';
import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import round from 'lodash/round';
import { Card as CardLegacy, CardBody as CardBodyLegacy } from 'reactstrap';

const ProductionChart: React.FC<{
  height?: number | null;
  disablePadding?: boolean;
  printable?: boolean;
  currency: Currency;
}> = ({ height, disablePadding, printable, currency }) => {
  const { locale } = useUser();
  const { t } = useTranslation(locale);
  const { ytd, monthly, selectedDate } = useRevenueReportingContext();
  const currencySymbol = getSymbol(currency);

  if (!ytd) return null;
  const production = monthly
    ?.find((v) => v.field === 'total_production')
    ?.data.map((data) => {
      return {
        y: round(+data.value, 2) / 1e6 || null,
      };
    });

  const revenue = monthly
    ?.find((v) => v.field === 'total_revenue')
    ?.data.map((data) => {
      return {
        y: round(+data.value, 2) || null,
      };
    });

  const businessPlanRevenue = monthly
    ?.find((v) => v.field === 'business_plan_revenue')
    ?.data.map((data) => {
      return {
        y: round(+data.value, 2) || null,
      };
    });

  const options: Highcharts.Options = {
    chart: {
      // height - padding top in rem + padding bottom in rem - 2px clearfix
      height: disablePadding ? `${Math.floor(height || 0)}px` : `${Math.floor(height || 0) - 1.5 * 16 * 2 - 2}px`,
    },
    xAxis: {
      max: selectedDate.getFullYear() === new Date().getFullYear() ? dayjs().startOf('month').valueOf() : null,
    },
    yAxis: [
      {
        title: { text: `${t('common.production')} (GWh)`, style: { fontSize: '1rem' } },
        labels: {
          formatter() {
            const value = typeof this.value === 'string' ? parseFloat(this.value) : this.value;

            return new Intl.NumberFormat(locale, { notation: 'compact' }).format(value);
          },
        },
      },
      {
        title: { text: t('common.revenues'), style: { fontSize: '1rem' } },
        opposite: true,
        labels: {
          formatter() {
            const value = typeof this.value === 'string' ? parseFloat(this.value) : this.value;

            return new Intl.NumberFormat(locale, {
              currency: currency,
              style: 'currency',
              notation: 'compact',
            }).format(value);
          },
        },
      },
    ],
  };

  const series: Highcharts.Options['series'] = [
    {
      type: 'spline',
      color: 'var(--chart-production)',
      name: t('common.production'),
      data: production,
      tooltip: { valueSuffix: 'GWh' },
      pointIntervalUnit: 'month',
      pointStart: dayjs(selectedDate).startOf('year').valueOf(),
    },
    {
      type: 'column',
      color: 'var(--chart-business-plan)',
      name: t('sales_management.bp_budget'),
      data: businessPlanRevenue,
      yAxis: 1,
      tooltip: { valueSuffix: currencySymbol },
      pointIntervalUnit: 'month',
      pointStart: dayjs(selectedDate).startOf('year').valueOf(),
    },
    {
      type: 'column',
      color: 'var(--chart-revenue)',
      name: t('common.revenues'),
      data: revenue,
      yAxis: 1,
      tooltip: { valueSuffix: currencySymbol },
      pointIntervalUnit: 'month',
      pointStart: dayjs(selectedDate).startOf('year').valueOf(),
    },
  ];

  if (printable) {
    return (
      <CardLegacy className="ProductionChart">
        <CardBodyLegacy>{options && <Chart options={options} oneToOne series={series} hideLegend />}</CardBodyLegacy>
      </CardLegacy>
    );
  }

  return (
    <Card className="ProductionChart">
      {options && (
        <div style={{ width: '100%' }} data-cy="production-chart">
          <Chart options={options} oneToOne series={series} hideLegend />
        </div>
      )}
    </Card>
  );
};

export default ProductionChart;
