import React from 'react';
import { useTabs } from '@GDM/Tabs';
import { TabIds } from './bsm.types';
import { ActivityTracking, Subscriptions } from './Tabs';

export const TabContents: React.FC = () => {
  const { currentTab } = useTabs<TabIds>();

  const tabs: Record<TabIds, JSX.Element> = {
    subscriptions: <Subscriptions />,
    activity_tracking: <ActivityTracking />,
  };

  return <div className="p-4">{currentTab ? tabs[currentTab] : null}</div>;
};
