import React, { useMemo } from 'react';
import { SidebarContextType } from '../sidebar.types';
import { sidebarContext } from './sidebar.context';

export const SidebarProvider: React.FC<React.PropsWithChildren<{ masquerading: boolean; isSuperUser: boolean }>> = ({
  children,
  isSuperUser,
  masquerading,
}) => {
  const sidebarContextValue = useMemo<SidebarContextType>(
    () => ({ isSuperUser, masquerading }),
    [isSuperUser, masquerading],
  );

  return <sidebarContext.Provider value={sidebarContextValue}>{children}</sidebarContext.Provider>;
};
