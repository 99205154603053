import React from 'react';
import styles from './owner.module.scss';
import { OwnersTable } from './OwnersTable';

export const OwnersTab: React.FC = () => {
  return (
    <div className={styles['size-table']}>
      <OwnersTable />
    </div>
  );
};
