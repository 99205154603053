import React, { useState } from 'react';
import { Icon } from '@GDM/Icon';
import { useRequest } from '@hooks/useRequest';
import { api_token_v2_users_path } from '@utils/routes';
import styles from '../../Sidebar/sidebar.module.scss';
import { TokenExport } from './TokenExport';

export const GenerateToken: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { data, execute } = useRequest<{ token: string }>(api_token_v2_users_path(), 'GET', true);
  const token = data?.token || null;

  const handleClickToken = (e: React.MouseEvent) => {
    e.preventDefault();

    execute?.();
    setIsOpen(true);
  };

  return (
    <>
      <a href="#" onClick={handleClickToken}>
        <Icon name="Key" size={16} className={styles.icon} />
        <span>API Token</span>
      </a>

      <TokenExport token={token} isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} />
    </>
  );
};
