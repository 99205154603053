import React, { useEffect, useState } from 'react';
import { useUser } from '@context/User.context';
import { Button, DropDownButton } from '@GDM/Button';
import { Spinner } from '@GDM/Spinner';
import { Text } from '@GDM/Text';
import { useRequest } from '@hooks/useRequest';
import { api_v1_launchtr_addtroperation_website_path } from '@utils/routes';
import type Book from '@utils/types/book';
import { DataTypeFiltersForm as DataTypeFiltersForm } from '@utils/types/dataFilter';
import type { Meter } from '@utils/types/meter';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import { UseFormReturn } from 'react-hook-form';

export const Actions = ({
  startDate,
  endDate,
  meter,
  book,
  identifier,
  form,
}: {
  startDate: Date | null;
  endDate: Date | null;
  meter?: Meter;
  book?: Book;
  identifier: string;
  form: UseFormReturn<DataTypeFiltersForm>;
}) => {
  const [launchTrAlreadyDone, setLaunchTrAlreadyDone] = useState<boolean>(false);
  const { watch: getFilterValues } = form;
  const { launchtr } = useUser();
  const launchTelereleveAllowed = Boolean(launchtr && !book);

  const parsedStartDate = dayjs(startDate).format('YYYY-MM-DD HH:mm:00');
  const parsedEndDate = dayjs(endDate).format('YYYY-MM-DD HH:mm:00');

  // Launch telereleve
  const request = useRequest(
    api_v1_launchtr_addtroperation_website_path({ params: { meter: meter?.name } }),
    'GET',
    true,
  );

  const handleCsvClick = (): void => {
    window.location.href =
      './get_production_data.csv?' +
      `refcompteur=${identifier}` +
      `&firstdate=${parsedStartDate}` +
      `&lastdate=${parsedEndDate}` +
      `&is_book=${!!book}` +
      `&addconso=${getFilterValues('showConso')}` +
      `&addtension=${getFilterValues('showTension')}` +
      `&addinverters=${getFilterValues('showInverters')}` +
      `&addreactive=${getFilterValues('showReactive')}` +
      `&addproductible=${getFilterValues('showPotentialPower')}`;
  };

  const handleXlsClick = (): void => {
    window.location.href =
      './get_production_data.xls?' +
      `refcompteur=${identifier}` +
      `&firstdate=${parsedStartDate}` +
      `&lastdate=${parsedEndDate}` +
      `&is_book=${!!book}` +
      `&addconso=${getFilterValues('showConso')}` +
      `&addtension=${getFilterValues('showTension')}` +
      `&addinverters=${getFilterValues('showInverters')}` +
      `&addreactive=${getFilterValues('showReactive')}` +
      `&addproductible=${getFilterValues('showPotentialPower')}`;
  };

  useEffect(() => {
    setLaunchTrAlreadyDone(request.loaded);
  }, [request.loaded]);

  return (
    <div className="ml-auto d-flex gap-2">
      {launchTelereleveAllowed && (
        <>
          {request.loading && <Spinner size="sm" />}
          {request.error && <Text type="danger" text="monitoring.portfolio.launch_tr_error" size="sm" />}
          <Button
            size="xxs"
            variant="primary-2"
            onClick={() => request.execute?.()}
            disabled={launchTrAlreadyDone}
            tooltip="monitoring.portfolio.launch_tr"
            icon={launchTrAlreadyDone ? 'Check' : 'PhoneCall'}
          />
        </>
      )}

      <DropDownButton label="common.download" icon="Download" size="xxs" variant="primary-2" position="right">
        <Button variant="sub-button" text="common.csv" onClick={debounce(handleCsvClick, 500)} />
        <Button variant="sub-button" text="common.xls" onClick={debounce(handleXlsClick, 500)} />
      </DropDownButton>
    </div>
  );
};
