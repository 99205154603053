import React, { useContext, useState } from 'react';
import { useUser } from '@context/User.context';
import { DatePicker } from '@GDM/DatePicker';
import { FilterContainer, Filters } from '@GDM/Filters';
import { RadioButtons } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { contractTypeList } from '@utils/constants';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Option } from '@utils/types/common-types';
import { ContractType } from '@utils/types/contract';
import filtersContext, { FormatOption, ViewOption } from './filtersContext';

export const MetricsFilters: React.FC<{
  startDate: Date | null;
  endDate: Date | null;
  onDateChange: (dates: [Date | null, Date | null]) => void;
}> = (props) => {
  const [dates, setDates] = useState<[Date | null, Date | null]>([props.startDate, props.endDate]);
  const { contracts, view, format } = useContext(filtersContext);
  const { t } = useTranslation();
  const { main_country } = useUser();
  const TYPES = [...contractTypeList[main_country || 'FR']];

  const contractTypeOptions: Option<ContractType | ''>[] = [
    { value: '', label: t('common.all'), defaultChecked: true },
    ...TYPES.sort(sortOptionsByLabelAsc),
  ];

  const formatOptions: Option<keyof FormatOption>[] = [
    { value: 'monthly', label: t('common.monthly') },
    { value: 'cumulated', label: t('common.cumulative') },
  ];

  const viewOptions: Option<keyof ViewOption>[] = [
    { value: 'table', label: t('common.table') },
    { value: 'chart', label: t('common.chart') },
  ];

  const onChange = (dates: [Date | null, Date | null]) => {
    setDates(dates);
    if (dates.every(Boolean)) props.onDateChange(dates);
  };

  return (
    <Filters>
      <FilterContainer size="datepicker">
        <DatePicker startDate={dates[0]} endDate={dates[1]} onChange={onChange} picks="months" selectsRange />
      </FilterContainer>

      <FilterContainer size="fit">
        <RadioButtons
          name="format-type"
          options={formatOptions}
          selected={format.selected}
          onChange={(opt) => format.select(opt)}
        />
      </FilterContainer>

      <FilterContainer size="fit">
        <RadioButtons
          name="view-type"
          options={viewOptions}
          selected={view.selected}
          onChange={(opt) => view.select(opt)}
        />
      </FilterContainer>

      <FilterContainer size="fit">
        <RadioButtons
          name="contract-type"
          options={contractTypeOptions}
          selected={contracts.selected}
          onChange={(opt) => contracts.select(opt)}
        />
      </FilterContainer>
    </Filters>
  );
};
