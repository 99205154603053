import React from 'react';
import { Definition, DefinitionList } from '@GDM/DefinitionList';
import { TypeIcon } from '@utils/getTypeMeterIcons';
import { Installation } from '@utils/types/installation';
import classNames from 'classnames';
import { Popup } from 'react-leaflet';
import styles from './simulations.module.scss';

export const MapPopup: React.FC<{ installation: Installation }> = ({ installation }) => {
  return (
    <Popup className={styles.popup}>
      <div className={classNames(styles['popup-title'], 'mb-3')}>
        <TypeIcon type={installation.energy} /> <b>{installation.name}</b>
      </div>

      <DefinitionList>
        {(installation.average_q50 || installation.average_q50 === 0) && (
          <Definition label="pricing.average_deviation" value={installation.average_q50.toFixed(2)} />
        )}
        <Definition label="pricing.n_simulations" value={installation.nb_simulations} />
      </DefinitionList>
    </Popup>
  );
};
