import React, { useState } from 'react';
import { Restricted } from '@components/Restricted';
import { useUser } from '@context/User.context';
import { Alert } from '@GDM/Alert';
import { Chart } from '@GDM/Chart/Chart';
import type Book from '@utils/types/book';
import type { EnergyType } from '@utils/types/common-types';
import { DataTypeFiltersForm } from '@utils/types/dataFilter';
import { DataPeriod } from '@utils/types/dataPeriod';
import type MarketPlayer from '@utils/types/market_player';
import type { Meter } from '@utils/types/meter';
import { QfuData } from '@utils/types/qfuData';
import { useForm } from 'react-hook-form';
import { DataTypeFilters, InvertersChart, QfuChart } from './charts';
import { defaultValues, initEndDate, initStartDate } from './constants';
import { Actions } from './header/Actions';
import { ProductionFilters } from './header/ProductionFilters';
import { ProductionHeader } from './header/ProductionHeader';
import { useInverterData, useReactiveAnalyses, type ProductionType } from './hooks';
import { useAllProductionData } from './hooks/useAllProductionData';
import { useFiltersForm } from './hooks/useFiltersForm';
import { useProductionChart } from './hooks/useProductionChart';
import { useQueryDates } from './hooks/useQueryDates';
import { useSelectedDateRange } from './hooks/useSelectedDateRange';
import { useTimezone } from './hooks/useTimezone';
import styles from './production.module.scss';
import { DateRange } from './production.types';
import { ReactiveConstraints } from './ReactiveConstraints';
import { Widgets } from './Widgets';
import { computePowerMinMaxAverage } from './utils/computePowerMinMaxAverage';

export type ProductionFilters = {
  dataPeriod: DataPeriod;
  dates: DateRange;
  showReactivePowerMonitoring: boolean;
  showProductionMinMaxAverage: boolean;
  selectedMarketPriceCurve: string;
};

export const Production = ({
  meter,
  book,
  marketPlayer,
}: {
  meter?: Meter;
  book?: Book;
  marketPlayer?: MarketPlayer;
}) => {
  const { permissions } = useUser();
  const timezone = useTimezone(meter?.country || book?.installation_countries?.[0]);

  const type = getType(meter, book, marketPlayer);
  const isBook = type === 'book';
  const identifier = meter?.name || book?.uuid || marketPlayer?.id || '';
  const legacyIdentifier = isBook ? book?.name : meter?.name;
  const energyType: EnergyType | undefined = book ? 'book' : meter?.installation?.energy;
  const showForecast = Boolean(permissions?.includes('display:forecast') || book || meter?.installation?.show_forecast);
  const showDataTypeFilter = Boolean(energyType !== 'other' || meter?.producteur);
  const displayAutoConso = Boolean(book?.autoconso);
  const isAutoConso = book?.autoconso;
  const countries = [
    ...(book?.installation_countries || []),
    ...(meter?.country ? [meter.country] : []),
    ...(marketPlayer?.country ? [marketPlayer.country] : []),
  ];

  const filtersForm = useFiltersForm(Boolean(marketPlayer), countries);
  const selectedMarketPriceCurve = filtersForm.watch('selectedMarketPriceCurve');

  const [dates, dataPeriod, showReactiveConstraints, showProductionMinMaxAverage] = filtersForm.watch([
    'dates',
    'dataPeriod',
    'showReactivePowerMonitoring',
    'showProductionMinMaxAverage',
  ]);
  const [startDate, endDate] = useQueryDates(dates[0], dates[1]);

  const [chartExtremes, setChartExtremes] = useState<[number, number]>([
    initStartDate.getTime(),
    initEndDate.getTime(),
  ]);

  const [selectedDateRange, setSelectedDateRange] = useSelectedDateRange(startDate, endDate);

  const dataTypeForm = useForm<DataTypeFiltersForm>({ defaultValues });

  const [showInverters, showMarketPrices] = dataTypeForm.watch(['showInverters', 'showMarketPrices']);

  // Data used for the reactive power monitoring
  // Can be used to populate a ReactiveTable or to plot a QfuChart
  const {
    data: reactiveAnalyses,
    isLoading: areReactiveAnalysesLoading,
    error: reactiveAnalysesError,
  } = useReactiveAnalyses(identifier, startDate, endDate, isBook);

  // Reactive power monitoring data, in both of its possible types
  const qfuData: QfuData | null = reactiveAnalyses?.q_fu
    ? {
        ...reactiveAnalyses.q_fu,
        // Filter the data to only show the data that is within the chart's time range
        tab_data: reactiveAnalyses.q_fu.tab_data.filter(({ time }) => {
          const [start, end] = chartExtremes;

          return time * 1000 >= start && time * 1000 <= end;
        }),
      }
    : null;
  const reactiveTableData = reactiveAnalyses?.tan_phi;

  const qFuAggregate =
    qfuData?.tab_data &&
    computePowerMinMaxAverage({
      series: qfuData.tab_data,
      dataPeriod: 'minutes',
      timeFilter: chartExtremes,
      mapToTimeSeriesPoint: (point) => [point.time * 1000, Number(point.billable_reactive_power)],
    });

  const qFuBillablePower = qFuAggregate?.energy;

  const qFuPenaltyDraft = Number(qFuBillablePower) * Number(reactiveAnalyses?.price);
  const qFuPenalty = !isNaN(qFuPenaltyDraft) ? qFuPenaltyDraft : null;

  // Inverter data is not (yet?) included in new api
  const { data: inverterRequestedData } = useInverterData(identifier, startDate, endDate, isBook, dataPeriod);
  const inverterAll = inverterRequestedData?.inverterAll;
  const inverterData = inverterRequestedData?.inverterData;

  // Flags that determines if the display of inverter data is possible
  const inverterFilterDisabled = isBook || !inverterData?.invlist || !inverterAll?.length;
  const inverterChartDisabled = isBook || !inverterData?.invlist?.length;

  const { productionData, marketPrices, legacyProdData, isChartLoading, areProdCurvesLoading } = useAllProductionData({
    startDate,
    endDate,
    dataPeriod,
    identifier,
    legacyIdentifier,
    type,
  });

  const marketPricesCurve = marketPrices?.[selectedMarketPriceCurve] || [];

  const { series, options } = useProductionChart({
    data: productionData,
    dataPeriod,
    availability: legacyProdData?.availability,
    inverterAll,
    meter,
    showForecast,
    onChartExtremesSet: setChartExtremes,
    onDateRangeSelection: setSelectedDateRange,
    form: dataTypeForm,
    marketPricesCurve,
    selectedMarketPriceCurve,
  });

  return (
    <>
      <ProductionHeader>
        <ProductionFilters
          dataPeriod={dataPeriod}
          type={type}
          enableReactivePowerTable={Boolean(!book && meter?.reactive_constraint)}
          filtersForm={filtersForm}
          marketPrices={marketPrices}
          showMarketPriceCurve={showMarketPrices}
        />
        {type !== 'market_player' && (
          <Actions
            form={dataTypeForm}
            startDate={startDate}
            endDate={endDate}
            meter={meter}
            book={book}
            identifier={identifier}
          />
        )}
      </ProductionHeader>

      <div className={styles['chart-container']}>
        {showDataTypeFilter && type !== 'market_player' && (
          <DataTypeFilters
            form={dataTypeForm}
            meterType={energyType}
            periodType={dataPeriod}
            inverterFilterDisabled={inverterFilterDisabled}
            isAutoConso={isAutoConso}
            showForecast={showForecast}
          />
        )}

        <Chart
          series={series}
          options={options}
          isLoading={isChartLoading}
          data-cy="production-chart"
          timezone={timezone}
          hideLegend
          key={JSON.stringify(series)}
        />

        {!inverterChartDisabled && showInverters && <InvertersChart inverterData={inverterData} timezone={timezone} />}
      </div>

      {showReactiveConstraints && qfuData && <QfuChart qfuData={qfuData} />}

      {type !== 'market_player' && (
        <>
          <Restricted permissions={['display:view_monitoring_dashboard_meter_performances']}>
            <Widgets
              country={meter?.country}
              reactivePenaltyPrice={reactiveAnalyses?.price}
              maxPower={meter?.maxvalue}
              constraint={meter?.reactive_constraint}
              identifier={identifier}
              timeSeries={productionData || {}}
              isBook={isBook}
              dataPeriod={dataPeriod}
              energyType={energyType}
              inverterAll={inverterAll}
              autoConsoAppears={displayAutoConso}
              chartExtremes={chartExtremes}
              isLoading={areProdCurvesLoading}
              selectedDateRange={selectedDateRange}
              showProductionMinMaxAverage={showProductionMinMaxAverage}
              qFuPenalty={qFuPenalty}
            />
          </Restricted>

          {showReactiveConstraints && (
            <Restricted
              permissions={['display:view_monitoring_dashboard_meter_reactive']}
              fallback={
                <div className="d-flex justify-content-center mt-4">
                  <Alert variant="warning" label="monitoring.installation.contact_us_reactive" />
                </div>
              }
            >
              <ReactiveConstraints
                data={reactiveTableData || null}
                isLoading={areReactiveAnalysesLoading}
                error={reactiveAnalysesError?.message || null}
              />
            </Restricted>
          )}
        </>
      )}
    </>
  );
};

const getType = (meter?: Meter, book?: Book, marketPlayer?: MarketPlayer): ProductionType => {
  if (meter) return 'installation';
  if (book) return 'book';
  if (marketPlayer) return 'market_player';

  throw new Error('No type found for the given parameters');
};
