import React from 'react';
import { Input } from '@GDM/forms';
import { useFilters } from './useFilters';

export const ClientFilter: React.FC = () => {
  const { clientId, setClientId } = useFilters();

  return (
    <Input
      icon="Search"
      autoComplete="off"
      placeholder="common.client_id"
      data-cy="client_id-filter"
      value={clientId}
      onChange={(e) => {
        setClientId?.(e.target.value);
      }}
    />
  );
};
