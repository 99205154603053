import React from 'react';
import Page from '@pages/Page/Page';
import { User } from '@utils/types/user';
import { PricingMapsFilters } from './Actions/PricingMapsFilters';
import { PricingFiltersProvider } from './PricingFiltersProvider';
import { PricingMapContainer } from './PricingMapContainer';
import { PricingMapProvider } from './PricingMapProvider';
import { SelectedPricingMapProvider } from './SelectedPricingMapProvider';
import { PricingMapsContext } from './utils/pricing-maps.types';
import { pricingMapsContext } from './utils/pricingMaps.context';

export const PricingMaps: React.FC<{
  user: User;
}> = ({ user }) => {
  return (
    <PricingMapProvider>
      <SelectedPricingMapProvider user={user}>
        <PricingFiltersProvider user={user}>
          <pricingMapsContext.Consumer>
            {({ loading }: PricingMapsContext) => (
              <Page user={user} title="common.national_maps" header={<PricingMapsFilters />} isLoading={loading}>
                {/* <PeriodSelect /> */}
                <PricingMapContainer />
              </Page>
            )}
          </pricingMapsContext.Consumer>
        </PricingFiltersProvider>
      </SelectedPricingMapProvider>
    </PricingMapProvider>
  );
};
