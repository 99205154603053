import React from 'react';
import { tString } from '@hooks/useTranslation';
import { Locale } from '@utils/types/common-types';
import chroma from 'chroma-js';
import styles from './pricing-map.module.scss';

export const LegendContent: React.FC<{
  locale: Locale;
  isDeviation: boolean;
  scale: chroma.Scale<chroma.Color>;
  minValue: number;
  rangeOfValues: number;
}> = ({ locale, isDeviation, scale, minValue, rangeOfValues }) => {
  {
    /* Hooks doesn't work as a tooltip as it's not rendered as a React Component due to
     * leaflet's limitations.
     */
  }
  const t = tString(locale);
  const steps = new Array(10).fill('');

  const fieldName = isDeviation ? t('pricing.maps.market_deviation_ratio') : t('pricing.maps.capture_price_ratio');
  const subTitle = isDeviation
    ? t('pricing.maps.legend.market_deviation_ratio')
    : t('pricing.maps.legend.capture_price_ratio');

  return (
    <div className={styles['legend']}>
      <div className="tt:uppercase fw:700 mb-2">{fieldName}&nbsp;*</div>

      <div className={styles['legend-columns']}>
        {steps.map((_, i) => {
          const value = minValue + ((i + 0.5) / steps.length) * rangeOfValues;

          return (
            <div key={value} className={styles['value']}>
              <span style={{ color: scale(value).hex() }} className="mr-1">
                ⏺
              </span>
              <span>{value.toFixed(1)}%</span>
            </div>
          );
        })}
      </div>

      {/* It doesn't work as a tooltip as it's not rendered as a React Component due to
       * leaflet's limitations.
       */}
      <div className="mt-2 text-italic ta:right">*&nbsp;{subTitle}</div>
    </div>
  );
};
