import React from 'react';
import { Input } from '@GDM/forms';
import { useFieldRules } from '@pages/Simulations/NewSimulation/utils/getFieldRules';
import { SimulationForm } from '@pages/Simulations/NewSimulation/utils/simulations.types';
import { Controller, useFormContext } from 'react-hook-form';
import styles from '../../../new-simulation.module.scss';

export const MaximumPowerInput: React.FC<{ index: number }> = ({ index }) => {
  const { control } = useFormContext<SimulationForm>();
  const { fieldRules } = useFieldRules();
  const rules = fieldRules('ppa_wind_bounds_attributes.0.maximum_power');

  return (
    <Controller
      control={control}
      name={`ppa_wind_bounds_attributes.${index}.maximum_power`}
      rules={rules}
      render={({ field, fieldState }) => (
        <div className={styles['constraint-input-container']}>
          <Input
            {...field}
            hasError={!!fieldState.error}
            type="number"
            label="pricing.project.max_power"
            errorMessage={fieldState.error ? 'pricing.form.errors.ppa_wind_bounds.maximum_power' : null}
            size="lg"
            suffix="%"
            min={0}
            max={100}
            value={field.value ?? ''}
            full
          />
        </div>
      )}
    />
  );
};
