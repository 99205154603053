import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { User } from '@utils/types/user';
import { OwnerForm } from './OwnerForm';

const queryClient = new QueryClient();

const EditOwner: React.FC<{ user: User; id: string }> = ({ user, id }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <OwnerForm user={user} id={id} />
    </QueryClientProvider>
  );
};

export default EditOwner;
