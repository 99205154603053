import React from 'react';
import { Modal } from '@GDM/Modal';
import { MeterInvoice } from '@utils/types/meter-invoice';
import { useForm } from 'react-hook-form';
import { SuccessMessage } from './SuccessMessage';
import { SwapMeterForm } from './SwapMeterForm';
import { useSwapMeterModal } from './swapMeterModal.context';
import type { SwapMeterForm as SwapMeterFormType } from './swapMeterModal.types';

export const SwapMeterModal: React.FC<{ isLoading: boolean; invoice?: MeterInvoice }> = ({ isLoading, invoice }) => {
  const { isOpen, toggle, handleSubmit, invoiceId, loaded, error } = useSwapMeterModal();
  const form = useForm<SwapMeterFormType>({
    defaultValues: {
      change_date: new Date(invoice?.end_date ?? new Date()),
      first_production_index: '0',
      last_production_index: '',
      first_consumption_index: '0',
      last_consumption_index: '',
    },
  });

  const isFinished = loaded && !error;

  return (
    <Modal
      isOpen={isOpen && !!invoiceId && !!invoice}
      header="sales_management.swap_meter_modal.meter_switch_label"
      toggle={toggle}
      submitAction={isFinished ? toggle : form.handleSubmit(handleSubmit)}
      saveButtonText={isFinished ? 'common.close' : 'common.send'}
      hideCancelButton={isFinished}
      isLoading={isLoading}
      size="lg"
    >
      {isFinished && <SuccessMessage />}
      {!isFinished && invoice && <SwapMeterForm form={form} invoice={invoice} />}
    </Modal>
  );
};
