import React, { useContext } from 'react';
import { ContractFilters } from '@components/Contracts/ContractFilters';
import { useConsumeContracts } from '@context/contracts.context';
import { ContractsTable } from '@pages/Contracts/Contracts/Table';
import { Actions, MassEditModal } from '../Actions';
import { contractsPageContext } from '../context/contractsPage.context';
import { ContractSlideInPanel } from '../ContractSlideInPanel';

export const TableWrapper: React.FC<{
  showBookFilter?: boolean;
}> = ({ showBookFilter }) => {
  const { contracts, isLoading } = useConsumeContracts();
  const { massEditModalOpen, setMassEditModalOpen } = useContext(contractsPageContext);

  return (
    <>
      <ContractSlideInPanel />

      <MassEditModal isOpen={massEditModalOpen ?? false} toggle={() => setMassEditModalOpen?.(!massEditModalOpen)} />

      {!isLoading && (
        <>
          <ContractFilters showBookFilter={showBookFilter} />
          <Actions openMassEditModal={() => setMassEditModalOpen?.(true)} />
        </>
      )}

      {!isLoading && contracts && <ContractsTable />}
    </>
  );
};
