import React, { useState } from 'react';
import { Button } from '@GDM/Button';
import { Icon } from '@GDM/Icon';
import { Modal } from '@GDM/Modal';
import ReactDOM from 'react-dom';

type TokenExportProps = {
  token: string | null;
  isOpen: boolean;
  toggle: () => void;
};

export const TokenExport: React.FC<TokenExportProps> = ({ token, isOpen, toggle }) => {
  const [copied, setIsCopied] = useState<boolean>(false);
  const [loading, setIsLoading] = useState<boolean>(false);
  const node = document.getElementById('token-cc');

  const handleClipboardSave = async (): Promise<void | null> => {
    setIsLoading(true);
    setIsCopied(false);

    if (!navigator.clipboard || !token) {
      setIsLoading(false);

      return Promise.resolve(null);
    }

    try {
      navigator.clipboard.writeText(token);
      setIsCopied(true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Cannot copy to clipboard', err);
    } finally {
      setIsLoading(false);
    }
  };

  const Header = (
    <div className="d-flex align-items-center">
      <Icon name="Key" size={16} className="mr-2" />
      <span className="mr-2">API Token</span>
      {navigator.clipboard && !copied && (
        <Button
          icon="Clipboard"
          tooltip="Click to copy"
          variant="primary-2"
          floating
          onClick={handleClipboardSave}
          isLoading={loading}
        />
      )}
      {navigator.clipboard && copied && <Button icon="Check" tooltip="Copied!" variant="primary-1" size="sm" />}
    </div>
  );

  return (
    node &&
    ReactDOM.createPortal(
      <Modal size="md" isOpen={isOpen} toggle={toggle} header={Header} cancelButtonText="common.close">
        <pre onClick={handleClipboardSave}>
          <textarea
            rows={3}
            className="p-2"
            style={{ width: '100%', resize: 'none', background: 'var(--grey-lightest)' }}
            value={token || ''}
            readOnly
          />
        </pre>
      </Modal>,
      node,
    )
  );
};
