import React, { useContext } from 'react';
import { Checkbox } from '@GDM/forms';
import { Row } from '@tanstack/react-table';
import { Simulation } from '@utils/types/simulation';
import SelectedRowsContext from '../../state/selectedRows';

const SelectRowCell: React.FC<{ row: Row<Simulation> }> = (props) => {
  const { selectedRows, selectRow, unselectRow } = useContext(SelectedRowsContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      selectRow(props.row.original);
    } else {
      unselectRow(props.row.original);
    }
  };

  const checked = selectedRows.map((s) => s.id).includes(props.row.original.id);

  return <Checkbox checked={checked} onChange={handleChange} />;
};

export default SelectRowCell;
