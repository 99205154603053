import React from 'react';
import { Col, Row } from '@GDM/layout';
import { Map } from '@GDM/Map';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import { COUNTRIES } from '@utils/constants/countries';
import Installation from '@utils/types/installation';
import { BookMap } from 'pages/ui/shared/maps';
import styles from './book-informations.module.scss';
import ProjectBookInstallations from './ProjectBookInstallations';

export const BookInformations: React.FC = () => {
  const project = useProjectContext();
  const installations = project?.book?.installations;
  const hasNoCoordinates = installations?.every((i) => !i.latitude && !i.longitude);
  const country = project.book?.country || 'FR';
  const { lat, lng } = COUNTRIES[country].coordinates;

  if (!installations) {
    return null;
  }

  return (
    <Row>
      {!hasNoCoordinates && (
        <Col md={6} className={styles['map-container']}>
          <Map center={lat && lng ? [lat, lng] : [0, 0]}>
            <BookMap installations={installations as Installation[]} />
          </Map>
        </Col>
      )}
      <Col md={6} className={styles['installation-list']}>
        {!!installations && <ProjectBookInstallations installations={installations} />}
      </Col>
    </Row>
  );
};
