import React, { useCallback, useMemo, useState } from 'react';
import { Restricted } from '@components/Restricted';
import { Alert } from '@GDM/Alert';
import { Button } from '@GDM/Button';
import { Spinner } from '@GDM/Spinner';
import { Table, TableBody, TableHead } from '@GDM/Table';
import { useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import { getSortedRowModel, useReactTable, getCoreRowModel, ColumnDef } from '@tanstack/react-table';
import { v2_data_loggers_path } from '@utils/routes';
import { DataLogger } from '@utils/types/datalogger';
import { useInstallationsPageContext } from '../../context/installations-page.context';
import { DataLoggerModal } from './Modal';

export const DataLoggersTab: React.FC = () => {
  const { t } = useTranslation();
  const { toggleDataLoggerModal, dataLoggerModalVisible } = useInstallationsPageContext();

  // States
  const { data: dataLoggers, loaded, loading } = useRequest<DataLogger[]>(v2_data_loggers_path(), 'GET');
  const [selectedDataLogger, setSelectedDataLogger] = useState<DataLogger | undefined>();

  const handleClick = useCallback(
    (dataLogger: DataLogger) => () => {
      setSelectedDataLogger(dataLogger);
      toggleDataLoggerModal();
    },
    [toggleDataLoggerModal],
  );

  // Table
  const columns = useMemo<ColumnDef<DataLogger>[]>(
    () =>
      [
        { header: 'common.name', accessorKey: 'name' },
        { header: 'common.identifier', accessorKey: 'identifier' },
        {
          header: '',
          id: 'actions',
          cell: ({ row }) => {
            return (
              <Restricted permissions={['display:edit_monitoring']}>
                <Button floating variant="primary-2" onClick={handleClick(row.original)} icon="Edit" />
              </Restricted>
            );
          },
        },
      ] satisfies ColumnDef<DataLogger>[],
    [handleClick],
  );
  const data: DataLogger[] = useMemo(() => dataLoggers || [], [dataLoggers]);
  const table = useReactTable({
    columns,
    data,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  // Event handlers
  const noDataFound = loaded && data.length === 0;
  const dataFound = data.length > 0;

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center p-4">
        <Spinner />
      </div>
    );

  return (
    <div>
      <DataLoggerModal
        visible={dataLoggerModalVisible}
        toggle={toggleDataLoggerModal}
        dataLogger={selectedDataLogger}
      />

      {noDataFound && (
        <div className="p-3">
          <Alert variant="warning">{t('admin.installations.no_datalogger_found_warning')}</Alert>
        </div>
      )}

      {dataFound && (
        <Table hasActions className="mt-3">
          <TableHead table={table} />
          <TableBody table={table} />
        </Table>
      )}
    </div>
  );
};
