import React from 'react';
import { Icon } from '@GDM/Icon';
import { Col, Row } from '@GDM/layout';
import { Text } from '@GDM/Text';
import styles from './success-message.module.scss';

export const SuccessMessage: React.FC = () => {
  return (
    <Row>
      <Col className={styles['message']}>
        <Icon name="CheckCircle" size={32} className={styles['message-icon']} />
        <Text
          text="sales_management.swap_meter_modal.modal_confirmation_text"
          size="md"
          className={styles['message-text']}
        />
      </Col>
    </Row>
  );
};
