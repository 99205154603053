import { useRequest } from '@hooks/useRequest';
import { formatSystemDate } from '@utils/formatters';
import {
  by_type_index_v2_invoices_path,
  by_type_loadcurve_v2_invoices_path,
  by_type_rec_v2_invoices_path,
  by_type_yearly_regul_v2_invoices_path,
  v2_meter_invoicings_capa_index_path,
} from '@utils/routes';
import { MeterInvoice, MeterInvoiceSource } from '@utils/types/meter-invoice';

const invoicesUrl: Record<MeterInvoiceSource, (opts?: { params?: Record<string, string | undefined> }) => string> = {
  capa: v2_meter_invoicings_capa_index_path,
  load_curve: by_type_loadcurve_v2_invoices_path,
  rec: by_type_rec_v2_invoices_path,
  yearly_regul: by_type_yearly_regul_v2_invoices_path,
  index: by_type_index_v2_invoices_path,
};

export const useInvoicesByType = (invoiceType: MeterInvoiceSource, startDate: Date, endDate?: Date | null) => {
  const req = useRequest<MeterInvoice[]>(
    invoicesUrl[invoiceType]({
      params: {
        start_date: startDate ? formatSystemDate(startDate) : undefined,
        end_date: endDate ? formatSystemDate(endDate) : undefined,
      },
    }),
    'GET',
    /**
     * If endDate is undefined, we only need startDate, because that means endDate is not required
     * If endDate is null, it means that endDate is required and we should wait until it's set before fetching
     */
    endDate === null,
  );

  return req;
};
