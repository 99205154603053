import React, { useMemo } from 'react';
import { Table, TableBody, TableHead } from '@GDM/Table';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { DataList, IndexData } from '@utils/DashboardMeter/useIndexInvoicingData';
import { isANumber } from '@utils/isANumber';
import { useIndexTab } from '../../useIndexTab';
import styles from './index-invoicing-table.module.scss';
import { indexInvoicingColumns } from './indexInvoicingColumns';

export const IndexInvoicingTable: React.FC<{
  invoicingData?: DataList;
  title: string;
}> = ({ invoicingData, title }) => {
  const { highlightedRow, setHighlightedRow } = useIndexTab();

  const data: IndexData[] = useMemo(() => {
    const validData = invoicingData
      ? ((Object.keys(invoicingData) as (keyof DataList)[])
          .filter((item) => !!invoicingData[item as keyof DataList].data)
          .map((item) => ({
            ...invoicingData[item as keyof DataList].data,
            type: invoicingData[item as keyof DataList].type,
          })) as IndexData[])
      : [];

    return validData;
  }, [invoicingData]);
  const columns = useMemo(() => indexInvoicingColumns(title, invoicingData), [title, invoicingData]);
  const table = useReactTable({ columns, data, enableSorting: false, getCoreRowModel: getCoreRowModel() });

  return (
    <div className={styles.wrapper}>
      <Table className={styles['index-invoicing-table']}>
        <TableHead table={table} />
        <TableBody
          table={table}
          onMouseEnterRow={setHighlightedRow}
          onMouseLeaveRow={() => setHighlightedRow?.(undefined)}
          highlightedRows={isANumber(highlightedRow) ? [highlightedRow] : undefined}
        />
      </Table>
    </div>
  );
};
