import React, { useMemo } from 'react';
import { installationsContext } from '@context/installations.context';
import { Locale } from '@utils/types/common-types';
import { CountryCode } from '@utils/types/countries';
import { Currency } from '@utils/types/currency';
import Installation from '@utils/types/installation';
import MarketPlayer from '@utils/types/market_player';
import { User } from '@utils/types/user';
import { formatDate } from './components/pdf/ReportHeader';
import ReportPageCharts from './components/pdf/ReportPageCharts';
import ReportPageLayout from './components/pdf/ReportPageLayout';
import ReportPageSiteBySite from './components/pdf/ReportPageSiteBySite';
import { revenueReportingContext } from './context';
import { RevenueReportingContext, RevenueReportsResponse } from './reporting.types';

type PortfolioPageProps = {
  locale: Locale;
  user: User;
  reportDate: string;
  bookName: string | null;
  country: CountryCode | null;
  marketPlayers: MarketPlayer[] | null;
  overview_report: RevenueReportsResponse[number]['data'];
  split_report: RevenueReportingContext['splitData'];
  currency: Currency;
  installations: Installation[];
};

const PortfolioPdf: React.FC<PortfolioPageProps> = ({
  user,
  locale,
  reportDate,
  bookName,
  country,
  installations,
  marketPlayers,
  overview_report,
  split_report,
  currency,
}) => {
  const revenueReportingContextValue: RevenueReportingContext = useMemo(
    () => ({
      selectedDate: new Date(reportDate),
      cumulative: overview_report.cumulative,
      monthly: overview_report.monthly,
      splitData: split_report,
      selectedBook: null,
      selectedCountry: country,
      currency,
    }),
    [overview_report.cumulative, overview_report.monthly, reportDate, split_report, country, currency],
  );

  const marketPlayerName = marketPlayers?.length === 1 ? marketPlayers[0].short_name : null;

  return (
    <installationsContext.Provider value={{ installations }}>
      <revenueReportingContext.Provider value={revenueReportingContextValue}>
        <ReportPageLayout
          user={user}
          locale={locale}
          reportDate={reportDate}
          bookName={bookName}
          marketPlayerName={marketPlayerName}
          title={`Reporting Mensuel ${formatDate(reportDate)}`}
        >
          <ReportPageCharts currency={currency} />
        </ReportPageLayout>

        <ReportPageLayout
          user={user}
          locale={locale}
          reportDate={reportDate}
          bookName={bookName}
          marketPlayerName={marketPlayerName}
          title="Résultats Site-par-Site"
        >
          <ReportPageSiteBySite showAll />
        </ReportPageLayout>
      </revenueReportingContext.Provider>
    </installationsContext.Provider>
  );
};

export default PortfolioPdf;
