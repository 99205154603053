import React, { useCallback } from 'react';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { useBooksContext } from '@context/BooksContext';
import { useDynamicOptions } from '@GDM/Filters';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Option } from '@utils/types/common-types';
import { MeterInvoice } from '@utils/types/meter-invoice';
import { useInvoicesContext } from 'pages/sales-management/invoicing/invoicesContext';
import { useInvoicesFilters } from 'pages/sales-management/invoicing/invoicesFiltersContext';

export const BookFilter: React.FC = () => {
  const form = useInvoicesFilters();
  const { books } = useBooksContext();
  const { filteredInvoices, allInvoices } = useInvoicesContext();

  const getOptionsCallback = useCallback(
    (invoices: MeterInvoice[]) => {
      const installations = Array.from(new Set(invoices.map((invoice) => invoice.installation_name)));

      const filteredBooks =
        books?.filter((b) => {
          return installations.filter((i) => b.installation_names.includes(i)).length > 0;
        }) || [];

      const options: Option<string>[] = filteredBooks
        .map((book) => ({ label: book.name, value: book.uuid }))
        .sort(sortOptionsByLabelAsc);

      return options;
    },
    [books],
  );
  const options: Option<string>[] = useDynamicOptions(getOptionsCallback, 'book_id', filteredInvoices, allInvoices);

  return (
    <ControlledSelect
      control={form.control}
      name="book_id"
      isMulti
      inline
      classNamePrefix="book_select"
      options={options}
      placeholder="admin.books.book_name"
    />
  );
};
