import React, { useEffect, useRef } from 'react';
import useIsOnScreen from '@hooks/useIsOnScreen';
import { Installation } from '@utils/types/installation';
import { FeatureGroup as FeatureGroupType } from 'leaflet';
import { FeatureGroup, useMap } from 'react-leaflet';
import { MapMarker } from './MapMarker';
import { MapPopup } from './MapPopup';

type SimulationsMapProps = {
  installations: Installation[];
};

export const SimulationsMapFeature: React.FC<SimulationsMapProps> = ({ installations }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const installationsWithSims = React.useMemo(() => installations.filter((i) => i.nb_simulations > 0), [installations]);
  const featureGroupRef = useRef<FeatureGroupType>(null);
  const map = useMap();
  const isVisible = useIsOnScreen(containerRef);

  useEffect(() => {
    const resizeMap = () => map?.invalidateSize();
    resizeMap();
    window.addEventListener('resize', resizeMap);

    const bounds = featureGroupRef?.current?.getBounds();
    if (bounds?.isValid()) map.fitBounds(bounds);

    return window.removeEventListener('resize', resizeMap);
  }, [isVisible, map, featureGroupRef]);

  return (
    <div ref={containerRef}>
      <FeatureGroup ref={featureGroupRef}>
        {installationsWithSims.map((installation) => (
          <MapMarker key={installation.name} installation={installation}>
            <MapPopup installation={installation} />
          </MapMarker>
        ))}
      </FeatureGroup>
    </div>
  );
};
