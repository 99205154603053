import React from 'react';
import { Definition } from '@GDM/DefinitionList';
import useTranslation from '@hooks/useTranslation';
import { Meter } from '@utils/types/meter';
import { useMeterInfo } from '../useMeterInfo';
import { InvoicingTypes } from './invoicing-type.types';
import { InvoicingTypeModal } from './InvoicingTypeModal';

export const InvoicingTypeDefinition: React.FC<{ meter: Meter }> = ({ meter }) => {
  const { facturation_type } = useMeterInfo();
  const { t } = useTranslation();
  const value =
    facturation_type && Object.keys(InvoicingTypes).includes(facturation_type)
      ? InvoicingTypes[facturation_type as keyof typeof InvoicingTypes]
      : null;

  return (
    <Definition
      label="common.filters.facturation_type"
      value={value ? t(value) : t('common.none')}
      modal={{
        node: <InvoicingTypeModal meter={meter} />,
        title: 'monitoring.installation.modify_invoicing',
        permission: 'display:edit_monitoring',
      }}
    />
  );
};
