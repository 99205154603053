import { useEffect, useState } from 'react';
import { MeterInvoice } from '@utils/types/meter-invoice';
import { MonitoringInvoicesFilters } from '../invoices.types';
import { getFilteredInvoices } from './getFilteredInvoices';

export const useFilteredInvoices = (
  allInvoices: MeterInvoice[],
  loaded: boolean,
): {
  filters: MonitoringInvoicesFilters;
  setFilters: React.Dispatch<React.SetStateAction<MonitoringInvoicesFilters>>;
  filteredInvoices: MeterInvoice[];
  onFilterChange: (filters: MonitoringInvoicesFilters) => void;
} => {
  const [filters, setFilters] = useState<MonitoringInvoicesFilters>({
    installationName: '',
    invoicingType: 'All',
  });
  const [filteredInvoices, setFilteredInvoices] = useState<MeterInvoice[]>([]);

  const onFilterChange = (filters: MonitoringInvoicesFilters) => {
    const invoices = getFilteredInvoices(filters, allInvoices);

    setFilters(filters);
    setFilteredInvoices(invoices);
  };

  useEffect(() => {
    if (loaded) {
      const invoices = getFilteredInvoices(filters, allInvoices);

      setFilteredInvoices(invoices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allInvoices, loaded]);

  return {
    filters,
    setFilters,
    filteredInvoices,
    onFilterChange,
  };
};
