import React, { useEffect, useMemo, useState } from 'react';
import { DropDownButton } from '@GDM/Button';
import { Filters as FilterWrapper, FilterContainer } from '@GDM/Filters';
import { isValidDate } from '@utils/isValidDate';
import matchSorterInstanceStrict from '@utils/matchSorterInstanceStrict';
import { EventType } from '@utils/types/event';
import { usePortfolioTable } from '../DashboardViewAll/PortfolioTable/context/usePortfolioTable';
import { DashboardViewAllData, TypeFilter } from '../portfolio.definitions';
import { usePortfolio } from '../usePortfolio';
import { AlertsFilter } from './AlertsFilters';
import { BookFilters } from './BookFilters';
import { ClientFilter } from './clientIdFilter';
import { CommissioningDatesFilter } from './CommissioningDateFilter';
import ContractTypeFilter from './ContractTypeFilter';
import { CountryFilters } from './CountryFilters';
import { DatesFilter } from './DatesFilter';
import { FiltersContext, filtersContext } from './filters.context';
import { InstallationFilters } from './InstallationFilters';
import { MapToggle } from './MapToggle';
import PortfolioFilters from './PortfolioFilters';

export const Filters: React.FC = () => {
  const [type, setType] = useState<TypeFilter>('all');
  const [country, setCountry] = useState<FiltersContext['country']>(null);
  const [installationName, setInstallationName] = useState<string>('');
  const [clientId, setClientId] = useState<string>('');
  const [commissioningDates, setCommissioningDates] = useState<[string, string] | null>(null);
  const startDate = commissioningDates && new Date(commissioningDates[0]);
  const endDate = commissioningDates && new Date(commissioningDates[1]);
  const [bookName, setBookName] = useState<string>('');
  const [events, setEvents] = useState<FiltersContext['events']>([]);
  const [contractTypes, setContractTypes] = useState<FiltersContext['contractTypes']>([]);

  const { infoFilters } = usePortfolioTable();
  const { data, dataType, loading, setPortfolio } = usePortfolio();
  const puissanceCreteMax = useMemo(
    () => (data && data?.length > 0 ? Math.ceil(Math.max(...data.map((d) => d.puissancecrete || 0))) : 0),
    [data],
  );
  const energyMax = useMemo(
    () => (data && data?.length > 0 ? Math.ceil(Math.max(...data.map((d) => Number(d.sumdata) || 0))) : 0),
    [data],
  );

  const [filters, setFilters] = useState<FiltersContext['filters']>({
    puiCrete: [0, puissanceCreteMax],
    energy: [0, energyMax],
    capacityFactor: [0, 100],
    coverage: [0, 100],
  });

  const filterData = (d: DashboardViewAllData) => {
    if (type && type !== 'all' && d.type !== type) return false;

    return true;
  };

  useEffect(() => {
    let portfolio = dataType === 'books' ? data?.filter(({ book }) => book) : data?.filter(({ book }) => !book);

    portfolio ||= [];

    if (installationName !== '' || (bookName !== '' && dataType === 'books')) {
      portfolio = matchSorterInstanceStrict(portfolio, installationName || bookName, { keys: ['ref_centrale'] });
    }

    if (dataType === 'installations' && bookName !== '') {
      portfolio = matchSorterInstanceStrict(portfolio, bookName, { keys: ['books'] });
    }

    if (dataType === 'installations' && clientId !== '') {
      portfolio = matchSorterInstanceStrict(portfolio, clientId, { keys: ['id_client'] });
    }

    if (dataType === 'installations' && country) {
      portfolio = matchSorterInstanceStrict(portfolio, country, { keys: ['installation_country'] });
    }

    if (dataType === 'installations' && contractTypes.length > 0) {
      portfolio = portfolio.filter((dashboardData) =>
        contractTypes.some((contractType) => {
          // Cannot use includes because contract_type can either be null or undefined
          if (contractType === null && !dashboardData.contract_type) {
            return !dashboardData.contract_type;
          }

          return dashboardData.contract_type === contractType;
        }),
      );
    }

    if (commissioningDates) {
      portfolio = portfolio.filter((d) => {
        const itemDate = new Date(d.commissioning_date);

        return (
          !isValidDate(startDate) ||
          !isValidDate(endDate) ||
          (startDate && endDate && itemDate > startDate && itemDate < endDate)
        );
      });
    }

    if (filters) {
      portfolio = portfolio?.filter((d) => {
        const puissanceCrete = d.puissancecrete;
        const energy = Number(d.sumdata) || 0;
        const capacityFactor = d.capacity_factor;
        const coverage = d.coverage;

        const condition =
          (puissanceCrete || 0) >= filters.puiCrete[0] &&
          (puissanceCrete || 0) <= filters.puiCrete[1] &&
          energy >= filters.energy[0] &&
          energy <= filters.energy[1] &&
          Number(capacityFactor) >= filters.capacityFactor[0] &&
          Number(capacityFactor) <= filters.capacityFactor[1] &&
          Number(coverage) >= filters.coverage[0] &&
          Number(coverage) <= filters.coverage[1];

        return condition;
      });
    }

    if (events?.length) {
      portfolio = portfolio?.filter((d) => {
        return d.event_types?.some((event_type) => events.includes(event_type));
      });
    }

    setPortfolio?.(portfolio.filter(filterData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bookName,
    dataType,
    filters,
    installationName,
    country,
    setPortfolio,
    type,
    events,
    commissioningDates,
    clientId,
    contractTypes,
  ]);

  const contextValue = useMemo<FiltersContext>(() => {
    const context: FiltersContext = {
      type,
      setType,
      country,
      setCountry,
      installationName,
      setInstallationName,
      commissioningDates,
      setCommissioningDates,
      clientId,
      setClientId,
      bookName,
      setBookName,
      filters,
      setFilters,
      events,
      setEvents,
      contractTypes,
      setContractTypes,
    };

    return context;
  }, [
    type,
    country,
    setCountry,
    installationName,
    commissioningDates,
    clientId,
    bookName,
    filters,
    events,
    contractTypes,
    setContractTypes,
  ]);

  const handleEventFilterChange = (checked: boolean, eventType: EventType) => {
    setEvents((currentEvents) => {
      if (checked) {
        return [...(currentEvents || []), eventType];
      } else {
        return currentEvents?.filter((event) => event !== eventType);
      }
    });
  };

  return (
    <filtersContext.Provider value={contextValue}>
      <FilterWrapper className="p-0">
        <FilterContainer size="datepicker">
          <DatesFilter />
        </FilterContainer>

        {dataType === 'installations' && <InstallationFilters />}
        {dataType === 'installations' && <CountryFilters />}
        {dataType === 'books' && <BookFilters />}

        {dataType === 'installations' && (
          <FilterContainer size="fit">
            <ContractTypeFilter />
          </FilterContainer>
        )}

        <FilterContainer size="fit">
          <div style={{ zIndex: 100 }} className={loading ? 'unavailable' : ''}>
            <DropDownButton label="common.metrics" icon="Filter" size="xs" position="right">
              {data && !!data.length && <PortfolioFilters />}
            </DropDownButton>
          </div>
        </FilterContainer>
        <FilterContainer size="fit">
          <AlertsFilter initialEvents={events} onEventFilterChange={handleEventFilterChange} />
        </FilterContainer>
        {infoFilters.commissioningDate && (
          <FilterContainer size="fit">
            <CommissioningDatesFilter />
          </FilterContainer>
        )}
        {infoFilters.clientId && (
          <FilterContainer size="fit">
            <ClientFilter />
          </FilterContainer>
        )}

        <FilterContainer size="fit">
          <MapToggle />
        </FilterContainer>
      </FilterWrapper>
    </filtersContext.Provider>
  );
};
