import React from 'react';
import useTranslation from '@hooks/useTranslation';
import InstallationBooks from '@ui/InstallationBooks';
import Installation from '@utils/types/installation';
import styles from '../../characteristics.module.scss';

export const Books: React.FC<{ installation: Installation }> = ({ installation }) => {
  const { t } = useTranslation();

  if (!installation.books?.length) return null;

  const bookNames = installation.books.map((book) => book.name);

  return (
    <div className={styles.item}>
      <div className="tt:uppercase fw:700 mb-4 d-flex">
        <span className="mr-2">{t('common.books').toLocaleUpperCase()}</span>
      </div>

      {!!installation.books?.length && <InstallationBooks books={bookNames} />}
    </div>
  );
};
