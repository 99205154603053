import React from 'react';
import { Restricted } from '@components/Restricted';
import { useUser } from '@context/User.context';
import { Badge } from '@GDM/Badge';
import { Icon } from '@GDM/Icon';
import { Tooltip } from '@GDM/Tooltip/Tooltip';
import { useGenKey } from '@hooks/useGenKey';
import { CellContext } from '@tanstack/react-table';
import { iconDictionary } from '@utils/constants';
import { EventIcon } from '@utils/getTypeMeterIcons';
import { DashboardViewAllData } from '../../../portfolio.definitions';
import styles from '../../../portfolio.module.scss';
import { usePortfolioTable } from '../context/usePortfolioTable';

export const NameCell: React.FC<React.PropsWithChildren<CellContext<DashboardViewAllData, unknown>>> = ({ row }) => {
  const { permissions, isAuthorized } = useUser();
  const { getKey } = useGenKey();
  const { infoFilters } = usePortfolioTable();
  const userIsSalesFocusOnly = isAuthorized(['display:view_salesfocus']) && !isAuthorized(['display:view_monitoring']);
  const value = row.original.ref_centrale;
  const isDraft = row.original.installation_status === 'draft';

  let resource = 'meter';
  if (userIsSalesFocusOnly) resource = 'installation';
  if (row.original.type === 'book') resource = 'book';

  const url = `/v2/dashboard_meter/?${resource}=${encodeURIComponent(value)}`;

  return (
    <a href={url} className={styles['meter-name']}>
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center">
          <Icon name={iconDictionary[row.original.type || 'other']} size={14} className="mr-2" />
          <span>{value}</span>
          {isDraft && <Badge className="ml-2 d-flex align-items-center" variant="danger" label="common.draft" />}
        </div>
        {row.original.id_client && infoFilters.clientId && (
          <Tooltip tooltip="common.client_id">
            <div className="text-grey fw:300 text-small">{row.original.id_client}</div>
          </Tooltip>
        )}
      </div>
      <Restricted permissions={['display:view_monitoring_alerts_page']}>
        <div>
          {row.original.event_types &&
            row.original.event_types.map((event_type, i) =>
              event_type === 'OutOfRangeReactive' &&
              !permissions?.includes('display:view_monitoring_dashboard_meter_reactive')
                ? null
                : row.original.event_types?.[i] && (
                    <EventIcon className="ml-2" type={event_type} key={getKey({ type: row.original.event_types[i] })} />
                  ),
            )}
        </div>
      </Restricted>
    </a>
  );
};
