import React from 'react';
import { Chart } from '@GDM/Chart';
import merge from 'lodash/merge';

const defaultOptions: Highcharts.Options = {
  chart: {
    type: 'area',
    margin: [2, 0, 2, 0],
    width: 120,
    height: 20,
    style: {
      overflow: 'visible',
    },
  },
  xAxis: {
    type: 'datetime',
  },
  yAxis: {
    endOnTick: false,
    startOnTick: false,
    tickPositions: [0],
  },
  plotOptions: {
    area: {
      fillOpacity: 0.25,
    },
    series: {
      lineWidth: 1,
      marker: {
        radius: 1,
        states: {
          hover: {
            radius: 2,
          },
        },
      },
    },
  },
};

type Props = { options: Highcharts.Options };

export const Sparkline: React.FC<Props> = ({ options }) => {
  const mergedOptions = merge(defaultOptions, options);

  return <Chart options={mergedOptions} series={options.series} hideLegend />;
};
