import React from 'react';
import { useUser } from '@context/User.context';
import { ValueCard } from '@GDM/Card';
import { ValueWithUnit } from '@GDM/ValueWithUnit';
import { appendColon } from '@utils/string';
import styles from '../realised.module.scss';

export const CapturePriceCard: React.FC<{ localCapturePrice: number | null; nationalCapturePrice: number | null }> = ({
  localCapturePrice,
  nationalCapturePrice,
}) => {
  const { locale } = useUser();

  return (
    <ValueCard value={nationalCapturePrice} title="pricing.local_capture_price" unit="EUR/MWh" className={styles.card}>
      <span dangerouslySetInnerHTML={{ __html: appendColon('pricing.national_capture_price', locale) }} />{' '}
      <ValueWithUnit value={localCapturePrice} unit="EUR/MWh" />
    </ValueCard>
  );
};
