import React, { useEffect, useMemo, useState } from 'react';
import { useSubUsersContext } from '@context/admin/users/subusers.context';
import { Button } from '@GDM/Button';
import { Input } from '@GDM/forms';
import { Text } from '@GDM/Text';
import { patch, post, useRequest } from '@hooks/useRequest';
import { v2_sub_client_admin_index_path, v2_sub_client_admin_path } from '@utils/routes';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Book } from '@utils/types/book';
import { type LightWeightInstallation } from '@utils/types/installation';
import { SubUserWithAdditionalInfo } from '@utils/types/user';
import isEmpty from 'lodash/isEmpty';
import { FormProvider, useForm } from 'react-hook-form';
import { UserFormType } from '../../types';
import { CompanyEmailInput } from './CompanyEmailInput';
import { InstallationsBooksSelect } from './InstallationsBooksSelect';
import { InternalCheckbox } from './InternalCheckbox';
import { NameInput } from './NameInput';

export const UserInfos: React.FC<{
  selectedSubUser: SubUserWithAdditionalInfo | null;
  onClose?: () => void;
}> = ({ selectedSubUser, onClose }) => {
  const [showPassword, setShowPassword] = useState(false);
  const { addSubUser, updateSubUser, books, installations } = useSubUsersContext();

  const options = useMemo(() => formatOptions(books, installations), [books, installations]);
  const selectedInstallations = options
    .flatMap((opt) => opt.options)
    .filter(
      (opt) =>
        selectedSubUser?.installations?.map((installation) => installation.name)?.includes(opt.label) ||
        selectedSubUser?.books?.map((book) => book.name)?.includes(opt.label),
    );

  const form = useForm<UserFormType>({
    defaultValues: {
      access_admin_installations: selectedSubUser?.access_admin_installations || false,
      email: selectedSubUser?.email || '',
      installations: selectedInstallations,
      name: selectedSubUser?.name || '',
      internal: !selectedSubUser?.limited || false,
    },
  });

  const createUserRequest = useRequest<SubUserWithAdditionalInfo>(v2_sub_client_admin_index_path(), post, true);
  const updateUserRequest = useRequest<SubUserWithAdditionalInfo>(
    v2_sub_client_admin_path(selectedSubUser?.uuid || 'none'),
    patch,
    true,
  );

  const disabled = !!selectedSubUser?.uuid;

  useEffect(() => {
    if (createUserRequest.loaded) {
      if (createUserRequest.errors) {
        createUserRequest.errors.forEach((e) => {
          const keys = Object.keys(e) as (keyof UserFormType)[];
          keys.forEach((key) => form.setError(key, { message: 'has error' }));
        });
      }

      if (createUserRequest.data) addSubUser(createUserRequest.data);
    }
  }, [createUserRequest.data, createUserRequest.loaded, addSubUser, createUserRequest.errors, form]);

  useEffect(() => {
    if (updateUserRequest.loaded) {
      if (updateUserRequest.errors) {
        updateUserRequest.errors.forEach((e) => {
          const keys = Object.keys(e) as (keyof UserFormType)[];
          keys.forEach((key) => form.setError(key, { message: 'has error' }));
        });
      }

      if (updateUserRequest.data) updateSubUser(updateUserRequest.data);
    }
  }, [updateUserRequest.data, updateUserRequest.loaded, updateSubUser, updateUserRequest.errors, form]);

  const onSubmit = (value: UserFormType) => {
    const { name, email, internal, access_admin_installations } = value;
    const installations = value.installations.filter((i) => i.type === 'installation').map((i) => i.value);
    const books = value.installations.filter((i) => i.type === 'book').map((i) => i.value);
    const user: Partial<SubUserWithAdditionalInfo> = { name, email, limited: !internal, access_admin_installations };

    const data: {
      user: Partial<SubUserWithAdditionalInfo>;
      installations: string[];
      books: string[];
    } = { installations, books, user };

    if (!selectedSubUser?.uuid) {
      createUserRequest.execute?.(data);
    } else {
      updateUserRequest.execute?.(data);
    }
  };

  return (
    <FormProvider {...form}>
      <div>
        <NameInput disabled={disabled} />
      </div>

      <div className="my-3">
        <CompanyEmailInput disabled={disabled} />
      </div>

      <div className="my-3">
        <InternalCheckbox disabled={disabled} />
      </div>

      <div className="my-3">
        <InstallationsBooksSelect options={options} />
      </div>

      {selectedSubUser?.uuid ? (
        <div className="my-3">
          <Input
            label="common.password"
            type={showPassword ? 'text' : 'password'}
            disabled
            value={selectedSubUser.init_password}
            suffixButtonProps={{
              icon: showPassword ? 'EyeOff' : 'Eye',
              variant: 'primary-2',
              onClick: () => setShowPassword((v) => !v),
            }}
          />
        </div>
      ) : null}

      {!isEmpty(form.formState.errors) && <Text type="danger" text="errors.form_invalid" />}
      {createUserRequest.error && <Text type="danger" text={createUserRequest.error.code} icon="AlertCircle" />}

      <div className="d-flex">
        {onClose && (
          <Button variant="link" size="sm" text="common.cancel" onClick={onClose} className="ml-auto mr-2 mt-3" />
        )}

        <Button
          className="mr-0 mt-3"
          data-cy="button-saving-add-user"
          type="submit"
          disabled={createUserRequest.loading || updateUserRequest.loading || !isEmpty(form.formState.errors)}
          onClick={form.handleSubmit(onSubmit)}
          isLoading={createUserRequest.loading || updateUserRequest.loading}
          size="sm"
          variant="primary-2"
          text="common.save"
        />
      </div>
    </FormProvider>
  );
};

const formatOptions = (
  books: Book[],
  installations: LightWeightInstallation[],
): { label: string; options: UserFormType['installations'] }[] => {
  const booksOptions: UserFormType['installations'] =
    books?.map((book) => ({
      label: book.name,
      value: book.name,
      type: 'book',
      energy: 'book',
      installations_size: book.installations_size,
    })) || [];

  const installationsOptions: UserFormType['installations'] =
    installations?.map((installation) => ({
      label: installation.name,
      value: installation.name,
      type: 'installation',
      energy: installation.energy,
      status: installation.status,
      installations_size: 1,
    })) || [];

  return [
    { label: 'common.books', options: booksOptions.sort(sortOptionsByLabelAsc) },
    { label: 'common.installations', options: installationsOptions.sort(sortOptionsByLabelAsc) },
  ];
};
