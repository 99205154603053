import React from 'react';
import { sectionStyles } from '@GDM/forms';
import { DateInput } from '@pages/Contracts/Contract/Form/components/Inputs/Date';
import { ListInput } from '@pages/Contracts/Contract/Form/components/Inputs/List';
import { NumericInput } from '@pages/Contracts/Contract/Form/components/Inputs/Numeric';
import { getSymbol } from '@utils/currency/getSymbol';
import { ContractForm } from '@utils/types/contract';
import { Currency } from '@utils/types/currency';
import { Control } from 'react-hook-form';
import pricingStyles from '../pricing.module.scss';

export const RefPrice = ({
  control,
  readOnly,
  currency,
}: {
  control: Control<ContractForm>;
  readOnly: boolean;
  currency: Currency;
}) => (
  <NumericInput
    id="RefPrice"
    name="contract_sub_periods_attributes.0.display_price"
    control={control}
    label="sales_management.ref_price"
    readOnly={readOnly}
    data-cy="ref-price"
    suffix={`${getSymbol(currency)}/MWh`}
  />
);

export const RefPriceWithRateChange = ({
  control,
  readOnly,
  currency,
  birthdayDate,
}: {
  control: Control<ContractForm>;
  readOnly: boolean;
  currency: Currency;
  birthdayDate: ContractForm['birthday_date'];
}) => (
  <div className={sectionStyles.row}>
    <RefPrice control={control} readOnly={readOnly} currency={currency} />
    <ListInput
      id="RateChangePicker"
      name="rate_change"
      control={control}
      options={[
        { value: 'commissioning_date', label: 'sales_management.commissioning_date' },
        { value: '1st_nov', label: 'sales_management.1st_nov' },
        { value: '1st_jan', label: 'sales_management.1st_jan' },
      ]}
      menuPlacement="top"
      className={pricingStyles['rate-change-picker']}
      isSearchable
      readOnly={readOnly}
      classNamePrefix="select-rate-change"
      label="sales_management.rate_change"
    />
    <DateInput
      id="BirthdayDatePicker"
      name="birthday_date"
      label="sales_management.commissioning_date"
      data-cy="birthday-date"
      readOnly={readOnly}
      control={control}
      tooltip={birthdayDate && new Date(birthdayDate) > new Date() ? 'sales_management.date_in_the_future' : null}
    />
  </div>
);
