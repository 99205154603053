/**
 * THIS FILE IS AUTO_GENERATED DO NOT UPDATE IT
 * RUN THE FOLLOWING COMMAND TO GENERATE IT : rails runner "./scripts/front_static/generate_price_accepted_sources_types.rb"
 */

export const SPOT_SOURCES = {
  BE: ['EEX'],
  DE: ['EEX'],
  ES: ['EEX'],
  FR: ['EEX'],
  IT: ['IPEX', 'EEX'],
  NL: ['EEX'],
  PL: ['EEX', 'TGE_HOURLY'],
  PT: ['EEX'],
  UK: ['EPEX', 'IMRP', 'N2EX'],
  FI: ['N2EX'],
} as const;
