import React from 'react';
import { UserProvider } from '@components/providers/UserProvider';
import { Alert } from '@GDM/Alert';
import { useRequest } from '@hooks/useRequest';
import { v2_global_messages_path } from '@utils/routes';
import { GlobalMessage } from '@utils/types/globalMessage';
import { User } from '@utils/types/user';

export const GlobalMessages: React.FC<{ user: User }> = ({ user }) => {
  const { data: messages, error } = useRequest<GlobalMessage[]>(v2_global_messages_path(), 'GET', false);

  if (!messages || messages.length === 0 || !!error) return null;

  return (
    <UserProvider user={user}>
      <div className="mb-4 empty-no-margin" data-cy="global-messages">
        {messages.map((message) => (
          <Alert
            className="mb-2 text-small"
            key={message.id}
            label={user.locale === 'fr' ? message.fr_message : message.en_message}
            variant={message.message_type}
            size="sm"
            dismissible
          />
        ))}
      </div>
    </UserProvider>
  );
};
