import React from 'react';
import { Input } from '@GDM/forms';
import { useFieldRules } from '@pages/Simulations/NewSimulation/utils/getFieldRules';
import { SimulationForm } from '@pages/Simulations/NewSimulation/utils/simulations.types';
import dayjs from 'dayjs';
import { Controller, useFormContext } from 'react-hook-form';
import styles from '../../../new-simulation.module.scss';

export const FinishInput: React.FC<{ index: number }> = ({ index }) => {
  const { control } = useFormContext<SimulationForm>();
  const { fieldRules } = useFieldRules();
  const rules = fieldRules('ppa_wind_bounds_attributes.0.finish');

  return (
    <Controller
      control={control}
      name={`ppa_wind_bounds_attributes.${index}.finish`}
      rules={rules}
      render={({ field, fieldState }) => (
        <div className={styles['constraint-input-container']}>
          <Input
            type="time"
            hasError={!!fieldState.error}
            onChange={(e) => {
              const splitTime = e.target.value.split(':');
              const date: Date = dayjs().set('hour', +splitTime[0]).set('minute', +splitTime[1]).toDate();
              field.onChange(date);
            }}
            value={dayjs(field?.value).format('HH:mm')}
            label="pricing.project.finish_time"
            size="lg"
            full
          />
        </div>
      )}
    />
  );
};
