import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button } from '@GDM/Button';
import { Select } from '@GDM/forms';
import { Col, Row } from '@GDM/layout';
import { Modal } from '@GDM/Modal';
import useTranslation from '@hooks/useTranslation';
import { Option } from '@utils/types/common-types';
import { selectedContractsContext } from '../context';
import styles from '../contracts.module.scss';
import { RemitForm } from './RemitForm';
import { SubPeriodPropertyForm } from './SubPeriodPropertyForm';

type UpdatableKeys = 'display_price' | 'sent_to_remit';

export const MassEditModal: React.FC<{ isOpen: boolean; toggle: () => void }> = ({ isOpen, toggle }) => {
  const { selectedContracts } = useContext(selectedContractsContext);
  const { t } = useTranslation();

  const [selectedKey, setSelectedKey] = useState<UpdatableKeys | null | undefined>(null);

  const PROPERTY_OPTIONS: Option<UpdatableKeys>[] = [
    { label: 'sales_management.mass_update_fixed_price_soa', value: 'display_price' },
    { label: 'sales_management.mass_update_sent_to_remit', value: 'sent_to_remit' },
  ];

  const closeModal = useCallback(() => {
    setSelectedKey(null);
    toggle();
  }, [toggle]);

  useEffect(() => {
    return () => {
      setSelectedKey(null);
    };
  }, [isOpen]);

  return (
    <Modal
      header={t('sales_management.mass_update_title', { contract_nb: selectedContracts.length })}
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      hideCancelButton
      scrollable
    >
      <Row className={styles['mass-edit-top-row']}>
        <Col xl="6">
          <Select
            label="sales_management.mass_update_property_to_update"
            options={PROPERTY_OPTIONS}
            selectedOption={selectedKey}
            onChange={(option) => setSelectedKey(option?.value)}
            size="lg"
          />
        </Col>
      </Row>

      {selectedKey === 'sent_to_remit' && <RemitForm onAfterUpdate={closeModal} />}
      {selectedKey === 'display_price' && (
        <SubPeriodPropertyForm propertyToUpdate={selectedKey} onAfterUpdate={closeModal} />
      )}

      {!selectedKey && (
        <div className={styles['modal-footer']}>
          <Button size="sm" type="button" variant="link" onClick={toggle} text="common.cancel" />
        </div>
      )}
    </Modal>
  );
};
