import React from 'react';
import { Icon } from '@GDM/Icon';
import { LightDefinition } from '@GDM/LightDefinitionList';
import { ValueWithUnit } from '@GDM/ValueWithUnit';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import { formatDate } from '@utils/formatParseDate';
import styles from './simulation-settings.module.scss';
import { SimulationSettingsSolar } from './SimulationSettingsSolar';
import { SimulationSettingsWind } from './SimulationSettingsWind';

export const SimulationSettingsInstallation: React.FC = () => {
  const project = useProjectContext();

  return (
    <>
      {project.installation?.energy === 'wind' && <SimulationSettingsWind />}
      {project.installation?.energy === 'solar' && <SimulationSettingsSolar />}

      {project.simulation?.start_date && project.simulation?.end_date && (
        <LightDefinition
          className={styles.definition}
          label="pricing.project.ref_period"
          value={
            <div className={styles['ref-period']}>
              {formatDate(project.simulation.start_date)}
              <Icon name="ArrowRight" size={14} className="mx-1" />
              {formatDate(project.simulation.end_date)}
            </div>
          }
        />
      )}

      {project.simulation?.fixed_stop_strike && (
        <LightDefinition
          className={styles.definition}
          label="pricing.project.fixed_strike"
          value={<ValueWithUnit value={project.simulation.fixed_stop_strike} unit="EUR/MWh" unstyled />}
        />
      )}

      {project.simulation?.dynamic_stop_strike && (
        <LightDefinition
          className={styles.definition}
          label="pricing.project.dynamic_stop_strike"
          value={<ValueWithUnit value={project.simulation.dynamic_stop_strike} unit="EUR/MWh" unstyled />}
        />
      )}
    </>
  );
};
