import React from 'react';
import { AbsoluteValueWithPercentageCell } from '@GDM/Table/Cells/AbsoluteValueWithPercentageCell';
import { CellContext } from '@tanstack/react-table';
import { computeBillablePowerAsPercentage } from '@utils/invoices/computeBillablePowerAsPercentage';
import { MeterInvoice } from '@utils/types/meter-invoice';

export const BillableProdCell: React.FC<CellContext<MeterInvoice, unknown>> = ({ row: { original }, getValue }) => {
  return (
    <AbsoluteValueWithPercentageCell
      value={getValue()}
      percentage={computeBillablePowerAsPercentage(original)}
      percentageTooltip="monitoring.invoicing.billable_percentage_tooltip"
    />
  );
};
