import React from 'react';
import { Text } from '@GDM/Text';
import { MeterInvoice } from '@utils/types/meter-invoice';
import { InstallationInfo } from './InstallationInfo';

export const EmailInfoModal: React.FC<{
  invoices: MeterInvoice[];
  className?: string;
}> = ({ invoices, className }) => {
  if (invoices.length === 0) return null;

  return (
    <div className={className}>
      <Text className="fw:600 mb-2" text="monitoring.invoicing.sent_by_email" size="md" />
      {invoices.map((inv, index) => (
        <InstallationInfo invoice={inv} last={index === invoices.length - 1} key={inv.uuid} />
      ))}
    </div>
  );
};
