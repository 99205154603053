import React from 'react';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import classNames from 'classnames';
import styles from './cards.module.scss';
import { ConfidenceIntervalCard } from './ConfidenceIntervalCard';
import { MarketDeviationFactorCard } from './MarketDeviationFactorCard';

export const Cards: React.FC = () => {
  const project = useProjectContext();
  const isBook = project?.isBook;

  return (
    <div className={classNames(styles['cards-container'], isBook && styles['cards-container-book'])}>
      <MarketDeviationFactorCard />
      <ConfidenceIntervalCard confidence="50" />
      <ConfidenceIntervalCard confidence="80" />
    </div>
  );
};
