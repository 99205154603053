import React, { useState } from 'react';
import { Icon, IconNames } from '@GDM/Icon';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import styles from './extendable-box.module.scss';

export const ExtendableBox: React.FC<
  React.PropsWithChildren<{
    className?: string;
    icon?: IconNames;
    title?: string;
  }>
> = ({ children, className, icon, title }) => {
  const { t } = useTranslation();
  // The difference is than isOpen is just the 'open' state,
  // and isExpanded is when the box is fully open (the animation take 700ms to complete)
  const [isOpen, setIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const classList = classNames(styles['extendable-box'], className, {
    [styles['is-open']]: isOpen,
    [styles['is-expanded']]: isExpanded,
  });

  const toggle = (previousValue: boolean) => {
    if (!previousValue) {
      setTimeout(() => setIsExpanded(true), 700);
    } else {
      setIsExpanded(false);
    }

    setIsOpen(!previousValue);
  };

  return (
    <div className={classList}>
      <button
        className={classNames(styles['button'], styles['trigger'])}
        onClick={() => toggle(isOpen)}
        title={t(title)}
      >
        <Icon name={icon || 'Settings'} size={20} title={title} />
      </button>
      <div className={styles['content']}>
        <div className={styles['children']}>{children}</div>

        <button className={classNames(styles['button'], styles['collapse'])} onClick={() => toggle(isOpen)}>
          <Icon name="ChevronLeft" size={24} />
        </button>
      </div>
    </div>
  );
};
