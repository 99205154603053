import React from 'react';
import { ValueCard } from '@GDM/Card';
import useTranslation from '@hooks/useTranslation';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import styles from './cards.module.scss';

export const LocalCapturePriceYearCard: React.FC = () => {
  const project = useProjectContext();

  const { q50_forward } = project?.full_merchant?.full_merchant_result || {};
  const { full_merchant_cannibalized_distribution_cat } = project?.full_merchant || {};
  const { t } = useTranslation();
  const year = full_merchant_cannibalized_distribution_cat?.[1];

  return (
    <ValueCard
      title={t('pricing.project.full_merchant_capture_price', { year })}
      value={q50_forward || null}
      unit="EUR/MWh"
      className={styles.card}
    />
  );
};
