import React from 'react';
import { useSelectedInvoicesContext } from '@context/selectInvoices.context';
import { usePermittedStatus } from '@hooks/requests/invoices/usePermittedStatus';
import useTranslation from '@hooks/useTranslation';
import { useInvoicesContext } from 'pages/sales-management/invoicing/invoicesContext';
import useInvoiceNumbering from 'pages/sales-management/invoicing/utils/useInvoiceNumbering';
import styles from '../actions.module.scss';

type SendEmailButtonProps = {
  handleSendEmail: () => void;
};

const SendEmailButton: React.FC<SendEmailButtonProps> = ({ handleSendEmail }) => {
  const { t } = useTranslation();
  const { disabled } = usePermittedStatus('generated');
  const { handleClick } = useInvoiceNumbering();
  const { filteredInvoices } = useInvoicesContext();
  const { selectedInvoicesUuids } = useSelectedInvoicesContext();

  const nominatedSelectedInvoices = filteredInvoices.filter(
    (invoice) => selectedInvoicesUuids.includes(invoice.uuid) && invoice.contract_invoicing_platform,
  );

  return (
    <button
      onClick={handleClick(handleSendEmail, 'send_email')}
      disabled={disabled || nominatedSelectedInvoices.length > 0}
      className={styles['menu-element']}
      data-cy="send-email-button"
    >
      {t('sales_management.invoice_tooltip_send_final_invoice')}
    </button>
  );
};

export default SendEmailButton;
