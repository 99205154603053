import React from 'react';
import { useUser } from '@context/User.context';
import { useSelectedInstallation } from '../context/selectedInstallation.context';
import { EndDateInput } from '../fields/General/EndDateInput';
import { FullMerchantToggle } from '../fields/General/FullMerchantToggle';
import { PerturbatePricesToggle } from '../fields/General/PerturbatePricesToggle';
import { StartDateInput } from '../fields/General/StartDateInput';
import { GermanSimulation } from '../GermanSimulation';
import styles from '../new-simulation.module.scss';

export const SimulationSection: React.FC = () => {
  const { selectedInstallation } = useSelectedInstallation();
  const user = useUser();
  const country = selectedInstallation?.country || user.geolocation[0];

  return (
    <div className="p-3">
      <div className={styles['sub-form']}>
        <div className={styles.row}>
          <StartDateInput />
          <EndDateInput />
        </div>

        <div className={styles.row}>
          <FullMerchantToggle />
        </div>

        {country === 'FR' && (
          <div className={styles.row}>
            <PerturbatePricesToggle />
          </div>
        )}

        {country === 'DE' && <GermanSimulation />}
      </div>
    </div>
  );
};
