import React from 'react';
import { Definition } from '@GDM/DefinitionList';
import useTranslation from '@hooks/useTranslation';
import { formatSystemDate } from '@utils/formatters';
import { Meter } from '@utils/types/meter';
import { useMeterInfo } from '../useMeterInfo';
import { CommissioningDateModal } from './CommissioningDateModal';

export const CommissioningDateDefinition: React.FC<{ meter: Meter }> = ({ meter }) => {
  const { commissioning_date } = useMeterInfo();
  const { t } = useTranslation();

  return (
    <Definition
      label="monitoring.installation.commissioning_date"
      value={commissioning_date ? formatSystemDate(commissioning_date) : t('common.none')}
      modal={{
        node: <CommissioningDateModal meter={meter} />,
        title: 'monitoring.installation.commissioning_date_title',
        permission: 'display:edit_monitoring',
      }}
    />
  );
};
