import React from 'react';
import { Select } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { PricingMapsFilters } from '../utils/pricing-maps.types';

export const ResultSelect: React.FC<{
  onChange: (option: Option<PricingMapsFilters['result']> | null) => void;
  selectedOption: PricingMapsFilters['result'] | null;
}> = ({ selectedOption, onChange }) => {
  const options: Option<PricingMapsFilters['result']>[] = [
    { label: 'common.deviation', value: 'deviation' },
    { label: 'pricing.portfolio.full_merchant', value: 'full_merchant' },
  ];

  return <Select options={options} selectedOption={selectedOption} onChange={onChange} />;
};
