import React, { useCallback } from 'react';
import { Button } from '@GDM/Button';
import { useKeypress } from '@hooks/useKeyPress';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import styles from './slide-in-panel.module.scss';

/**
 * @param toggle - a function whose `direction` argument defines whether the
 * panel should _open_ (`true`) or _close_ (`false`).
 */
export const SlideInPanel: React.FC<
  React.PropsWithChildren<{
    actions?: React.ReactNode;
    isOpen?: boolean;
    title?: string | React.ReactNode;
    subtitle?: string;
    toggle: (direction?: boolean) => void;
  }>
> = ({ actions, children, isOpen, subtitle, title, toggle }) => {
  const { t } = useTranslation();

  const handleEscapeKeyPress = useCallback(() => {
    if (!isOpen) return;

    toggle(false);
  }, [isOpen, toggle]);

  useKeypress('Escape', handleEscapeKeyPress);

  return (
    <>
      <div className={classNames(styles.backdrop, !!isOpen && styles['is-open'])} onClick={() => toggle(false)} />
      <div className={classNames(styles.wrapper, !!isOpen && styles['is-open'])}>
        <div className={styles.header}>
          <div
            className={styles['title-wrapper']}
            title={typeof title === 'string' ? `${t(title)} - ${t(subtitle)}` : ''}
          >
            <div className={styles.title}>{typeof title === 'string' ? t(title) : title}</div>
            {subtitle && <span className={styles['subtitle']}>{t(subtitle)}</span>}
          </div>
          {actions && <div className={styles.actions}>{actions}</div>}
          <div className={styles.close}>
            <Button
              icon="X"
              tooltip="common.close"
              data-place="left"
              data-cy="close-slide-in-panel"
              onClick={() => toggle(false)}
              floating
            />
          </div>
        </div>
        <div className={styles['content-wrapper']}>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </>
  );
};
