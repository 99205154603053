import React from 'react';
import { Definition } from '@GDM/DefinitionList';
import { ValueWithUnit } from '@GDM/ValueWithUnit';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import { useSelectedYear } from '@pages/Simulations/Simulation/utils/selectedYear.context';
import dayjs from 'dayjs';

const getBaseloadMarketPriceLabel = (selectedYear: string | null) => {
  if (!selectedYear) return null;

  const year = dayjs().year();
  const selectedYearNumber = Number(selectedYear);

  if (year === selectedYearNumber) {
    return {
      label: 'pricing.project.baseload_market_price_label_current_year',
      tooltip: 'pricing.project.baseload_market_price_tooltip_current_year',
    };
  } else if (year > selectedYearNumber) {
    return {
      label: 'pricing.project.baseload_market_price_label_past',
      tooltip: null,
    };
  } else if (year < selectedYearNumber) {
    return {
      label: 'pricing.project.baseload_market_price_label_future',
      tooltip: null,
    };
  }
};

export const BaseloadMarketPriceDefinition: React.FC = () => {
  const project = useProjectContext();
  const { selectedYear } = useSelectedYear();
  const { forward_prices, full_merchant_cannibalized_distribution_cat } = project?.full_merchant || {};

  if (!selectedYear) return null;

  const yearIndex = full_merchant_cannibalized_distribution_cat?.[selectedYear as unknown as number];
  const forwardPrice = forward_prices?.[yearIndex as unknown as number];
  const labels = getBaseloadMarketPriceLabel(yearIndex || null);

  if (!labels) return null;

  return (
    <Definition
      label={labels.label}
      tooltip={labels.tooltip}
      value={<ValueWithUnit value={forwardPrice} unit="EUR/MWh" />}
    />
  );
};
