import React from 'react';
import { Definition } from '@GDM/DefinitionList';
import { Meter } from '@utils/types/meter';
import { useMeterInfo } from '../useMeterInfo';
import { PowerModal } from './PowerModal';

export const PowerDefinition: React.FC<{ meter: Meter }> = ({ meter }) => {
  const { nominal_power } = useMeterInfo();

  const powerTitle = meter.is_it_production_meter ? 'common.power_rating' : 'common.power_reference';

  return (
    <Definition
      label={powerTitle}
      value={nominal_power ? `${nominal_power} kW` : '-'}
      data-cy="nominal-power"
      modal={{
        node: <PowerModal meter={meter} />,
        title: powerTitle,
        permission: 'display:edit_monitoring',
      }}
    />
  );
};
