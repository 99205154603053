import React, { useMemo, useState } from 'react';
import { AltContent } from '@GDM/AltContent';
import { Col, Row } from '@GDM/layout';
import { Map } from '@GDM/Map';
import Installation from '@utils/types/installation';
import { InstallationMap } from 'pages/ui/shared/maps';
import styles from '../characteristics.module.scss';
import { Panels } from '../shared';
import { Books } from './Books';
import { InstallationInfo } from './InstallationInfo';
import { InstallationInfoContextType, installationInfoContext } from './InstallationInfo/installation-info.context';

export const InstallationCharacteristics: React.FC<{ installation: Installation }> = ({ installation }) => {
  const [lat, setLat] = useState(installation.latitude || null);
  const [lng, setLng] = useState(installation.longitude || null);
  const [client_id, setClientId] = useState(installation.external_ref || null);
  const [erpCode, setErpCode] = useState(installation.erp_code);
  const [siret, setSiret] = useState(installation.siret || null);

  const contextValue = useMemo<InstallationInfoContextType>(
    () => ({
      lat,
      setLat,
      lng,
      setLng,
      client_id,
      setClientId,
      erpCode,
      setErpCode,
      siret,
      setSiret,
    }),
    [client_id, erpCode, lat, lng, siret],
  );

  if (!installation) return <AltContent />;

  return (
    <installationInfoContext.Provider value={contextValue}>
      <Row>
        {!!lat && !!lng && (
          <Col xl={4} className={styles['map-container']}>
            <Map center={[Number(lat), Number(lng)]}>
              <InstallationMap name={installation.name} lat={lat} lng={lng} />
            </Map>
          </Col>
        )}
        <Col>
          <InstallationInfo installation={installation} />
        </Col>
      </Row>

      <div className={styles.configs}>
        <div className={styles.column}>
          <Books installation={installation} />
        </div>
        <div className={styles.column}>
          {installation.energy === 'solar' && installation.has_meter && (
            <div className={styles.item}>
              <Panels meterName={installation.name} />
            </div>
          )}
        </div>
      </div>
    </installationInfoContext.Provider>
  );
};
