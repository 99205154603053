import React from 'react';
import classNames from 'classnames';
import { FieldArrayWithId } from 'react-hook-form';
import { EndMonthInput } from '../fields/Wind/Constraint/EndMonthInput';
import { FinishInput } from '../fields/Wind/Constraint/FinishInput';
import { MaximumPowerInput } from '../fields/Wind/Constraint/MaximumPowerInput';
import { StartInput } from '../fields/Wind/Constraint/StartInput';
import { StartMonthInput } from '../fields/Wind/Constraint/StartMonthInput';
import styles from '../new-simulation.module.scss';
import { SimulationForm } from '../utils/simulations.types';
import { WindConstraintHeader } from './WindConstraintHeader';

export const WindConstraint: React.FC<{
  index: number;
  field: FieldArrayWithId<SimulationForm, 'ppa_wind_bounds_attributes', 'internalId'>;
  handleRemove: (id: number) => () => void;
}> = ({ field, index, handleRemove }) => {
  return (
    <div key={field.internalId} className={styles['wind-constraint']}>
      <WindConstraintHeader index={index} handleRemove={handleRemove} />

      <div className={classNames(styles['constraints-row'])}>
        <StartMonthInput index={index} />
        <EndMonthInput index={index} />
        <StartInput index={index} />
        <FinishInput index={index} />
        <MaximumPowerInput index={index} />
      </div>
    </div>
  );
};
