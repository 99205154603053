import React from 'react';
import { Input } from '@GDM/forms/Input/Input';
import { useFormContext, Controller } from 'react-hook-form';
import { CreateModalForm } from '../../CreateModal/types';
import styles from '../../unavailabilities.module.scss';

export const CommentInput: React.FC = () => {
  const { control } = useFormContext<CreateModalForm>();

  return (
    <Controller
      control={control}
      name="comment"
      render={({ field }) => (
        <Input
          onChange={field.onChange}
          value={field.value ?? ''}
          label="monitoring.unavailabilities.comment"
          className={styles['comment-input']}
        />
      )}
    />
  );
};
