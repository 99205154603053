import React, { ReactNode } from 'react';
import { Card } from '@GDM/Card';
import { useRevenueReportingContext } from '@pages/Reporting/utils/hooks/useRevenueReportingContext';
import classNames from 'classnames';
import styles from './results.module.scss';

export const Results: React.FC<{
  prod: ReactNode;
  prodP0: ReactNode;
  price: ReactNode;
  aggregPrice: ReactNode;
  hedge_quantity: ReactNode;
  hedge_price: ReactNode;
  chart: ReactNode;
  'data-cy'?: string;
}> = ({ 'data-cy': dataCy, prod, prodP0, price, aggregPrice, chart, hedge_quantity, hedge_price }) => {
  const { loading } = useRevenueReportingContext();

  return (
    <div className={classNames(styles.container, loading && styles['is-loading'])} data-cy={dataCy}>
      <div className={styles.production}>{prod}</div>
      <div className={styles['prod-p0']}>{prodP0}</div>
      <div className={styles['average-price']}>{price}</div>
      <div className={styles['average-aggreg-price']}>{aggregPrice}</div>
      {hedge_quantity && <div className={styles['hedge-quantity']}>{hedge_quantity}</div>}
      {hedge_price && <div className={styles['hedge-price']}>{hedge_price}</div>}

      <div className={styles.chart}>
        <Card className={styles.card} data-cy="total-revenue-chart">
          <div className={styles['chart-container']}>{chart}</div>
        </Card>
      </div>
    </div>
  );
};
