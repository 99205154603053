import React from 'react';
import { NumberCell } from '@GDM/Table/Cells/NumberCell';
import { CellContext } from '@tanstack/react-table';
import { Unavailability } from '@utils/types/unavailability';

export const PmaxCell: React.FC<React.PropsWithChildren<CellContext<Unavailability, unknown>>> = ({
  row: { original },
}) => {
  return <NumberCell value={original.p_max} numberOptions={{ maximumFractionDigits: 2, minimumFractionDigits: 2 }} />;
};
