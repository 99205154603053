import React from 'react';
import { Input } from '@GDM/forms';
import { Controller, useFormContext } from 'react-hook-form';
import { useFieldRules } from '../../utils/getFieldRules';
import { SimulationForm } from '../../utils/simulations.types';

export const NameInput: React.FC<{ disabled: boolean }> = ({ disabled }) => {
  const form = useFormContext<SimulationForm>();
  const { fieldRules } = useFieldRules();
  const rules = fieldRules('installation_attributes.name');

  return (
    <Controller
      control={form.control}
      rules={rules}
      name="installation_attributes.name"
      render={({ field, fieldState }) => (
        <Input
          {...field}
          value={field.value ?? ''}
          hasError={!!fieldState.error}
          errorMessage={fieldState.error ? 'pricing.form.errors.installation.name' : null}
          full
          disabled={disabled}
          size="lg"
          label="common.name"
          data-cy="installation-name-input"
        />
      )}
    />
  );
};
