import React from 'react';
import { Input, Select } from '@GDM/forms';
import { Textarea } from '@GDM/forms/Textarea';
import { Option } from '@utils/types/common-types';
import { CompanyBillingDetails } from '@utils/types/company_billing_details';
import { Controller } from 'react-hook-form';
import styles from '../subscription-form.module.scss';
import { useSubscriptionForm } from '../useSubscriptionForm';

export const BillingDetails: React.FC = () => {
  const { control, readonly } = useSubscriptionForm();

  const countryOptions: Option<`${CompanyBillingDetails['country']}`>[] = [
    { value: 'FR', label: 'France' },
    { value: 'ES', label: 'España' },
    { value: 'IT', label: 'Italia' },
    { value: 'CH', label: 'Suisse' },
    { value: 'UK', label: 'United Kingdom' },
    { value: 'BE', label: 'Belgique/België' },
    { value: 'DE', label: 'Deutschland' },
  ];

  const currencyOptions: Option<`${CompanyBillingDetails['currency']}`>[] = [
    { value: 'EUR', label: 'Euro' },
    { value: 'CHF', label: 'Franc Suisse' },
    { value: 'USD', label: 'United States Dollar' },
    { value: 'GBP', label: 'Pound Sterling' },
  ];

  const paymentTypeOptions: Option<`${CompanyBillingDetails['payment_type']}`>[] = [
    { value: 'bank_transfer', label: 'Bank Transfer' },
    { value: 'withdrawal', label: 'Withdrawal' },
  ];

  const taxStatusOptions: Option<`${CompanyBillingDetails['tax_status']}`>[] = [
    { value: 'taxable', label: 'Taxable' },
    { value: 'exempt', label: 'Exempt' },
  ];

  const timeZoneOptions: Option<`${CompanyBillingDetails['time_zone']}`>[] = [
    { value: 'Europe/Paris', label: 'Paris' },
    { value: 'Europe/London', label: 'London' },
  ];

  return (
    <>
      <div className={styles.section}>
        <Controller
          control={control}
          name="company_billing_detail.siren"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              id="siren"
              className={styles.input}
              value={field.value ?? ''}
              label="common.siren"
              size="lg"
              hasError={!!fieldState.error}
              readOnly={readonly}
              full
            />
          )}
        />
        <Controller
          control={control}
          name="company_billing_detail.tax_status"
          render={({ field, fieldState }) => (
            <Select
              options={taxStatusOptions}
              className={styles.input}
              selectedOption={field.value}
              label="common.tax_status"
              onChange={(option) => field.onChange(option?.value)}
              size="lg"
              hasError={!!fieldState.error}
              readOnly={readonly}
              full
            />
          )}
        />
        <Controller
          control={control}
          name="company_billing_detail.tax_id"
          render={({ field, fieldState }) => (
            <Input
              {...field}
              className={styles.input}
              value={field.value ?? ''}
              label="common.tax_id"
              size="lg"
              hasError={!!fieldState.error}
              readOnly={readonly}
              full
            />
          )}
        />
      </div>
      <div className={styles.section}>
        <Controller
          control={control}
          name="company_billing_detail.emails_raw"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <Textarea
              {...field}
              value={field.value ?? ''}
              label="common.emails"
              size="lg"
              tooltip="subscriptions.form.emails_info"
              hasError={!!fieldState.error}
              readOnly={readonly}
              full
            />
          )}
        />
      </div>
      <div className={styles.section}>
        <Controller
          control={control}
          name="company_billing_detail.country"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <Select
              options={countryOptions}
              className={styles.input}
              selectedOption={field.value}
              label="common.country"
              onChange={(option) => field.onChange(option?.value)}
              size="lg"
              hasError={!!fieldState.error}
              readOnly={readonly}
              full
            />
          )}
        />
        <Controller
          control={control}
          name="company_billing_detail.currency"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <Select
              options={currencyOptions}
              className={styles.input}
              selectedOption={field.value}
              label="common.currency"
              onChange={(option) => field.onChange(option?.value)}
              size="lg"
              hasError={!!fieldState.error}
              readOnly={readonly}
              full
            />
          )}
        />
        <Controller
          control={control}
          name="company_billing_detail.time_zone"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <Select
              options={timeZoneOptions}
              className={styles.input}
              selectedOption={field.value}
              label="common.time_zone"
              onChange={(option) => field.onChange(option?.value)}
              size="lg"
              hasError={!!fieldState.error}
              readOnly={readonly}
              full
            />
          )}
        />
        <Controller
          control={control}
          name="company_billing_detail.payment_type"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <Select
              options={paymentTypeOptions}
              className={styles.input}
              selectedOption={field.value}
              label="common.payment_type"
              onChange={(option) => field.onChange(option?.value)}
              size="lg"
              hasError={!!fieldState.error}
              readOnly={readonly}
              full
            />
          )}
        />
        <Controller
          control={control}
          name="company_billing_detail.mandate_ref"
          render={({ field, fieldState }) => (
            <Input
              {...field}
              className={styles.input}
              value={field.value ?? ''}
              label="common.mandate_ref"
              size="lg"
              hasError={!!fieldState.error}
              readOnly={readonly}
              full
            />
          )}
        />
        <Controller
          control={control}
          name="company_billing_detail.iban"
          render={({ field, fieldState }) => (
            <Input
              {...field}
              className={styles.input}
              value={field.value ?? ''}
              label="common.iban"
              size="lg"
              hasError={!!fieldState.error}
              readOnly={readonly}
              full
            />
          )}
        />
        <Controller
          control={control}
          name="company_billing_detail.bic"
          render={({ field, fieldState }) => (
            <Input
              {...field}
              className={styles.input}
              value={field.value ?? ''}
              label="common.bic"
              size="lg"
              hasError={!!fieldState.error}
              readOnly={readonly}
              full
            />
          )}
        />
      </div>
    </>
  );
};
