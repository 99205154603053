import React, { useState } from 'react';
import { CurrencyModalButton } from '@components/Currency/CurrencyModal';
import { usePlayersContext } from '@components/providers';
import { Restricted } from '@components/Restricted';
import { Button, DropDownButton } from '@GDM/Button';
import { DatePicker } from '@GDM/DatePicker';
import { FilterContainer, Filters } from '@GDM/Filters';
import { RadioButtons, Select } from '@GDM/forms';
import { CountrySelect } from '@GDM/forms';
import { Spinner } from '@GDM/Spinner';
import { Text } from '@GDM/Text';
import { RequestError } from '@hooks/useRequest';
import { getSymbol } from '@utils/currency/getSymbol';
import { isCountryCode } from '@utils/filters/isCountryCode';
import Book from '@utils/types/book';
import { Option } from '@utils/types/common-types';
import { Currency } from '@utils/types/currency';
import MarketPlayer from '@utils/types/market_player';
import classNames from 'classnames';
import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';
import { ReportingTabs } from '../reporting.types';
import { useRevenueReportingContext } from '../utils/hooks/useRevenueReportingContext';
import styles from './reports-actions.module.scss';

export const ReportsActions: React.FC<{
  selectedDate: Date;
  handleDateChange: (date: Date | null) => void;
  books: Book[];
  optionsBooks: Option[];
  tabs: ReportingTabs;
  setActiveTab: React.Dispatch<React.SetStateAction<keyof ReportingTabs>>;
  activeTab: keyof ReportingTabs;
  loadingAnnualReport: boolean;
  loading: boolean;
  handleExport: () => void;
  reportRequestLoading: boolean;
  reportRequestError: RequestError | null;
  handleExportAnnualRevenue: ({ forecast }: { forecast: boolean }) => () => void;
  currencies: Currency[];
  selectedCurrency: Currency;
  setSelectedCurrency: React.Dispatch<React.SetStateAction<Currency>>;
}> = ({
  selectedDate,
  handleDateChange,
  books,
  optionsBooks,
  tabs,
  setActiveTab,
  activeTab,
  loadingAnnualReport,
  loading,
  handleExport,
  reportRequestLoading,
  reportRequestError,
  handleExportAnnualRevenue,
  currencies,
  selectedCurrency,
  setSelectedCurrency,
}) => {
  const {
    selectedBook,
    setSelectedBook,
    setSelectedPlayerId,
    selectedPlayerId,
    selectedCountry,
    setSelectedCountry,
    currency,
  } = useRevenueReportingContext();
  const { players } = usePlayersContext();
  const [dateError, setDateError] = useState(false);

  const sortedPlayers = players ? sortBy<MarketPlayer>(players, ['player_type', 'short_name']) : players;
  const playerOptions: Option[] = [
    { value: 'ALL', label: 'monitoring.installation.revenue.all_owners', isItalic: true },
    ...(sortedPlayers?.map(({ id, short_name }) => ({ value: id, label: short_name })) ?? []),
  ];
  const allOwnerIds = players?.filter((p) => p.player_type === 'owner')?.map((p) => p.id);

  const radioOptions = (Object.keys(tabs) as (keyof ReportingTabs)[]).map((tabKey) => ({
    label: tabs[tabKey],
    value: tabKey,
  }));

  const maxDate =
    dayjs().date() > 24 ? dayjs().endOf('month').toDate() : dayjs().subtract(1, 'month').endOf('month').toDate();

  return (
    <div className="d-flex flex-column">
      <Filters className="p-0">
        <FilterContainer size="datepicker">
          <DatePicker
            selected={selectedDate}
            onChange={(date) => {
              if (!date) return;

              if (date > new Date(maxDate)) return setDateError(true);

              setDateError(false);
              handleDateChange(date);
            }}
            picks="months"
            data-cy="date-picker"
            className="date-picker"
            maxDate={maxDate}
            hasError={dateError}
          />
        </FilterContainer>

        {books && (
          <FilterContainer>
            <Select
              options={optionsBooks}
              placeholder="common.book"
              selectedOption={selectedBook}
              onChange={(option) => setSelectedBook?.(option?.value ?? null)}
              isClearable
            />
          </FilterContainer>
        )}
        {books && (
          <FilterContainer>
            <Select
              options={playerOptions}
              placeholder="common.market_player"
              selectedOption={Array.isArray(selectedPlayerId) ? 'ALL' : selectedPlayerId ?? null}
              onChange={(option) => {
                const value = option?.value === 'ALL' ? allOwnerIds : option?.value;
                setSelectedPlayerId?.(value);
              }}
              isClearable
            />
          </FilterContainer>
        )}

        <FilterContainer size="fit">
          <CountrySelect
            placeholder="common.country"
            selectedOption={selectedCountry}
            onChange={(option) =>
              setSelectedCountry?.(option?.value && isCountryCode(option.value) ? option.value : null)
            }
            isClearable
          />
        </FilterContainer>

        <FilterContainer size="fit">
          <Select
            selectedOption={selectedCurrency}
            options={currencies.map((c) => ({ label: `${getSymbol(c)} (${c})`, value: c }))}
            onChange={(option) => option && setSelectedCurrency(option.value)}
          />
        </FilterContainer>

        <FilterContainer size="fit">
          <RadioButtons
            name="reporting-tabs"
            options={radioOptions}
            onChange={(e) => setActiveTab(e)} // TODO: update when strict mode is enabled
            selected={activeTab}
          />
        </FilterContainer>
      </Filters>

      <div className="d-flex justify-content-start align-items-center ml-auto gap-2">
        {(loadingAnnualReport || loading) && <Spinner size="sm" className="mx-1" />}
        {reportRequestError && (
          <Text
            type="danger"
            icon="AlertCircle"
            text={reportRequestError.code || reportRequestError.message}
            className="mr-3"
            data-cy="export-error"
          />
        )}

        <DropDownButton
          icon="Download"
          label="common.download"
          size="xxs"
          tooltip="common.download"
          variant="primary-2"
          position="right"
          data-cy="export"
        >
          <Button
            className={classNames('px-2', styles['dropdown-button'])}
            size="xs"
            variant="sub-button"
            onClick={handleExport}
            disabled={reportRequestLoading}
          >
            PDF
          </Button>

          <Restricted permissions={['display:revenue_reports']}>
            <Button
              className={classNames('px-2', styles['dropdown-button'])}
              size="xs"
              variant="sub-button"
              onClick={handleExportAnnualRevenue({ forecast: false })}
              disabled={loadingAnnualReport || selectedDate.getFullYear() < 2022}
              data-cy="export-annual-revenue"
            >
              Excel
            </Button>
          </Restricted>
        </DropDownButton>

        <CurrencyModalButton
          currency={currency}
          startDate={dayjs(selectedDate).startOf('year').toDate()}
          endDate={dayjs(selectedDate).add(1, 'month').toDate()}
        />
      </div>
    </div>
  );
};
