import React, { useEffect } from 'react';
import classNames from 'classnames';
import styles from './collapse.module.scss';

export const Collapse: React.FC<React.PropsWithChildren<{ isOpen: boolean; className?: string }>> = ({
  isOpen,
  children,
  className,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = React.useState<number>(0);

  useEffect(() => {
    if (ref.current) {
      setHeight(isOpen ? ref.current.scrollHeight : 0);
    }
  }, [isOpen]);

  return (
    <div ref={ref} style={{ height: `${height}px` }} className={classNames(styles.collapse, className)}>
      {children}
    </div>
  );
};
