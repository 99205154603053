import React from 'react';
import classNames from 'classnames';
import styles from './card.module.scss';

export const CardBody: React.FC<
  React.PropsWithChildren<{
    className?: string;
  }>
> = ({ children, className }) => {
  return <div className={classNames(styles['card-body'], className)}>{children}</div>;
};
