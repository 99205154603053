import React, { useMemo, useRef } from 'react';
import { useUser } from '@context/User.context';
import { Chart, exportCSVChartAction } from '@GDM/Chart';
import { ChartRefContent } from '@GDM/Chart/chart.types';
import { getDefaultOptions } from '@GDM/Chart/default-options';
import { MonthlyMarketResultAggregatorPlayer } from '@hooks/m0s/useM0Aggreg';
import useTranslation from '@hooks/useTranslation';
import { getSymbol } from '@utils/currency/getSymbol';
import { getDateInTimezone } from '@utils/date';
import { appendColon, hashToHexCode, stringToHash } from '@utils/string';
import { MonthlyMarketResult } from '@utils/types/m0';
import { useNationalPricesContext } from '../context';

type Props = {
  data: MonthlyMarketResultAggregatorPlayer;
  base: MonthlyMarketResult[];
  solar: boolean;
};

export const M0AggregChart: React.FC<Props> = ({ base, data, solar }) => {
  const { t, tLocalized } = useTranslation();
  const { locale } = useUser();
  const { timezone, currency } = useNationalPricesContext();

  const ref = useRef<ChartRefContent>(null);
  const currencyValue = getSymbol(currency);

  const series = useMemo<Highcharts.Options['series']>(() => {
    const lines: Highcharts.Options['series'] = Object.keys(data)
      .filter((k) => data[k].data.length > 0)
      .map((k) => {
        const { data: value, name } = data[k];
        const color = hashToHexCode(stringToHash(name));

        return {
          type: 'spline',
          name,
          color,
          data: value.map((d) => [getDateInTimezone(d.start_date, timezone).valueOf(), d.value]),
          cropThreshold: 59999,
          lineWidth: 1.5,
          tooltip: { valueSuffix: `${currencyValue}/MWh` },
        };
      });

    return [
      {
        type: 'spline',
        name: t('pricing.market_value'),
        data: base.map((d) => [getDateInTimezone(d.start_date, timezone).valueOf(), d.value]),
        color: solar ? 'var(--chart-solar)' : 'var(--chart-wind)',
        opacity: 0.75,
        cropThreshold: 59999,
        lineWidth: 3,
        tooltip: { valueSuffix: `${currencyValue}/MWh` },
      },
      ...lines,
    ];
  }, [base, data, solar, t, currencyValue, timezone]);

  if (series?.length && series.length == 0) return null;

  const options: Highcharts.Options = {
    chart: { type: 'spline', zoomType: 'x', height: 300 },
    xAxis: { gridLineWidth: 1, crosshair: true },
    yAxis: {
      title: {
        text: tLocalized('sales_management.national_prices.chart.historic_market_price', {
          unit: `${currencyValue}/MWh`,
        }),
      },
      gridLineWidth: 1,
    },
    exporting: { filename: `m0_aggregators_${solar ? 'solar' : 'wind'}` },
    tooltip: {
      ...getDefaultOptions(timezone, locale),
      pointFormatter() {
        return `<span style="color: ${this.color}">●</span> ${appendColon(
          this.series.name,
          locale,
        )} <b>${new Intl.NumberFormat(locale, { maximumFractionDigits: 8 }).format(this.y ?? 0)} ${
          this?.series?.options?.tooltip?.valueSuffix || ''
        }</b><br/>`;
      },
    },
  };

  return <Chart options={options} series={series} hideLegend ref={ref} handleDownloadCsv={exportCSVChartAction(ref)} />;
};
