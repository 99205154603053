import React from 'react';
import { Definition } from '@GDM/DefinitionList';
import useTranslation from '@hooks/useTranslation';
import { Meter } from '@utils/types/meter';
import { useMeterInfo } from '../useMeterInfo';
import { getReactiveInstructions } from './get-reactive-instructions';
import { ReactiveInstructionsModal } from './ReactiveInstructionsModal';

export const ReactiveInstructionsDefinition: React.FC<{ meter: Meter }> = ({ meter }) => {
  const { t } = useTranslation();
  const { reactiveConstraints, maxValue } = useMeterInfo();
  const instructions = getReactiveInstructions(reactiveConstraints, maxValue, t);

  const reactivePowerInstructionsValue = instructions ? (
    <span>
      <code>{instructions?.name}</code> {instructions.sentence}
    </span>
  ) : (
    'monitoring.installation.no_order'
  );

  return (
    <Definition
      label="common.reactive_setpoint"
      value={reactivePowerInstructionsValue}
      data-cy="reactive-definition"
      modal={{
        node: <ReactiveInstructionsModal meter={meter} />,
        title: 'common.reactive_setpoint',
        permission: 'display:edit_monitoring',
      }}
    />
  );
};
