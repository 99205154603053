import React from 'react';
import { useUser } from '@context/User.context';
import { AbsoluteValueWithPercentageCell } from '@GDM/Table/Cells/AbsoluteValueWithPercentageCell';
import useTranslation from '@hooks/useTranslation';
import { CellContext } from '@tanstack/react-table';
import { MeterInvoice } from '@utils/types/meter-invoice';

export const DeltaProdVsIndexCell: React.FC<CellContext<MeterInvoice, unknown>> = ({ getValue, row: { original } }) => {
  const { t } = useTranslation();
  const productionIndex = original.volume_production_index || 0;
  const { locale } = useUser();
  const numberFormat = new Intl.NumberFormat(locale);
  const tooltip = `${t('monitoring.invoicing.prod_from_indexes')}:<br /> ${numberFormat.format(productionIndex)} kWh`;
  const billableProd = original.volume_production;
  const fetchedValue = getValue();
  const value = typeof fetchedValue === 'number' ? fetchedValue : null;
  const percentage = value && billableProd ? (value / billableProd) * 100 : null;

  return (
    <AbsoluteValueWithPercentageCell
      value={value}
      percentage={percentage}
      colorNumber
      valueTooltip={tooltip}
      percentageTooltip="monitoring.invoicing.billable_percentage_tooltip"
    />
  );
};
