import React from 'react';
import { useUser } from '@context/User.context';
import { useRevenueReportingContext } from '@pages/Reporting/utils/hooks/useRevenueReportingContext';
import { DataWidget } from '@ui/DataWidget';
import { getSmartTranslation } from '@utils/getSmartTranslation';
import { isANumber } from '@utils/isANumber';
import { Card, Col, Row } from 'reactstrap';
import PieChart from '../charts/PieChart';
import { Results } from '../Results';

const MonthlyResults: React.FC<{ printable?: boolean }> = ({ printable }) => {
  const user = useUser();

  const { current, currency } = useRevenueReportingContext();

  if (!current) return <></>;

  const {
    aggregation_fee_unit_price,
    business_plan_delta_production,
    hedge_production,
    hedge_unit_price,
    market_revenue,
    negative_price_production,
    subsidy_revenue,
    total_production,
    total_revenue,
  } = current;

  const totalProduction = total_production?.value;
  const businessPlanDelta = business_plan_delta_production?.value;
  const averageUnitPrice =
    isANumber(total_production?.value) && isANumber(total_revenue?.value)
      ? (total_revenue?.value * 1000) / total_production?.value
      : null;
  const hedgeAmount = isANumber(hedge_production?.value) ? hedge_production.value * 1000 : null;
  const hedgePrice = hedge_unit_price?.value;
  const totalRevenue = total_revenue?.value;
  const negativePriceProduction = negative_price_production?.value;
  const marketRevenue = market_revenue?.value;
  const subsidyRevenue = subsidy_revenue?.value;
  const averageAggregatorFees = isANumber(aggregation_fee_unit_price?.value)
    ? aggregation_fee_unit_price?.value * -1000
    : null; // €c/MWh

  if (printable) {
    return (
      <Row className="reports-container reports-container-monthly">
        <Col md={7} className="reports-values">
          <div className="d-flex flex-wrap justify-content-between h-100">
            <div className="DataWidgetContainerStylesTop">
              <DataWidget
                printable={printable}
                title="common.production"
                value={totalProduction}
                unit="kWh"
                subTitle={
                  isANumber(businessPlanDelta)
                    ? {
                        label: 'Δ BP',
                        value: businessPlanDelta,
                        unit: 'kWh',
                        valueColor: businessPlanDelta > 0 ? 'green' : 'red',
                      }
                    : undefined
                }
              />
            </div>

            <div className="DataWidgetContainerStylesTop">
              <DataWidget
                printable={printable}
                title="sales_management.prod_p0"
                value={negativePriceProduction}
                unit="kWh"
              />
            </div>

            <div className="DataWidgetContainerStylesBottom">
              <DataWidget
                printable={printable}
                title={getSmartTranslation(user, 'sales_management.average_sale_price')}
                value={averageUnitPrice}
                unit={`${currency}/MWh`}
              />
            </div>

            {hedgeAmount && (
              <div className="DataWidgetContainerStylesTop">
                <DataWidget printable={printable} title="sales_management.hedge_volume" value={hedgeAmount} unit="kW" />
              </div>
            )}

            {hedgePrice && (
              <div className="DataWidgetContainerStylesBottom">
                <DataWidget
                  printable={printable}
                  title="sales_management.hedge_average_price"
                  value={hedgePrice}
                  unit={`${currency}/MWh`}
                />
              </div>
            )}

            <div className="DataWidgetContainerStylesBottom">
              <DataWidget
                printable={printable}
                title="sales_management.average_aggreg_price"
                value={averageAggregatorFees}
                unit={`${currency}/MWh`}
              />
            </div>
          </div>
        </Col>

        <Col md={5} className="reports-chart">
          <Card className="cardStyles">
            <PieChart
              printable={printable}
              total_revenues={totalRevenue}
              market_revenues={marketRevenue}
              subsidies_revenues={subsidyRevenue}
            />
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <Results
      prod={
        <DataWidget
          title="common.production"
          value={totalProduction}
          unit="kWh"
          subTitle={
            isANumber(businessPlanDelta)
              ? {
                  label: 'Δ BP',
                  value: businessPlanDelta,
                  unit: 'kWh',
                  valueColor: businessPlanDelta > 0 ? 'green' : 'red',
                  tooltip: 'sales_management.reports.bp_tooltip',
                }
              : undefined
          }
        />
      }
      prodP0={<DataWidget title="sales_management.prod_p0" value={negativePriceProduction} unit="kWh" />}
      price={
        <DataWidget
          title={getSmartTranslation(user, 'sales_management.average_sale_price')}
          value={averageUnitPrice}
          unit={`${currency}/MWh`}
        />
      }
      hedge_quantity={
        hedgeAmount ? <DataWidget title="sales_management.hedge_volume" value={hedgeAmount} unit="kW" /> : null
      }
      hedge_price={
        hedgePrice ? (
          <DataWidget title="sales_management.hedge_average_price" value={hedgePrice} unit={`${currency}/MWh`} />
        ) : null
      }
      aggregPrice={
        <DataWidget
          title="sales_management.average_aggreg_price"
          value={averageAggregatorFees}
          unit={`${currency}/MWh`}
        />
      }
      chart={
        <PieChart total_revenues={totalRevenue} market_revenues={marketRevenue} subsidies_revenues={subsidyRevenue} />
      }
    />
  );
};

export default MonthlyResults;
