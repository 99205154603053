import React, { useMemo } from 'react';
import { useUser } from '@context/User.context';
import { Alert } from '@GDM/Alert';
import { Map } from '@GDM/Map';
import { COUNTRIES } from '@utils/constants/countries';
import { CountryDefinitions } from '@utils/types/countries';
import classNames from 'classnames';
import { PortfolioAggregate } from '../DashboardViewAll/PortfolioAggregate';
import { Filters } from '../Filters';
import { PortfolioMap } from '../Map';
import styles from '../portfolio.module.scss';
import { usePortfolio } from '../usePortfolio';

export const Header: React.FC = () => {
  const { geolocation } = useUser();
  const { displayMode, portfolio, loading, errorBanner } = usePortfolio();

  const containsMarkers = useMemo(
    () => !!portfolio?.some((meter) => !!meter.latitude && !!meter.longitude),
    [portfolio],
  );

  const {
    coordinates,
    map: { defaultZoom },
  }: CountryDefinitions = geolocation?.[0]
    ? COUNTRIES[geolocation[0]]
    : { coordinates: { lat: 49.5034, lng: 9.5407 }, map: { defaultZoom: 4 } };

  return (
    <>
      {errorBanner && <Alert size="sm" variant="danger" label={errorBanner} className="mb-4" dismissible />}
      <Filters />

      <div className={classNames({ [styles['has-map']]: displayMode === 'map' }, styles['header-container'], 'my-3')}>
        {displayMode === 'map' && (
          <div className={styles['map-container']} data-cy="map">
            <Map
              center={[coordinates.lat || 49.5034, coordinates.lng || 9.5407]}
              zoom={defaultZoom}
              hideLayers={loading ? ['satellite'] : undefined}
            >
              <PortfolioMap data={portfolio} defaultZoom={defaultZoom} />
            </Map>
            {!containsMarkers && (
              <Alert
                className={styles['map-alert']}
                label="monitoring.portfolio.no_markers"
                variant="info"
                size="sm"
                dismissible
              />
            )}
          </div>
        )}

        <PortfolioAggregate />
      </div>
    </>
  );
};
