import React from 'react';
import { Filters, FilterContainer } from '@GDM/Filters';
import { useForm } from 'react-hook-form';
import { useOwnersContext } from '../../owners.context';
import { OwnerErpFilter } from './OwnerErpFilter';
import { OwnerCompanyNameFilter } from './OwnerNameFilter';
import { OwnerSirenFilter } from './OwnerSirenFilter';
import { useOwnerFilters } from './useOwnerFilters';

export type OwnerFiltersType = {
  company_number: string;
  erp_code: string;
  company_name: string;
};

export const OwnersFilters: React.FC = () => {
  const form = useForm<OwnerFiltersType>({
    defaultValues: {
      company_name: '',
      erp_code: '',
      company_number: '',
    },
  });

  const { setOwners, allOwners } = useOwnersContext();
  const filterOwners = useOwnerFilters(allOwners);
  const handleFilterChange = (params: OwnerFiltersType) => {
    const filteredOwners = filterOwners(params);
    setOwners(filteredOwners);
  };

  return (
    <Filters form={form}>
      <FilterContainer>
        <OwnerCompanyNameFilter onChange={handleFilterChange} />
      </FilterContainer>
      <FilterContainer>
        <OwnerErpFilter onChange={handleFilterChange} />
      </FilterContainer>
      <FilterContainer>
        <OwnerSirenFilter onChange={handleFilterChange} />
      </FilterContainer>
    </Filters>
  );
};
