import React from 'react';
import PdfPage from '@ui/PdfPage';
import { Locale } from '@utils/types/common-types';
import { User } from '@utils/types/user';
import ReportFooter from './ReportFooter';
import ReportHeader from './ReportHeader';

type ReportPageLayoutProps = {
  locale: Locale;
  user: User;
  reportDate: string;
  title: string;
  bookName: string | null;
  marketPlayerName: string | null;
};

const ReportPageLayout: React.FC<React.PropsWithChildren<ReportPageLayoutProps>> = ({
  user,
  locale,
  reportDate,
  children,
  bookName,
  marketPlayerName,
  title,
}) => {
  return (
    <PdfPage user={user} locale={locale} fixed={true}>
      <ReportHeader bookName={bookName} marketPlayerName={marketPlayerName} reportDate={reportDate} title={title} />

      {children}

      <ReportFooter />
    </PdfPage>
  );
};

export default ReportPageLayout;
