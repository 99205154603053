import React, { useMemo, useState } from 'react';
import { useUser } from '@context/User.context';
import merge from 'lodash/merge';
import { ProgressStepperContextType, Step } from '../progress-stepper';
import { progressStepperContext } from './progress-stepper.context';

export const ProgressStepperContext: React.FC<
  React.PropsWithChildren<{
    initialSteps?: Record<string, Step>;
    containerMaxWidth?: ProgressStepperContextType['containerMaxWidth'];
    infoBoxDefinitions?: ProgressStepperContextType['infoBoxDefinitions'];
    showNav?: ProgressStepperContextType['showNav'];
    singleStep: ProgressStepperContextType['singleStep'];
  }>
> = ({ children, containerMaxWidth, infoBoxDefinitions, initialSteps, showNav, singleStep }) => {
  const [steps, setSteps] = useState<ProgressStepperContextType['steps']>(initialSteps);
  const [infoBox, setInfoBox] = useState<ProgressStepperContextType['infoBox']>();
  const { isAuthorized } = useUser();

  /**
   * Because the steps are defined outside of the context of this component, and the builder logic
   * generates the `Step` components that would have had access to the context (which is where we
   * could've otherwise called the `setSteps` method), we have to listen to prop changes here
   * to make sure the steps are updated correctly (if they are dynamic.)
   */
  if (initialSteps && steps && Object.keys(initialSteps).join() !== Object.keys(steps).join()) {
    setSteps((currentSteps) => {
      const updatedSteps = Object.entries(initialSteps).reduce((acc, [key, value]) => {
        // Don't overwrite previously set progress for existing steps
        return { ...acc, [key]: { ...merge(value, currentSteps?.[key]) } };
      }, {});

      return updatedSteps;
    });
  }

  /**
   * Remove steps that the user doesn't have access to.
   */
  const permittedSteps: typeof initialSteps = useMemo(() => {
    if (!steps) return;

    const updatedSteps = Object.entries(steps).reduce((acc, [key, value]) => {
      if (value.permissions && !isAuthorized(value.permissions)) return acc;

      return { ...acc, [key]: value };
    }, {});

    return updatedSteps;
  }, [isAuthorized, steps]);

  const value: ProgressStepperContextType = useMemo(
    () => ({
      containerMaxWidth,
      infoBox,
      infoBoxDefinitions,
      setInfoBox,
      setSteps,
      showNav,
      steps: permittedSteps,
      singleStep,
    }),
    [containerMaxWidth, infoBox, infoBoxDefinitions, permittedSteps, showNav, singleStep],
  );

  return <progressStepperContext.Provider value={value}>{children}</progressStepperContext.Provider>;
};
