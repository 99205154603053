import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { unavailabilitiesContext } from '@context/UnavailabilitiesContext';
import { useRequest } from '@hooks/useRequest';
import { v2_unavailabilities_path } from '@utils/routes';
import { Unavailability } from '@utils/types/unavailability';
import cloneDeep from 'lodash/cloneDeep';

export const UnavailabilityProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { data: fetchedUnavailabilities, loading } = useRequest<Unavailability[]>(v2_unavailabilities_path(), 'GET');
  const [unavailabilities, setUnavailabilities] = useState<Unavailability[]>([]);
  const [selectedUnavailability, setSelectedUnavailability] = useState<Unavailability | null>(null);

  const updateUnavailability = useCallback(
    (unavailability: Unavailability) => {
      setUnavailabilities((oldUnavailbilities) => {
        const clonedUnavailabilities = cloneDeep(oldUnavailbilities);
        const index = clonedUnavailabilities.findIndex((u) => u.uuid === unavailability.uuid);
        if (index !== -1) clonedUnavailabilities[index] = unavailability;

        return clonedUnavailabilities;
      });
    },
    [setUnavailabilities],
  );

  const addUnavailability = useCallback(
    (unavailability: Unavailability) =>
      setUnavailabilities((oldUnavailabilities) => [...oldUnavailabilities, unavailability]),
    [setUnavailabilities],
  );

  useEffect(() => {
    setUnavailabilities(fetchedUnavailabilities ?? []);
  }, [fetchedUnavailabilities, setUnavailabilities]);

  const contextValue = useMemo(
    () => ({
      loading,
      unavailabilities,
      selectedUnavailability,
      setUnavailabilities,
      setSelectedUnavailability,
      updateUnavailability,
      addUnavailability,
    }),
    [addUnavailability, loading, selectedUnavailability, unavailabilities, updateUnavailability],
  );

  return <unavailabilitiesContext.Provider value={contextValue}>{children}</unavailabilitiesContext.Provider>;
};
