import React from 'react';
import { useDashboardMeter } from '@pages/DashboardMeter/useDashboardMeter';

import { NegativePrices } from './NegativePrices';

export const NegativePricesTab = () => {
  const { book, meter, installation } = useDashboardMeter();

  const resourceId = book?.uuid ?? meter?.name ?? '';
  const resourceName = book?.name ?? meter?.name ?? '';
  const isBook = Boolean(book);
  const isSolar = [meter?.installation?.energy, installation?.energy, ...(book?.installation_energies || [])].includes(
    'solar',
  );

  return <NegativePrices resourceName={resourceName} resourceId={resourceId} isBook={isBook} isSolar={isSolar} />;
};
