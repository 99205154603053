import React from 'react';
import Page from '@pages/Page';
import { Locale } from '@utils/types/common-types';
import { User } from '@utils/types/user';
import { DashboardViewAll } from './DashboardViewAll';
import { PortfolioTableProvider } from './DashboardViewAll/PortfolioTable/context/PortfolioTableProvider';
import { Header } from './Header';
import { Nowcast } from './Nowcast';
import { portfolioContext } from './portfolio.context';
import { PortfolioProvider } from './PortfolioProvider';

const Portfolio: React.FC<{
  locale: Locale;
  user: User;
}> = ({ locale, user }) => {
  return (
    <PortfolioProvider locale={user.locale}>
      <PortfolioTableProvider>
        <portfolioContext.Consumer>
          {(context) => (
            <Page
              fixedHeightContainer
              title="portfolio"
              locale={locale}
              user={user}
              isLoading={context.loading}
              error={typeof context.error === 'string' ? context.error : context.error?.code || context.error?.message}
              subtitle={
                !context.error && (
                  <Nowcast
                    real_time_ratio={context?.realtime_ratio}
                    sum_realtime_portfolio={context?.sum_realtime_portfolio}
                  />
                )
              }
              header={<Header />}
            >
              <DashboardViewAll />
            </Page>
          )}
        </portfolioContext.Consumer>
      </PortfolioTableProvider>
    </PortfolioProvider>
  );
};

export default Portfolio;
