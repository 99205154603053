import { COUNTRY_CODES } from '@utils/constants/countries';
import { InstallationFormField } from '../installation.types';

const everyCountriesExceptFrance = COUNTRY_CODES.filter((country) => country !== 'FR');

export const COMMON_FIELDS: InstallationFormField[] = [
  { name: 'name', required: true },
  { name: 'country', required: true },
  { name: 'energy', required: true, defaultValue: 'other' },
  { name: 'company_number', onlyForFieldValues: [{ field: 'owner_id', value: 'new_owner' }] },
  { name: 'bank', onlyForFieldValues: [{ field: 'owner_id', value: 'new_owner' }] },
  { name: 'erp_code', onlyForFieldValues: [{ field: 'owner_id', value: 'new_owner' }] },
  { name: 'swift_bic', onlyForFieldValues: [{ field: 'owner_id', value: 'new_owner' }] },
  { name: 'invoicing_emails', onlyForFieldValues: [{ field: 'owner_id', value: 'new_owner' }] },
  { name: 'iban', onlyForFieldValues: [{ field: 'owner_id', value: 'new_owner' }] },
  {
    name: 'short_name',
    onlyForFieldValues: [{ field: 'owner_id', value: 'new_owner' }],
    requiredForFieldValues: { owner_id: 'new_owner' },
    info: {
      title: 'owner.short_name',
      text: 'admin.installations.owner_short_name_info',
    },
    onlyForCountries: everyCountriesExceptFrance,
  },
  {
    name: 'long_name',
    onlyForFieldValues: [{ field: 'owner_id', value: 'new_owner' }],
    requiredForFieldValues: { owner_id: 'new_owner' },
    onlyForCountries: everyCountriesExceptFrance,
  },
  {
    name: 'street_address',
    onlyForFieldValues: [{ field: 'owner_id', value: 'new_owner' }],
    requiredForFieldValues: { owner_id: 'new_owner' },
    onlyForCountries: everyCountriesExceptFrance,
  },
  {
    name: 'postcode',
    onlyForFieldValues: [{ field: 'owner_id', value: 'new_owner' }],
    requiredForFieldValues: { owner_id: 'new_owner' },
    onlyForCountries: everyCountriesExceptFrance,
  },
  {
    name: 'city',
    onlyForFieldValues: [{ field: 'owner_id', value: 'new_owner' }],
    onlyForCountries: everyCountriesExceptFrance,
  },
  {
    name: 'vat_number',
    onlyForFieldValues: [{ field: 'owner_id', value: 'new_owner' }],
    requiredForFieldValues: { owner_id: 'new_owner' },
    onlyForCountries: everyCountriesExceptFrance,
  },
  { name: 'owner_id', required: true },
  { name: 'grid_id', required: true },
  { name: 'data_provider_id' },
  { name: 'prm', onlyForFieldValues: [{ field: 'grid_id', value: 1 }], required: true },
  { name: 'mandate_uuid', onlyForFieldValues: [{ field: 'grid_id', value: 1 }], required: true },
  {
    name: 'aiguillage',
    onlyForCountries: ['FR'],
    requiredForFieldValues: { boitier_ip: false },
    onlyForFieldValues: [
      { field: 'boitier_ip', value: false },
      { field: 'grid_id', value: 1 },
    ],
  },
  {
    name: 'typecompteur',
    requiredForFieldValues: { boitier_ip: false },
    onlyForFieldValues: [
      { field: 'boitier_ip', value: false },
      { field: 'grid_id', value: 1 },
    ],
  },
  { name: 'producteur', onlyForFieldValues: [{ field: 'grid_id', value: 1 }] },
  { name: 'meter_pass_address', requiredForFieldValues: { via_direct_connection: true } },
  {
    name: 'ip',
    onlyForFieldValues: [{ field: 'boitier_ip', value: true }],
    requiredForFieldValues: { boitier_ip: true, via_direct_connection: true },
  },
  {
    name: 'tcp_port',
    onlyForFieldValues: [{ field: 'boitier_ip', value: true }],
    requiredForFieldValues: { boitier_ip: true, via_direct_connection: true },
  },
  { name: 'code', requiredForFieldValues: { via_direct_connection: true } },
  { name: 'key', excludedForCountries: ['FR'], requiredForFieldValues: { via_direct_connection: true } },
  {
    name: 'key',
    requiredForFieldValues: { boitier_ip: false },
    onlyForFieldValues: [
      { field: 'boitier_ip', value: false },
      { field: 'grid_id', value: 1 },
    ],
  },
  { name: 'model', onlyForCountries: ['IT'], required: true },
  { name: 'serial_number', onlyForCountries: ['IT'] },
  {
    name: 'numero',
    requiredForFieldValues: { boitier_ip: false, via_direct_connection: true },
    onlyForFieldValues: [{ field: 'boitier_ip', value: false }],
  },
  { name: 'data_provider_number', requiredForFieldValues: { via_data_provider: true } },
  { name: 'boitier_ip', defaultValue: false },
  { name: 'via_data_provider', defaultValue: true },
  { name: 'via_direct_connection', defaultValue: false },
  { name: 'meter_request_name', excludedForFieldValues: [{ field: 'grid_id', value: 1 }] },
  { name: 'meter_request_comment', excludedForFieldValues: [{ field: 'grid_id', value: 1 }] },
  { name: 'meter_request_email', excludedForFieldValues: [{ field: 'grid_id', value: 1 }] },
];
