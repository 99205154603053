import { Option } from '@utils/types/common-types';
import { ContractType } from '@utils/types/contract';
import { CountryCode } from '@utils/types/countries';

/**
 * List of contract types based on installation country
 */
export const contractTypeList: Record<CountryCode | 'GLSF', Option<ContractType>[]> = {
  GLSF: [
    { value: 'ContractGo-LIGHT', label: 'GO/REC' },
    { value: 'ContractSoa-LIGHT', label: 'contracts.types.soa_light' },
  ],
  AT: [{ value: 'ContractSoa', label: 'uPPA' }],
  BE: [
    { value: 'ContractSoa', label: 'uPPA' },
    { value: 'ContractCppa', label: 'cPPA' },
    { value: 'ContractGo', label: 'GO' },
    { value: 'ContractGc', label: 'GC' },
    { value: 'ContractSwap', label: 'Swap' },
  ],
  BF: [{ value: 'ContractSoa', label: 'uPPA' }],
  BG: [{ value: 'ContractSoa', label: 'uPPA' }],
  CH: [
    { value: 'ContractSoa', label: 'uPPA' },
    { value: 'ContractCppa', label: 'cPPA' },
    { value: 'ContractGo', label: 'REC' },
    { value: 'ContractSwap', label: 'Swap' },
  ],
  CL: [{ value: 'ContractSoa', label: 'uPPA' }],
  DE: [
    { value: 'ContractSoa', label: 'Post-EEG (uPPA)' },
    { value: 'ContractCppa', label: 'cPPA' },
    { value: 'ContractGo', label: 'REC' },
    { value: 'ContractCrEdf', label: 'CfD EEG' },
    { value: 'ContractCr', label: 'CfD' },
    { value: 'ContractSwap', label: 'Swap' },
  ],
  DO: [{ value: 'ContractSoa', label: 'uPPA' }],
  ES: [
    { value: 'ContractSoa', label: 'uPPA' },
    { value: 'ContractCppa', label: 'cPPA' },
    { value: 'ContractGo', label: 'GdO' },
    { value: 'ContractSwap', label: 'Swap' },
  ],
  FI: [
    { value: 'ContractSoa', label: 'uPPA' },
    { value: 'ContractCppa', label: 'cPPA' },
  ],
  FR: [
    { value: 'ContractOa', label: 'contracts.types.oa' },
    { value: 'ContractCrEdf', label: 'contracts.types.cr_edf' },
    { value: 'ContractCr', label: 'contracts.types.cr' },
    { value: 'ContractSoa', label: 'contracts.types.soa' },
    { value: 'ContractCppa', label: 'contracts.types.cppa' },
    { value: 'ContractCapaProxy', label: 'contracts.types.capa_proxy' },
    { value: 'ContractCapa', label: 'contracts.types.capa' },
    { value: 'ContractGo', label: 'contracts.types.go' },
    { value: 'ContractSwap', label: 'contracts.types.swap' },
  ],
  GR: [
    { value: 'ContractSoa', label: 'uPPA' },
    { value: 'ContractCppa', label: 'cPPA' },
    { value: 'ContractGo', label: 'REC' },
    { value: 'ContractSwap', label: 'Swap' },
  ],
  HR: [{ value: 'ContractSoa', label: 'uPPA' }],
  IT: [
    { value: 'ContractSoa', label: 'uPPA' },
    { value: 'ContractCppa', label: 'cPPA' },
    { value: 'ContractGo', label: 'REC' },
    { value: 'ContractSwap', label: 'Swap' },
  ],
  KZ: [{ value: 'ContractSoa', label: 'uPPA' }],
  ME: [{ value: 'ContractSoa', label: 'uPPA' }],
  MX: [{ value: 'ContractSoa', label: 'uPPA' }],
  NL: [{ value: 'ContractSoa', label: 'uPPA' }],
  PL: [
    { value: 'ContractSoa', label: 'uPPA' },
    { value: 'ContractCppa', label: 'cPPA' },
    { value: 'ContractGo', label: 'GO' },
    { value: 'ContractSwapPl', label: 'Polish CfD' },
    { value: 'ContractSwap', label: 'Swap' },
  ],
  PT: [
    { value: 'ContractSoa', label: 'uPPA' },
    { value: 'ContractCppa', label: 'cPPA' },
    { value: 'ContractGo', label: 'REC' },
    { value: 'ContractSwap', label: 'Swap' },
  ],
  UK: [
    { value: 'ContractSoa', label: 'uPPA' },
    { value: 'ContractCppa', label: 'cPPA' },
    { value: 'ContractGo', label: 'REC' },
    { value: 'ContractSwap', label: 'Swap' },
  ],
  US: [{ value: 'ContractSoa', label: 'uPPA' }],
  UY: [{ value: 'ContractSoa', label: 'uPPA' }],
  ZA: [{ value: 'ContractSoa', label: 'uPPA' }],
};
