import React, { useCallback } from 'react';
import { useConsumeContracts } from '@context/contracts.context';
import { useDynamicOptions } from '@GDM/Filters';
import { Select } from '@GDM/forms';
import useBooks from '@hooks/requests/useBooks';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Option } from '@utils/types/common-types';
import { Contract } from '@utils/types/contract';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { ContractsFiltersType } from './ContractFilters';

export const BookFilters: React.FC = () => {
  const { data: books } = useBooks({ bookType: 'monitoring' });
  const form = useFormContext<ContractsFiltersType>();
  const { filterContracts, allContracts, contracts } = useConsumeContracts();
  const getOptionsCallback = useCallback(
    (contracts: Contract[]) => {
      const installations = Array.from(new Set(contracts.map((contract) => contract.installation.name)));

      const filteredBooks =
        books?.filter((b) => {
          return installations.filter((i) => b.installation_names.includes(i)).length > 0;
        }) || [];

      const options: Option<string>[] = filteredBooks
        .map((book) => ({ label: book.name, value: book.uuid }))
        .sort(sortOptionsByLabelAsc);

      return options;
    },
    [books],
  );
  const options: Option<string>[] = useDynamicOptions(getOptionsCallback, 'books', contracts, allContracts);

  const handleBookChange =
    (field: ControllerRenderProps<ContractsFiltersType, 'books'>) => (options: Option<string>[] | null) => {
      const selectedBooks = books?.filter((b) => options?.map((o) => o.value).includes(b.uuid)) || [];

      filterContracts({ ...form.getValues(), books: selectedBooks });
      field.onChange(selectedBooks);
      form.setValue('last_selected_filter', selectedBooks.length > 0 ? 'books' : null);
    };

  if (!books) return null;

  return (
    <Controller
      control={form.control}
      name="books"
      render={({ field }) => {
        return (
          <Select
            isMulti
            inline
            classNamePrefix="book_select"
            options={options}
            selectedOptions={field.value.map((book) => book.uuid)}
            onChange={handleBookChange(field)}
            placeholder="admin.books.book_name"
          />
        );
      }}
    />
  );
};
