import React from 'react';
import classNames from 'classnames';
import styles from './progress.module.scss';

export const Progress: React.FC<{
  className?: string;
  value: number;
  min?: number;
  dangerThreshold?: number;
  isLoading?: boolean;
  error?: boolean;
  variant?: 'normal' | 'good' | 'bad';
  valueSuffix?: string;
  valueInside?: boolean;
  'data-cy'?: string;
}> = ({
  className,
  'data-cy': dataCy,
  value,
  dangerThreshold,
  variant = 'normal',
  isLoading,
  error,
  valueSuffix = '%',
  valueInside,
}) => {
  error = error || isNaN(value) || value === null;

  if (dangerThreshold || dangerThreshold === 0) {
    variant = value <= dangerThreshold ? 'bad' : 'good';
  }

  if (valueInside && value < 15) {
    valueInside = false;
  }

  const displayedValue = !error ? `${Math.round(value)}${valueSuffix}` : '--';

  return (
    <div
      data-cy={dataCy}
      className={classNames(
        styles.container,
        styles[variant],
        { [styles.loading]: isLoading, [styles.error]: error, [styles.inside]: valueInside },
        className,
      )}
    >
      <span className={styles.value}>{displayedValue}</span>
      <div className={styles.progress}>
        <div className={styles['progress-value']} style={{ width: `${value}%` }} data-cy="progress">
          {!error && valueInside && !isLoading && displayedValue}
        </div>
      </div>
    </div>
  );
};
