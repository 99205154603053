import React from 'react';
import { Revenue } from '@pages/DashboardMeter/Tabs/Revenue/Revenue';
import MarketPlayer from '@utils/types/market_player';

export const Revenues: React.FC<{ identifier: string; marketPlayer: MarketPlayer }> = ({
  identifier,
  marketPlayer,
}) => {
  return <Revenue sourceType="market_player" identifier={identifier} marketPlayer={marketPlayer} />;
};
