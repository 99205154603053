import React, { useEffect } from 'react';
import { Checkbox, Input } from '@GDM/forms';
import { Col, Row } from '@GDM/layout';
import { Modal } from '@GDM/Modal';
import { patch, post, useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import { v2_meter_panel_path, v2_meter_panels_path } from '@utils/routes';
import Panel from '@utils/types/panel';
import { Controller, useForm } from 'react-hook-form';

type PanelForm = {
  power: string;
  tracker: boolean;
  corrected_azimuth: number | null;
  tilt: number | null;
};

export const PanelModalForm: React.FC<{
  visible: boolean;
  toggle: () => void;
  meterName: string;
  selectedPanel: Panel | null;
  addPanel: (panel: Panel) => void;
}> = ({ visible, toggle, selectedPanel, meterName, addPanel }) => {
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    unregister,
    formState: { errors },
  } = useForm<PanelForm>({ defaultValues: { tracker: false, power: '', corrected_azimuth: null, tilt: null } });
  const insertPanelRequest = useRequest<{ panel: Panel }>(v2_meter_panels_path(meterName), post, true);
  const updatePanelRequest = useRequest<{ panel: Panel }>(
    v2_meter_panel_path(meterName, selectedPanel?.uuid || 'none'),
    patch,
    true,
  );
  const trackerValue = watch('tracker');
  const { t } = useTranslation();

  const onSubmit = (panel: PanelForm) => {
    if (selectedPanel?.uuid) {
      updatePanelRequest.execute?.({ panel });
    } else {
      insertPanelRequest.execute?.({ panel });
    }
  };

  useEffect(() => {
    if (!visible) reset();
  }, [visible]);

  useEffect(() => {
    if (selectedPanel) {
      setValue('tracker', selectedPanel?.tracker || false);
      setValue('power', selectedPanel?.power || '');

      if (!selectedPanel?.tracker) {
        setValue('tilt', isNaN(selectedPanel?.tilt) ? null : selectedPanel.tilt);
        setValue(
          'corrected_azimuth',
          isNaN(selectedPanel?.corrected_azimuth) ? null : selectedPanel?.corrected_azimuth,
        );
      }
    }

    return () => {
      reset();
    };
  }, [reset, selectedPanel, setValue]);

  useEffect(() => {
    if (insertPanelRequest.loaded && insertPanelRequest.data?.panel) {
      addPanel(insertPanelRequest.data.panel);
    }
  }, [insertPanelRequest.loaded, insertPanelRequest.data]);

  useEffect(() => {
    if (updatePanelRequest.loaded && updatePanelRequest.data?.panel) {
      addPanel(updatePanelRequest.data.panel);
    }
  }, [updatePanelRequest.loaded, updatePanelRequest.data]);

  useEffect(() => {
    /**
     * to avoid bug, we should unregister unused inputs when there are not used
     */
    if (trackerValue === true) {
      unregister(['corrected_azimuth', 'tilt']);
    }
  }, [trackerValue]);

  return (
    <Modal
      isOpen={visible}
      toggle={toggle}
      header={selectedPanel ? 'monitoring.panels.edit_a_panel' : 'monitoring.panels.add_a_panel'}
      submitAction={handleSubmit(onSubmit)}
      isLoading={insertPanelRequest.loading || updatePanelRequest.loading}
    >
      <Controller
        rules={{ required: true }}
        name="power"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            label="common.power"
            className={errors?.power?.type === 'required' ? 'is-invalid' : ''}
            type="number"
            suffix="kW"
            hasError={errors?.power?.type === 'required' && !field.value?.length}
            full
          />
        )}
      />

      <Controller
        name="tracker"
        control={control}
        render={({ field }) => (
          <Checkbox
            className="mt-3"
            label="common.tracker"
            onChange={field.onChange}
            type="checkbox"
            checked={trackerValue}
          />
        )}
      />

      {!trackerValue && (
        <Row className="mt-2">
          <Col md={6}>
            <Controller
              rules={{ required: !trackerValue }}
              name="tilt"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  value={field.value ?? ''}
                  label="monitoring.panels.tilt"
                  type="number"
                  suffix="°"
                  hasError={errors?.tilt?.type === 'required'}
                  full
                />
              )}
            />
          </Col>

          <Col md={6}>
            <Controller
              rules={{ required: !trackerValue }}
              name="corrected_azimuth"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  value={field.value ?? ''}
                  label="monitoring.panels.azimut"
                  type="number"
                  suffix="°"
                  hasError={errors?.corrected_azimuth?.type === 'required'}
                  full
                />
              )}
            />
          </Col>
        </Row>
      )}

      {insertPanelRequest.error || updatePanelRequest.error ? (
        <p className="text-red">{t('errors.unknown_error')}</p>
      ) : null}
    </Modal>
  );
};
