import React from 'react';
import { Icon, IconNames } from '@GDM/Icon';
import useTranslation from '@hooks/useTranslation';
import styles from './sidebar-menu.module.scss';

export const SidebarMenu: React.FC<
  React.PropsWithChildren<{
    categoryName: string;
    icon: IconNames;
  }>
> = ({ categoryName, children, icon }) => {
  const { t } = useTranslation();

  return (
    <div className={styles['content']}>
      <ul className={styles['menu']}>
        <li className={styles['menu-category']}>
          <span>
            <Icon name={icon} size={16} />
            {t(categoryName)}
          </span>
        </li>

        {children}
      </ul>
    </div>
  );
};
