import React from 'react';
import { Button } from '@GDM/Button';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import styles from './default.module.scss';

export const DefaultSubSection = ({
  title,
  isOpen,
  children,
  className,
  onChange,
  name,
}: {
  title: string;
  isOpen: boolean;
  children: React.ReactNode;
  className?: string;
  onChange: (value: boolean) => void;
  name: string;
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles['container-sub-section']}>
      <div
        className={classNames(styles.title, !isOpen && 'cursor-pointer')}
        onClick={isOpen ? undefined : () => onChange(!isOpen)}
      >
        {t(title)}

        {isOpen ? (
          <Button
            icon="Trash2"
            floating
            variant="secondary"
            onClick={() => onChange(false)}
            data-cy={`delete_${name}`}
          />
        ) : (
          <Button icon="Plus" floating onClick={() => onChange(true)} data-cy={`create_${name}`} />
        )}
      </div>
      {isOpen && <div className={classNames(styles.content, className)}>{children}</div>}
    </div>
  );
};
