import React from 'react';
import { MeterNameCell } from '@components/ui/table/cell/MeterNameCell';
import { CellContext } from '@tanstack/react-table';
import { Simulation } from '@utils/types/simulation';

export const SimulationInstallationNameCell: React.FC<CellContext<Simulation, unknown>> = ({
  getValue,
  row: { original },
}) => {
  const value = getValue();
  if (typeof value !== 'string') return null;

  return (
    <MeterNameCell
      installation_energy={original.energy}
      installation_name={value}
      href={`/v2/ppa_projects/${encodeURIComponent(value)}`}
    />
  );
};
