import { Option } from '@utils/types/common-types';
import { ContractType, PriceType } from '@utils/types/contract';

export const PRICE_TYPE_OPTIONS_MAP: Record<PriceType | 'no-option', Option<PriceType | null>> = {
  'no-option': { label: 'common.none', value: null },
  fixed: { label: 'sales_management.price.fix', value: 'fixed' },
  spot: { label: 'sales_management.price.spot', value: 'spot' },
  imbalance_pos: { label: 'sales_management.price.imbalance_pos', value: 'imbalance_pos' },
  indexed: { label: 'sales_management.price.indexed', value: 'indexed' },
  prefcapa: { label: 'PREFCAPA', value: 'prefcapa' },
  m0: { label: 'sales_management.price.m0', value: 'm0' },
};

export const PRICE_TYPE_CONTRACT_OPTIONS_MAP: Partial<Record<ContractType, Option<PriceType | null>[]>> = {
  ContractCapa: [PRICE_TYPE_OPTIONS_MAP.fixed, PRICE_TYPE_OPTIONS_MAP.prefcapa],
  ContractCr: [
    PRICE_TYPE_OPTIONS_MAP.fixed,
    PRICE_TYPE_OPTIONS_MAP.imbalance_pos,
    PRICE_TYPE_OPTIONS_MAP.spot,
    PRICE_TYPE_OPTIONS_MAP.m0,
  ],
  ContractSoa: [
    PRICE_TYPE_OPTIONS_MAP.fixed,
    PRICE_TYPE_OPTIONS_MAP.spot,
    PRICE_TYPE_OPTIONS_MAP.imbalance_pos,
    PRICE_TYPE_OPTIONS_MAP.indexed,
  ],
  ContractCppa: [
    PRICE_TYPE_OPTIONS_MAP.fixed,
    PRICE_TYPE_OPTIONS_MAP.spot,
    PRICE_TYPE_OPTIONS_MAP.imbalance_pos,
    PRICE_TYPE_OPTIONS_MAP.indexed,
  ],
  ContractCapaProxy: [PRICE_TYPE_OPTIONS_MAP.fixed, PRICE_TYPE_OPTIONS_MAP.indexed],
  ContractSwap: [PRICE_TYPE_OPTIONS_MAP.fixed, PRICE_TYPE_OPTIONS_MAP.indexed],
  ContractGo: [PRICE_TYPE_OPTIONS_MAP.fixed, PRICE_TYPE_OPTIONS_MAP.indexed],
  'ContractGo-LIGHT': [PRICE_TYPE_OPTIONS_MAP['no-option'], PRICE_TYPE_OPTIONS_MAP.fixed],
  'ContractSoa-LIGHT': [PRICE_TYPE_OPTIONS_MAP['no-option'], PRICE_TYPE_OPTIONS_MAP.fixed],
};
