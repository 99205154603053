import React from 'react';
import { AcceptableUnits, ValueWithUnit } from '@GDM/ValueWithUnit';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';

type Confidence = {
  value: number | null;
  unit?: AcceptableUnits;
  variant?: 'primary-1' | 'primary-2' | 'secondary' | null;
};

export type ConfidenceTuple = [Confidence, Confidence];

export const ConfidenceValue: React.FC<{
  values: ConfidenceTuple;
  append?: string | React.ReactNode;
  className?: string;
}> = ({ values, append, className }) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      [
      {values.map(({ value, variant, unit }, index) => (
        // Disabling eslint react/no-array-index-key as it is a tuple and index will never change
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {value && value > 0 && (
            <span
              className={classNames({
                ['text-green']: variant === 'primary-1',
                ['text-blue']: variant === 'primary-2',
                ['text-red']: variant === 'secondary',
              })}
            >
              +
            </span>
          )}
          <ValueWithUnit value={value} unit={unit} valueVariant={variant} />
          {index === 0 && ';'}
        </React.Fragment>
      ))}
      ]{append ? <> {typeof append === 'string' ? t(append) : append}</> : null}
    </div>
  );
};
