import React, { useMemo } from 'react';
import { Button } from '@GDM/Button';
import { ContractForm, ContractSubPeriodForm, ContractType, PhaseType } from '@utils/types/contract';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import { makeSubPeriod } from '../../helpers/makeSubPeriod';
import styles from './sub-period-actions.module.scss';

const CONTRACT_TYPES_WITH_SPECIAL_PHASES: ContractType[] = ['ContractCr', 'ContractSoa'];

export const SubPeriodActions = ({
  form: { trigger },
  subPeriods,
  contractType,
  contractStartDate,
  contractEndDate,
}: {
  contractStartDate: Date | null;
  contractEndDate: Date | null;
  contractType: ContractType | null;
  form: UseFormReturn<ContractForm>;
  subPeriods: UseFieldArrayReturn<ContractForm, 'contract_sub_periods_attributes', 'field_internal_id'>;
}) => {
  const showSpecialPhaseButton = useMemo(
    () => CONTRACT_TYPES_WITH_SPECIAL_PHASES.includes(contractType as ContractType),
    [contractType],
  );

  const addSubPeriod = async (phase_type?: PhaseType): Promise<void> => {
    let end = contractEndDate;
    let start = contractStartDate;
    let price_model_attributes: ContractSubPeriodForm['price_model_attributes'] = null;

    if (subPeriods.fields.length) {
      const lastPeriod = subPeriods.fields.at(-1);

      const dayAfterLastPeriodEnd = dayjs(lastPeriod?.dates?.[1]).add(1, 'day').toDate();
      const dayBeforeFirstPeriodStart = dayjs(subPeriods.fields[0]?.dates?.[0]).subtract(1, 'day').toDate();

      if (phase_type) {
        end = dayBeforeFirstPeriodStart;
      } else {
        start = dayAfterLastPeriodEnd;
      }

      if (lastPeriod) price_model_attributes = lastPeriod.price_model_attributes;
    }

    if (!contractType) return;

    const period = makeSubPeriod(contractType, {
      dates: [start, end],
      phase_type,
      price_model_attributes,
    });

    if (phase_type) {
      subPeriods?.prepend(period);
    } else {
      subPeriods?.append(period);
    }

    // This makes sure the validation is triggered in the next loop, which is needed to make
    // sure the new sub-period dates are validated.
    if (await trigger('contract_sub_periods_attributes')) trigger('contract_sub_periods_attributes');
  };

  return (
    <div className={classNames('p-3', styles.wrapper)}>
      {showSpecialPhaseButton && (
        <Button
          size="sm"
          variant="outline"
          text="sales_management.add_test_period"
          onClick={() => addSubPeriod('testing')}
        />
      )}
      <Button
        size="sm"
        variant="primary-2"
        text="sales_management.add_period"
        onClick={() => addSubPeriod()}
        data-cy="add-period"
      />
    </div>
  );
};
