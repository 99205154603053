import React, { useEffect, useMemo } from 'react';
import { CountryDefinitions } from '@utils/types/countries';
import chroma from 'chroma-js';
import GeoRasterLayer from 'georaster-layer-for-leaflet/dist/georaster-layer-for-leaflet';
import L, { Point, divIcon } from 'leaflet';
import { renderToString } from 'react-dom/server';
import { MapPin } from 'react-feather';
import { Marker, useMap, useMapEvent } from 'react-leaflet';
import { getColorFromValues } from '../utils/getColorFromValues';
import { getPricingMapSettings } from '../utils/getPricingMapSettings';
import { GeoRasterData, PricingMapM0s } from '../utils/pricing-maps.types';
import { usePricingMapsFiltersContext } from '../utils/pricingMapsFilters.context';

export const PricingMapLayers: React.FC<{
  geoRaster: GeoRasterData;
  mask: GeoJSON.Feature;
  m0s: PricingMapM0s;
  setLatLng: (latLng: CountryDefinitions['coordinates']) => void;
  latLng: CountryDefinitions['coordinates'];
}> = ({ geoRaster, mask, m0s, setLatLng, latLng }) => {
  const { filters } = usePricingMapsFiltersContext();

  const isDeviation = filters.result === 'deviation';

  const { baselineValue, maxAbsValue } = useMemo(
    () => getPricingMapSettings(isDeviation, geoRaster, m0s.m0_prices, m0s.base_m0_prices),
    [isDeviation, geoRaster, m0s.m0_prices, m0s.base_m0_prices],
  );
  const map = useMap();

  const scale = useMemo(() => {
    return chroma
      .scale(['#ad002e', '#ff1654', '#fdfec9', '#054652', '#03252b'])
      .domain([baselineValue - maxAbsValue, baselineValue + maxAbsValue]);
  }, [baselineValue, maxAbsValue]);

  const layer = useMemo<L.GridLayer>(() => {
    return new GeoRasterLayer({
      georaster: geoRaster,
      opacity: 1,
      pixelValuesToColorFn: (values) => {
        return (
          ((filters.periodId || filters.periodId === 0) &&
            getColorFromValues(values, isDeviation, filters.periodId, m0s.base_m0_prices, m0s.m0_prices, scale)) ||
          '#ffffff00'
        );
      },
      resolution: 256,
      mask,
      mask_strategy: 'outside',
    });
  }, [filters.periodId, geoRaster, mask, m0s.base_m0_prices, m0s.m0_prices, scale, isDeviation]);

  useEffect(() => {
    if (map.hasLayer(layer)) {
      layer.redraw();

      return;
    }

    map.addLayer(layer);
  }, [map, layer]);

  useMapEvent('click', (e) => {
    setLatLng({ lat: e.latlng.lat, lng: e.latlng.lng });
  });

  if (!latLng.lat || !latLng.lng) return null;

  return (
    <Marker
      position={[latLng.lat, latLng.lng]}
      icon={divIcon({
        className: null as unknown as undefined,
        html: renderToString(<MapPin size={18} color="var(--primary-1)" />),
        popupAnchor: new Point(0, -18),
        iconAnchor: new Point(9, 18),
      })}
    />
  );
};
