import React from 'react';
import { Button } from '@GDM/Button';
import { useInstallationForm } from './hooks/useInstallationForm';

export const PageActions: React.FC<{
  onPreviousStep: () => void;
  onNextStep: () => void;
  isLoading: boolean;
  isFirstStep: boolean;
  isLastStep: boolean;
}> = ({ onPreviousStep, isLoading, isFirstStep, onNextStep, isLastStep }) => {
  const form = useInstallationForm();
  const isEdit = !!form.watch('uuid');
  const isDirty = form.formState.isDirty;
  const hasNotChanged = isEdit && !isDirty;

  const saveLabel = isLastStep ? 'common.save' : 'common.save_and_continue';

  return (
    <div className="d-flex">
      <Button
        text="common.previous"
        variant="link"
        size="sm"
        className="mr-3"
        data-cy="back-button"
        disabled={isFirstStep}
        onClick={onPreviousStep}
        type="button"
      />
      <Button
        name="draft"
        type="submit"
        text="common.save_draft"
        variant="outline"
        size="sm"
        className="mr-3"
        disabled={isLoading || isLastStep}
        isLoading={isLoading}
        data-cy="save-draft-button"
      />
      <Button
        name="save"
        type={hasNotChanged ? 'button' : 'submit'}
        text={saveLabel}
        variant="primary-2"
        size="sm"
        disabled={isLoading || (hasNotChanged && isLastStep)}
        isLoading={isLoading}
        onClick={hasNotChanged ? () => onNextStep() : undefined}
        data-cy="save-button"
      />
    </div>
  );
};
