import React, { useMemo } from 'react';
import { useRequest } from '@hooks/useRequest';
import { all_v2_pricing_maps_path } from '@utils/routes';
import { PricingMap } from './utils/pricing-maps.types';
import { pricingMapsContext } from './utils/pricingMaps.context';

export const PricingMapProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const req = useRequest<PricingMap[]>(all_v2_pricing_maps_path(), 'GET');

  const contextValue = useMemo(() => {
    return { pricingMaps: req.data ?? [], loading: req.loading };
  }, [req.data, req.loading]);

  return <pricingMapsContext.Provider value={contextValue}>{children}</pricingMapsContext.Provider>;
};
