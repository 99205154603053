import React from 'react';
import { DefinitionModalBody, DefinitionModalFooter } from '@GDM/DefinitionList';
import { Input } from '@GDM/forms';
import { patch, useRequest } from '@hooks/useRequest';
import { v2_installation_path } from '@utils/routes';
import Installation from '@utils/types/installation';
import { useInstallationInfo } from '../useInstallationInfo';

export const ErpCodeModal: React.FC<{ name: string }> = ({ name }) => {
  const { erpCode, setErpCode } = useInstallationInfo();

  const { loading: isLoading, execute } = useRequest<Installation>(
    v2_installation_path(name),
    ({ url }) =>
      patch<Installation, { installation: Partial<Installation> }>({
        url,
        body: { installation: { erp_code: erpCode } },
      }),
    true,
  );

  return (
    <>
      <DefinitionModalBody>
        <Input value={erpCode} onChange={(e) => setErpCode?.(e.target.value)} full />
      </DefinitionModalBody>
      <DefinitionModalFooter save={execute} isLoading={isLoading} />
    </>
  );
};
