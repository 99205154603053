import React from 'react';
import { ValueWithUnit } from '@GDM/ValueWithUnit';

export const EnergyCell: React.FC<{
  value: number | null;
  color?: 'red' | 'green' | 'default';
}> = ({ color, value }) => {
  return (
    <div className="ta:right">
      <ValueWithUnit value={value} unit="kWh" className={`text-${color}`} />
    </div>
  );
};
