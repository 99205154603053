import React from 'react';
import { AltContent } from '@GDM/AltContent';
import { Spinner } from '@GDM/Spinner';
import useTranslation from '@hooks/useTranslation';
import { useIndexInvoicingData } from '@utils/DashboardMeter/useIndexInvoicingData';
import { formatSystemDate } from '@utils/formatters';
import { getDataKeys } from '../helpers';
import { IndexInvoicingTable } from '../tables';
import { useIndexTab } from '../useIndexTab';

export const InvoicingTab: React.FC = () => {
  const { invoicingDate } = useIndexTab();
  const { t } = useTranslation();
  const req = useIndexInvoicingData(invoicingDate);

  const firstPeriod = formatSystemDate(req.data?.all_data_prev?.prod_indexactif?.date) || '';
  const lastPeriod = formatSystemDate(req.data?.all_data_last?.prod_indexactif?.date) || '';

  const dataPresent = !!(
    req.data &&
    getDataKeys(req.data.all_data_last).length &&
    getDataKeys(req.data.all_data_prev).length &&
    getDataKeys(req.data.all_data_dif).length
  );

  return (
    <div>
      {req.loading && (
        <div className="d-flex align-items-center justify-content-center my-4">
          <Spinner />
        </div>
      )}

      {!req.loading && !dataPresent && <AltContent />}

      {!req.loading && dataPresent && (
        <div>
          <div className="mt-3 mb-3">
            <IndexInvoicingTable
              invoicingData={req.data?.all_data_dif}
              title={t('monitoring.installation.delta_index')}
            />

            <div className="mt-3 mb-3">
              <IndexInvoicingTable
                invoicingData={req.data?.all_data_prev}
                title={`${t('monitoring.installation.index_start')} ${firstPeriod} (kWh)`}
              />
            </div>

            <div className="mt-3 mb-3">
              <IndexInvoicingTable
                invoicingData={req.data?.all_data_last}
                title={`${t('monitoring.installation.index_end')} ${lastPeriod} (kWh)`}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
