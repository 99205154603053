import React, { useEffect } from 'react';
import { Restricted } from '@components/Restricted';
import { Button } from '@GDM/Button';
import { post, useRequest } from '@hooks/useRequest';
import { v2_ppa_simulation_hide_path, v2_ppa_simulations_path } from '@utils/routes';
import { useProjectContext } from '../utils/project.context';

export const DeleteSimulation: React.FC = () => {
  const project = useProjectContext();
  const req = useRequest(v2_ppa_simulation_hide_path(project.simulation?.name || 'none'), post, true);

  const handleDelete = () => {
    req.execute?.();
  };

  useEffect(() => {
    if (req.loaded && !req.error) {
      window.location.href = v2_ppa_simulations_path();
    }
  }, [req.loaded, req.error]);

  if (!project.simulation?.name) return null;

  return (
    <Restricted permissions={['display:edit_pricing']}>
      <Button
        icon="Trash2"
        size="xxs"
        variant="secondary"
        tooltip="pricing.project.delete_simulation"
        onClick={handleDelete}
        isLoading={req.loading}
        data-cy="delete-simulation"
      />
    </Restricted>
  );
};
