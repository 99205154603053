import React, { useState } from 'react';
import { DefinitionModalBody, DefinitionModalFooter } from '@GDM/DefinitionList';
import axiosInstance from '@utils/axiosInstance';
import { getErrorMessage } from '@utils/parsers';
import { v2_reactive_constraint_path } from '@utils/routes';
import { Meter } from '@utils/types/meter';
import { FormProvider as Provider, useForm } from 'react-hook-form';
import { useMeterInfo } from '../../useMeterInfo';
import { CommonQfuFields } from './CommonQfuFields';
import { convertQfuQMins } from './convert-qfu-instructions';
import { handleRequestStack } from './handle-request-stack';
import { Instructions } from './Instructions';
import { PeriodicityPicker } from './PeriodicityPicker';
import styles from './reactive-form.module.scss';
import { TypePicker } from './TypePicker';
import { ReactiveForm } from './types';

export const ReactiveInstructionsModal: React.FC<{ meter?: Meter }> = ({ meter }) => {
  const { reactiveConstraints, maxValue, setMaxValue, setNominalPower, setReactiveConstraints } = useMeterInfo();
  const [requestErrors, setRequestErrors] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const onSave = async (): Promise<void | string> => {
    setIsLoading(true);

    const body = form.getValues();

    setMaxValue?.(Number(body.max_value));

    if (body.type === 'qfu' && meter?.installation?.energy === 'wind' && typeof body.max_value === 'string')
      setNominalPower?.(body.max_value);

    // Just deleting everything

    if (body.periodicity === null && reactiveConstraints?.length) {
      try {
        await Promise.all(
          reactiveConstraints.map((i) =>
            i.uuid ? axiosInstance(false).delete(v2_reactive_constraint_path(i.uuid)) : null,
          ),
        );

        return setReactiveConstraints?.(undefined);
      } catch (e) {
        const message = getErrorMessage(e);
        setRequestErrors(message);

        return message;
      } finally {
        setIsLoading(false);
      }
    }

    if (!body.instructions || !body.instructions.length) return;

    // Handle updates/deletes/creates

    try {
      const instructions = await handleRequestStack(
        { new: convertQfuQMins(body.instructions), old: reactiveConstraints || [] },
        meter?.name,
      );
      setReactiveConstraints?.(instructions);
    } catch (e) {
      const message = getErrorMessage(e);
      setRequestErrors(message);

      return message;
    } finally {
      setIsLoading(false);
    }
  };

  const form = useForm<ReactiveForm>({
    defaultValues: {
      periodicity:
        reactiveConstraints && reactiveConstraints.length
          ? reactiveConstraints.length > 1
            ? 'seasonal'
            : 'constant'
          : null,
      type: reactiveConstraints?.[0]?.reactive_type,
      instructions: convertQfuQMins(reactiveConstraints),
      max_value: maxValue?.toString(),
      voltage: reactiveConstraints?.[0]?.voltage?.toString(),
    },
    mode: 'onChange',
  });

  return (
    <Provider {...form}>
      <DefinitionModalBody>
        <div className="mb-3">
          <PeriodicityPicker />
        </div>

        {form.watch('periodicity') && (
          <div className={styles['form-container']}>
            <TypePicker />
            <CommonQfuFields />

            <Instructions />
          </div>
        )}
      </DefinitionModalBody>

      <DefinitionModalFooter
        save={onSave}
        isLoading={isLoading}
        error={requestErrors}
        isDisabled={!form.formState.isValid}
      />
    </Provider>
  );
};
