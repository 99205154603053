import React from 'react';
import { CapturePriceVsBaseLoadYear } from './CapturePriceVsBaseLoadYear';
import styles from './cards.module.scss';
import { ConfidenceInterval50YearCard } from './ConfidenceInterval50YearCard';
import { LocalCapturePriceYearCard } from './LocalCapturePriceYearCard';
import { LocalMarketCaptureFactorYear } from './LocalMarketCaptureFactorYear';
import { MarketCaptureConfidenceIntervalCard } from './MarketCaptureConfidenceIntervalCard';
import { NationalCapturePriceYearCard } from './NationalCapturePriceYearCard';

export const CardsFullMerchant: React.FC = () => {
  return (
    <div className={styles['cards-container']}>
      <LocalMarketCaptureFactorYear />
      <LocalCapturePriceYearCard />
      <NationalCapturePriceYearCard />
      <CapturePriceVsBaseLoadYear />
      <MarketCaptureConfidenceIntervalCard />
      <ConfidenceInterval50YearCard />
    </div>
  );
};
