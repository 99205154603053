import React, { useContext, useEffect } from 'react';
import { useConsumeContracts } from '@context/contracts.context';
import { Button } from '@GDM/Button';
import { Checkbox } from '@GDM/forms';
import { useModal } from '@GDM/Modal';
import { Text } from '@GDM/Text';
import useSendToRemit from '@hooks/requests/useSendToRemit';
import { selectedContractsContext } from '../context';
import styles from '../contracts.module.scss';

export const RemitForm: React.FC<{ onAfterUpdate: () => void }> = ({ onAfterUpdate }) => {
  const { selectedContracts } = useContext(selectedContractsContext);
  const [checked, setChecked] = React.useState(false);
  const { sendToRemit, loading, error, finished, contracts } = useSendToRemit();
  const { replaceContracts } = useConsumeContracts();
  const { toggle } = useModal();

  const handleSave = () => {
    sendToRemit({
      contract_ids: selectedContracts.map((c) => c.id),
      send_to_remit: checked,
    });
  };

  useEffect(() => {
    if (finished && contracts) {
      replaceContracts(contracts);
      onAfterUpdate();
    }
  }, [finished, contracts, replaceContracts, onAfterUpdate]);

  useEffect(() => {
    setChecked(selectedContracts.every((c) => c?.sent_to_remit));
  }, [selectedContracts]);

  return (
    <>
      <div className="mt-3 mb-2">
        <Checkbox
          label="common.yes"
          onChange={(e) => {
            setChecked(e.currentTarget.checked);
          }}
          checked={checked}
        />

        {error && <Text text={error} type="danger" className="mt-2" />}
      </div>

      <div className={styles['modal-footer']}>
        <Button size="sm" type="button" variant="link" onClick={toggle} text="common.cancel" />

        <Button
          size="sm"
          variant="primary-2"
          onClick={handleSave}
          disabled={loading}
          isLoading={loading}
          text="common.save"
        />
      </div>
    </>
  );
};
