import React, { useCallback, useEffect, useState } from 'react';
import { useSelectedInvoicesContext } from '@context/selectInvoices.context';
import { Dialog } from '@GDM/Dialog';
import { Icon } from '@GDM/Icon';
import { usePermittedStatus } from '@hooks/requests/invoices/usePermittedStatus';
import useDownloadPdfInvoices from '@hooks/requests/useDownloadPdfInvoices';
import { useGenerateInvoice } from '@hooks/requests/useGenerateInvoice';
import useTranslation from '@hooks/useTranslation';
import { MeterInvoiceSource } from '@utils/types/meter-invoice';
import { useInvoicesContext } from 'pages/sales-management/invoicing/invoicesContext';
import useInvoiceNumbering from 'pages/sales-management/invoicing/utils/useInvoiceNumbering';
import styles from '../actions.module.scss';
import { useNominationStatus } from './useNominationStatus';

const GenerateFinalInvoicesButton: React.FC<{ invoiceType: MeterInvoiceSource }> = ({ invoiceType }) => {
  const { t } = useTranslation();
  const { selectedInvoicesUuids } = useSelectedInvoicesContext();
  const { handleClick } = useInvoiceNumbering();
  const { updateInvoices } = useInvoicesContext();
  const { disabled } = usePermittedStatus('generated');
  const { allSelectedInvoicesCanBeNominatedOnCegedim } = useNominationStatus();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { handleDownloadPDF, loading: pdfLoading } = useDownloadPdfInvoices(
    selectedInvoicesUuids,
    invoiceType === 'capa',
  );

  const downloadFinalInvoices = useCallback(() => {
    handleDownloadPDF();
  }, [handleDownloadPDF]);

  const generateRequest = useGenerateInvoice({
    invoices: selectedInvoicesUuids.map((id) => ({ id })),
  });

  useEffect(() => {
    if (!generateRequest.loading && generateRequest.data) {
      updateInvoices(generateRequest.data);
    }
  }, [generateRequest.loading, generateRequest.data, updateInvoices]);

  const generateAndDownloadInvoices = useCallback(async () => {
    await generateRequest.execute?.();
    downloadFinalInvoices();
  }, [generateRequest, downloadFinalInvoices]);

  // if the invoices can be generated to Cegedim, open a dialog that asks the user if they are sure.
  const handleButtonClick = useCallback(() => {
    if (allSelectedInvoicesCanBeNominatedOnCegedim) return setIsDialogOpen(true);

    return handleClick(generateAndDownloadInvoices, 'generate')();
  }, [allSelectedInvoicesCanBeNominatedOnCegedim, generateAndDownloadInvoices, handleClick]);

  const isLoading = pdfLoading || generateRequest.loading;

  return (
    <>
      <Dialog
        size="md"
        isOpen={isDialogOpen}
        message="sales_management.can_be_nominated_on_cegedim"
        saveButtonText="sales_management.issue_anyway"
        cancelButtonText="common.close"
        confirmAction={() => {
          handleClick(generateAndDownloadInvoices, 'generate')();
          setIsDialogOpen(false);
        }}
        toggle={() => setIsDialogOpen(false)}
      />

      <button
        disabled={disabled}
        data-cy="issue-invoice-button"
        onClick={handleButtonClick}
        className={styles['menu-element']}
      >
        {isLoading ? (
          <Icon name="Loader" size={14} className="rotate" />
        ) : (
          t('sales_management.invoice_tooltip_generate_final_invoice')
        )}
      </button>
    </>
  );
};

export default GenerateFinalInvoicesButton;
