import '@styles/GDM';
import React from 'react';
import useTranslation from '@hooks/useTranslation';
import styles from './login.module.scss';
import LoginForm from './LoginForm';

const LoginLayout: React.FC<{
  authenticity_token: string;
  sso_source?: 'engie' | 'urbasolar' | 'eurowatt' | 'cve';
}> = ({ authenticity_token, sso_source }) => {
  const { t } = useTranslation();

  return (
    <div className={styles['login-form-layout']}>
      <div className={`${styles['login-form']} px-5 pt-5 pb-6`}>
        <img src="/img/streem-logo.svg" alt="(Streem logo)" />
        <h1 className="mt-3 mb-4">{t('sign_in.title')}</h1>
        <LoginForm authenticity_token={authenticity_token} sso_source={sso_source} />
      </div>
    </div>
  );
};

export default LoginLayout;
