import React from 'react';
import { Colon } from '@GDM/Colon';
import useTranslation from '@hooks/useTranslation';
import { CellContext } from '@tanstack/react-table';
import MarketPlayer from '@utils/types/market_player';

export const BankInfoCell: React.FC<CellContext<MarketPlayer, unknown>> = ({ row: { original } }) => {
  const { t } = useTranslation();

  return (
    <>
      {original.bank && (
        <div>
          {t('owner.bank')}
          <Colon /> <span className="fw:400">{original.bank}</span>
        </div>
      )}
      {original.iban && (
        <div>
          {t('owner.iban')}
          <Colon /> <span className="fw:400">{original.iban}</span>
        </div>
      )}
      {original.swift_bic && (
        <div>
          {t('owner.swift_bic')}
          <Colon /> <span className="fw:400">{original.swift_bic}</span>
        </div>
      )}
    </>
  );
};
