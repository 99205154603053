import React, { useContext } from 'react';
import { Checkbox } from '@GDM/forms';
import { CellContext } from '@tanstack/react-table';
import { Contract } from '@utils/types/contract';
import { selectedContractsContext } from '../../context';

export const CheckboxCell: React.FC<CellContext<Contract, unknown>> = ({ row: { original } }) => {
  const { selectContract, selectedContracts } = useContext(selectedContractsContext);

  return (
    <Checkbox
      name={`select-row-${original.id}`}
      checked={selectedContracts.findIndex((c) => c.id === original?.id) > -1}
      onChange={() => selectContract(original)}
    />
  );
};
