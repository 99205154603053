import React from 'react';
import { Select } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { DEFAULT_INSTRUCTION, SUMMER_INSTRUCTION, WINTER_INSTRUCTION } from '../default-instructions';
import { ReactiveForm } from '../types';
import { useReactiveForm } from '../useReactiveForm';

export const PeriodicityPicker: React.FC = () => {
  const { control, getValues, setValue } = useReactiveForm();

  const periodicityOptions: Option<NonNullable<ReactiveForm['periodicity']> | null>[] = [
    { value: null, label: 'monitoring.installation.no_order' },
    { value: 'constant', label: 'monitoring.installation.constant_order' },
    { value: 'seasonal', label: 'monitoring.installation.seasonal_order' },
  ];

  /** We have to reset on change to avoid sending wrong dates. */
  const onChange = (
    field: ControllerRenderProps<ReactiveForm, 'periodicity'>,
    value: ReactiveForm['periodicity'],
  ): void => {
    field.onChange(value);

    if (value === 'constant') {
      return setValue('instructions', [
        {
          ...getValues('instructions')?.[0],
          ...DEFAULT_INSTRUCTION,
          reactive_type: getValues('type'),
        },
      ]);
    }

    if (value === 'seasonal') {
      return setValue('instructions', [
        {
          ...getValues('instructions')?.[0],
          ...WINTER_INSTRUCTION,
          reactive_type: getValues('type'),
        },
        {
          ...getValues('instructions')?.[1],
          ...SUMMER_INSTRUCTION,
          reactive_type: getValues('type'),
        },
      ]);
    }
  };

  return (
    <Controller
      name="periodicity"
      control={control}
      render={({ field }) => (
        <Select
          {...field}
          label="monitoring.installation.periodicity_type"
          options={periodicityOptions}
          selectedOption={field.value || null}
          classNamePrefix="periodicity"
          onChange={(option) => onChange(field, option?.value)}
        />
      )}
    />
  );
};
