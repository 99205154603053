import React from 'react';
import { FilterContainer, Filters } from '@GDM/Filters';
import { CountrySelect } from '@GDM/forms';
import { isCountryCode } from '@utils/filters/isCountryCode';
import { usePricingMapsFiltersContext } from '../utils/pricingMapsFilters.context';
import { PeriodSelect } from './PeriodSelect/PeriodSelect';
import { ResultSelect } from './ResultSelect';
import { RunSelect } from './RunSelect';

export const PricingMapsFilters: React.FC = () => {
  const { filters, setFilters } = usePricingMapsFiltersContext();

  return (
    <Filters className="p-0">
      <FilterContainer>
        <CountrySelect
          selectedOption={filters.country}
          onChange={(option) =>
            setFilters({
              ...filters,
              country: option?.value && isCountryCode(option.value) ? option.value : null,
            })
          }
        />
      </FilterContainer>

      <FilterContainer size="datepicker">
        <RunSelect
          country={filters.country}
          selectedOption={filters.run}
          onChange={(option) => setFilters({ ...filters, run: option?.value || null })}
        />
      </FilterContainer>

      <FilterContainer>
        <ResultSelect
          selectedOption={filters.result}
          onChange={(option) => setFilters({ ...filters, result: option?.value || null })}
        />
      </FilterContainer>

      <FilterContainer size="fit">
        <PeriodSelect
          selectedOption={filters.periodId?.toString() || ''}
          onChange={(option) => setFilters({ ...filters, periodId: Number(option?.value) || null })}
        />
      </FilterContainer>
    </Filters>
  );
};
