import React, { useEffect } from 'react';
import { Badge } from '@GDM/Badge';
import { Button } from '@GDM/Button';
import { SlideInPanel } from '@GDM/SlideInPanel';
import { SubscriptionForm } from '@pages/BSM/forms/SubscriptionForm';
import { SubscriptionFormProvider } from '@pages/BSM/forms/SubscriptionFormProvider';
import { useSubscriptions } from '../context';
import { MODULE_VARIANT_MAPPING } from '../helpers';

export const SubscriptionSlideInPanel: React.FC = () => {
  const { selectedSubscription, setSelectedSubscription } = useSubscriptions();

  // This value has to be transitioned 500ms after the selected contract changes because
  // there's a slide-in transition that's 500ms long and when closing, all the content will
  // disappear before the panel has slid back in.
  useEffect(() => {
    const timeout = selectedSubscription ? 0 : 500;
    const transition = setTimeout(() => setSelectedSubscription?.(selectedSubscription), timeout);

    return () => clearTimeout(transition);
  });

  const TitleNode: React.FC = () => {
    return (
      <>
        <span className="mr-2">{selectedSubscription?.email}</span>
        {selectedSubscription?.product && (
          <Badge
            label={selectedSubscription.product}
            variant={MODULE_VARIANT_MAPPING[selectedSubscription.product]}
            className="tt:capitalize"
          />
        )}
      </>
    );
  };

  return (
    <SlideInPanel
      data-cy="subscription-slide-in"
      actions={
        <Button
          href={`internal/subscriptions/${selectedSubscription?.uuid}/edit`}
          icon="Edit3"
          tooltip="common.edit"
          data-place="bottom"
          floating
        />
      }
      isOpen={!!selectedSubscription}
      title={<TitleNode />}
      toggle={() => {
        setSelectedSubscription?.(null);
        window.location.hash = '';
      }}
    >
      <SubscriptionFormProvider defaultValues={selectedSubscription} readonly>
        <SubscriptionForm />
      </SubscriptionFormProvider>
    </SlideInPanel>
  );
};
