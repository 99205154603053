import React from 'react';
import useTranslation from '@hooks/useTranslation';
import { CellContext } from '@tanstack/react-table';
import { Unavailability } from '@utils/types/unavailability';

export const StatusCell: React.FC<React.PropsWithChildren<CellContext<Unavailability, unknown>>> = ({ getValue }) => {
  const { t } = useTranslation();
  const value = getValue();

  return <>{value && t(`monitoring.unavailabilities.status.${value}`)}</>;
};
