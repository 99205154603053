import React from 'react';
import { CellContext } from '@tanstack/react-table';
import { Contract } from '@utils/types/contract';

export const PMaxCell: React.FC<CellContext<Contract, unknown>> = ({ row: { original } }) => {
  if (original?.p_max || original?.p_max === 0) {
    return <div>{original?.p_max?.toFixed(0)} kW</div>;
  }

  return null;
};
