import React, { useContext } from 'react';
import { selectInvoicesContext } from '@context/selectInvoices.context';
import { Checkbox } from '@GDM/forms';
import { Row } from '@tanstack/react-table';
import { MeterInvoice } from '@utils/types/meter-invoice';

type Props = {
  row: Row<MeterInvoice>;
};

export const CheckboxCell: React.FC<Props> = ({ row }) => {
  const { selectedInvoicesUuids, addInvoice, unselectInvoice } = useContext(selectInvoicesContext);

  return (
    <Checkbox
      name={`invoice-checkbox-${row.original?.uuid}`}
      disabled={row.original?.uuid === undefined}
      checked={selectedInvoicesUuids.includes(row.original?.uuid)}
      onChange={(e) => {
        if (e.target.checked) {
          addInvoice(row.original);
        } else {
          unselectInvoice(row.original);
        }
      }}
    />
  );
};
