import React from 'react';
import useTranslation from '@hooks/useTranslation';
import { unapprove_v2_invoice_path } from '@utils/routes';
import { MeterInvoice } from '@utils/types/meter-invoice';
import { InvoiceDestructiveActionButton } from './InvoiceDestructiveActionButton';

export const UnapproveInvoiceButton: React.FC<{
  invoice: MeterInvoice;
  className?: string;
}> = ({ invoice, className }) => {
  const { t } = useTranslation();

  if (invoice.status !== 'approved') return null;

  return (
    <InvoiceDestructiveActionButton
      modalTitle="sales_management.unapprove_title"
      confirmationText={t('sales_management.confirm_unapprove', {
        newStatus: t('common.filters.invoice_status.created'),
      })}
      invoice={invoice}
      data-cy="unapprove-invoice"
      path={unapprove_v2_invoice_path(invoice.uuid)}
      submitButtonInfo={t('sales_management.revert_to_created')}
      permissions={['display:edit_invoices']}
      className={className}
      icon="CornerUpLeft"
    />
  );
};
