import React, { useMemo, useState } from 'react';
import { localStorageHelpers } from '../helpers/tableFilterHelpers';
import { PortfolioTableContext, portfolioTableContext } from './portfolio.context';

export const PortfolioTableProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [infoFilters, setInfoFilters] = useState<PortfolioTableContext['infoFilters']>(
    localStorageHelpers.getTableColumnFilters() || {
      clientId: false,
      commissioningDate: false,
    },
  );

  const updateInfoFilters = (items: Partial<PortfolioTableContext['infoFilters']>) => {
    setInfoFilters((prev) => {
      const updated = { ...prev, ...items };

      localStorageHelpers.setTableColumnFilters(updated);

      return updated;
    });
  };

  const context = useMemo<PortfolioTableContext>(
    () => ({
      infoFilters,
      setInfoFilters: updateInfoFilters,
    }),
    [infoFilters],
  );

  return <portfolioTableContext.Provider value={context}>{children}</portfolioTableContext.Provider>;
};
