import React from 'react';
import { Select } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import Book from '@utils/types/book';
import { Option } from '@utils/types/common-types';
import { ExportFormData } from 'pages/Export/export.types';
import { Controller, ControllerRenderProps, useFormContext, useWatch } from 'react-hook-form';

type BookNamesSelectProps = {
  books: Book[];
};

export const BookNamesSelect: React.FC<BookNamesSelectProps> = ({ books }) => {
  const { control } = useFormContext<ExportFormData>();
  const { t } = useTranslation();
  const meterNames = useWatch({ control, name: 'meterNames' });

  const options = books
    .map((book) => ({
      label: `${book.name} (${t('common.n_installations', { count: book.installations_size })})`,
      value: book.name,
      type: 'book',
    }))
    .sort(sortOptionsByLabelAsc);

  const handleChange = (field: ControllerRenderProps<ExportFormData, 'books'>) => (options: Option[] | null) =>
    field.onChange(books.filter((book) => options?.map((option) => option.value).includes(book.name)));

  return (
    <Controller
      name="books"
      control={control}
      rules={{ required: meterNames.length === 0, minLength: meterNames.length ? 0 : 1 }}
      render={({ field }) => (
        <Select
          id="books"
          label="common.books"
          options={options}
          selectedOptions={field.value.map((book) => book.name)}
          isMulti
          onChange={handleChange(field)}
          hasSelectAll
        />
      )}
    />
  );
};
