import React from 'react';
import { Badge } from '@GDM/Badge';
import { Tooltip } from '@GDM/Tooltip/Tooltip';
import useTranslation from '@hooks/useTranslation';
import { Contract } from '@utils/types/contract';
import { ContractNumberCell } from '../ContractNumberCell';
import { TypeBadge } from './TypeBadge';

export const TypeCell: React.FC<{ contract: Partial<Contract> }> = ({ contract }) => {
  const { t } = useTranslation();

  const tooltip = contract.card_i ? `${t('sales_management.card_i')} : ${contract.card_i}` : undefined;

  return (
    <Tooltip tooltip={tooltip}>
      <div>
        <TypeBadge contract={contract} />
        {contract.contract_nb && (
          <div className="mt-1 d-flex align-items-center">
            <ContractNumberCell contractNumber={contract.contract_nb} />
            {contract.power_augmentation_percentage && (
              <Badge className="ml-2" tooltip="sales_management.power_augmentation_title">
                {contract.power_augmentation_percentage}%
              </Badge>
            )}
          </div>
        )}
      </div>
    </Tooltip>
  );
};
