import React, { useContext, useEffect, useMemo } from 'react';
import { selectInvoicesContext } from '@context/selectInvoices.context';
import { Modal } from '@GDM/Modal';
import { Text } from '@GDM/Text';
import { useNominationRequest, NominationInvoiceRequestBody } from '@hooks/requests/useNominationRequest';
import useTranslation from '@hooks/useTranslation';
import { InstallationInfo } from './EmailModal/InstallationInfo';
import { useInvoicesContext } from './invoicesContext';

export const NominateModal: React.FC<{
  isOpen: boolean;
  toggle: () => void;
  source: 'cegedim' | 'edf_oa';
}> = ({ isOpen, toggle, source }) => {
  const { t } = useTranslation();

  const { selectedInvoicesUuids } = useContext(selectInvoicesContext);
  const { allInvoices, updateInvoices } = useInvoicesContext();
  const selectedInvoices = useMemo(
    () => allInvoices.filter((i) => selectedInvoicesUuids.includes(i.uuid)),
    [allInvoices, selectedInvoicesUuids],
  );

  const data: NominationInvoiceRequestBody | undefined = useMemo(() => {
    if (source === 'edf_oa')
      return {
        invoices: selectedInvoices.map((invoice) => ({ id: invoice.uuid })),
      };
    if (source === 'cegedim')
      return {
        invoices: selectedInvoices.map((invoice) => ({
          id: invoice.uuid,
          invoice_nb: invoice.invoice_nb_expression,
        })),
      };
  }, [source, selectedInvoices]);

  const nominateRequest = useNominationRequest(data);

  const submitAction = () => {
    nominateRequest.execute?.();
    if (isOpen) toggle();
  };

  useEffect(() => {
    if (!nominateRequest.loading && nominateRequest.data) {
      updateInvoices(nominateRequest.data);
    }
  }, [nominateRequest.loading, nominateRequest.data, updateInvoices]);

  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      header={t(`sales_management.nominate_invoices_to_${source}.title`)}
      submitAction={submitAction}
      submitDisabled={nominateRequest.loading}
      isLoading={nominateRequest.loading}
      saveButtonText="common.send"
      data-cy={`nominate-${source}-modal`}
    >
      {selectedInvoices.map((inv, index) => (
        <InstallationInfo
          invoice={inv}
          last={index === selectedInvoicesUuids.length - 1}
          key={inv.uuid}
          source={source}
        />
      ))}
      <Text text={`sales_management.nominate_invoices_to_${source}.click_name`} className="mt-3" />
      {nominateRequest.error && (
        <Text text={`sales_management.nominate_invoices_to_${source}.error`} type="danger" className="mt-3" />
      )}
    </Modal>
  );
};
