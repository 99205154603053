import React from 'react';
import { useUser } from '@context/User.context';
import { Tab, Tabs } from '@GDM/Tabs';
import { useDashboardMeter } from './useDashboardMeter';

export enum TabNames {
  PRODUCTION = 'production',
  REVENUE = 'revenue',
  NEGATIVE_PRICES = 'negative_prices',
  CONTRACTS_CAPS = 'contracts_caps',
  EVENTS = 'alerts',
  INDEX = 'index',
  M0 = 'm0',
  CONTRACTS = 'contracts',
  INVOICES = 'invoices',
  CHARACTERISTICS = 'characteristics',
}

export const TabNav: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { book, meter, installation } = useDashboardMeter();
  const { isAggregator, isConsumer, limited } = useUser();
  const installationOnly = installation && !book && !meter;
  const isUK =
    installation?.country === 'UK' ||
    meter?.country === 'UK' ||
    book?.installation_countries.every((country) => country === 'UK');
  const openEventCount = meter?.nb_ongoing_events || book?.nb_ongoing_events;

  const tabList: Array<Tab<TabNames>> = [
    {
      id: TabNames.PRODUCTION,
      name: isConsumer ? 'common.production_aggregator' : 'common.production',
      permissions: ['display:view_monitoring_dashboard_meter_prod_tab'],
      condition: !installationOnly,
    },
    {
      id: TabNames.REVENUE,
      name: isAggregator ? 'common.revenues_aggregator' : 'common.revenues',
      permissions: ['display:external_salesfocus'],
    },
    {
      id: TabNames.NEGATIVE_PRICES,
      name: 'common.negative_prices',
      permissions: ['display:view_salesfocus'],
      condition: !installationOnly,
    },
    {
      id: TabNames.CONTRACTS_CAPS,
      name: 'common.contracts_caps',
      permissions: ['display:view_salesfocus'],
      condition: !book && !installationOnly && !isUK,
    },
    {
      id: TabNames.EVENTS,
      name: 'common.alerts',
      permissions: ['display:view_monitoring_dashboard_meter_alerts_tab'],
      badge: openEventCount ? `${openEventCount}` : undefined,
      condition: !installationOnly,
    },
    {
      id: TabNames.INDEX,
      name: 'common.index',
      permissions: ['display:view_monitoring_dashboard_meter_index_tab'],
      condition: !book && !installationOnly && !isUK,
    },
    {
      id: TabNames.M0,
      name: 'common.market_perf',
      permissions: ['display:view_monitoring_dashboard_meter_m0_tab'],
      condition: !limited && Boolean(book?.has_m0 || meter?.has_m0 || installation?.meter?.has_m0),
    },
    {
      id: TabNames.CONTRACTS,
      name: 'common.contracts',
      permissions: ['display:view_salesfocus'],
    },
    {
      id: TabNames.INVOICES,
      name: 'common.invoices',
      permissions: ['display:view_salesfocus'],
    },
    {
      id: TabNames.CHARACTERISTICS,
      name: 'common.characteristics',
      permissions: ['display:view_monitoring_dashboard_meter_features_tab', 'display:view_salesfocus'],
    },
  ];

  return (
    <Tabs tabs={tabList} defaultTab={installationOnly ? TabNames.CHARACTERISTICS : undefined}>
      {children}
    </Tabs>
  );
};
