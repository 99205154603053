import React from 'react';
import { LightDefinition } from '@GDM/LightDefinitionList';
import { ValueWithUnit } from '@GDM/ValueWithUnit';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import styles from './simulation-settings.module.scss';

export const SimulationSettingsSolar: React.FC = () => {
  const project = useProjectContext();

  return (
    <>
      <LightDefinition
        className={styles.definition}
        label="common.power"
        value={<ValueWithUnit value={project.simulation?.maximum_power} unit="kW" unstyled />}
      />
      {project.simulation?.tracker && (
        <>
          <LightDefinition className={styles.definition} label="pricing.project.tracker" value="common.yes" />

          <LightDefinition
            className={styles.definition}
            label="pricing.project.inclination_max"
            value={
              <>
                <ValueWithUnit value={project.simulation?.maxangle_tracker_morning} unit="degree" unstyled /> /{' '}
                <ValueWithUnit value={project.simulation?.maxangle_tracker_evening} unit="degree" unstyled />
              </>
            }
          />
        </>
      )}

      {!project.simulation?.tracker && (
        <>
          <LightDefinition
            className={styles.definition}
            label="pricing.project.inclination"
            value={<ValueWithUnit value={project.simulation?.inclination} unit="degree" unstyled />}
          />
        </>
      )}

      <LightDefinition
        className={styles.definition}
        label="pricing.project.orientation"
        value={<ValueWithUnit value={project.simulation?.orientation} unit="degree" unstyled />}
      />
    </>
  );
};
