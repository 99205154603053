import React from 'react';
import { Definition, DefinitionList } from '@GDM/DefinitionList';
import useTranslation from '@hooks/useTranslation';
import { Popup } from 'react-leaflet';
import { DashboardViewAllData } from '../portfolio.definitions';
import styles from './map.module.scss';
import { PopupTitle } from './PopupTitle';

export const MapPopup: React.FC<{ meter: DashboardViewAllData }> = ({ meter }) => {
  const { t } = useTranslation();

  return (
    <Popup className={styles.popup}>
      <a className="d-block mb-2" href={`/v2/dashboard_meter/?meter=${meter.ref_centrale}`}>
        <PopupTitle meter={meter} />
      </a>
      <DefinitionList>
        <Definition label={t('common.specific_energy')} value={`${meter.hourprod || '--'} h`} />
        <Definition label={t('common.energy')} value={`${meter.sumdata || '--'} kWh`} />
        <Definition label={t('common.last_point')} value={`${meter.maxdate || '--'}`} />
      </DefinitionList>
    </Popup>
  );
};
