import React from 'react';
import { useUser } from '@context/User.context';
import { Badge } from '@GDM/Badge';
import getContractTypeLabel from '@utils/contract/getLabel';
import { ContractType } from '@utils/types/contract';

export const ContractCell: React.FC<{ value: ContractType }> = ({ value }) => {
  const { main_country } = useUser();
  const countryRef = main_country || 'FR';

  return <>{value && <Badge label={getContractTypeLabel(value, countryRef)} />}</>;
};
