import React from 'react';
import { Button } from '@GDM/Button';
import Page from '@pages/Page';
import { User } from '@utils/types/user';
import { BSMProvider } from './context';
import { TabContainer } from './TabContainer';

export const BSM: React.FC<{
  user: User;
}> = ({ user }) => {
  return (
    <BSMProvider>
      <Page
        titleIcon="Award"
        title="subscriptions.title"
        user={user}
        pageActions={
          <Button
            href="/internal/subscriptions/new"
            text="subscriptions.new.title"
            variant="primary-2"
            size="sm"
            data-cy="new-subscription"
          />
        }
      >
        <TabContainer />
      </Page>
    </BSMProvider>
  );
};
