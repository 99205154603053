import React from 'react';
import { useUser } from '@context/User.context';
import { Badge } from '@GDM/Badge';
import getContractTypeLabel from '@utils/contract/getLabel';
import { Contract } from '@utils/types/contract';

export const TypeBadge: React.FC<{ contract: Partial<Contract> }> = ({ contract }) => {
  const { main_country } = useUser();
  const countryRef = contract.installation?.country || main_country || 'FR';

  return (
    <>
      {contract.type && <Badge label={getContractTypeLabel(contract.type, countryRef)} />}
      {contract.status === 'dummy' && <Badge variant="danger" className="ml-1" label="common.dummy" />}
      {contract.status === 'draft' && <Badge variant="danger" className="ml-1" label="common.draft" />}
    </>
  );
};
