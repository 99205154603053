import React from 'react';
import { Icon } from '@GDM/Icon';
import { ProgressCircle } from '@GDM/ProgressCircle';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import { useProgressStepper } from '../context/useProgressStepper';
import { card } from '../progress-stepper.module.scss';
import styles from './nav.module.scss';

export const Nav: React.FC = () => {
  const { t } = useTranslation();
  const { steps, singleStep } = useProgressStepper();

  const keys = steps ? Object.keys(steps) : [];

  return (
    <div className={classNames(card, styles.nav)}>
      {steps &&
        Object.values(steps).map((step, index) => (
          <div
            key={step.title}
            className={classNames(styles['nav-item'], singleStep && singleStep !== keys[index] && 'unavailable')}
          >
            <span className={styles.title}>{t(step.title)}</span>
            <div className={styles.progress}>
              {step.isValid ? (
                <Icon name="CheckCircle" size={20} />
              ) : (
                <ProgressCircle value={step.progress} animated={true} />
              )}
            </div>
          </div>
        ))}
    </div>
  );
};
