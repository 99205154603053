import React from 'react';
import { Select } from '@GDM/forms';
import { RequestError } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import Text from '@ui/text';
import MarketPlayer from '@utils/types/market_player';
import { useFormContext } from 'react-hook-form';
import { Controller } from 'react-hook-form';

export const AggregatorsInput: React.FC<{
  aggregators: MarketPlayer[] | null;
  isLoading: boolean;
  error?: RequestError | null;
}> = ({ aggregators, isLoading, error }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  const options = aggregators?.map((aggregator) => ({ label: aggregator.short_name, value: aggregator.id })) || [];

  return (
    <Controller
      name="aggregator_id"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
        <>
          <Select
            label="common.offtaker"
            isLoading={isLoading}
            isDisabled={isLoading}
            hasError={!!error}
            options={options}
            selectedOption={field.value}
            onChange={(option) => field.onChange(option?.value)}
            classNamePrefix="select-offtaker"
          />
          {errors?.aggregator_id && (
            <Text color="danger">{t('common.is_required_dynamic', { key: t('common.offtaker') })}</Text>
          )}
        </>
      )}
    />
  );
};
