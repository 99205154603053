import React from 'react';
import { Button } from '@GDM/Button';
import classNames from 'classnames';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { WIND_CONSTRAINT_DEFAULT_VALUES } from '../config/default-values';
import styles from '../new-simulation.module.scss';
import { SimulationForm } from '../utils/simulations.types';
import { WindConstraint } from './WindConstraint';

export const WindConstraints: React.FC = () => {
  const { control, trigger } = useFormContext<SimulationForm>();
  const { fields, remove, insert } = useFieldArray({
    control,
    name: 'ppa_wind_bounds_attributes',
    keyName: 'internalId',
  });

  const handleAdd = async () => {
    insert(fields.length, WIND_CONSTRAINT_DEFAULT_VALUES);

    if (await trigger('ppa_wind_bounds_attributes')) trigger('ppa_wind_bounds_attributes');
  };

  const handleRemove = (index: number) => () => {
    remove(index);
  };

  return (
    <div>
      {fields.map((field, index) => (
        <WindConstraint key={field.internalId} field={field} index={index} handleRemove={handleRemove} />
      ))}

      <div className={classNames(styles['section-footer'], fields.length > 0 && styles['section-footer-separator'])}>
        <Button
          text="pricing.project.add_constraint"
          icon="Plus"
          variant="primary-2"
          size="sm"
          className="ml-auto"
          onClick={handleAdd}
        />
      </div>
    </div>
  );
};
