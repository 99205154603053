import React, { useMemo } from 'react';
import { BooksPageContext, booksPageContext } from './books-page.context';

export const BooksPageProvider: React.FC<React.PropsWithChildren<BooksPageContext>> = ({
  children,
  onSelect,
  onDelete,
}) => {
  const context = useMemo<BooksPageContext>(() => ({ onSelect, onDelete }), [onSelect, onDelete]);

  return <booksPageContext.Provider value={context}>{children}</booksPageContext.Provider>;
};
