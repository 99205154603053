import React from 'react';
import { Modal } from '@GDM/Modal';
import useTranslation from '@hooks/useTranslation';

export const Dialog: React.FC<
  React.PropsWithChildren<{
    className?: string;
    error?: string;
    info?: string;
    message?: string;
    header?: string | React.ReactNode;
    hideCancelButton?: boolean;
    isLoading?: boolean;
    isOpen: boolean;
    saveButtonText?: string;
    cancelButtonText?: string;
    size?: 'sm' | 'md';
    'data-cy'?: string;
    confirmAction?: () => void;
    submitDisabled?: boolean;
    toggle?: () => void;
  }>
> = ({
  cancelButtonText,
  className,
  children,
  confirmAction,
  'data-cy': dataCy,
  error,
  header,
  hideCancelButton,
  info,
  isLoading,
  isOpen,
  message,
  saveButtonText,
  size,
  submitDisabled,
  toggle,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size={size || 'sm'}
      className={className}
      data-cy={dataCy}
      header={header}
      info={info}
      error={error}
      hideCancelButton={hideCancelButton}
      submitAction={confirmAction}
      saveButtonText={saveButtonText || 'common.confirm'}
      submitDisabled={submitDisabled}
      isLoading={isLoading}
      cancelButtonText={cancelButtonText || 'common.cancel'}
    >
      {message && typeof message === 'string' && <div dangerouslySetInnerHTML={{ __html: t(message) }} />}
      {children}
    </Modal>
  );
};
