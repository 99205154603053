import React from 'react';
import { MandateFormType } from '@context/AdMeterForm.context';
import { Select } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { useInstallationsPageContext } from '../../context/installations-page.context';

interface MandateOption extends Option {
  expired?: boolean;
}

const SelectMandate: React.FC<{
  value: string;
  onChange: (formData: MandateFormType) => void;
}> = ({ value, onChange }) => {
  const { mandates } = useInstallationsPageContext();
  const handleMandateChange = (mandateId?: string) => {
    const mandate = mandates.find((i) => i.uuid === mandateId);
    if (mandate) {
      const newValues: MandateFormType = {
        mandateId: mandate.uuid,
        signatoryName: mandate.signatory_name || '',
        signatoryEmail: mandate.signatory_email || '',
        sirets: mandate.sirets?.join(','),
        mandateFile: null,
        status: mandate.status,
        mandataire: mandate.representative,
        signType: 'online',
      };

      onChange(newValues);
    } else {
      onChange({
        mandateId: mandateId ?? '',
        signatoryName: '',
        signatoryEmail: '',
        sirets: '',
        mandateFile: null,
        status: '',
        mandataire: '',
        signType: 'online',
      });
    }
  };

  const options: MandateOption[] = [
    { value: 'select_mandate', label: 'common.select_default_placeholder' },
    { value: 'new_mandate', label: 'admin.installations.new_mandate' },
    { value: '-', label: '───────────', disabled: true },
    ...mandates.map((mandat) => ({
      value: mandat.uuid,
      label: mandat.representative,
      expired: mandat.status === 'expired',
    })),
  ];

  const isOptionDisabled = (option: MandateOption) => (option.expired ?? option.disabled) || false;

  return (
    <div className="mb-3">
      <Select
        label="admin.installations.select_mandate"
        options={options}
        selectedOption={value}
        isOptionDisabled={isOptionDisabled}
        onChange={(opt) => handleMandateChange(opt?.value)}
        classNamePrefix="select-mandate"
      />
    </div>
  );
};

export default SelectMandate;
