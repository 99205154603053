import React, { useEffect, useState } from 'react';
import { Modal } from '@GDM/Modal';
import { post, put, useRequest } from '@hooks/useRequest';
import { v2_book_path, v2_books_path } from '@utils/routes';
import Book from '@utils/types/book';
import Installation from '@utils/types/installation';
import { FormProvider, useForm } from 'react-hook-form';
import { BookFormContext } from './book-form.types';
import { BookForm } from './BookForm';

export const BookFormModal: React.FC<{
  isOpen: boolean;
  toggle: () => void;
  installations: Installation[];
  addBook: (book: Book) => void;
  selectedBook?: Book | null;
}> = ({ isOpen, toggle, installations, addBook, selectedBook }) => {
  const [formError, setFormError] = useState<string | undefined>();

  const {
    data: insertRequestData,
    loaded: insertRequestLoaded,
    loading: insertRequestLoading,
    error: insertRequestError,
    execute: insertRequestExecute,
  } = useRequest<{ book: Book }>(v2_books_path(), post, true);
  const {
    data: updateRequestData,
    loaded: updateRequestLoaded,
    loading: updateRequestLoading,
    error: updateRequestError,
    execute: updateRequestExecute,
  } = useRequest<{ book: Book }>(v2_book_path(selectedBook?.uuid ?? 'none'), put, true);

  const form = useForm<BookFormContext>({
    mode: 'onChange',
    defaultValues: {
      name: selectedBook?.name || '',
      installations: selectedBook?.installation_names || [],
    },
  });

  const { reset, handleSubmit } = form;

  const isLoading = insertRequestLoading ?? updateRequestLoading;
  const error = insertRequestError ?? updateRequestError;

  const handleCreate = ({ name, installations }: BookFormContext) => {
    const data = { installations, name: name.trim() };
    const isUpdate = !!selectedBook;

    try {
      if (isUpdate) {
        updateRequestExecute?.(data);
      } else {
        insertRequestExecute?.(data);
      }
    } catch (e) {
      setFormError((e as { code: string })?.code || (e as { message: string })?.message || 'errors.unknown');
    }
  };

  useEffect(() => {
    if (insertRequestLoaded && insertRequestData) {
      addBook(insertRequestData.book);
      reset();
    }
  }, [insertRequestData, insertRequestLoaded, addBook, reset]);

  useEffect(() => {
    if (updateRequestLoaded && updateRequestData) {
      addBook(updateRequestData.book);
      reset();
    }
  }, [updateRequestData, updateRequestLoaded, addBook, reset]);

  return (
    <FormProvider {...form}>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        header={selectedBook ? 'admin.books.update_book' : 'admin.books.create_book'}
        submitAction={handleSubmit(handleCreate)}
        data-cy="create-book-modal"
        isLoading={isLoading}
        submitDisabled={!form.formState.isValid}
        error={error?.code ?? error?.message ?? formError}
      >
        <BookForm installations={installations} />
      </Modal>
    </FormProvider>
  );
};
