import React, { useMemo } from 'react';
import { Table, TableBody, TableHead } from '@GDM/Table';
import { getSortedRowModel, useReactTable, getCoreRowModel, ColumnDef } from '@tanstack/react-table';
import { BookNameCell } from '@ui/table/cell/BookNameCell';

type DataType = {
  name: string;
};

const InstallationBooks: React.FC<{ books: string[] }> = ({ books }) => {
  const data = useMemo(() => books.map((name) => ({ name })), [books]);

  const columns = useMemo<ColumnDef<DataType>[]>(
    () => [
      {
        header: 'common.name',
        accessorKey: 'name',
        cell: (info) => <BookNameCell name={info.getValue()} isPricing={false} />,
      },
    ],
    [],
  );
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Table>
      <TableHead table={table} />
      <TableBody table={table} />
    </Table>
  );
};

export default InstallationBooks;
