import React from 'react';
import { DatePicker } from '@GDM/DatePicker';
import { Controller, useFormContext } from 'react-hook-form';
import { CreateModalForm } from '../../CreateModal/types';

export const StartDateInput: React.FC = () => {
  const { control, trigger } = useFormContext<CreateModalForm>();

  return (
    <Controller
      control={control}
      name="dates.start_date"
      render={({ field: { onChange, value } }) => (
        <DatePicker
          onChange={(date) => {
            onChange(date);
            trigger('dates');
          }}
          selected={value}
          label="common.start"
          picks="datetime"
        />
      )}
    />
  );
};
