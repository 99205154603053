import React, { useCallback, useState } from 'react';
import { cancelModalContext } from './context';

export const CancelModalProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(() => {
    setIsOpen((open) => !open);
  }, [setIsOpen]);

  return <cancelModalContext.Provider value={{ isOpen, toggle }}>{children}</cancelModalContext.Provider>;
};
