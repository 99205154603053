import React from 'react';
import { Alert } from '@GDM/Alert';
import { Tabs } from '@GDM/Tabs';
import { useRequest } from '@hooks/useRequest';
import Page from '@pages/Page';
import { details_v2_market_player_path } from '@utils/routes';
import MarketPlayer from '@utils/types/market_player';
import { User } from '@utils/types/user';
import { TABS } from './market-players.constants';
import { MarketPlayersTabContent } from './MarketPlayersTabContent';

export const MarketPlayers: React.FC<{ user: User }> = ({ user }) => {
  const uuid = location.pathname.split('/').pop();
  const {
    data: marketPlayer,
    loading,
    error,
  } = useRequest<MarketPlayer>(details_v2_market_player_path(uuid || ''), 'GET');

  return (
    <Page
      titleIcon={error ? undefined : 'Briefcase'}
      title={marketPlayer?.short_name ?? (error ? 'Market Player' : '')}
      user={user}
      isLoading={loading}
      layout={!marketPlayer ? 'no-background' : 'classic'}
    >
      {!marketPlayer && !loading && <Alert variant="danger" label={error?.code} />}
      {!!marketPlayer && (
        <Tabs tabs={TABS} className="mb-3">
          <MarketPlayersTabContent marketPlayer={marketPlayer} />
        </Tabs>
      )}
    </Page>
  );
};
