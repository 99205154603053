import React from 'react';
import { Icon } from '@GDM/Icon';
import { Tooltip } from '@GDM/Tooltip/Tooltip';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import styles from './badge.module.scss';

export type BadgeProps = {
  className?: string;
  label?: string;
  tooltip?: string | null;
  variant?: 'default' | 'danger' | 'primary-1' | 'primary-2' | 'orange' | 'light-blue';
  onDismiss?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export const Badge: React.FC<React.PropsWithChildren<BadgeProps>> = ({
  children,
  className,
  label,
  tooltip,
  onDismiss,
  variant,
}) => {
  const { t } = useTranslation();
  if (!children && !label) return null;

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    if (!onDismiss) return;

    onDismiss(e);
  };

  return (
    <Tooltip tooltip={tooltip}>
      <div
        className={classNames(styles.badge, className, variant && styles[variant], onDismiss && styles.dismissible)}
        onClick={onClick}
      >
        {t(label) || children}
        {onDismiss && <Icon name="X" size="11" className="ml-2" />}
      </div>
    </Tooltip>
  );
};
