import React, { useState } from 'react';
import { DefinitionModalBody, DefinitionModalFooter } from '@GDM/DefinitionList';
import { Input } from '@GDM/forms';
import { useRequest } from '@hooks/useRequest';
import { getErrorMessage } from '@utils/parsers';
import { change_external_ref_v2_dashboard_meter_index_path } from '@utils/routes';
import { Meter } from '@utils/types/meter';
import { useMeterInfo } from '../useMeterInfo';

export const ClientIdModal: React.FC<{ meter: Meter }> = ({ meter }) => {
  const { client_id, setClientId } = useMeterInfo();
  const [localClientId, setLocalClientId] = useState(client_id ?? null);

  const { loading: isLoading, execute } = useRequest(
    change_external_ref_v2_dashboard_meter_index_path({
      meter: meter.name,
      external_ref: localClientId,
    }),
    'GET',
    true,
  );

  const saveUpdate = async () => {
    try {
      const response = await execute?.();
      setClientId?.(localClientId ?? null);

      if (typeof response === 'string') return response;
    } catch (e) {
      return getErrorMessage(e);
    }
  };

  return (
    <>
      <DefinitionModalBody>
        <Input
          value={localClientId ?? ''}
          full
          placeholder="14030219"
          onChange={(e) => setLocalClientId(e.target.value)}
        />
      </DefinitionModalBody>
      <DefinitionModalFooter save={saveUpdate} isLoading={isLoading} />
    </>
  );
};
