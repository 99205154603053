import React, { useEffect, useState } from 'react';
import { Col, Row } from '@GDM/layout';
import { Modal } from '@GDM/Modal';
import { Spinner } from '@GDM/Spinner';
import { post, useRequest } from '@hooks/useRequest';
import { v2_inverters_path, v2_inverters_update_several_path } from '@utils/routes';
import { Inverter } from '@utils/types/inverter';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import FormField from './FormField';

type PropTypes = {
  toggle: () => void;
  visible: boolean;
  meterName: string;
};

const ModalForm: React.FC<PropTypes> = ({ toggle, visible, meterName }) => {
  const [inverters, setInverters] = useState<Inverter[]>([]);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const getInvertersRequest = useRequest<Inverter[]>(
    v2_inverters_path({ params: { meter_name: meterName } }),
    'GET',
    true,
  );
  const insertInvertersRequest = useRequest<Inverter[]>(v2_inverters_update_several_path(), post, true);

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    insertInvertersRequest.execute?.({ inverters: data });
  };

  useEffect(() => {
    if (!visible) reset();
    if (visible) getInvertersRequest.execute?.();
  }, [visible]);

  useEffect(() => {
    if (getInvertersRequest.loading && getInvertersRequest.data) {
      setInverters(getInvertersRequest.data);
    }
  }, [getInvertersRequest.loading, getInvertersRequest.data]);

  useEffect(() => {
    if (insertInvertersRequest.loaded) toggle();
  }, [insertInvertersRequest.loaded]);

  useEffect(() => {
    if (inverters) {
      inverters.forEach((inv) => {
        setValue(inv['ref'], inv['val']);
      });
    }
  }, [inverters]);

  return (
    <Modal
      isOpen={visible}
      toggle={toggle}
      error={insertInvertersRequest.error?.message}
      submitDisabled={insertInvertersRequest.loading}
      isLoading={insertInvertersRequest.loading}
      header="common.edit_inverters_pmax"
      submitAction={handleSubmit(onSubmit)}
      scrollable
    >
      {getInvertersRequest.loading && (
        <div className="ts:center p-3">
          <Spinner />
        </div>
      )}
      <form>
        <Row>
          {inverters.map((inverter) => (
            <Col sm={4} key={inverter.ref}>
              <FormField inverter={inverter} errors={errors} control={control} />
            </Col>
          ))}
        </Row>
      </form>
    </Modal>
  );
};

export default ModalForm;
