import React from 'react';
import { AltContent } from '@GDM/AltContent';
import { useRevenueReportingContext } from '../../utils/hooks/useRevenueReportingContext';
import ReportsTable from './ReportsTable';

export const SitesReports: React.FC<{ disablePadding?: boolean; printable?: boolean; showAll?: boolean }> = ({
  disablePadding,
  printable,
  showAll,
}) => {
  const { split } = useRevenueReportingContext();

  if (!split) return <AltContent label="common.fetching_data" />;

  return <ReportsTable disablePadding={disablePadding} printable={printable} showAll={showAll} />;
};
