import React from 'react';
import { AltContent } from '@GDM/AltContent';
import { Spinner } from '@GDM/Spinner';
import { useIndexData } from '@utils/DashboardMeter/useIndexData';
import { IndexGraph } from '../graphs/IndexGraph';
import { IndexDeltaTable, IndexTable } from '../tables';
import { useIndexTab } from '../useIndexTab';

export const CurrentTab: React.FC = () => {
  const { currentDates, selectedTypeParam } = useIndexTab();
  const req = useIndexData(currentDates.startDate, currentDates.endDate, selectedTypeParam);

  const dataPresent = !!(
    req.data &&
    req.data.indexseries &&
    req.data.all_index_data &&
    req.data.all_index_data.data?.length &&
    req.data.all_index_data.periodes?.length
  );

  return (
    <div>
      {!req.data && req.loading && (
        <div className="my-4 d-flex align-items-center justify-content-center">
          <Spinner />
        </div>
      )}

      {!req.loading && !dataPresent && !req?.data && <AltContent data-cy="no-index-data" />}

      {req.data && (
        <>
          <IndexDeltaTable indexData={req.data.all_index_data.data} periods={req.data.all_index_data.periodes} />

          <IndexGraph
            indexSeries={req.data.indexseries}
            isLoading={req.loading}
            className="my-3"
            dates={currentDates}
          />

          <IndexTable indexData={req.data.all_index_data.data} periods={req.data.all_index_data.periodes} />
        </>
      )}
    </div>
  );
};
