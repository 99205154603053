import React from 'react';
import { useUser } from '@context/User.context';
import { TextColor } from '@utils/types/colors';
import classnames from 'classnames';
import styles from './number-cell.module.scss';

type Props = {
  value: number | null;
  color?: TextColor;
  options?: Intl.NumberFormatOptions;
};

export const NumberCell: React.FC<Props> = ({ value, color, options }) => {
  const { locale } = useUser();
  const className = classnames(`text-${color}`, styles['number-cell']);

  return (
    <div className={className}>{typeof value === 'number' && new Intl.NumberFormat(locale, options).format(value)}</div>
  );
};

NumberCell.defaultProps = {
  color: 'default',
};
