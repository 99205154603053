import React from 'react';
import { useConsumeContracts } from '@context/contracts.context';
import { useDynamicOptions } from '@GDM/Filters';
import { Select } from '@GDM/forms';
import getContractTypeLabel from '@utils/contract/getLabel';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Option } from '@utils/types/common-types';
import { Contract, ContractType } from '@utils/types/contract';
import { CountryCode } from '@utils/types/countries';
import uniqBy from 'lodash/uniqBy';
import { Controller, useFormContext } from 'react-hook-form';
import { ContractsFiltersType } from './ContractFilters';

const getOptions = (contracts: Contract[]) => {
  const contractTypes: { type: ContractType; country: CountryCode | null }[] = contracts.map((contract) => ({
    type: contract.type,
    country: contract.installation?.country,
  }));

  const options: Option<Contract['type']>[] = [
    ...contractTypes
      .map((c) => ({
        label: getContractTypeLabel(c.type, c.country) || getContractTypeLabel(c.type, 'FR'),
        value: c.type,
      }))
      .sort((a, b) => sortOptionsByLabelAsc(a, b, true)),
  ];

  return uniqBy(options, 'value');
};

export const ContractTypeFilter: React.FC = () => {
  const form = useFormContext<ContractsFiltersType>();
  const { filterContracts, contracts, allContracts } = useConsumeContracts();

  const options = useDynamicOptions(getOptions, 'contract_type', contracts, allContracts);

  return (
    <Controller
      name="contract_type"
      control={form.control}
      render={({ field }) => (
        <Select
          isMulti
          name="contract-type-picker"
          options={options}
          selectedOptions={field.value}
          placeholder="common.filters.contract_type"
          classNamePrefix="contracts-filter-contract_type"
          isClearable
          onChange={(values) => {
            const internalValues = values?.map((v) => v.value) ?? [];
            filterContracts({
              ...form.getValues(),
              contract_type: internalValues,
              last_selected_filter: 'contract_type',
            });
            field.onChange(internalValues);
            form.setValue('last_selected_filter', internalValues.length ? 'contract_type' : null);
          }}
        />
      )}
    />
  );
};
