import React from 'react';
import useTranslation from '@hooks/useTranslation';
import ReactPaginate from 'react-paginate';
import styles from './table-pagination.module.scss';

export const TablePagination: React.FC<{
  pageCount: number;
  gotoPage: (page: number) => void;
}> = ({ pageCount, gotoPage }) => {
  const { t } = useTranslation();

  return (
    <ReactPaginate
      pageCount={pageCount}
      pageRangeDisplayed={2}
      marginPagesDisplayed={1}
      initialPage={0}
      onPageChange={({ selected }) => gotoPage(selected)}
      nextLabel={t('common.next')}
      previousLabel={t('common.previous')}
      containerClassName={styles.pagination}
      pageClassName={styles.item}
      activeClassName={styles['item-active']}
      breakClassName={styles.item}
      nextClassName={styles['item-control']}
      previousClassName={styles['item-control']}
      disabledClassName={styles['item-disabled']}
    />
  );
};
