import React, { useMemo, useState } from 'react';
import { useUser } from '@context/User.context';
import { Button } from '@GDM/Button';
import { Table, TableActions, TableBody, TableHead, TablePageSizeSelect, TablePagination } from '@GDM/Table';
import {
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  getCoreRowModel,
  ColumnDef,
} from '@tanstack/react-table';
import { edit_v2_owner_path } from '@utils/routes';
import MarketPlayer from '@utils/types/market_player';
import { useOwnersContext } from '../owners.context';
import { AutoNumberingCell } from './AutoNumberingCell';
import { BankInfoCell } from './BankInfoCell';
import { InvoicingEmailCell } from './InvoicingEmailCell';

export const OwnersTable: React.FC = () => {
  const { permissions } = useUser();
  const { owners } = useOwnersContext();
  const data = owners || [];

  const columns = useMemo<ColumnDef<MarketPlayer>[]>(() => {
    const columns: ColumnDef<MarketPlayer>[] = [
      { accessorKey: 'long_name', header: 'owner.long_name' },
      { accessorKey: 'short_name', header: 'owner.short_name' },
      { accessorKey: 'erp_code', header: 'owner.erp_code' },
      { accessorKey: 'company_number', header: 'owner.company_number', enableSorting: false },
      {
        accessorKey: 'invoice_auto_numbering',
        header: 'owner.invoice_auto_numbering',
        cell: AutoNumberingCell,
        enableSorting: false,
      },
      { accessorKey: 'bank', header: 'owner.bank_info', cell: BankInfoCell, enableSorting: false },
      {
        accessorKey: 'invoicing_emails',
        header: 'owner.invoicing_email',
        cell: InvoicingEmailCell,
      },
    ];

    if (permissions && permissions.includes('display:edit_owner'))
      columns.push({
        id: 'actions',
        cell: ({ row: { original } }) => {
          return <Button variant="primary-2" floating icon="Edit2" href={edit_v2_owner_path(original.id)} />;
        },
      });

    return columns;
  }, [permissions]);

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 25 });

  const table = useReactTable({
    columns,
    data,
    sortDescFirst: false,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    state: { pagination },
  });

  const pageSize = pagination.pageSize;

  return (
    <>
      <Table hasActions>
        <TableHead table={table} />
        <TableBody data-cy="owner-table-body" table={table} />
      </Table>

      <TableActions>
        <TablePageSizeSelect pageSize={pageSize} setPageSize={table.setPageSize} totalEntries={data.length} />
        {data.length > pageSize && <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />}
      </TableActions>
    </>
  );
};
