import React from 'react';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import { Controller } from 'react-hook-form';
import { IpCheckbox } from '../../fields/IpCheckbox';
import { BaseFields } from './BaseFields';
import { DefaultSubSection } from './DefaultSubSection';

export const DirectConnection = () => {
  const { control } = useInstallationForm();

  return (
    <>
      <Controller
        control={control}
        name="via_direct_connection"
        render={({ field }) => (
          <DefaultSubSection
            title="admin.installations.via_direct_connection"
            isOpen={field.value}
            onChange={field.onChange}
            name="direct_connection"
          >
            <IpCheckbox />

            <BaseFields />
          </DefaultSubSection>
        )}
      />
    </>
  );
};
