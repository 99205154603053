import React from 'react';
import { Select } from '@GDM/forms';
import type { Option } from '@utils/types/common-types';
import type { MeterInvoiceRevenue } from '@utils/types/meter-invoice';
import { Controller, useFormContext } from 'react-hook-form';
import styles from '../update-modal.module.scss';
import type { UpdateInvoiceForm } from '../update-modal.types';

export const RevenueTypePicker: React.FC<{ index: number }> = ({ index }) => {
  const { control, watch } = useFormContext<UpdateInvoiceForm>();
  const revenueType = watch(`revenues.${index}.revenue_type`);

  const safeOptions: Option<MeterInvoiceRevenue['revenue_type']>[] = [
    { value: 'aggreg_fees', label: 'invoice.revenues.types.alt.aggreg_fees' },
    { value: 'total_prod', label: 'invoice.revenues.types.alt.total_prod' },
  ];

  const options: Option<MeterInvoiceRevenue['revenue_type']>[] = [...safeOptions];

  const isSafeOption = options.some((option) => option.value === revenueType);

  if (!isSafeOption) {
    options.push({ value: revenueType, label: `invoice.revenues.types.${revenueType}` });
  }

  return (
    <Controller
      control={control}
      name={`revenues.${index}.revenue_type`}
      rules={{ required: true }}
      render={({ field, fieldState }) => (
        <div className={styles.input}>
          <Select
            options={options}
            selectedOption={field.value}
            onChange={(option) => field.onChange(option?.value)}
            hasError={!!fieldState.error}
            errorMessage={fieldState.error?.message}
            isDisabled={!isSafeOption}
            full
          />
        </div>
      )}
    />
  );
};
