import React from 'react';
import { ModalHeader as ModalHeaderLegacy, ModalHeaderProps } from 'reactstrap';
import styles from './modal.module.scss';

export const ModalHeader: React.FC<ModalHeaderProps> = ({ children, ...props }) => {
  return (
    <ModalHeaderLegacy className={styles.header} {...props}>
      {children}
    </ModalHeaderLegacy>
  );
};
