import React from 'react';
import type { DataProvider as TDataProvider } from '@api/dataProviders';
import ControlledInput from '@components/FormInputs/ControlledInput';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { useInternalFormContext } from '@GDM/forms';
import { useGrids } from '@hooks/requests/useGrids';
import { useFormDataProviders } from '@pages/Installations/Installation/hooks/useFormDataProviders';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import type { Option } from '@utils/types/common-types';
import { Controller } from 'react-hook-form';
import { DefaultSubSection } from './DefaultSubSection';

export const DataProvider = () => {
  const { watch, control } = useInstallationForm();
  const { country } = useInternalFormContext();
  const grids = useGrids(country);

  const allDataProviders = useFormDataProviders(watch);

  const options = [...allDataProviders.map(dataProviderToOption), { value: null, label: 'common.other' }];

  return (
    <Controller
      control={control}
      name="via_data_provider"
      render={({ field }) => (
        <DefaultSubSection
          title="admin.installations.via_data_provider"
          isOpen={field.value}
          onChange={field.onChange}
          name="data_provider"
        >
          <div className="d-flex gap-3">
            <ControlledSelect
              control={control}
              name="data_provider_id"
              options={options.sort(sortOptionsByLabelAsc)}
              isLoading={grids.isLoading}
              label="admin.installations.data_provider"
              size="lg"
              classNamePrefix="data-provider-select"
              isDisabled={options.length <= 2}
              isClearable
            />

            <ControlledInput
              control={control}
              name="data_provider_number"
              label="admin.installations.meter_number"
              size="lg"
              flexFull
            />
          </div>
        </DefaultSubSection>
      )}
    />
  );
};

const dataProviderToOption = (dataProvider: TDataProvider): Option<TDataProvider['id']> => ({
  value: dataProvider.id,
  label: dataProvider.name,
});
