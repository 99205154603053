import React from 'react';
import { useUser } from '@context/User.context';
import { ValueCard } from '@GDM/Card';
import { ValueWithUnit } from '@GDM/ValueWithUnit';
import { appendColon } from '@utils/string';
import styles from '../realised.module.scss';

export const M0Card: React.FC<{ totalSimulatedM0?: number | null; totalRealisedM0: number | null }> = ({
  totalSimulatedM0,
  totalRealisedM0,
}) => {
  const { locale } = useUser();

  return (
    <ValueCard
      value={totalRealisedM0}
      title="pricing.market_value_deviation"
      unit="EUR/MWh"
      valueVariant={totalRealisedM0 && totalRealisedM0 < 0 ? 'secondary' : 'primary-1'}
      className={styles.card}
    >
      <span dangerouslySetInnerHTML={{ __html: appendColon('pricing.project.vs_simulation', locale) }} />{' '}
      <ValueWithUnit
        value={totalSimulatedM0}
        unit="EUR/MWh"
        valueVariant={totalSimulatedM0 && totalSimulatedM0 < 0 ? 'secondary' : 'primary-1'}
      />
    </ValueCard>
  );
};
