import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { useBooksContext } from '@context/BooksContext';
import isCancelled from '@utils/filters/isCancelled';
import isContractType from '@utils/filters/isContractType';
import isForecastType from '@utils/filters/isForecastType';
import isRegul from '@utils/filters/isRegul';
import matchInstallationName from '@utils/filters/matchInstallationName';
import matchInvoiceSources from '@utils/filters/matchInvoiceSources';
import Book from '@utils/types/book';
import { MeterInvoice } from '@utils/types/meter-invoice';
import get from 'lodash/get';
import { InvoiceFilters, useInvoicesFilters } from './invoicesFiltersContext';

type InvoicesContext = {
  allInvoices: MeterInvoice[];
  filteredInvoices: MeterInvoice[];
  filteredInvoicesUuids: string[];
  setFilteredInvoicesUuids: Dispatch<SetStateAction<string[]>>;
  updateInvoice: (invoice: MeterInvoice) => void;
  updateInvoices: (invoices: MeterInvoice[]) => void;
};

const invoicesContext = createContext<InvoicesContext>({
  allInvoices: [],
  filteredInvoices: [],
  filteredInvoicesUuids: [],
  setFilteredInvoicesUuids: () => {},
  updateInvoice: () => {},
  updateInvoices: () => {},
});

export const useInvoicesContext = (): InvoicesContext => useContext(invoicesContext);

export const InvoicesConsumer = invoicesContext.Consumer;

/**
 * Returns a list of invoices that match the filters
 */
const getFilteredInvoices = (invoices: MeterInvoice[], books: Book[] | null, filters: InvoiceFilters) => {
  let filteredInvoices = invoices;

  if (filters.installation_name && filters.installation_name.length > 0) {
    filteredInvoices = filteredInvoices.filter((invoice) => {
      return filters.installation_name.includes(invoice.installation_name);
    });
  }

  if (filters.installation_country) {
    filteredInvoices = filteredInvoices.filter(
      (invoice) => filters.installation_country === invoice.installation_country,
    );
  }

  if (filters.buyer_name && filters.buyer_name.length > 0) {
    filteredInvoices = filteredInvoices.filter((invoice) => {
      return filters.buyer_name.includes(invoice.contract_buyer_short_name);
    });
  }

  if (filters.card_i && filters.card_i.length > 0) {
    filteredInvoices = filteredInvoices.filter((invoice) => filters.card_i.includes(invoice.contract_card_i));
  }

  if (filters.contract_nb && filters.contract_nb.length > 0) {
    filteredInvoices = filteredInvoices.filter((invoice) => filters.contract_nb.includes(invoice.contract_nb));
  }

  if (filters.invoice_status && filters.invoice_status.length > 0) {
    filteredInvoices = filteredInvoices.filter((invoice) => filters.invoice_status.includes(invoice.status));
  }

  const installationNames =
    books?.filter((b) => filters.book_id.includes(b.uuid) || !filters.book_id).flatMap((b) => b.installation_names) ??
    [];

  filteredInvoices = filteredInvoices.filter((meterInvoice: MeterInvoice) => {
    const booksMatch = filters.book_id.length === 0 || matchInstallationName(installationNames, meterInvoice);

    return (
      isContractType(meterInvoice.contract_type, filters) &&
      isForecastType(meterInvoice, filters) &&
      isRegul(meterInvoice, filters) &&
      isCancelled(meterInvoice, filters) &&
      matchInvoiceSources(meterInvoice, filters) &&
      booksMatch
    );
  });

  return filteredInvoices;
};

export const InvoicesProvider: React.FC<React.PropsWithChildren<{ invoices: MeterInvoice[] }>> = ({
  invoices,
  children,
}) => {
  const { books } = useBooksContext();
  const form = useInvoicesFilters();
  const { watch, setValue } = form;
  const filters = watch();
  const [allInvoices, setAllInvoices] = useState<MeterInvoice[]>(invoices);
  const [filteredInvoicesUuids, setFilteredInvoicesUuids] = useState<string[]>(
    getFilteredInvoices(invoices, books, filters).map((i) => i.uuid),
  );

  /**
   * This is to make sure invoices and filteredInvoices are always in sync and to avoid having to MeterInvoice lists
   */
  const filteredInvoices = useMemo(
    () => allInvoices.filter((i) => filteredInvoicesUuids.includes(i.uuid)),
    [allInvoices, filteredInvoicesUuids],
  );

  /*
   * Update the invoices state with a invoice, usually called after an API call
   */
  const updateInvoice = useCallback((invoice: MeterInvoice) => {
    setAllInvoices((prevInvoices) => {
      const invoices = prevInvoices.map((i) => {
        if (i.uuid === invoice.uuid) {
          return invoice;
        }

        return i;
      });

      return invoices;
    });
  }, []);

  /*
   * Update the invoices state with multiple invoice, usually called after an API call
   */
  const updateInvoices = useCallback((invoices?: MeterInvoice[]) => {
    if (!invoices) return;

    setAllInvoices((prevInvoices) => {
      const updatedInvoices = prevInvoices.map((i) => {
        const invoice = invoices?.find((inv) => inv.uuid === i.uuid);

        if (invoice) {
          return invoice;
        }

        return i;
      });

      return updatedInvoices;
    });
  }, []);

  useEffect(() => {
    const subscription = watch((values, { name }) => {
      const newFilteredInvoices = getFilteredInvoices(allInvoices, books, values as InvoiceFilters);

      setFilteredInvoicesUuids(newFilteredInvoices.map((i) => i.uuid));

      if (name !== 'last_selected_filter') {
        const keyName = name?.split('.')[0] as InvoiceFilters['last_selected_filter'];

        // Using get to get deeply nested values and because values[name] throws a typescript error
        const value = name ? get(values, name) : null;
        const isValueValid = (Array.isArray(value) && value.length > 0) || value;

        setValue('last_selected_filter', keyName && isValueValid ? keyName : null);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, allInvoices, books, setValue]);

  const memoizedValue = useMemo(
    () => ({
      allInvoices,
      filteredInvoices,
      filteredInvoicesUuids,
      setFilteredInvoicesUuids,
      updateInvoice,
      updateInvoices,
    }),
    [allInvoices, filteredInvoices, filteredInvoicesUuids, setFilteredInvoicesUuids, updateInvoice, updateInvoices],
  );

  return <invoicesContext.Provider value={memoizedValue}>{children}</invoicesContext.Provider>;
};

export default invoicesContext;
