import React from 'react';
import { DefinitionList } from '@GDM/DefinitionList';
import classNames from 'classnames';
import { BaseloadMarketPriceDefinition } from './BaseloadMarketPriceDefinition';
import styles from './full-merchant-definition.module.scss';
import { LocalCapturePriceDefinition } from './LocalCapturePriceDefinition';
import { LocalQualityFactorDefinition } from './LocalQualityFactorDefinition';
import { NationalCapturePriceDefinition } from './NationalCapturePriceDefinition';
import { NationalQualityFactorDefinition } from './NationalQualityFactorDefinition';

export const FullMerchantDefinition: React.FC = () => {
  return (
    <DefinitionList className={classNames('mt-3', styles['definition-list'])}>
      <BaseloadMarketPriceDefinition />
      <NationalCapturePriceDefinition />
      <NationalQualityFactorDefinition />
      <LocalCapturePriceDefinition />
      <LocalQualityFactorDefinition />
    </DefinitionList>
  );
};
