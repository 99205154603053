import React from 'react';
import { CellContext } from '@tanstack/react-table';
import { Simulation } from '@utils/types/simulation';
import styles from '../../simulations.module.scss';

export const SimulationNameCell: React.FC<CellContext<Simulation, unknown>> = ({ row: { original } }) => {
  return (
    <a
      href={`/v2/ppa_projects/${encodeURIComponent(original.installation_name)}?ppa_simulation_id=${original.id}`}
      className={styles['simulation-name-cell']}
    >
      {original.name}
    </a>
  );
};
