import React from 'react';
import { usePermittedStatus } from '@hooks/requests/invoices/usePermittedStatus';
import useTranslation from '@hooks/useTranslation';
import useInvoiceNumbering from 'pages/sales-management/invoicing/utils/useInvoiceNumbering';
import styles from '../actions.module.scss';
import { useNominationStatus } from './useNominationStatus';

export const NominateCegedimButton: React.FC<{ toggleNominateModal?: () => void }> = ({ toggleNominateModal }) => {
  const { t } = useTranslation();

  const { handleClick } = useInvoiceNumbering();
  const { disabled } = usePermittedStatus('requested_submission_external_service');
  const { allSelectedInvoicesCanBeNominatedOnCegedim } = useNominationStatus();

  return (
    <button
      onClick={toggleNominateModal ? handleClick(toggleNominateModal, 'nominate_to_cegedim') : undefined}
      disabled={disabled || !allSelectedInvoicesCanBeNominatedOnCegedim}
      className={styles['menu-element']}
    >
      {t('sales_management.nominate_to_cegedim')}
    </button>
  );
};
