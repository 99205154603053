import React from 'react';
import { Button } from '@GDM/Button';
import { useSubscriptionForm } from './useSubscriptionForm';

export const Header: React.FC = () => {
  const { formState, loading } = useSubscriptionForm();

  return (
    <>
      <Button href="/internal" text="common.back" variant="link" size="sm" className="mr-3" />
      <Button
        onClick={() => {}}
        text="common.save"
        variant="primary-2"
        size="sm"
        disabled={!formState.isValid || loading}
        type="submit"
        isLoading={loading}
      />
    </>
  );
};
