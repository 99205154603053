import React, { useContext } from 'react';
import { unavailabilitiesContext } from '@context/UnavailabilitiesContext';
import { ProgressCard, ValueCard } from '@GDM/Card';
import { Col, Row } from '@GDM/layout';
import safeSumReducer from '@utils/safeSumReducer';
import classNames from 'classnames';
import { useUnavailabilityFiltersContext } from './Filters/context';
import styles from './unavailabilities.module.scss';

type Props = {
  availabilities: [number, number][];
  total_p_max: number;
};

export const AvailabilityCards: React.FC<Props> = ({ availabilities, total_p_max }) => {
  const { chartVisible } = useUnavailabilityFiltersContext();
  const { loading } = useContext(unavailabilitiesContext);

  const avg_availabilities = availabilities
    ? availabilities.map((availability) => availability[1]).reduce(safeSumReducer, 0) / availabilities.length
    : null;
  const availability_ratio = total_p_max && avg_availabilities ? 100 * (avg_availabilities / total_p_max) : null;

  return (
    <Col md={chartVisible ? 4 : 12}>
      <Row>
        <Col md={chartVisible ? 12 : 4} className={classNames({ 'mb-3': chartVisible }, styles['card-col'])}>
          <ValueCard
            title="monitoring.unavailabilities.average_availability"
            value={avg_availabilities ? avg_availabilities * 1000 : null}
            unit="kW"
            tooltip="monitoring.unavailabilities.average_availability_tooltip"
          />
        </Col>
        <Col md={chartVisible ? 12 : 4} className={styles['card-col']}>
          <ProgressCard
            value={availability_ratio ?? 0}
            isLoading={loading}
            title="monitoring.unavailabilities.availability_ratio"
            dangerThreshold={50}
          />
        </Col>
      </Row>
    </Col>
  );
};
