import React from 'react';
import { MeterNameCell } from '@components/ui/table/cell/MeterNameCell';
import { CellContext } from '@tanstack/react-table';
import { Unavailability } from '@utils/types/unavailability';

export const InstallationCell: React.FC<React.PropsWithChildren<CellContext<Unavailability, unknown>>> = ({
  row: { original },
}) => {
  const installation = original.installation;

  return (
    <MeterNameCell
      hasMeter={installation.has_meter}
      installation_name={installation.name}
      installation_energy={installation.energy}
    />
  );
};
