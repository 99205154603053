import React from 'react';
import { Button } from '@GDM/Button';

export const FormPageActions: React.FC<{ backButtonHref?: string; isLoading: boolean }> = ({
  backButtonHref,
  isLoading,
}) => {
  return (
    <div className="d-flex">
      {backButtonHref && (
        <Button
          text="common.back"
          variant="link"
          size="sm"
          href={backButtonHref}
          className="mr-3"
          data-cy="back-button"
        />
      )}
      <Button
        type="submit"
        text="common.save"
        variant="primary-2"
        size="sm"
        disabled={isLoading}
        isLoading={isLoading}
        data-cy="save-button"
      />
    </div>
  );
};
