import React, { useState, useCallback, useMemo } from 'react';
import { InstallationProvider } from '@components/Installations/InstallationProvider';
import { Chart } from '@GDM/Chart';
import { ExtendableBox } from '@GDM/ExtendableBox';
import { Checkbox } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import Page from '@pages/Page';
import { chartColors } from '@utils/constants/chartColors';
import { User } from '@utils/types/user';
import classNames from 'classnames';
import styles from './comparison.module.scss';
import { ComparisonContext, ComparisonData } from './comparison.types';
import { comparisonContext } from './context';
import { ComparisonForm } from './Form/ComparisonForm';
import { ComparisonTable } from './Table/ComparisonTable';

const Comparison: React.FC<{ user: User }> = ({ user }) => {
  const { t } = useTranslation(user.locale);
  const [comparisonData, setComparisonData] = useState<ComparisonData | null | undefined>();
  const [series, setSeries] = useState<Highcharts.SeriesOptionsType[]>([]);

  const options: Highcharts.Options = { yAxis: { title: { text: t('monitoring.comparison.yaxis') } } };

  const setComparisonDataAndSeries = useCallback((data?: ComparisonData | null) => {
    if (!data) return;

    const colors = Object.values(chartColors);

    setComparisonData(data);
    setSeries(
      data?.normalized_data?.map(({ name, data }, index) => {
        const colorIndex = index > colors.length - 1 ? index % colors.length : index;

        return { name, data, type: 'line', color: colors[colorIndex], visible: true };
      }),
    );
  }, []);

  const handleCheckboxChange = useCallback(
    (series: Highcharts.SeriesOptionsType) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setSeries((previous) =>
        previous.map((s) => ({ ...s, visible: s.name === series.name ? e.target.checked : s.visible })),
      );
    },
    [],
  );

  const comparisonContextValue: ComparisonContext = useMemo(
    () => ({ comparisonData, setComparisonData: setComparisonDataAndSeries }),
    [comparisonData, setComparisonDataAndSeries],
  );

  return (
    <InstallationProvider type="operational">
      <comparisonContext.Provider value={comparisonContextValue}>
        <Page title="monitoring.comparison.title" user={user} header={<ComparisonForm />}>
          {!!comparisonData?.normalized_data?.length && (
            <div className={classNames('p-2 mb-5', styles['expandable-box-container'])}>
              <ExtendableBox className={styles['expandable-box']}>
                <div className={styles['checkboxes-container']}>
                  {series.map((s) => (
                    <Checkbox
                      label={s.name}
                      key={s.name}
                      color={s.color?.toString()}
                      defaultChecked
                      onChange={handleCheckboxChange(s)}
                    />
                  ))}
                </div>
              </ExtendableBox>
              <Chart series={series} options={options} data-cy="chart" hideLegend />
            </div>
          )}
          {!!comparisonData?.dates_list && (
            <ComparisonTable dateList={comparisonData?.dates_list} dataPoints={comparisonData?.normalized_data} />
          )}
        </Page>
      </comparisonContext.Provider>
    </InstallationProvider>
  );
};

export default Comparison;
