import React, { useState } from 'react';
import { FilterContainer, Filters } from '@GDM/Filters';
import { RadioButtons } from '@GDM/forms';
import { energyOptions } from '@utils/constants/energyOptions';
import { EnergyType } from '@utils/types/common-types';
import { Simulation } from '@utils/types/simulation';
import { BookFilter } from './BookFilter';

type SimulationBookFilters = {
  installationsName: string[];
  type: EnergyType | 'all';
};

const BooksFilters: React.FC<{
  setSimulations: React.Dispatch<React.SetStateAction<Simulation[]>>;
  allSimulations: Simulation[];
}> = ({ allSimulations, setSimulations }) => {
  const [filters, setFilters] = useState<SimulationBookFilters>({ installationsName: [], type: 'all' });

  function setFilter(key: keyof SimulationBookFilters, value: string | string[] | (EnergyType | 'all')) {
    setFilters((prev) => {
      const newFilters = { ...prev, [key]: value };

      handleFiltering(newFilters);

      return newFilters;
    });
  }

  const handleFiltering = (filters: SimulationBookFilters) => {
    let filteredData = [...allSimulations];

    filteredData = filteredData.filter((d) => filters.type === 'all' || filters.type === d.energy);

    if (filters.installationsName.length > 0) {
      filteredData = filteredData.filter((d) => filters.installationsName.includes(d.installation_name));
    }

    setSimulations(filteredData);
  };

  return (
    <Filters>
      <FilterContainer>
        <BookFilter onChange={(names) => setFilter('installationsName', names)} />
      </FilterContainer>

      <FilterContainer size="fit">
        <RadioButtons
          options={energyOptions}
          onChange={(type) => setFilter('type', type)}
          selected={filters.type === 'all' ? 'all' : filters.type}
        />
      </FilterContainer>
    </Filters>
  );
};

export default BooksFilters;
