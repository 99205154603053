import React from 'react';
import useTranslation from '@hooks/useTranslation';
import { CellContext } from '@tanstack/react-table';
import { Contract } from '@utils/types/contract';
import styles from './seller-cell.module.scss';

export const SellerCell: React.FC<CellContext<Contract, unknown>> = ({ row: { original } }) => {
  const { t } = useTranslation();
  const name = original?.seller?.short_name || original?.seller?.long_name;

  return (
    <div className={styles['seller-cell']}>
      {name && <>{name}</>}
      {!name && <span className={styles['no-data']}>{t('contracts.table.no_seller')}</span>}
    </div>
  );
};
