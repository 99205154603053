import React from 'react';
import styles from './contract-number-cell.module.scss';

export const ContractNumberCell: React.FC<{ contractNumber: string }> = ({ contractNumber }) => {
  return (
    <div className={styles['contract-number-cell']}>
      <span>N°</span> : {contractNumber}
    </div>
  );
};
