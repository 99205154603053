import React, { useRef } from 'react';
import { Chart, exportCSVChartAction } from '@GDM/Chart';
import { ChartRefContent } from '@GDM/Chart/chart.types';
import useTranslation from '@hooks/useTranslation';
import { formatSystemDate } from '@utils/formatters';
import Highcharts from 'highcharts';
import { IndexData, IndexTabContextType } from '../index-tab.definitions';

export const IndexGraph: React.FC<{
  indexSeries: IndexData;
  isLoading: boolean;
  dates: IndexTabContextType['currentDates'];
  className?: string;
}> = ({ className, indexSeries, isLoading, dates }) => {
  const data = indexSeries.map(([timestamp, value]) => [timestamp * 1000, value]);
  const { t } = useTranslation();
  const ref = useRef<ChartRefContent>(null);

  const series: Highcharts.Options['series'] = [
    { name: t('common.index'), data, type: 'line', tooltip: { valueSuffix: 'kWh' } },
  ];

  const options: Highcharts.Options = {
    yAxis: { title: { text: 'kWh' } },
    exporting: { filename: `index_${formatSystemDate(dates.startDate)}_${formatSystemDate(dates.endDate)}` },
  };

  return (
    <Chart
      ref={ref}
      options={options}
      series={series}
      className={className}
      isLoading={isLoading}
      hideLegend
      handleDownloadCsv={exportCSVChartAction(ref)}
    />
  );
};
