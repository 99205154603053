import React from 'react';
import { Definition } from '@GDM/DefinitionList';
import { useNumbers } from '@hooks/useNumbers';
import { useInstallationInfo } from '../useInstallationInfo';
import { CoordinatesModal } from './CoordinatesModal';

export const CoordinatesDefinition: React.FC<{ name: string }> = ({ name }) => {
  const { lat, lng } = useInstallationInfo();
  const { format } = useNumbers(1);

  return (
    <Definition
      label="common.coordinates"
      value={lat && lng ? `${format(+lat)}°, ${format(+lng)}°` : '-'}
      tooltip="common.lat_lng"
      data-cy="coordinates-definition"
      modal={{
        node: <CoordinatesModal name={name} />,
        title: 'common.coordinates',
        permission: 'display:edit_monitoring',
      }}
    />
  );
};
