import React, { useCallback, useEffect } from 'react';
import { Spinner } from '@GDM/Spinner';
import { useOwners } from '@hooks/requests/useOwners';
import MarketPlayer from '@utils/types/market_player';
import { OwnersContextType } from './owner.types';
import { ownersContext } from './owners.context';

export const OwnerProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const ownersRequest = useOwners();
  const [owners, setOwners] = React.useState<MarketPlayer[]>([]);
  const [allOwners, setAllOwners] = React.useState<MarketPlayer[]>([]);

  useEffect(() => {
    if (ownersRequest.data) {
      setOwners(ownersRequest.data);
      setAllOwners(ownersRequest.data);
    }
  }, [ownersRequest.data]);

  const updateOwner = useCallback((owner: MarketPlayer) => {
    setOwners((prev) => {
      const newOwners = prev.map((o) => (o.id === owner.id ? owner : o));

      return newOwners;
    });
  }, []);

  const contextValue = React.useMemo<OwnersContextType>(
    () => ({ owners, updateOwner, setOwners, allOwners }),
    [owners, updateOwner, setOwners, allOwners],
  );

  if (ownersRequest.loading) {
    return (
      <div className="ta:center my-3">
        <Spinner />
      </div>
    );
  }

  return <ownersContext.Provider value={contextValue}>{children}</ownersContext.Provider>;
};
