import React from 'react';
import { Button } from '@GDM/Button';
import { MeterInvoice } from '@utils/types/meter-invoice';

export const OpenHistoryModalButton: React.FC<{
  setHistoryModalIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setClickedInvoice?: (invoice: MeterInvoice) => void;
  invoice: MeterInvoice;
  className?: string;
}> = ({ setHistoryModalIsOpen, setClickedInvoice, invoice, className }) => {
  if (!setHistoryModalIsOpen) return null;

  return (
    <Button
      floating
      icon="Eye"
      onClick={() => {
        setClickedInvoice?.(invoice);
        setHistoryModalIsOpen(true);
      }}
      className={className}
    />
  );
};
