import React, { useMemo } from 'react';
import { MeterNameCell } from '@components/ui/table/cell/MeterNameCell';
import { HeadCell, NumberCell, TableHead, TableBody, Table } from '@GDM/Table';
import { Text } from '@GDM/Text';
import { ProjectInstallation } from '@pages/Simulations/Simulation/utils/simulation.types';
import { useReactTable, getCoreRowModel, getSortedRowModel, ColumnDef } from '@tanstack/react-table';

const ProjectBookInstallations: React.FC<{ installations: ProjectInstallation[] }> = ({ installations }) => {
  const columns = useMemo<ColumnDef<ProjectInstallation>[]>(
    () => [
      {
        header: () => <HeadCell label="common.installation" />,
        accessorKey: 'name',
        cell: ({ row: { original } }) => (
          <MeterNameCell
            hasMeter={original.has_meter}
            installation_name={original.name}
            installation_energy={original.energy}
            hasLocation={!!original.latitude && !!original.longitude}
          />
        ),
      },
      {
        id: 'energy',
        header: () => <HeadCell label="common.total_power" tooltip="kW" />,
        accessorFn: (row) => row.last_simulation?.maximum_power,
        cell: ({ getValue }) => <NumberCell value={getValue<number>()} />,
      },
      {
        id: 'turbine',
        header: () => <HeadCell label="common.turbine" />,
        accessorFn: (row) => row.last_simulation?.turbine?.name,
      },
      {
        id: 'turbine_power',
        header: () => <HeadCell label="pricing.project.turbine_power" tooltip="kW" />,
        accessorFn: (row) => row.last_simulation?.turbine?.maximum_power,
        cell: ({ getValue }) => <NumberCell value={getValue<number>()} />,
      },
      {
        id: 'tracker',
        header: () => <HeadCell label="pricing.project.tracker" />,
        accessorFn: (row) => row.last_simulation?.tracker,
        cell: ({ getValue }) => <Text text={getValue<boolean>() ? 'common.yes' : 'common.no'} className="fw:400" />,
      },
      {
        id: 'inclination',
        header: () => <HeadCell label="pricing.project.inclination" tooltip="°" />,
        accessorFn: (row) => row.last_simulation?.inclination,
        cell: ({ getValue }) => <NumberCell value={getValue<number>()} />,
        enableSorting: false,
      },
      {
        id: 'orientation',
        header: () => <HeadCell label="pricing.project.orientation" tooltip="°" />,
        accessorFn: (row) => row.last_simulation?.orientation,
        cell: ({ getValue }) => <NumberCell value={getValue<number>()} />,
        enableSorting: false,
      },
    ],
    [],
  );

  const table = useReactTable({
    columns,
    data: installations,
    sortDescFirst: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Table>
      <TableHead table={table} />
      <TableBody table={table} />
    </Table>
  );
};

export default ProjectBookInstallations;
