import React from 'react';
import { Input } from '@GDM/forms';
import { Controller, useFormContext } from 'react-hook-form';
import { useFieldRules } from '../../utils/getFieldRules';
import { SimulationForm } from '../../utils/simulations.types';

export const LongitudeInput: React.FC<{ disabled: boolean }> = ({ disabled }) => {
  const form = useFormContext<SimulationForm>();
  const { fieldRules } = useFieldRules();
  const rules = fieldRules('installation_attributes.longitude');

  return (
    <Controller
      control={form.control}
      name="installation_attributes.longitude"
      rules={rules}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          hasError={!!fieldState.error}
          errorMessage={fieldState.error?.message}
          type="number"
          step="0.01"
          full
          disabled={disabled}
          size="lg"
          label="common.longitude"
          value={field.value ?? ''}
          data-cy="installation-longitude"
        />
      )}
    />
  );
};
