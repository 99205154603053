import React, { FC } from 'react';
import { Restricted } from '@components/Restricted';
import { DropDownButton } from '@GDM/Button';
import { Checkbox } from '@GDM/forms';
import { EventType } from '@utils/types/event';
import classNames from 'classnames';
import { FiltersContext } from './filters.context';
import styles from './filters.module.scss';

type AlertsFilterProps = {
  initialEvents: FiltersContext['events'];
  onEventFilterChange: (checked: boolean, eventType: EventType) => void;
};

export const AlertsFilter: FC<AlertsFilterProps> = ({ initialEvents, onEventFilterChange }) => {
  return (
    <DropDownButton
      label="common.alerts"
      icon="Filter"
      size="xs"
      dropDownMenuClassName="p-3"
      data-cy="DropDown-portfolio-type-filter"
      position="right"
    >
      <div className={classNames(styles['events-filters-dropdown'], 'd-flex', 'flex-column')}>
        <Restricted permissions={['display:view_monitoring']}>
          <Checkbox
            checked={!!initialEvents?.includes('HighUnderPerformance')}
            onChange={(e) => onEventFilterChange(e.target.checked, 'HighUnderPerformance')}
            label="monitoring.alerts.modal.high_under_performance"
            name="checkbox-high_under_performance"
          />
          <Checkbox
            checked={!!initialEvents?.includes('ProductionStop')}
            onChange={(e) => onEventFilterChange(e.target.checked, 'ProductionStop')}
            label="monitoring.alerts.modal.production_stop"
            name="checkbox-production_stop"
          />
          <Checkbox
            checked={!!initialEvents?.includes('OutOfRangeReactive')}
            onChange={(e) => onEventFilterChange(e.target.checked, 'OutOfRangeReactive')}
            label="monitoring.alerts.modal.out_of_range_reactive"
            name="checkbox-out_of_range_reactive"
          />
          <Checkbox
            checked={!!initialEvents?.includes('CommunicationFailure')}
            onChange={(e) => onEventFilterChange(e.target.checked, 'CommunicationFailure')}
            label="monitoring.alerts.modal.communication_failure"
            name="checkbox-communication_failure"
          />
          <Checkbox
            checked={!!initialEvents?.includes('UnderPerformance')}
            onChange={(e) => onEventFilterChange(e.target.checked, 'UnderPerformance')}
            label="monitoring.alerts.modal.under_performance"
            name="checkbox-under_performance"
          />
        </Restricted>

        <Restricted permissions={['display:view_salesfocus']}>
          <Checkbox
            checked={!!initialEvents?.includes('AnnualProductionExceedsCap')}
            onChange={(e) => onEventFilterChange(e.target.checked, 'AnnualProductionExceedsCap')}
            label="monitoring.alerts.modal.prod_annual_exceeds_cap"
            name="checkbox-prod_annual_exceeds_cap"
          />
          <Checkbox
            checked={!!initialEvents?.includes('AnnualProductionExceedsDafr')}
            onChange={(e) => onEventFilterChange(e.target.checked, 'AnnualProductionExceedsDafr')}
            label="monitoring.alerts.modal.prod_annual_exceeds_dafr"
            name="checkbox-prod_annual_exceeds_dafr"
          />
          <Checkbox
            checked={!!initialEvents?.includes('ProductionOnNegativePrice')}
            onChange={(e) => onEventFilterChange(e.target.checked, 'ProductionOnNegativePrice')}
            label="monitoring.alerts.modal.prod_on_negative_price"
            name="checkbox-prod_on_negative_price"
          />
          <Checkbox
            checked={!!initialEvents?.includes('InvoiceMissingData')}
            onChange={(e) => onEventFilterChange(e.target.checked, 'InvoiceMissingData')}
            label="monitoring.alerts.modal.invoice_missing_data"
            name="checkbox-invoice_missing_data"
          />
          <Checkbox
            checked={!!initialEvents?.includes('HighDiffPublishedProduction')}
            onChange={(e) => onEventFilterChange(e.target.checked, 'HighDiffPublishedProduction')}
            label="monitoring.alerts.modal.high_diff_published_production"
            name="checkbox-high_diff_published_production"
          />
        </Restricted>
      </div>
    </DropDownButton>
  );
};
