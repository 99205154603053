import { ContractForm } from '@utils/types/contract';
import { ContractType } from '@utils/types/contract/unions';
import { UseFormReturn } from 'react-hook-form';
import { AdditionalInfo } from '../../Form/sections/AdditionalInfo/AdditionalInfo';
import { Cap } from '../../Form/sections/Cap/Cap';
import { ContractParams } from '../../Form/sections/ContractParams/ContractParams';
import { GeneralInfo } from '../../Form/sections/GeneralInfo/GeneralInfo';
import { HedgeBlocks } from '../../Form/sections/HedgeBlocks/HedgeBlocks';
import { InvoiceSettings } from '../../Form/sections/InvoiceSettings/InvoiceSettings';
import { PaymentDelay } from '../../Form/sections/PaymentDelay/PaymentDelay';
import { Pricing } from '../../Form/sections/Pricing/Pricing';
import { Remit } from '../../Form/sections/Remit/Remit';
import { SubPeriods } from '../../Form/sections/SubPeriods/SubPeriods';
import { Taxes } from '../../Form/sections/Taxes/Taxes';
import { ContractFormGlobals, ContractFormSection } from './types';

export const SECTIONS_NAMES = [
  'general-information',
  'payment-delay',
  'additional-info',
  'contract-params',
  'pricing',
  'cap',
  'taxes',
  'sub-periods',
  'hedge-blocks',
  'invoicing-settings',
  'remit',
];

export const SECTIONS_RECORD: Record<
  typeof SECTIONS_NAMES[number],
  Pick<ContractFormSection, 'title' | 'Component' | 'permissions' | 'displayPredicate'>
> = {
  'general-information': {
    title: 'contracts.steps.general_information',
    Component: GeneralInfo,
  },
  'payment-delay': {
    title: 'payment_delay.section_name',
    Component: PaymentDelay,
  },
  'additional-info': {
    title: 'contracts.steps.additional_info',
    permissions: ['display:non_engie'],
    Component: AdditionalInfo,
  },
  'contract-params': {
    title: 'contracts.steps.contract_params',
    Component: ContractParams,
  },
  pricing: {
    title: 'contracts.steps.pricing',
    Component: Pricing,
  },
  cap: {
    title: 'contracts.steps.cap',
    Component: Cap,
  },
  taxes: {
    title: 'common.taxes',
    Component: Taxes,
  },
  'sub-periods': {
    title: 'contracts.steps.sub_periods',
    Component: SubPeriods,
  },
  'hedge-blocks': {
    title: 'contracts.steps.hedge_blocks',
    Component: HedgeBlocks,
  },
  'invoicing-settings': {
    title: 'contracts.steps.invoice_settings',
    Component: InvoiceSettings,
    // Only display invoicing settings for buyers that have SY by Cegedim.
    displayPredicate: (form: UseFormReturn<ContractForm>, globals: ContractFormGlobals) => {
      const buyersThatHaveSyByCegedim = ['EDF OA'];
      const [calcBase, contractType] = form.watch(['calc_base', 'type']);

      if (!globals.buyer?.short_name) return false;

      return (
        buyersThatHaveSyByCegedim.includes(globals.buyer.short_name) &&
        (Boolean(calcBase) || contractType === 'ContractCrEdf')
      );
    },
  },
  remit: {
    title: 'common.remit',
    Component: Remit,
    // Only display REMIT section for contracts of installations whose nominal power is higher than 10MW.
    displayPredicate: (_, globals: ContractFormGlobals) => {
      if (!globals.installation) return false;

      return globals.installation.p_max > 10000;
    },
  },
};

/**
 * Sections per contract type
 *
 * This object defines the sections that are displayed for each contract type
 */
export const SECTIONS_PER_CONTRACT_TYPES: Record<ContractType, (keyof typeof SECTIONS_RECORD)[]> = {
  ContractSwapPl: ['general-information', 'sub-periods', 'payment-delay', 'additional-info'],
  ContractCapa: ['general-information', 'pricing', 'sub-periods', 'payment-delay', 'additional-info'],
  ContractCapaProxy: ['general-information', 'sub-periods', 'payment-delay', 'additional-info'],
  ContractCppa: ['general-information', 'taxes', 'sub-periods', 'hedge-blocks', 'payment-delay', 'additional-info'],
  ContractCr: ['general-information', 'contract-params', 'sub-periods', 'payment-delay', 'additional-info'],
  ContractCrEdf: [
    'general-information',
    'contract-params',
    'pricing',
    'cap',
    'invoicing-settings',
    'payment-delay',
    'additional-info',
  ],
  ContractGc: ['general-information', 'sub-periods', 'payment-delay', 'additional-info'],
  ContractGo: ['general-information', 'sub-periods', 'payment-delay', 'additional-info'],
  ContractOa: [
    'general-information',
    'contract-params',
    'pricing',
    'cap',
    'invoicing-settings',
    'payment-delay',
    'additional-info',
  ],
  ContractSoa: ['general-information', 'sub-periods', 'hedge-blocks', 'payment-delay', 'additional-info'],
  ContractSwap: ['general-information', 'sub-periods', 'payment-delay', 'additional-info'],
  'ContractGo-LIGHT': ['general-information', 'sub-periods'],
  'ContractSoa-LIGHT': ['general-information', 'sub-periods'],
};
