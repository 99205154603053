import React from 'react';
import { AbsoluteValueWithPercentageCell } from '@GDM/Table/Cells/AbsoluteValueWithPercentageCell';
import { Row } from '@tanstack/react-table';
import { MeterInvoice } from '@utils/types/meter-invoice';

// ! page doesn't exist in the Cell type, we cannot type this component with Cell<MeterInvoice>
export const DeltaProdVsDevice: React.FC<{ value?: number; row: Row<MeterInvoice>; getValue?: () => unknown }> = ({
  value,
  getValue,
  row: { original },
}) => {
  value = value || (getValue?.() as number) || 0;
  const billableProd = original.volume_production;
  const percentage = value && billableProd ? (value / billableProd) * 100 : null;

  return (
    <AbsoluteValueWithPercentageCell
      value={value}
      percentage={percentage}
      colorNumber
      percentageTooltip="monitoring.invoicing.billable_percentage_tooltip"
    />
  );
};
