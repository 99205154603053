import React from 'react';
import { getInstallationsByBookId } from '@api/installations';
import { Col, Row } from '@GDM/layout';
import { Map } from '@GDM/Map';
import { Spinner } from '@GDM/Spinner';
import { useQuery } from '@tanstack/react-query';
import { Book } from '@utils/types/book';
import { BookMap } from 'pages/ui/shared/maps';
import styles from './book-characteristics.module.scss';
import { BookInstallations } from './BookInstallations';
import { EnergyTypeSummaryTable } from './EnergyTypeSummaryTable';

export const BookCharacteristics: React.FC<{ book: Book }> = ({ book }) => {
  const { data: installations, isPending: loading } = useQuery({
    queryKey: ['installations', book.uuid],
    queryFn: () => getInstallationsByBookId(book.uuid),
  });

  const hasNoCoordinates = installations?.every((i) => !i.latitude && !i.longitude);

  if (loading)
    return (
      <div className="ta:center">
        <Spinner />
      </div>
    );

  return (
    <>
      <Row>
        {!!installations && !hasNoCoordinates && (
          <Col md={6} className={styles['map-container']}>
            <Map center={[47.0273, 2.2137]}>
              <BookMap installations={installations} />
            </Map>
          </Col>
        )}
        <Col md={6} className={styles['installation-list']}>
          {!!installations && <BookInstallations installations={installations} />}
        </Col>
      </Row>
      {installations && (
        <Row className="mt-4">
          <Col md={6}>
            <EnergyTypeSummaryTable installations={installations} />
          </Col>
        </Row>
      )}
    </>
  );
};
