import React, { useCallback, useMemo, useState } from 'react';
import { MandateFormType } from '@context/AdMeterForm.context';
import { useUser } from '@context/User.context';
import { Button } from '@GDM/Button';
import { Modal } from '@GDM/Modal';
import { Table, TableBody, TableHead } from '@GDM/Table';
import { getSortedRowModel, useReactTable, getCoreRowModel, ColumnDef } from '@tanstack/react-table';
import { Mandate, MandateStatus } from '@utils/types/mandate';
import { useInstallationsPageContext } from '../../context/installations-page.context';
import FormMandates from '../../form/FormMandates';
import RenderStatus from '../../form/text/RenderStatus';
import styles from './mandates.table.module.scss';

const StatusCell: React.FC<{ value: MandateStatus }> = ({ value }) => <RenderStatus status={value} />;

const EMPTY_MANDATE: MandateFormType = {
  signatoryName: '',
  signatoryEmail: '',
  sirets: '',
  mandateId: 'new_mandate',
  mandateFile: null,
  status: '',
  signType: 'online',
  mandataire: '',
};

export const MandatesTab: React.FC = () => {
  const { mandateModalVisible, toggleMandateModal, mandates, filteredMandates, loading } =
    useInstallationsPageContext();
  const [selectedMandate, setSelectedMandate] = useState<MandateFormType>(EMPTY_MANDATE);

  const toggle = useCallback(() => {
    toggleMandateModal();

    if (mandateModalVisible) {
      setSelectedMandate(EMPTY_MANDATE);
    }
  }, [mandateModalVisible, toggleMandateModal, setSelectedMandate]);

  const handleClick = useCallback(
    (id: string) => {
      const mandate = mandates.find((m) => m.uuid === id);

      if (mandate) {
        setSelectedMandate({
          signatoryName: mandate?.signatory_name || '',
          signatoryEmail: mandate?.signatory_email || '',
          sirets: mandate?.sirets.join(',') || '',
          mandateId: mandate.uuid || '',
          mandateFile: null,
          status: mandate?.status,
          signType: 'online',
          mandataire: mandate?.representative,
        });

        toggle();
      }
    },
    [setSelectedMandate, toggle, mandates],
  );

  const user = useUser();

  const columns = useMemo<ColumnDef<Mandate>[]>(
    () =>
      [
        { header: 'common.representative', accessorKey: 'representative' },
        { header: 'common.start_date', accessorKey: 'start_date' },
        { header: 'common.end_date', accessorKey: 'end_date' },
        {
          header: 'common.active',
          accessorKey: 'status',
          cell: ({ getValue }) => <StatusCell value={getValue<MandateStatus>()} />,
        },
        ...(user.isAuthorized(['update:mandat'])
          ? ([
              {
                header: '',
                id: 'actions',
                enableSorting: false,
                cell: ({ row }) => (
                  <Button floating variant="primary-2" onClick={() => handleClick(row.original.uuid)} icon="Edit3" />
                ),
              },
            ] satisfies ColumnDef<Mandate>[])
          : []),
      ] satisfies ColumnDef<Mandate>[],
    [handleClick, user],
  );

  const table = useReactTable({
    columns,
    data: filteredMandates,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className={styles['size-mandates-table']}>
      <Table hasActions>
        <TableHead table={table} />
        <TableBody table={table} isLoading={loading} />
      </Table>

      <Modal
        isOpen={mandateModalVisible}
        toggle={toggle}
        size="lg"
        header="admin.installations.add_mandate"
        hideCancelButton
      >
        <FormMandates selectedMandate={selectedMandate} allowSelect={false} onClose={toggle} />
      </Modal>
    </div>
  );
};
