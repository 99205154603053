import React, { useCallback, useMemo, useState } from 'react';
import { MeterNameCell } from '@components/ui/table/cell/MeterNameCell';
import { Button } from '@GDM/Button';
import { Table, TableActions, TableBody, TableHead, TablePageSizeSelect, TablePagination } from '@GDM/Table';
import {
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  getCoreRowModel,
  ColumnDef,
} from '@tanstack/react-table';
import Installation from '@utils/types/installation';
import { useFormContext } from 'react-hook-form';
import styles from './book-form.module.scss';
import { BookFormContext } from './book-form.types';

export const SelectedBooksTable: React.FC<{ installations: Installation[] }> = ({ installations }) => {
  const { setValue } = useFormContext<BookFormContext>();
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 15 });

  const removeInstallation = useCallback(
    (uuid: Installation['uuid']) => {
      setValue(
        'installations',
        installations.filter((i) => i.uuid !== uuid).map((i) => i.name),
      );
    },
    [installations, setValue],
  );

  const columns = useMemo(
    () =>
      [
        {
          header: 'common.name',
          accessorKey: 'name',
          cell: ({ row: { original }, getValue }) => (
            <MeterNameCell
              hasMeter={original.has_meter}
              installation_name={getValue<string>()}
              installation_energy={original.energy}
              isDraft={original.status === 'draft'}
            />
          ),
        },
        {
          header: () => <div className="w-1" />,
          id: 'actions',
          cell: (props) => (
            <div className="w-1">
              <Button
                floating
                variant="secondary"
                data-cy="delete-contract"
                className="ml-2"
                onClick={() => removeInstallation(props.row.original.uuid)}
                icon="Trash2"
              />
            </div>
          ),
        },
      ] satisfies ColumnDef<Installation>[],
    [removeInstallation],
  );

  const table = useReactTable({
    columns,
    data: installations,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    state: { pagination },
    initialState: {
      sorting: [{ id: 'name', desc: false }],
    },
  });

  const pageSize = pagination.pageSize;

  return (
    <>
      <Table data-cy="book-installation-list" className={styles['installation-list']}>
        <TableHead table={table} />
        <TableBody table={table} />
      </Table>

      {installations.length > pageSize && (
        <TableActions className="p-0">
          <TablePageSizeSelect
            pageSize={table.getPageOptions().length}
            setPageSize={table.setPageSize}
            totalEntries={installations.length}
          />
          <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />
        </TableActions>
      )}
    </>
  );
};
