import React from 'react';
import { Restricted } from '@components/Restricted';
import { NotificationMenu } from './NotificationMenu';
import styles from './notifications.module.scss';

export const Notifications: React.FC = () => {
  return (
    <Restricted permissions={['display:view_monitoring_alerts_page']}>
      <div className={styles['notifications']}>
        <NotificationMenu />
      </div>
    </Restricted>
  );
};
