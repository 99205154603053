import React from 'react';
import useBooks from '@hooks/requests/useBooks';
import { BookSelect } from '@ui/BookSelect';

type BookFiltersProps = {
  onChange: (names: string[]) => void;
};

export const BookFilter: React.FC<BookFiltersProps> = ({ onChange }) => {
  const { data: books } = useBooks({ bookType: 'monitoring' });
  const [bookUuid, setBookUuid] = React.useState<string | null>(null);

  const handleBookChange = (id: string | null) => {
    const book = books?.find((b) => b.uuid === id);

    if (book) {
      onChange(book.installation_names);
    } else {
      onChange([]);
    }

    setBookUuid(id);
  };

  if (!books) return null;

  return <BookSelect books={books} value={bookUuid} onChange={handleBookChange} />;
};
