import React, { useContext, useMemo } from 'react';
import { Chart } from '@GDM/Chart';
import useTranslation from '@hooks/useTranslation';
import dayjs from 'dayjs';
import TimeZone from 'dayjs/plugin/timezone';
import Utc from 'dayjs/plugin/utc';
import filtersContext from './filtersContext';
import { MetricsResponse } from './utils/hooks/useMetrics';

dayjs.extend(Utc);
dayjs.extend(TimeZone);

type InvoicesGeneratedChartProps = {
  metrics: MetricsResponse | null;
};

const cumulativeSum = (sum: number) => (value: number) => (sum += value);

const InvoicesGeneratedChart: React.FC<InvoicesGeneratedChartProps> = ({ metrics }) => {
  const { format } = useContext(filtersContext);
  const cumulated = format.selected === 'cumulated';
  const { t } = useTranslation();

  const data = useMemo<Partial<MetricsResponse>>(() => {
    const categories = metrics?.categories;
    const approved = metrics?.approved;
    const created = metrics?.created;
    const rejected = metrics?.rejected;
    const requested_submission_external_service = metrics?.requested_submission_external_service;
    const accepted_external_service = metrics?.accepted_external_service;
    const generated = metrics?.generated;
    const accepted_offtaker = metrics?.accepted_offtaker;
    const payment_initiated = metrics?.payment_initiated;
    const paid = metrics?.paid;
    const ignored = metrics?.ignored;
    const pending = metrics?.pending;
    const sent_to_erp = metrics?.sent_to_erp;

    if (cumulated) {
      return {
        categories,
        approved: approved?.map(cumulativeSum(0)) || [],
        created: created?.map(cumulativeSum(0)) || [],
        rejected: rejected?.map(cumulativeSum(0)) || [],
        requested_submission_external_service: requested_submission_external_service?.map(cumulativeSum(0)) || [],
        accepted_external_service: accepted_external_service?.map(cumulativeSum(0)) || [],
        generated: generated?.map(cumulativeSum(0)) || [],
        accepted_offtaker: accepted_offtaker?.map(cumulativeSum(0)) || [],
        payment_initiated: payment_initiated?.map(cumulativeSum(0)) || [],
        paid: paid?.map(cumulativeSum(0)) || [],
        ignored: ignored?.map(cumulativeSum(0)) || [],
        pending: pending?.map(cumulativeSum(0)) || [],
        sent_to_erp: sent_to_erp?.map(cumulativeSum(0)) || [],
      };
    }

    return {
      categories,
      approved,
      created,
      rejected,
      requested_submission_external_service,
      accepted_external_service,
      generated,
      accepted_offtaker,
      payment_initiated,
      paid,
      ignored,
      pending,
      sent_to_erp,
    };
  }, [metrics, cumulated]);

  const options: Highcharts.Options = {
    plotOptions: { column: { stacking: 'normal', opacity: 0.85 } },
    xAxis: { type: 'category', categories: data.categories },
    yAxis: { title: { text: t('common.invoices') } },
  };

  const series: Highcharts.Options['series'] = [
    { type: 'column', name: t('common.filters.invoice_status.created'), data: data.created },
    { type: 'column', name: t('common.filters.invoice_status.approved'), data: data.approved },
    { type: 'column', name: t('common.filters.invoice_status.generated'), data: data.generated },
    { type: 'column', name: t('common.filters.invoice_status.rejected'), data: data.rejected },
    {
      type: 'column',
      name: t('common.filters.invoice_status.requested_submission_external_service'),
      data: data.requested_submission_external_service,
    },
    {
      type: 'column',
      name: t('common.filters.invoice_status.accepted_external_service'),
      data: data.accepted_external_service,
    },
    { type: 'column', name: t('common.filters.invoice_status.accepted_offtaker'), data: data.accepted_offtaker },
    { type: 'column', name: t('common.filters.invoice_status.payment_initiated'), data: data.payment_initiated },
    { type: 'column', name: t('common.filters.invoice_status.paid'), data: data.paid },
    { type: 'column', name: t('common.filters.invoice_status.ignored'), data: data.ignored },
    { type: 'column', name: t('common.filters.invoice_status.pending'), data: data.pending },
    { type: 'column', name: t('common.filters.invoice_status.sent_to_erp'), data: data.sent_to_erp },
  ];

  return (
    <div>
      <Chart series={series} options={options} immutable={true} hideLegend />
    </div>
  );
};

export default InvoicesGeneratedChart;
