import React from 'react';
import { Input, Select } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import Text from '@ui/text';
import { isANumber } from '@utils/isANumber';
import { Option } from '@utils/types/common-types';
import { CapacityUnit } from '@utils/types/unavailability';
import { useFormContext, Controller } from 'react-hook-form';
import { CreateModalForm, CapacityForm } from '../../CreateModal/types';
import styles from '../../unavailabilities.module.scss';

const validateCapacity = (capacity: CapacityForm) => {
  const { value, unit } = capacity;
  if (unit === 'percentage' && isANumber(value) && (value < 0 || value > 100)) return 'errors.invalid_percentage_range';

  return true;
};

export const CapacityInput: React.FC = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<CreateModalForm>();
  const options: Option<CapacityUnit>[] = [
    { label: 'MW', value: 'MW' },
    { label: '%', value: 'percentage' },
  ];

  return (
    <div>
      <Controller
        control={control}
        name="capacity"
        rules={{ validate: validateCapacity }}
        render={({
          field: {
            value: { value, unit },
            onChange,
          },
          fieldState: { error },
        }) => (
          <div>
            <div className={styles['capacity-input-group']}>
              <Input
                type="number"
                onChange={(e) => onChange({ value: e.target.value, unit })}
                value={value ?? ''}
                step={0.001}
                autoComplete="off"
                label="monitoring.unavailabilities.capacity_value"
                className={styles['capacity-value-input']}
                hasError={!!error}
              />
              <Select
                options={options}
                selectedOption={unit}
                onChange={(option) => onChange({ value, unit: option?.value })}
                className={styles['capacity-unit-input']}
              />
            </div>
            {!!error && <Text color="danger">{t(error.message)}</Text>}
          </div>
        )}
      />
    </div>
  );
};
