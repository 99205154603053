import React from 'react';
import styles from './progress-circle.module.scss';

/**
 * @param size the value is in `rem`.
 */
export const ProgressCircle: React.FC<{
  animated?: boolean;
  value?: number;
  min?: number;
  max?: number;
  color?: string;
  size?: number;
}> = ({ value = 0, min = 0, max = 100, color = 'var(--primary-1)', animated = true, size = 1.25 }) => {
  const style: React.CSSProperties = {
    '--value': value,
    '--fg': color,
    '--animation-speed': animated ? '1s' : '0s',
    '--size': `${size}rem`,
  };

  return (
    <div
      role="progressbar"
      aria-valuenow={value}
      aria-valuemin={min}
      aria-valuemax={max}
      style={style}
      className={styles['progress-bar']}
    />
  );
};
