import React, { useMemo } from 'react';
import { useInstallationsContext } from '@context/installations.context';
import { Select } from '@GDM/forms';
import { Text } from '@GDM/Text';
import Installation from '@utils/types/installation';
import { Controller, useFormContext } from 'react-hook-form';
import { useFieldRules } from '../../utils/getFieldRules';
import { SimulationForm } from '../../utils/simulations.types';

export const InstallationSelect: React.FC = () => {
  const form = useFormContext<SimulationForm>();
  const { installations, isLoading } = useInstallationsContext();
  const { fieldRules } = useFieldRules();
  const rules = fieldRules('installation_attributes.id');

  const options = useMemo(() => {
    if (isLoading || !installations) {
      return [];
    }

    return [
      { label: 'pricing.project.new_project', value: '' },
      ...installations.map((installation: Installation) => ({
        label: installation.has_meter ? `[MONITORED] ${installation.name}` : installation.name,
        value: installation.uuid,
      })),
    ];
  }, [installations, isLoading]);

  return (
    <Controller
      control={form.control}
      rules={rules}
      name="installation_attributes.id"
      render={({ field, fieldState }) => (
        <>
          <Select
            classNamePrefix="installation-select"
            label="common.project"
            size="lg"
            full
            options={options}
            isLoading={isLoading}
            selectedOption={field.value}
            onChange={(option) => field.onChange(option?.value)}
            isClearable
            hasError={!!fieldState.error}
          />
          {fieldState.error && <Text text={fieldState.error.message} type="danger" />}
        </>
      )}
    />
  );
};
