import React from 'react';
import { Icon } from '@GDM/Icon';
import { CellContext } from '@tanstack/react-table';
import { Contract } from '@utils/types/contract';

export const CountryCell: React.FC<CellContext<Contract, unknown>> = ({ row: { original } }) => {
  return (
    <Icon
      size={14}
      name={original.installation?.country || 'XX'}
      title={original.installation?.country || 'errors.installation.unknown_country'}
    />
  );
};
