import React from 'react';
import { Restricted } from '@components/Restricted';
import { useUnavailabilitiesContext } from '@context/UnavailabilitiesContext';
import { Button } from '@GDM/Button';
import { CellContext } from '@tanstack/react-table';
import { Unavailability } from '@utils/types/unavailability';
import { useFormContext } from 'react-hook-form';
import { useCancelModalContext } from '../CancelModal/context';
import { useCreateModalContext } from '../CreateModal/context';
import { convertUnavailabilityToForm } from '../CreateModal/converters';
import { CreateModalForm } from '../CreateModal/types';

export const ActionsCell: React.FC<React.PropsWithChildren<CellContext<Unavailability, unknown>>> = ({
  row: { original },
}) => {
  const { reset } = useFormContext<CreateModalForm>();
  const { toggle: toggleCreateModal } = useCreateModalContext();
  const { toggle: toggleCancelModal } = useCancelModalContext();
  const { setSelectedUnavailability } = useUnavailabilitiesContext();

  const onEditClick = () => {
    setSelectedUnavailability(original);
    reset(convertUnavailabilityToForm(original), { keepDefaultValues: true });
    toggleCreateModal?.();
  };

  const onCancelClick = () => {
    setSelectedUnavailability(original);
    toggleCancelModal?.();
  };

  const isEditingDisabled = false; //dayjs(original.end_date) < dayjs();
  const isCancelDisabled = false; //original.status !== 'planned';

  return (
    <>
      <Restricted permissions={['display:edit_monitoring']}>
        <Button
          className="ml-2"
          onClick={onEditClick}
          icon="Edit3"
          variant="primary-2"
          floating
          disabled={isEditingDisabled}
        />
        <Button
          className="ml-2"
          onClick={onCancelClick}
          icon="Trash2"
          variant="secondary"
          floating
          disabled={isCancelDisabled}
        />
      </Restricted>
    </>
  );
};
