import React from 'react';
import { Input } from '@GDM/forms';
import { getSymbol } from '@utils/currency/getSymbol';
import { isANumber } from '@utils/isANumber';
import type { Currency } from '@utils/types/currency';
import classNames from 'classnames';
import round from 'lodash/round';
import { useFormContext } from 'react-hook-form';
import { parseRevenuesNumberValues } from '../../utils/parseRevenuesNumberValues';
import styles from '../update-modal.module.scss';
import type { UpdateInvoiceForm } from '../update-modal.types';

export const TotalInput: React.FC<{ index: number; currency: Currency }> = ({ index, currency }) => {
  const { watch } = useFormContext<UpdateInvoiceForm>();
  const values = watch(`revenues.${index}`);
  const { quantity, unit_price } = parseRevenuesNumberValues(values);
  const total = isANumber(quantity) && isANumber(unit_price) ? quantity * (unit_price / 100) : 0;
  const totalFormatted = total || total === 0 ? round(total, 2) : '';

  return (
    <Input
      className={classNames(styles.input, styles['input-total'])}
      type="number"
      suffix={getSymbol(currency)}
      disabled
      readOnly
      value={totalFormatted}
      data-cy={`revenues.${values.revenue_type}.total`}
    />
  );
};
