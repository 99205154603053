import React from 'react';
import { MeterNameCell } from '@components/ui/table/cell/MeterNameCell';
import { useConsumeContracts } from '@context/contracts.context';
import { CellContext } from '@tanstack/react-table';
import { Contract } from '@utils/types/contract';

export const ContractMeterNameCell: React.FC<CellContext<Contract, unknown>> = ({ row: { original } }) => {
  const { infoFilters } = useConsumeContracts();

  return (
    <MeterNameCell
      hasMeter={original.installation.has_meter}
      installation_name={original.installation.name}
      installation_energy={original.installation.energy}
      client_id={infoFilters.clientId ? original.installation.external_ref : undefined}
      erpCode={original.installation.erp_code}
      isDraft={original.installation.status === 'draft'}
    />
  );
};
