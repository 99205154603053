import React, { useMemo } from 'react';
import { DateCell, NumberCell, Table, TableBody, TableHead } from '@GDM/Table';
import useTranslation from '@hooks/useTranslation';
import { ColumnDef, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import Book from '@utils/types/book';
import { ActionCell } from './cells/ActionCell';
import { TotalPowerCell } from './cells/TotalPowerCell';

export const BooksTable: React.FC<{
  books: Book[];
  isLoading: boolean;
  className: string;
}> = ({ books, className, isLoading }) => {
  const { t } = useTranslation();
  const columns = useMemo<ColumnDef<Book>[]>(
    () =>
      [
        { header: 'common.name', accessorKey: 'name' },
        {
          header: 'admin.books.n_installations',
          accessorKey: 'installations_size',
          cell: (props) => <NumberCell value={props.getValue<Book['installations_size']>()} />,
        },
        { header: t('common.total_power') + ' (kW)', accessorKey: 'total_power', cell: TotalPowerCell },
        {
          header: 'common.created_at',
          accessorKey: 'created_at',
          cell: (props) => <DateCell value={props.getValue<Book['created_at']>()} />,
        },
        { id: 'actions', cell: ActionCell },
      ] satisfies ColumnDef<Book>[],
    [t],
  );

  const table = useReactTable({
    columns,
    data: books,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: { sorting: [{ id: 'name', desc: false }] },
  });

  return (
    <Table hasActions className={className}>
      <TableHead table={table} />
      <TableBody isLoading={isLoading} table={table} />
    </Table>
  );
};
