import React, { useMemo } from 'react';
import { Row } from '@GDM/layout';
import { Actions } from './Actions';
import { AvailabilityCards } from './AvailabilityCards';
import { AvailabilityChart } from './AvailabilityChart';
import { UnavailabilityFilters } from './Filters';
import { useUnavailabilityFiltersContext } from './Filters/context';
import { computeAvailabilities, computeTotalPmax } from './utils/computeAvailabilities';

export const Header: React.FC = () => {
  const { form, filteredUnavailabilities, filteredInstallations, chartVisible } = useUnavailabilityFiltersContext();

  const startDate = form?.watch('startDate') ?? null;
  const endDate = form?.watch('endDate') ?? null;

  const total_p_max = useMemo(() => {
    return computeTotalPmax(filteredInstallations);
  }, [filteredInstallations]);

  const availabilities = useMemo(
    () => computeAvailabilities(filteredUnavailabilities, total_p_max, startDate, endDate),
    [filteredUnavailabilities, total_p_max, startDate, endDate],
  );

  return (
    <>
      <UnavailabilityFilters />
      <Row>
        {chartVisible && <AvailabilityChart availabilities={availabilities} />}
        <AvailabilityCards availabilities={availabilities} total_p_max={total_p_max} />
      </Row>
      <div className="mb-2">
        <Actions />
      </div>
    </>
  );
};
