import React, { useState } from 'react';
import { useInvoicesByType } from '@hooks/requests/meter-invoices/useInvoicesByType';
import dayjs from 'dayjs';
import InvoicePageWrapper from '../InvoicePageWrapper';

const RecInvoices = () => {
  const [startDate, setStartDate] = useState<Date>(dayjs().startOf('year').subtract(1, 'year').toDate());
  const [endDate, setEndDate] = useState<Date | null>(dayjs().endOf('year').toDate());
  const req = useInvoicesByType('rec', startDate, endDate);

  const handleDateChange = (startDate: Date | null, endDate?: Date | null) => {
    const endOfMonth = endDate ? dayjs(endDate).endOf('month').add(1, 'day').toDate() : null;

    setStartDate(dayjs(startDate).toDate());
    setEndDate(endOfMonth);
  };

  return (
    <InvoicePageWrapper
      invoices={req.data ?? []}
      invoiceType="rec"
      isLoading={req.loading}
      startDate={startDate}
      endDate={endDate}
      onDateChange={handleDateChange}
      isDateRange
    />
  );
};

export default RecInvoices;
