import React from 'react';
import { Spinner } from '@GDM/Spinner';
import styles from './simulation.module.scss';
import { projectContext } from './utils/project.context';
import { useProject } from './utils/useProject';

export const ProjectProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const name = window.location.pathname.split('/').filter(Boolean).pop();
  const simulationId = new URLSearchParams(window.location.search).get('ppa_simulation_id');
  const project = useProject(name, simulationId);

  if (project.loading)
    return (
      <div className={styles['project-spinner-container']}>
        <Spinner />
      </div>
    );

  return <projectContext.Provider value={project}>{children}</projectContext.Provider>;
};
