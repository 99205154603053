import { useMemo } from 'react';
import { useConsumeContracts } from '@context/contracts.context';
import { ColumnDef } from '@tanstack/react-table';
import { Contract } from '@utils/types/contract';
import { CONTRACT_COLUMNS as C } from './ColumnList';

export function useColumns() {
  const { infoFilters } = useConsumeContracts();

  const columns = useMemo<ColumnDef<Contract>[]>(() => {
    return [
      C.CHECKBOX,
      C.TYPE,
      ...(infoFilters.country ? [C.COUNTRY] : []),
      C.INSTALLATION,
      ...(infoFilters.commissioningDate ? [C.COMMISSIONING_DATE] : []),
      ...(infoFilters.cardI ? [C.CARD_I] : []),
      ...(infoFilters.p_max ? [C.P_MAX] : []),
      ...(infoFilters.seller ? [C.SELLER] : []),
      ...(infoFilters.sellerSiren ? [C.SELLER_SIREN] : []),
      ...(infoFilters.buyer ? [C.BUYER] : []),
      ...(infoFilters.horizon ? [C.HORIZON] : []),
      ...(infoFilters.pricing ? [C.PRICING] : []),
      C.ACTIONS,
    ];
  }, [infoFilters]);

  return { columns };
}
