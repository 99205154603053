import React from 'react';
import { Select } from '@GDM/forms';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import { useSelectedYear } from '@pages/Simulations/Simulation/utils/selectedYear.context';
import { Option } from '@utils/types/common-types';

export const YearPicker: React.FC = () => {
  const project = useProjectContext();
  const { full_merchant_cannibalized_distribution_cat } = project?.full_merchant || {};
  const { selectedYear, setSelectedYear } = useSelectedYear();

  const values = full_merchant_cannibalized_distribution_cat
    ? Object.values(full_merchant_cannibalized_distribution_cat)
    : [];

  const options: Option[] = values.map((year, index) => ({
    value: index.toString(),
    label: year,
  }));

  return (
    <Select
      className="ml-2"
      selectedOption={selectedYear}
      options={options}
      onChange={(option) => setSelectedYear(option?.value || null)}
    />
  );
};
