import React from 'react';
import { useUser } from '@context/User.context';
import { Tooltip } from '@GDM/Tooltip/Tooltip';
import { AcceptableUnits } from '@GDM/ValueWithUnit';
import { specialUnitsMap } from '@GDM/ValueWithUnit/config';
import useTranslation from '@hooks/useTranslation';
import { isANumber } from '@utils/isANumber';
import classnames from 'classnames';
import styles from './number-cell.module.scss';

export type NumberCellProps = {
  getValue?: () => unknown;
  value?: number | null | unknown;
  color?: 'blue' | 'green' | 'grey' | 'orange' | 'red' | 'default';
  fractionDigits?: number;
  unit?: AcceptableUnits;
  numberOptions?: Intl.NumberFormatOptions;
  tooltip?: string | null;
};

export const NumberCell: React.FC<NumberCellProps> = ({
  getValue,
  value,
  color,
  unit,
  numberOptions,
  fractionDigits,
  tooltip,
}) => {
  const { locale } = useUser();
  const { t } = useTranslation();
  const className = classnames(styles['number-cell'], color && `text-${color}`);

  const numberFormatOptions = { ...numberOptions };

  if (typeof fractionDigits === 'number') {
    numberFormatOptions.maximumFractionDigits = fractionDigits;
    numberFormatOptions.minimumFractionDigits = fractionDigits;
  }

  const valueToUse = value ?? getValue?.();

  return (
    <Tooltip tooltip={tooltip}>
      <div className={className}>
        {isANumber(valueToUse) ? new Intl.NumberFormat(locale, numberFormatOptions).format(Number(valueToUse)) : ''}
        {typeof valueToUse === 'number' && (
          <span className={styles.unit}>{t((unit && specialUnitsMap.get(unit)?.default) || unit)}</span>
        )}
        {(valueToUse === null || valueToUse === undefined) && '--'}
      </div>
    </Tooltip>
  );
};
