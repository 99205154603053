import React from 'react';
import classNames from 'classnames';
import { MapContainer, TileLayer } from 'react-leaflet';
import styles from './map.module.scss';

export const Map: React.FC<
  React.PropsWithChildren<{
    className?: string;
    center?: [number, number];
    zoom?: number;
    hideLayers?: ('satellite' | 'map')[];
  }>
> = ({ center, children, className, zoom, hideLayers }) => {
  return (
    <MapContainer
      className={classNames(styles.container, className)}
      center={center || [46.7, 1.7]}
      zoom={zoom || 18}
      scrollWheelZoom={true}
    >
      {/* The satellite layer */}
      {!hideLayers?.includes('satellite') && (
        <TileLayer
          minZoom={15}
          maxZoom={20}
          url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
        />
      )}

      {/* The map layer */}
      {!hideLayers?.includes('map') && (
        <TileLayer
          minZoom={0}
          maxZoom={14}
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          attribution='<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          subdomains="abcd"
        />
      )}

      {children}
    </MapContainer>
  );
};
