import React from 'react';
import classNames from 'classnames';
import styles from './definition-list.module.scss';

/** List of definitions, like in the characteristics tab of the meter dashboard. */
export const DefinitionList: React.FC<React.PropsWithChildren<{ className?: string; style?: React.CSSProperties }>> = ({
  children,
  className,
  style,
}) => {
  const classList = classNames(styles?.['definition-list'], className);

  return (
    <dl className={classList} style={style}>
      {children}
    </dl>
  );
};
