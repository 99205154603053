import React from 'react';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { Button } from '@GDM/Button';
import { useInternalFormContext } from '@GDM/forms';
import { Hr } from '@GDM/Hr';
import { useGrids } from '@hooks/requests/useGrids';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { capitalizeFirstLetter } from '@utils/string';
import type { CountryCode } from '@utils/types/countries';
import { useShowMeterForm } from '../../context/showMeterForm.context';
import { useFormDataProviders } from '../../hooks/useFormDataProviders';
import { useInstallationForm } from '../../hooks/useInstallationForm';
import { useInstallationFormQuery } from '../../useInstallationFormQuery';
import { Default } from './GridSection/Default/Default';
import { DefaultIt } from './GridSection/Default/DefaultIt';
import { Enedis } from './GridSection/Enedis';

export const MeterSettingsSection = () => {
  const { control, watch } = useInstallationForm();
  const [selectedGridId, selectedDataProviderId] = watch(['grid_id', 'data_provider_id']);
  const { showMeterForm, setShowMeterForm } = useShowMeterForm();
  const {
    query: { data: installation },
  } = useInstallationFormQuery();

  const { country } = useInternalFormContext();

  const grids = useGrids(country);
  const selectedGrid = grids.data?.find(({ id }) => id === selectedGridId);
  const dataProviders = useFormDataProviders(watch);
  const selectedDataProvider = dataProviders.find(({ id }) => id === selectedDataProviderId);

  const Template = getDateProviderTemplateName(selectedDataProvider?.name?.toLowerCase(), country);

  const gridOptions = grids.data?.map(({ id, name }) => ({ value: id, label: name })) || [];

  return (
    <div className="d-flex flex-column">
      {!installation?.has_meter && (
        <>
          <div className="p-3">
            <Button
              text={showMeterForm ? 'common.cancel' : 'admin.installations.add_installation_meter'}
              variant={showMeterForm ? 'link-secondary' : 'link'}
              size="sm"
              data-cy="create-meter"
              icon={showMeterForm ? 'XSquare' : 'PlusSquare'}
              onClick={() => setShowMeterForm((state) => !state)}
            />
          </div>

          {showMeterForm && <Hr className="m-0" />}
        </>
      )}

      {showMeterForm && (
        <>
          <div className="p-3">
            <ControlledSelect
              control={control}
              name="grid_id"
              options={gridOptions.sort(sortOptionsByLabelAsc)}
              isLoading={grids.isLoading}
              label="common.grid"
              classNamePrefix="grid-select"
              size="lg"
            />
          </div>

          {selectedGrid && <Template />}
        </>
      )}
    </div>
  );
};

const getDateProviderTemplateName = (name?: string, country?: CountryCode | null) => {
  const templates: { [key: string]: () => JSX.Element } = {
    enedis: Enedis,
    default: Default,
    defaultIt: DefaultIt,
  };

  if (!name || !templates[name]) {
    const countryTemplateName = country ? `default${capitalizeFirstLetter(country.toLowerCase())}` : '';

    return templates[countryTemplateName] || templates.default;
  }

  return templates[name];
};
