import React, { useMemo } from 'react';
import { useUser } from '@context/User.context';
import { DatePicker } from '@GDM/DatePicker';
import { FilterContainer, Filters } from '@GDM/Filters';
import { RadioButtons, RadioOption } from '@GDM/forms';
import dayjs from 'dayjs';
import { IndexTabName, IndexType } from './index-tab.definitions';
import { useIndexTab } from './useIndexTab';

export const IndexFilters: React.FC = () => {
  const { permissions } = useUser();
  const {
    selectedTab,
    setSelectedTab,
    invoicingDate,
    setInvoicingDate,
    currentDates,
    setCurrentDates,
    selectedTypeParam,
    setSelectedTypeParam,
  } = useIndexTab();

  const handleDateChange = (date: [Date | null, Date | null] | Date | null) => {
    if (Array.isArray(date) && selectedTab === 'current') {
      setCurrentDates?.({ startDate: date[0], endDate: date[1] });
    }
    if (!Array.isArray(date) && selectedTab === 'invoicing') {
      setInvoicingDate?.(date);
    }
  };

  const options: RadioOption<IndexTabName>[] = [
    { value: 'current', label: 'common.current' },
    { value: 'invoicing', label: 'common.invoicing' },
  ];

  const typeParamOptions: RadioOption<IndexType>[] = useMemo(() => {
    let options: RadioOption<IndexType>[] = [
      { label: 'monitoring.installation.label_prod_indexactif', value: 'prod_indexactif' },
    ];

    if (permissions?.includes('display:view_monitoring_dashboard_meter_reactive'))
      options = options.concat([
        { label: 'monitoring.installation.label_prod_indexreactifpos', value: 'prod_indexreactifpos' },
        { label: 'monitoring.installation.label_prod_indexreactifneg', value: 'prod_indexreactifneg' },
      ]);

    options.push({ label: 'monitoring.installation.label_conso_indexactif', value: 'conso_indexactif' });
    if (permissions?.includes('display:view_monitoring_dashboard_meter_reactive'))
      options = options.concat([
        { label: 'monitoring.installation.label_conso_indexreactifpos', value: 'conso_indexreactifpos' },
        { label: 'monitoring.installation.label_conso_indexreactifneg', value: 'conso_indexreactifneg' },
      ]);

    return options;
  }, [permissions]);

  const lastMonth = dayjs().subtract(1, 'month').endOf('month').toDate();

  return (
    <Filters className="p-0">
      <FilterContainer size="datepicker">
        <DatePicker
          startDate={currentDates.startDate}
          endDate={currentDates.endDate}
          onChange={handleDateChange}
          selectsRange={selectedTab === 'current'}
          picks={selectedTab === 'invoicing' ? 'months' : 'default'}
          maxDate={selectedTab === 'current' ? new Date() : lastMonth}
          selected={selectedTab === 'invoicing' ? invoicingDate : null}
        />
      </FilterContainer>
      <FilterContainer size="fit">
        <RadioButtons options={options} onChange={(e) => setSelectedTab?.(e)} selected={selectedTab} />
      </FilterContainer>
      {selectedTab === 'current' && (
        <FilterContainer size="fit">
          <RadioButtons
            options={typeParamOptions}
            selected={selectedTypeParam}
            onChange={(e) => setSelectedTypeParam?.(e)}
            name="typeParamSelect"
          />
        </FilterContainer>
      )}
    </Filters>
  );
};
