import React, { useEffect, useState } from 'react';
import { DefinitionModalBody, DefinitionModalFooter } from '@GDM/DefinitionList';
import { Input } from '@GDM/forms';
import { patch, useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import { getErrorMessage } from '@utils/parsers';
import { v2_installation_path } from '@utils/routes';
import { InstallationWithMeterInfo as Installation } from '@utils/types/installation';
import { Meter } from '@utils/types/meter';
import { useMeterInfo } from '../useMeterInfo';

export const CoordinatesModal: React.FC<{ meter: Meter }> = ({ meter }) => {
  const { lat, setLat, lng, setLng } = useMeterInfo();
  const [coordinates, setCoordinates] = useState({ lat, lng });
  const { t } = useTranslation();

  const {
    loading: isLoading,
    loaded,
    error,
    execute,
  } = useRequest(
    v2_installation_path(meter.name),
    ({ url }) =>
      patch<Installation, { installation: Partial<Installation> }>({
        url,
        body: {
          installation: {
            latitude: Number(coordinates.lat),
            longitude: Number(coordinates.lng),
          },
        },
      }),
    true,
  );

  const saveUpdate = async () => {
    try {
      const response = await execute?.();

      if (typeof response === 'string') return response;
    } catch (e) {
      return getErrorMessage(e);
    }
  };

  useEffect(() => {
    if (loaded && !error) {
      setLat?.(coordinates.lat);
      setLng?.(coordinates.lng);
    }
  }, [error, loaded]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <DefinitionModalBody>
        <Input
          value={coordinates.lat}
          label="common.latitude"
          full
          placeholder="45.1"
          type="number"
          onChange={(e) => setCoordinates((c) => ({ ...c, lat: e.target.value }))}
          data-cy="latitude"
        />
        <Input
          className="mt-2"
          value={coordinates.lng}
          label="common.longitude"
          full
          placeholder="1.12"
          type="number"
          onChange={(e) => setCoordinates((c) => ({ ...c, lng: e.target.value }))}
          data-cy="longitude"
        />
      </DefinitionModalBody>
      <DefinitionModalFooter
        error={error?.message && t(`errors.installation.${error?.message}`, { field: error?.field })}
        save={saveUpdate}
        isLoading={isLoading}
      />
    </>
  );
};
