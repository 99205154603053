import React, { useState } from 'react';
import { DefinitionModalBody, DefinitionModalFooter } from '@GDM/DefinitionList';
import { Input } from '@GDM/forms';
import { useRequest } from '@hooks/useRequest';
import { change_pr_expected_v2_dashboard_meter_index_path } from '@utils/routes';
import { Meter } from '@utils/types/meter';
import { useMeterInfo } from '../useMeterInfo';

export const ExpectedPrModal: React.FC<{ meter?: Meter }> = ({ meter }) => {
  const { pr_expected, setPrExpected } = useMeterInfo();
  const [expectedPr, setExpectedPr] = useState(`${Number(pr_expected) * 100}`);

  const { loading: isLoading, execute: updatePrExpected } = useRequest(
    change_pr_expected_v2_dashboard_meter_index_path({
      meter: meter?.name,
      prexpected: Number(expectedPr).toFixed(1),
    }),
    'GET',
    true,
  );

  const saveUpdate = () => {
    setPrExpected?.(`${Number(expectedPr) / 100}`);
    updatePrExpected?.();
  };

  return (
    <>
      <DefinitionModalBody>
        <Input
          value={expectedPr}
          full
          type="number"
          step="0.1"
          placeholder="82.0"
          suffix="%"
          onChange={(e) => setExpectedPr(e.target.value)}
          data-cy="pr-expected-input"
        />
      </DefinitionModalBody>
      <DefinitionModalFooter save={saveUpdate} isLoading={isLoading} />
    </>
  );
};
