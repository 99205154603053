import React from 'react';
import { Icon } from '@GDM/Icon';
import { Tooltip } from '@GDM/Tooltip/Tooltip';
import useTranslation from '@hooks/useTranslation';
import { CellContext } from '@tanstack/react-table';
import { MeterInvoice, MeterInvoiceFinalStatus } from '@utils/types/meter-invoice';
import classNames from 'classnames';
import styles from './issuance-status-cell.module.scss';

export const IssuanceStatusCell: React.FC<CellContext<MeterInvoice, unknown>> = ({ row: { original } }) => {
  const { t } = useTranslation();
  const { status, last_status_at, invoice_nb_expression, status_message, external_service } = original;
  const tooltip = invoice_nb_expression ? `${t('invoice.number')}: ${invoice_nb_expression}` : undefined;

  const greenIconClassNames = styles['picto-success'];
  const greyIconClassNames = styles['picto-neutral'];
  const redIconClassNames = styles['picto-danger'];

  const iconSize = 16;

  const statusCell: Record<MeterInvoiceFinalStatus, JSX.Element> = {
    accepted_external_service: <Icon name="Send" size={iconSize} className={greenIconClassNames} />,
    accepted_offtaker: <Icon name="CheckSquare" size={iconSize} className={greenIconClassNames} />,
    approved: <Icon name="CheckCircle" size={iconSize} className={greenIconClassNames} />,
    created: <Icon name="FilePlus" size={iconSize} className={greenIconClassNames} />,
    generated: <Icon name="Send" size={iconSize} className={greenIconClassNames} />,
    paid: <Icon name="ThumbsUp" size={iconSize} className={greenIconClassNames} />,
    payment_initiated: <Icon name="Clock" size={iconSize} className={greyIconClassNames} />,
    rejected: <Icon name="AlertTriangle" size={iconSize} className={redIconClassNames} />,
    requested_submission_external_service: <Icon name="Send" size={iconSize} className={greenIconClassNames} />,
    ignored: <Icon name="EyeOff" size={iconSize} className={greyIconClassNames} />,
    pending: <Icon name="Pause" size={iconSize} className={greyIconClassNames} />,
    sent_to_erp: <Icon name="Send" size={iconSize} className={greenIconClassNames} />,
  };

  if (!statusCell[status]) {
    return null;
  }

  return (
    <Tooltip tooltip={tooltip}>
      <div className={styles['issuance-status-cell']}>
        <div className="d-flex justify-content-start align-items-center mb-2">
          {statusCell[status]}
          <div className={classNames(styles.title, 'ml-1')}>{t(`common.filters.invoice_status.${status}`)}</div>
          {external_service ? (
            <span className={classNames(styles['status-message'], 'ml-1')}>
              {t(`common.filters.external_service.${external_service}`)}
            </span>
          ) : status_message ? (
            <span className={classNames(styles['status-message'], 'ml-1')}>
              {t(`common.filters.invoice_message.${status_message}`)}
            </span>
          ) : (
            <></>
          )}
        </div>

        <div className={styles.date}>{last_status_at}</div>
      </div>
    </Tooltip>
  );
};
