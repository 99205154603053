import React from 'react';
import { FilterType } from '@GDM/Filters/filters.types';
import { ContractType } from '@utils/types/contract';
import { CountryCode } from '@utils/types/countries';
import { MeterInvoiceFinalStatus, type LoadCurveSource } from '@utils/types/meter-invoice';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

type ForecastType = 'forecast' | 'forecast_volume' | 'non_forecast';

export type FormForecastType = ForecastType | '';

export type InvoiceFilters = FilterType<{
  installation_country: CountryCode | null;
  installation_name: string[];
  buyer_name: string[];
  contract_type: ContractType[];
  forecast_type: FormForecastType;
  book_id: string[];
  card_i: string[];
  invoice_status: MeterInvoiceFinalStatus[];
  showRegul: boolean;
  showCancelled: boolean;
  contract_nb: string[];
  sources: Array<LoadCurveSource | 'index'>;
}>;

export const defaultFilters: InvoiceFilters = {
  installation_country: null,
  installation_name: [],
  buyer_name: [],
  contract_type: [],
  forecast_type: 'forecast',
  book_id: [],
  card_i: [],
  invoice_status: [],
  showRegul: true,
  showCancelled: true,
  contract_nb: [],
  last_selected_filter: null,
  sources: [],
};

export const useInvoicesFilters = () => useFormContext<InvoiceFilters>();

export const InvoicesFiltersProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const form = useForm<InvoiceFilters>({ defaultValues: defaultFilters });

  return <FormProvider {...form}>{children}</FormProvider>;
};
