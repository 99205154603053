import React, { useEffect } from 'react';
import { DatePickerProps } from '@GDM/DatePicker';
import { ContractFormQueries } from '@pages/Contracts/Contract/hooks/useContractForm/useContractFormQueries';
import { ContractForm } from '@utils/types/contract';
import { ContractType } from '@utils/types/contract/unions';
import { CountryCode } from '@utils/types/countries';
import dayjs from 'dayjs';
import { UseFormReturn } from 'react-hook-form';
import { DateValidators } from '../../../../hooks/useDateValidation';
import {
  CONTRACT_TYPES_WITH_AGGREGATION_FEES,
  CONTRACT_TYPES_WITH_HEDGE_PROFILE,
  CONTRACT_TYPES_WITH_INDEX_FORMULA,
  CONTRACT_TYPES_WITH_NEGATIVE_PRICES,
  CONTRACT_TYPES_WITH_PRICE_RULES,
} from '../../constants/contractTypesSubPeriodTypes';
import { DatesAndPriceType } from './components/DatesAndPriceType';
import { HedgeProfile } from './components/HedgeProfile/HedgeProfile';
import { SubPeriodPricing } from './components/SubPeriodPricing';

export function EnergyProductionSubPeriod({
  index,
  form,
  readOnly,
  datesValidation,
  selectedPeriod,
  customPeriods,
  onClickPeriod,
  country,
  queries: { indexFormulas, priceRules },
}: {
  country?: CountryCode | null;
  queries: Pick<ContractFormQueries, 'indexFormulas' | 'priceRules'>;
  index: number;
  form: UseFormReturn<ContractForm>;
  readOnly: boolean;
  currency: ContractForm['currency'];
  datesValidation: DateValidators;
} & Pick<DatePickerProps, 'selectedPeriod' | 'customPeriods' | 'onClickPeriod'>) {
  const { watch } = form;
  const [startDate, endDate, volumeType, priceType, rawContractType, currency] = watch([
    `contract_sub_periods_attributes.${index}.dates.0`,
    `contract_sub_periods_attributes.${index}.dates.1`,
    `contract_sub_periods_attributes.${index}.volume_type`,
    `contract_sub_periods_attributes.${index}.price_type`,
    'type',
    'currency',
  ]);

  // Sub-period would not be displayed if contract type was not valid
  const contractType = rawContractType as ContractType;

  const hasAggregatorFees = CONTRACT_TYPES_WITH_AGGREGATION_FEES.includes(contractType);
  const hasIndexFormula = CONTRACT_TYPES_WITH_INDEX_FORMULA.includes(contractType);
  const hasNegativePrices = CONTRACT_TYPES_WITH_NEGATIVE_PRICES.includes(contractType);
  const hasPriceRules = CONTRACT_TYPES_WITH_PRICE_RULES.includes(contractType);
  const canHaveHedgeProfile = CONTRACT_TYPES_WITH_HEDGE_PROFILE.includes(contractType) && priceType === 'fixed';

  const showHedgeProfile = canHaveHedgeProfile && volumeType === 'hedge_profile';

  useEffect(() => {
    if (priceType !== 'spot' || !country) return;

    form.setValue(`contract_sub_periods_attributes.${index}.price_model_attributes.area`, country);
  }, [priceType, country, form, index]);

  return (
    <>
      <DatesAndPriceType
        index={index}
        hedgeProfileMode={canHaveHedgeProfile}
        form={form}
        country={country}
        selectedPeriod={selectedPeriod}
        customPeriods={customPeriods}
        onClickPeriod={onClickPeriod}
        readOnly={readOnly}
        contractType={contractType}
        datesValidation={datesValidation}
      />

      {canHaveHedgeProfile && showHedgeProfile && (
        <HedgeProfile
          index={index}
          form={form}
          readOnly={readOnly}
          currency={currency}
          startDate={dayjs(startDate).toDate()}
          endDate={dayjs(endDate).toDate()}
        />
      )}

      {(!canHaveHedgeProfile || !showHedgeProfile) && (
        <SubPeriodPricing
          index={index}
          form={form}
          readOnly={readOnly}
          priceRules={priceRules}
          indexFormulas={indexFormulas}
          country={country}
          contractType={contractType}
          currency={currency}
          hasAggregatorFees={hasAggregatorFees}
          hasNegativePrices={hasNegativePrices}
          hasPriceRules={hasPriceRules}
          hasIndexFormula={hasIndexFormula}
        />
      )}
    </>
  );
}
