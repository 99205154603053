import React from 'react';
import { SitesReports } from '../SitesReports';

const ReportPageSiteBySite: React.FC<{ showAll: boolean }> = ({ showAll }) => {
  return (
    <div className="SitesReportsContainer">
      <SitesReports disablePadding printable showAll={showAll} />
    </div>
  );
};

export default ReportPageSiteBySite;
