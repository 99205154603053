import React from 'react';
import { Definition, DefinitionList } from '@GDM/DefinitionList';
import Installation from '@utils/types/installation';
import { ClientIdDefinition } from './ClientIdDefinition';
import { CoordinatesDefinition } from './CoordinatesDefinition';
import { ErpCodeDefinition } from './ErpCodeDefinition';
import { SiretDefinition } from './SiretDefinition';

export const InstallationInfo: React.FC<{ installation: Installation }> = ({ installation }) => {
  let energy = 'common.unknown';
  if (installation?.energy === 'solar') energy = 'common.pv.solar';
  if (installation?.energy === 'wind') energy = 'common.wind.eolian';
  if (installation?.energy === 'hydro') energy = 'common.hydro.short';

  return (
    <DefinitionList>
      <Definition label="common.type" value={energy} data-cy="type-definition" />

      <CoordinatesDefinition name={installation.name} />

      <ClientIdDefinition name={installation.name} />

      <ErpCodeDefinition name={installation.name} />

      <SiretDefinition installation={installation} />
    </DefinitionList>
  );
};
