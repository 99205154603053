import React from 'react';
import { useUser } from '@context/User.context';
import useTranslation from '@hooks/useTranslation';
import { Permission } from '@utils/types/permission';
import classNames from 'classnames';
import styles from './sidebar-menu-item.module.scss';

export type SidebarMenuItemProps = {
  link: string;
  label: string;
  disabled?: boolean;
  hidden?: boolean;
  permissions?: Permission[];
  isActive?: boolean;
};

type SidebarMenuItemStateProps = {
  activeLink?: string;
};

export const SidebarMenuItem: React.FC<SidebarMenuItemStateProps & SidebarMenuItemProps> = ({
  activeLink,
  link,
  label,
  disabled,
  hidden,
  permissions,
  isActive,
}) => {
  isActive = isActive ?? activeLink === link.split('?')[0];
  const user = useUser();
  const { t } = useTranslation();

  if (hidden || (permissions && !user.isAuthorized(permissions))) {
    return null;
  }

  return (
    <li className={styles['item']}>
      <a
        href={link}
        data-cy={link}
        className={classNames(
          styles['item-link'],
          isActive && styles['item-link-active'],
          disabled && styles['item-link-disabled'],
        )}
      >
        <span>{t(label)}</span>
      </a>
    </li>
  );
};
