import jsonToCsv, { type HeadCsv } from '@utils/json-to-csv';
import type { Locale } from '@utils/types/common-types';
import dayjs from 'dayjs';
import { granularityFormat, type Granularity } from '../constants/granularities';
import type { NegativePrice } from '../negative-prices.types';
import type { NegativeHour } from './getFormattedNegativeHours';

const exportCsvHourly = (prices: NegativePrice[], showInstallation: boolean, locale: Locale): string => {
  const header: (HeadCsv<NegativePrice> | false)[] = [
    showInstallation &&
      ({
        key: 'installation',
        label: 'common.installation',
        transform: (_, row) => row.installation?.name ?? '',
      } satisfies HeadCsv<NegativePrice>),
    {
      key: 'start_time',
      label: 'common.date',
      transform: (_, row) => dayjs(row.start_time).format(granularityFormat.hourly),
    },
    { key: 'price', label: 'common.price', unit: 'EUR/MWh' },
    {
      key: 'production',
      label: 'common.production',
      transform: (_, row) => (row.production === 0 ? '0' : row.production),
      unit: 'kWh',
    },
    {
      key: 'productible',
      label: 'common.productible',
      transform: (_, row) => (row.productible === 0 ? '0' : row.productible),
      unit: 'kWh',
    },
    {
      key: 'estimated_cost',
      label: 'common.cost',
      unit: 'EUR',
    },
  ];

  const filteredHeaders = header.filter((head): head is HeadCsv<NegativePrice> => Boolean(head));

  return jsonToCsv(
    prices.toSorted((price1, price2) => price1.start_time.localeCompare(price2.start_time)),
    filteredHeaders,
    { locale },
  );
};

const exportCsvGrouped = (hours: NegativeHour[], locale: Locale, granularity: Granularity): string => {
  const header: HeadCsv<NegativeHour>[] = [
    {
      key: 'startDate',
      label: 'common.date',
      transform: (value, row) => dayjs(row.startDate).format(granularityFormat[granularity]),
    },
    {
      key: 'negativePriceHours',
      label: 'common.n_negative_price_hours',
      transform: (_, row) => (row.negativePriceHours === 0 ? '0' : row.negativePriceHours),
    },
    {
      key: 'productionHours',
      label: 'common.n_negative_price_prod_hours',
      transform: (_, row) => (row.productionHours === 0 ? '0' : row.productionHours),
    },
    {
      key: 'interruptionHours',
      label: 'common.n_interruption_hours_on_negative_price',
      transform: (_, row) => (row.interruptionHours === 0 ? '0' : row.interruptionHours),
    },
    {
      key: 'productionOnNegativeHours',
      label: 'common.prod_during_negative_price',
      transform: (_, row) => (row.productionOnNegativeHours === 0 ? '0' : row.productionOnNegativeHours),
      unit: 'kWh',
    },
    {
      key: 'productibleOnNegativeHours',
      label: 'common.productible_during_negative_price',
      transform: (_, row) => (row.productibleOnNegativeHours === 0 ? '0' : row.productibleOnNegativeHours),
      unit: 'kWh',
    },
    {
      key: 'estimatedCostOnNegativeHours',
      label: 'common.cost',
      unit: 'EUR',
    },
  ];

  return jsonToCsv(
    hours.toSorted((price1, price2) => price1.startDate.localeCompare(price2.startDate)),
    header,
    { locale },
  );
};

export const exportCsv = (
  hours: NegativeHour[],
  prices: NegativePrice[],
  granularity: Granularity,
  showInstallation: boolean,
  locale: Locale,
): string => {
  if (['daily', 'monthly'].includes(granularity)) {
    return exportCsvGrouped(hours, locale, granularity);
  }

  return exportCsvHourly(prices, showInstallation, locale);
};
