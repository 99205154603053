import React from 'react';
import { Alert } from '@GDM/Alert';
import { AxiosResponseLoadingState } from '@utils/types/useAxios';

const RequestStatusMessage: React.FC<React.PropsWithChildren<{ status: AxiosResponseLoadingState }>> = ({
  status,
  children,
}) => {
  if (status === 'LOADED') {
    return (
      <Alert size="sm" variant="success" className="mb-2" dismissible>
        {children}
      </Alert>
    );
  }

  return null;
};

export default RequestStatusMessage;
