import React, { useEffect } from 'react';
import { Button } from '@GDM/Button';
import { Text } from '@GDM/Text';
import { UserInternal } from '@utils/types/user';
import { COMMON_USERS } from './common-users';
import styles from './masquerade.module.scss';
import { MASQUERADES_LOG_KEY, goToUser, makeEmailIdDictionary } from './utils';

export const Links: React.FC<{ users: UserInternal[] }> = ({ users }) => {
  const commonUserDictionary = makeEmailIdDictionary(COMMON_USERS, users);
  const commonUsers = Object.entries(commonUserDictionary).sort(([emailA], [emailB]) => emailA.localeCompare(emailB));
  const favorites: Record<string | number, number> = JSON.parse(localStorage.getItem(MASQUERADES_LOG_KEY) || '{}');

  const top5Favorites = Object.entries(favorites)
    .map(([id, count]) => ({ id, count, email: users.find((user) => user.id?.toString() === id)?.email || null }))
    .sort((a, b) => b.count - a.count)
    .slice(0, 5);

  // Listen to keyboard event and trigger something when the user hits the key 't'
  useEffect(() => {
    const SPECIAL_EMAILS = ['test+cypress@streem.eu', 'streemdemo@streem.eu'];

    const specialUsers = makeEmailIdDictionary(SPECIAL_EMAILS, users);

    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.target as HTMLElement)?.id.includes('select-')) return;
      if (event.key === 't') {
        const testUserId = specialUsers[SPECIAL_EMAILS[0]];
        if (testUserId) goToUser(testUserId?.toString());
      }

      if (event.key === 's') {
        const streemDemoUserId = specialUsers[SPECIAL_EMAILS[1]];
        if (streemDemoUserId) goToUser(streemDemoUserId?.toString());
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Text text="use_sub_client.choose_common" className="mt-4 mb-1 fw:700" icon="Star" />
      <div className={styles['button-list']}>
        {commonUsers?.map(([email, id]) => (
          <Button key={id} variant="primary-2" size="sm" onClick={() => goToUser(id)}>
            {email}
          </Button>
        ))}
      </div>

      {top5Favorites && !!top5Favorites.length && (
        <div className={styles['top-5']}>
          <Text text="use_sub_client.top_5" className="mt-3 mb-1 fw:700" icon="Info" />

          <div className={styles['button-list']}>
            {top5Favorites?.map(({ email, id, count }) => (
              <Button
                key={id}
                variant="primary-2"
                size="sm"
                onClick={() => goToUser(id)}
                text={email}
                tooltip={`${count} hit${count > 1 ? 's' : ''}`}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};
