import React from 'react';
import { Input } from '@GDM/forms';
import { Controller, useFormContext } from 'react-hook-form';
import { OwnerFiltersType } from './OwnerFilters';

type InstallationFilterProps = {
  onChange: (params: OwnerFiltersType) => void;
};

export const OwnerCompanyNameFilter: React.FC<InstallationFilterProps> = ({ onChange }) => {
  const formContext = useFormContext<OwnerFiltersType>();

  return (
    <Controller
      name="company_name"
      control={formContext.control}
      render={({ field }) => (
        <Input
          value={field.value}
          placeholder="common.name"
          data-cy="owner-filter-company-name"
          full
          icon="Search"
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            onChange({ ...formContext.getValues(), company_name: e.currentTarget.value });
            field.onChange(e.currentTarget.value);
          }}
        />
      )}
    />
  );
};
