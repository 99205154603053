import React from 'react';
import kFormatter from '@utils/kFormatter';
import { Range, getTrackBackground } from 'react-range';
import { IRenderThumbParams, IRenderTrackParams } from 'react-range/lib/types';

const Thumb = ({ props, isDragged }: IRenderThumbParams) => {
  return (
    <div {...props}>
      <div
        style={{
          width: isDragged ? '30px' : '15px',
          height: isDragged ? '30px' : '15px',
          borderRadius: '50%',
          backgroundColor: 'var(--primary-1)',
          color: '#fff',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '12px',
          fontWeight: 700,
        }}
      >
        {isDragged ? kFormatter(props['aria-valuenow']) : null}
      </div>
    </div>
  );
};

const Track =
  (values: number[], min: number, max: number) =>
  ({ props, children }: IRenderTrackParams) =>
    (
      <div
        onMouseDown={props.onMouseDown}
        onTouchStart={props.onTouchStart}
        style={{
          ...props.style,
          height: '3px',
          display: 'flex',
          width: '100%',
          borderRadius: '3px',
        }}
      >
        <div
          ref={props.ref}
          style={{
            background: getTrackBackground({ colors: ['#ccc', 'var(--primary-1)', '#ccc'], values, min, max }),
            height: '3px',
            width: '100%',
            borderRadius: '3px',
            alignSelf: 'center',
          }}
        >
          {children}
        </div>
      </div>
    );

export const InputRange: React.FC<{
  step?: number;
  min?: number;
  max?: number;
  values: number[];
  onChange: (values: number[]) => void;
  onFinalChange?: (values: number[]) => void;
}> = ({ step = 1, min = 0, max = 100, values, onChange, onFinalChange }) => {
  if (min >= max) max = min + 1;

  return (
    <Range
      step={step}
      min={min}
      max={max}
      values={values}
      onFinalChange={onFinalChange}
      onChange={onChange}
      renderThumb={Thumb}
      renderTrack={Track(values, min, max)}
    />
  );
};
