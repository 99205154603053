import React, { useState } from 'react';
import { Input } from '@GDM/forms';
import { Icon } from '@GDM/Icon';

export const PasswordCell: React.FC<{ value: string }> = ({ value }) => {
  const [showPassword, setShowPassword] = useState(false);

  if (!value) return <Icon name="Lock" size={14} />;

  return (
    <Input
      type={showPassword ? 'text' : 'password'}
      disabled
      value={value}
      suffixButtonProps={{
        variant: 'primary-2',
        icon: showPassword ? 'EyeOff' : 'Eye',
        size: 'sm',
        onClick: () => setShowPassword(!showPassword),
      }}
    />
  );
};
