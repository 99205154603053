import React from 'react';
import { sectionStyles } from '@GDM/forms';
import { ListInput } from '@pages/Contracts/Contract/Form/components/Inputs/List';
import { NumericInput } from '@pages/Contracts/Contract/Form/components/Inputs/Numeric';
import { getSymbol } from '@utils/currency/getSymbol';
import { Option } from '@utils/types/common-types';
import { ContractForm } from '@utils/types/contract';
import { Currency } from '@utils/types/currency';
import IndexFormula from '@utils/types/IndexFormula';
import { Control } from 'react-hook-form';

type IndexFormulaPickerProps = {
  control: Control<ContractForm>;
  options: Option<IndexFormula['formula']>[];
  isLoading: boolean;
  readOnly: boolean;
};

export const IndexFormulaPicker = ({ control, options, isLoading, readOnly }: IndexFormulaPickerProps) => (
  <ListInput
    id="IndexFormulaPicker"
    name="index_formula"
    control={control}
    options={options}
    isLoading={isLoading}
    label="sales_management.index_formula_select"
    menuPlacement="top"
    tooltip={!readOnly ? 'sales_management.index_formula_missing' : null}
    isSearchable
    readOnly={readOnly}
    classNamePrefix="select-index-formula"
  />
);

export const DetailedIndexFormulaPicker = ({
  control,
  options,
  isLoading,
  readOnly,
  currency,
  hasManagementBonus,
}: IndexFormulaPickerProps & { hasManagementBonus: boolean; currency: Currency }) => (
  <div className={sectionStyles.row}>
    <IndexFormulaPicker control={control} options={options} isLoading={isLoading} readOnly={readOnly} />
    <NumericInput
      id="IndexRefProduction"
      name="producer_price_insee_index_ref"
      control={control}
      label="sales_management.index_ref_prod"
      data-cy="index-ref-production"
      readOnly={readOnly}
    />
    {hasManagementBonus && (
      <NumericInput
        id="ManagementBonus"
        name="management_bonus"
        control={control}
        label="invoice.revenues.types.management_fees"
        data-cy="mandagement-fee"
        suffix={`${getSymbol(currency)}/MWh`}
        readOnly
      />
    )}
    <NumericInput
      id="IndexRefLabour"
      name="labour_cost_insee_index_ref"
      control={control}
      label="sales_management.index_ref_work"
      data-cy="index-ref-labour"
      readOnly={readOnly}
    />
  </div>
);
