import React from 'react';
import { Restricted } from '@components/Restricted';
import { Button } from '@GDM/Button';
import { CellContext } from '@tanstack/react-table';
import Book from '@utils/types/book';
import { useBooksPageProvider } from 'pages/Books/books-page.context';

export const ActionCell: React.FC<CellContext<Book, unknown>> = ({ row }) => {
  const { onDelete, onSelect: selectBook } = useBooksPageProvider();

  return (
    <>
      <a
        href={
          row.original.book_type === 'pricing'
            ? `/v2/ppa_projects/${row.original.name}`
            : `/v2/dashboard_meter/?book=${row.original.name}`
        }
        onClick={(e) => e.stopPropagation()}
      >
        <Button floating variant="primary-1" icon="Eye" />
      </a>

      <Restricted permissions={['update:book']}>
        <Button
          floating
          className="ml-1"
          variant="primary-2"
          onClick={() => selectBook(row.original.uuid)}
          icon="Edit3"
          data-cy="edit-book"
        />
      </Restricted>

      <Restricted permissions={['create:book']}>
        <Button
          floating
          className="ml-1"
          variant="secondary"
          onClick={() => onDelete(row.original.uuid)}
          icon="Trash2"
          data-cy="delete-book"
        />
      </Restricted>
    </>
  );
};
