import { useCallback } from 'react';
import matchSorterInstanceStrict from '@utils/matchSorterInstanceStrict';
import MarketPlayer from '@utils/types/market_player';
import cloneDeep from 'lodash/cloneDeep';
import { OwnerFiltersType } from './OwnerFilters';

export const useOwnerFilters = (allOwners: MarketPlayer[] | null) => {
  const filterOwners = useCallback(
    (params: OwnerFiltersType) => {
      if (Object.values(params).every((value) => value === '')) {
        return allOwners || [];
      }

      let filteredOwners = cloneDeep(allOwners || []);

      if (params.company_number) {
        filteredOwners = matchSorterInstanceStrict(filteredOwners, params.company_number, {
          keys: ['company_number'],
        });
      }

      if (params.erp_code) {
        filteredOwners = matchSorterInstanceStrict(filteredOwners, params.erp_code, {
          keys: ['erp_code'],
        });
      }

      if (params.company_name) {
        filteredOwners = matchSorterInstanceStrict(filteredOwners, params.company_name, {
          keys: ['short_name', 'long_name'],
        });
      }

      return filteredOwners;
    },
    [allOwners],
  );

  return filterOwners;
};
