import React from 'react';
import { Input } from '@GDM/forms/Input/Input';
import { useFormContext, Controller } from 'react-hook-form';
import { CreateModalForm } from '../../CreateModal/types';
import styles from '../../unavailabilities.module.scss';

export const PmaxInput: React.FC = () => {
  const { control } = useFormContext<CreateModalForm>();

  return (
    <Controller
      control={control}
      name="p_max"
      render={({ field }) => (
        <Input
          onChange={field.onChange}
          value={field.value ?? ''}
          type="number"
          autoComplete="off"
          label="monitoring.unavailabilities.p_max"
          suffix="MW"
          step={0.001}
          className={styles['pmax-input']}
        />
      )}
    />
  );
};
