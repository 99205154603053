import React, { useMemo, useState } from 'react';
import { MeterNameCell } from '@components/ui/table/cell/MeterNameCell';
import {
  DateCell,
  HeadCell,
  NumberCell,
  Table,
  TableActions,
  TableBody,
  TableHead,
  TablePageSizeSelect,
  TablePagination,
} from '@GDM/Table';
import {
  getCoreRowModel,
  useReactTable,
  type ColumnDef,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  type TableState,
} from '@tanstack/react-table';
import { getSymbol } from '@utils/currency/getSymbol';
import { granularityFormat, type Granularity } from './constants/granularities';
import type { GroupBy, NegativeHour } from './helpers/getFormattedNegativeHours';

export const GroupedByDateNegativePricesTable = ({
  negativeHours,
  isLoading,
  granularity,
  groupBy,
}: {
  negativeHours?: NegativeHour[];
  isLoading: boolean;
  granularity: Granularity;
  groupBy: GroupBy;
}) => {
  const columns = useColumns(granularity);
  const data = negativeHours ?? [];
  const [pagination, setPagination] = useState<TableState['pagination']>({ pageIndex: 0, pageSize: 50 });
  const sorting = useMemo<TableState['sorting']>(
    () => [groupBy === 'date' ? { id: 'startDate', desc: false } : { id: 'installation', desc: false }],
    [groupBy],
  );
  const columnVisibility = useMemo<TableState['columnVisibility']>(
    () => ({ installation: groupBy === 'installation', startDate: groupBy === 'date' }),
    [groupBy],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    state: { pagination, columnVisibility, sorting },
  });

  return (
    <>
      <Table>
        <TableHead table={table} />
        <TableBody table={table} isLoading={isLoading} />
      </Table>

      <TableActions>
        <TablePageSizeSelect
          pageSize={pagination.pageSize}
          setPageSize={table.setPageSize}
          totalEntries={data.length}
        />
        <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />
      </TableActions>
    </>
  );
};

const useColumns = (granularity: Granularity) => {
  return useMemo(
    () =>
      [
        {
          accessorKey: 'startDate',
          header: 'common.date',
          cell: (row) => <DateCell getValue={row.getValue} format={granularityFormat[granularity]} />,
        },
        {
          id: 'installation',
          accessorKey: 'installation.name',
          header: 'common.installation',
          cell: ({ row: { original } }) =>
            original.installation ? (
              <MeterNameCell
                installation_name={original.installation.name}
                installation_energy={original.installation.energy}
              />
            ) : null,
        },
        {
          accessorKey: 'negativePriceHours',
          header: () => <HeadCell label="common.n_negative_price_hours" className="text-wrap" />,
          cell: (row) => <NumberCell getValue={row.getValue} />,
        },
        {
          accessorKey: 'productionHours',
          header: () => <HeadCell label="common.n_negative_price_prod_hours" className="text-wrap" />,
          cell: (row) => <NumberCell getValue={row.getValue} />,
        },
        {
          accessorKey: 'interruptionHours',
          header: () => <HeadCell label="common.n_interruption_hours_on_negative_price" className="text-wrap" />,
          cell: (row) => <NumberCell getValue={row.getValue} />,
        },
        {
          accessorKey: 'productionOnNegativeHours',
          header: () => <HeadCell label="common.prod_during_negative_price" tooltip="kWh" className="text-wrap" />,
          cell: (row) => <NumberCell getValue={row.getValue} />,
        },
        {
          id: 'estimatedInterruptedProduction',
          accessorFn: (row) => row.productibleOnNegativeHours - row.productionOnNegativeHours,
          header: () => (
            <HeadCell
              label="common.estimated_interrupted_production"
              tooltip="common.estimated_interrupted_production_tooltip"
            />
          ),
          cell: (row) => <NumberCell getValue={row.getValue} />,
        },
        {
          accessorKey: 'estimatedCostOnNegativeHours',
          header: () => (
            <HeadCell label="common.estimated_cost_during_negative_price_hours" tooltip={getSymbol('EUR')} />
          ),
          cell: (row) => <NumberCell getValue={row.getValue} />,
        },
      ] satisfies ColumnDef<NegativeHour>[],
    [granularity],
  );
};
