import React from 'react';
import { Button, DropDownButton } from '@GDM/Button';
import { useLocalStorage } from '@hooks/useLocalStorage';

export const PageActions: React.FC = () => {
  const { set, clear } = useLocalStorage('STREEM_SKINS');

  const setValue = (value: string) => {
    set(value);
    location.reload();
  };

  const clearValue = () => {
    clear();
    location.reload();
  };

  return (
    <DropDownButton icon="Gift" size="xs" variant="outline">
      <Button variant="sub-button" text="None" onClick={() => clearValue()} />
      <Button variant="sub-button" text="Summer" onClick={() => setValue('summer')} />
      <Button variant="sub-button" text="Pride" onClick={() => setValue('pride')} />
      <Button variant="sub-button" text="Halloween" onClick={() => setValue('halloween')} />
      <Button variant="sub-button" text="Xmas" onClick={() => setValue('xmas')} />
    </DropDownButton>
  );
};
