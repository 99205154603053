import React, { useCallback, useContext } from 'react';
import { buyersContext } from '@context/buyers.context';
import { useConsumeContracts } from '@context/contracts.context';
import { useDynamicOptions } from '@GDM/Filters';
import { Select } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Option } from '@utils/types/common-types';
import { Contract } from '@utils/types/contract';
import MarketPlayer from '@utils/types/market_player';
import { Controller, useFormContext } from 'react-hook-form';
import { ContractsFiltersType } from './ContractFilters';

const isMarketPlayer = (marketPlayer: MarketPlayer | undefined): marketPlayer is MarketPlayer => !!marketPlayer;

export const BuyerFilter: React.FC = () => {
  const { t } = useTranslation();
  const form = useFormContext<ContractsFiltersType>();
  const { filterContracts, contracts, allContracts } = useConsumeContracts();
  const { buyers } = useContext(buyersContext);

  const getOptionsCallback = useCallback(
    (contracts: Contract[]) => {
      const userBuyersIds = Array.from(new Set(contracts.flatMap((c) => c.buyer?.id))).filter((id) => id);

      const userBuyers = userBuyersIds.map((id) => buyers.find((o) => o.id === id)).filter(isMarketPlayer);

      const options: Option<string>[] = userBuyers
        ?.map((ot) => ({ label: ot?.short_name || ot?.long_name || '', value: ot?.id }))
        .sort(sortOptionsByLabelAsc);

      return options;
    },
    [buyers],
  );

  const options: Option<string>[] = useDynamicOptions(getOptionsCallback, 'buyer_id', contracts, allContracts);

  return (
    <Controller
      name="buyer_id"
      control={form.control}
      render={({ field }) => (
        <Select
          {...field}
          isMulti
          classNamePrefix="contracts-filters-buyer-select"
          placeholder={t('sales_management.buyer')}
          selectedOptions={field.value}
          options={options}
          inline
          isClearable
          onChange={(opt) => {
            const values = opt?.map((o) => o.value) ?? [];

            filterContracts({ ...form.getValues(), buyer_id: values, last_selected_filter: 'buyer_id' });
            field.onChange(values);
            form.setValue('last_selected_filter', values.length > 0 ? 'buyer_id' : null);
          }}
        />
      )}
    />
  );
};
