import React from 'react';
import { Progress } from '@GDM/Progress';

type Props = {
  value?: number;
  getValue?: () => unknown;
};

const ProgressPercentCell: React.FC<Props> = ({ value, getValue }) => {
  value = value || (getValue?.() as number) || 0;

  if (value > 100) {
    throw new Error('ProgressPercentCell value must be equal or less than 100');
  }

  return <Progress value={value} variant="good" />;
};

export default ProgressPercentCell;
