import React from 'react';
import { Icon } from '@GDM/Icon';
import { Col, Row } from '@GDM/layout';
import useTranslation from '@hooks/useTranslation';

export const CompareChange: React.FC<{ oldValue: React.ReactNode; newValue: React.ReactNode; label: string }> = ({
  oldValue,
  newValue,
  label,
}) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col sm={12}>
        <Row>
          <Col sm={2}>
            <b>{t(label)}</b>
          </Col>

          <Col sm={4} className="text-nowrap text-truncate">
            {oldValue}
          </Col>

          <Col sm={1}>
            <Icon name="ArrowRight" size={14} />
          </Col>

          <Col sm={4}>{newValue}</Col>
        </Row>
      </Col>
    </Row>
  );
};
