import React, { useMemo, useState } from 'react';
import { User } from '@utils/types/user';
import { PricingMapsFilters } from './utils/pricing-maps.types';
import { pricingMapsFiltersContext } from './utils/pricingMapsFilters.context';
import { useSelectedPricingMapContext } from './utils/selectedPricingMap.context';

export const PricingFiltersProvider: React.FC<React.PropsWithChildren<{ user: User }>> = ({ children, user }) => {
  const { selectedPricingMap, allPeriods } = useSelectedPricingMapContext();
  const [filters, setFilters] = useState<PricingMapsFilters>({
    country: user.geolocation.filter(Boolean)[0],
    result: 'deviation',
    run: '',
    periodId: null,
  });

  if (selectedPricingMap && filters.run && parseInt(filters.run) !== selectedPricingMap.id) {
    setFilters({ ...filters, run: selectedPricingMap.id.toString() });
  }

  if (!filters.periodId && allPeriods.length) {
    const periodId = Number(allPeriods[allPeriods.length - 1]?.value) || null;

    setFilters({ ...filters, periodId });
  }

  const value = useMemo(
    () => ({
      filters,
      setFilters,
    }),
    [filters, setFilters],
  );

  return <pricingMapsFiltersContext.Provider value={value}>{children}</pricingMapsFiltersContext.Provider>;
};
