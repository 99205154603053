import React, { useContext } from 'react';
import { useBooksContext } from '@context/BooksContext';
import { BookSelect } from '@ui/BookSelect';
import intersection from 'lodash/intersection';
import { Controller, ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form';
import { unavailabilityFiltersContext } from './context';
import { UnavailabilitiesFiltersType } from './type/filters';

export const BookFilter: React.FC = () => {
  const form = useFormContext<UnavailabilitiesFiltersType>();
  const { filteredUnavailabilities } = useContext(unavailabilityFiltersContext);
  const { books } = useBooksContext();
  const handleBookChange = (field: ControllerRenderProps<FieldValues, 'bookUuid'>) => (uuid: string | null) => {
    field.onChange(uuid);
  };

  const allInstallationNames = filteredUnavailabilities
    ? filteredUnavailabilities.map((unavailability) => unavailability.installation.name)
    : [];
  const relevantBooks = books
    ? books.filter((book) => intersection(book.installation_names, allInstallationNames).length > 0)
    : [];

  return (
    <Controller
      name="bookUuid"
      render={({ field }) => {
        return <BookSelect books={relevantBooks} value={form.watch('bookUuid')} onChange={handleBookChange(field)} />;
      }}
    />
  );
};
