import React, { useState } from 'react';
import { DatePicker } from '@GDM/DatePicker';
import { useContractCaps } from '../useContractCaps';

export const DateSelector: React.FC = () => {
  const { startDate, setStartDate, endDate, setEndDate } = useContractCaps();
  const [localDates, setLocalDates] = useState<[Date | null, Date | null]>([startDate, endDate]);

  const handleDatesChange = (dateRange: [Date | null, Date | null]): void => {
    if (Array.isArray(dateRange)) {
      const [startDate, endDate] = dateRange;

      setLocalDates([startDate, endDate]);

      if (startDate && endDate) {
        setStartDate?.(startDate);
        setEndDate?.(endDate);
      }
    }
  };

  return (
    <DatePicker
      startDate={localDates[0]}
      endDate={localDates[1]}
      maxDate={new Date()}
      picks="months"
      onChange={handleDatesChange}
      selectsRange={true}
    />
  );
};
