import React from 'react';
import classNames from 'classnames';
import styles from './table.module.scss';

export const Table: React.FC<
  React.PropsWithChildren<{
    className?: string;
    hasActions?: boolean;
    stickyFirstColumn?: boolean;
    'data-cy'?: string;
  }>
> = ({ children, className, hasActions, 'data-cy': dataCy, stickyFirstColumn }) => {
  return (
    <div
      className={classNames(
        styles['table-container'],
        className,
        hasActions && 'has-actions',
        stickyFirstColumn && 'is-sticky-first-column',
      )}
    >
      <table className={styles.table} data-cy={dataCy}>
        {children}
      </table>
    </div>
  );
};
