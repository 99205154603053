import React, { useContext } from 'react';
import { Select } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { Option } from '@utils/types/common-types';
import { UnavailabilityStatus } from '@utils/types/unavailability';
import intersection from 'lodash/intersection';
import uniq from 'lodash/uniq';
import { Controller, useFormContext } from 'react-hook-form';
import { unavailabilityFiltersContext } from './context';
import { UnavailabilitiesFiltersType } from './type/filters';

export const StatusFilter: React.FC = () => {
  const { t } = useTranslation();
  const { filteredUnavailabilities } = useContext(unavailabilityFiltersContext);
  const form = useFormContext<UnavailabilitiesFiltersType>();

  const existingStatus: UnavailabilityStatus[] = uniq(
    filteredUnavailabilities.map((filteredUnavailabilities) => filteredUnavailabilities.status),
  );
  const allStatus: UnavailabilityStatus[] = ['planned', 'ongoing', 'done', 'cancelled'];

  const options: Option[] = [
    ...intersection(allStatus, existingStatus).map((status) => ({
      label: t(`monitoring.unavailabilities.status.${status}`),
      value: status,
    })),
  ];

  return (
    <Controller
      name="status"
      control={form.control}
      render={({ field }) => (
        <Select
          placeholder="common.status_label"
          options={options}
          selectedOption={field.value}
          onChange={(opt) => field.onChange(opt?.value)}
          isClearable
        />
      )}
    />
  );
};
