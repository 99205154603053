import React, { useMemo } from 'react';
import { Option } from '@utils/types/common-types';
import { ContractForm } from '@utils/types/contract';
import MarketPlayer from '@utils/types/market_player';
import { Control } from 'react-hook-form';
import { ListInput } from '../../../components/Inputs/List';

export const CppaMarketPlayers = ({
  control,
  isDisabled,
  readonly,
  isLoading,
  options,
}: {
  options: (Option<string> & { type: MarketPlayer['player_type'] })[];
  control: Control<ContractForm>;
  isDisabled: boolean;
  readonly: boolean | undefined;
  isLoading: boolean;
}) => {
  const balancingResponsiblePartyOptions = useMemo(() => {
    return options.filter(({ type }) => type === 'offtaker');
  }, [options]);

  return (
    <>
      <ListInput
        id="AdditionalBuyerPicker"
        name="additional_buyer_id"
        control={control}
        options={options}
        isLoading={isLoading}
        label="contracts.final_buyer"
        isDisabled={isDisabled}
        classNamePrefix="select-buyer"
        readOnly={readonly}
        isSearchable
      />
      <ListInput
        id="BalancingResponsiblePartyPicker"
        name="balancing_responsible_party_id"
        control={control}
        options={balancingResponsiblePartyOptions}
        isLoading={isLoading}
        label="contracts.brp"
        isDisabled={isDisabled}
        classNamePrefix="select-buyer"
        readOnly={readonly}
        isSearchable
        tooltip="contracts.brp_info"
      />
    </>
  );
};
