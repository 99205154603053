import React from 'react';
import { useUser } from '@context/User.context';
import { useTabs } from '@GDM/Tabs';
import { ContractsTab } from 'pages/ui/ContractsTab';
import { Invoices } from 'pages/ui/dashboard-meter/invoices';
import { Events } from 'pages/ui/shared/events';
import { TabNames } from './TabNav';
import { Characteristics, ContractCaps, IndexTab, M0Deviation, ProductionTab, Revenue } from './Tabs';
import { NegativePricesTab } from './Tabs/NegativePrices/NegativePricesTab';
import { useDashboardMeter } from './useDashboardMeter';

export const TabContainer: React.FC = () => {
  const { currentTab } = useTabs<TabNames>();
  const { book, meter, installation } = useDashboardMeter();
  const user = useUser();
  const identifier = book?.uuid || meter?.name || installation?.name;

  const TabComponents: Record<TabNames, JSX.Element> = {
    [TabNames.PRODUCTION]: <ProductionTab />,
    [TabNames.REVENUE]: <Revenue sourceType={book ? 'book' : 'installation'} identifier={identifier} />,
    [TabNames.NEGATIVE_PRICES]: <NegativePricesTab />,
    [TabNames.CONTRACTS_CAPS]: <ContractCaps />,
    [TabNames.EVENTS]: (
      <Events eventableId={identifier} eventableType={book ? 'Book' : meter ? 'Installation' : undefined} user={user} />
    ),
    [TabNames.INDEX]: <IndexTab />,
    [TabNames.M0]: <M0Deviation meter={meter} book={book} />,
    [TabNames.CONTRACTS]: (
      <ContractsTab meterId={meter?.id?.toString()} installationId={installation?.uuid} bookId={book?.uuid} />
    ),
    [TabNames.INVOICES]: <Invoices user={user} />,
    [TabNames.CHARACTERISTICS]: <Characteristics />,
  };

  return currentTab ? TabComponents[currentTab] : null;
};
