import React from 'react';
import { useUser } from '@context/User.context';
import { Chart } from '@GDM/Chart';
import useTranslation from '@hooks/useTranslation';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import { appendColon } from '@utils/string';
import dayjs from 'dayjs';
import styles from './charts.module.scss';

export const HourlyAnalysisChart: React.FC = () => {
  const project = useProjectContext();
  const { t } = useTranslation();
  const user = useUser();
  const { locale } = user;

  const { hourly_shape } = project?.result || {};

  if (!hourly_shape?.difference?.length || !hourly_shape?.meter?.length || !hourly_shape?.national?.length) return null;

  const options: Highcharts.Options = {
    legend: { enabled: false },
    yAxis: {
      title: { text: t('pricing.project.standard_production') },
    },
    xAxis: {
      title: {
        text: t('common.hour'),
      },
      endOnTick: true,
      labels: { step: 1 },
      categories: [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
      ],
      gridLineWidth: 0,
      crosshair: { width: 1 },
    },
    tooltip: {
      formatter: function () {
        return `${dayjs()
          .set('hour', Number(this.x) || 0)
          .set('minute', 0)
          .format('HH:mm')}<br/>${(this.points || [])
          .map(
            (point) =>
              `<span style="color: ${point.color}">●</span> ${appendColon(
                point.series.name,
                locale,
              )} <b>${new Intl.NumberFormat(locale, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(point.y)}</b>`,
          )
          .join('<br/>')}`;
      },
    },
  };
  const series: Highcharts.Options['series'] = [
    {
      name: t('pricing.project.local'),
      type: 'line',
      data: hourly_shape.meter,
    },
    {
      name: t('pricing.project.national'),
      type: 'line',
      data: hourly_shape.national,
    },
    {
      name: t('pricing.project.difference'),
      type: 'line',
      data: hourly_shape.difference,
    },
  ];

  return (
    <div className={styles.chart}>
      <Chart chartType="line" series={series} options={options} />
    </div>
  );
};
