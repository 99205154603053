import React from 'react';
import { DefinitionModalBody, DefinitionModalFooter } from '@GDM/DefinitionList';
import { Input } from '@GDM/forms';
import { post, useRequest } from '@hooks/useRequest';
import { change_erp_code_v2_dashboard_meter_index_path } from '@utils/routes';
import { Meter } from '@utils/types/meter';
import { useMeterInfo } from '../useMeterInfo';

export const ErpCodeModal: React.FC<{ meter?: Meter }> = ({ meter }) => {
  const { erpCode, setErpCode } = useMeterInfo();

  const { loading: isLoading, execute } = useRequest(
    change_erp_code_v2_dashboard_meter_index_path(),
    ({ url }) =>
      post<Record<string, never>, { meter_name?: string; erp_code?: string }>({
        url,
        body: {
          meter_name: meter?.name,
          erp_code: erpCode,
        },
      }),
    true,
  );

  return (
    <>
      <DefinitionModalBody>
        <Input value={erpCode} onChange={(e) => setErpCode?.(e.target.value)} full />
      </DefinitionModalBody>
      <DefinitionModalFooter save={execute} isLoading={isLoading} />
    </>
  );
};
