import React from 'react';
import { Badge } from '@GDM/Badge';
import { Icon } from '@GDM/Icon';
import { Tooltip } from '@GDM/Tooltip/Tooltip';
import useTranslation from '@hooks/useTranslation';
import { CellContext } from '@tanstack/react-table';
import getContractTypeLabel from '@utils/contract/getLabel';
import { MeterInvoice } from '@utils/types/meter-invoice';
import classNames from 'classnames';
import styles from './contract-cell.module.scss';

export const ContractCell: React.FC<CellContext<MeterInvoice, unknown>> = ({ row }) => {
  const { t } = useTranslation();
  let tooltip = '';

  tooltip += row.original.contract_card_i ? `${t('sales_management.card_i')} : ${row.original.contract_card_i}` : '';
  tooltip += row.original.contract_card_i && row.original.installation_erp_code ? '<br/>' : '';
  tooltip += row.original.contract_erp_code
    ? `${t('sales_management.erp_code')} : ${row.original.contract_erp_code}`
    : '';

  // TODO: HACK FOR SPANISH ACCOUNT
  const contractTypeLabel = getContractTypeLabel(row.original.contract_type, row.original.installation_country);

  return (
    <div>
      <div className="d-flex align-items-center">
        <Badge label={contractTypeLabel} />
        {row.original.power_augmentation_percentage && (
          <Badge className="ml-2" tooltip="sales_management.power_augmentation_title">
            {row.original.power_augmentation_percentage}%
          </Badge>
        )}
        <Icon
          size={14}
          name={row.original.installation_country || 'XX'}
          title={row.original.installation_country}
          className={classNames({ ['ml-2']: !!contractTypeLabel })}
        />
      </div>
      {row.original.contract_buyer_short_name ? (
        <Tooltip tooltip={tooltip}>
          <div className={styles['contract-cell-buyer']}>
            <Icon name="ArrowRightCircle" className="mr-2" size={14} />
            <div data-cy="cell-buyer-name">{row.original.contract_buyer_short_name}</div>
          </div>
        </Tooltip>
      ) : undefined}
    </div>
  );
};
