import React from 'react';
import { RadioButtons } from '@GDM/forms';
import { EnergyType, Option } from '@utils/types/common-types';
import { Controller, useFormContext } from 'react-hook-form';
import { UnavailabilitiesFiltersType } from './type/filters';

export const EnergyFilter: React.FC = () => {
  const form = useFormContext<UnavailabilitiesFiltersType>();

  const ENERGY_OPTIONS: Option<EnergyType | 'all'>[] = [
    { label: 'common.all', value: 'all' },
    { label: 'common.wind.eolian', value: 'wind' },
    { label: 'common.pv.solar', value: 'solar' },
    { label: 'common.hydro.short', value: 'hydro' },
  ];

  return (
    <Controller
      name="energy"
      control={form.control}
      render={({ field }) => (
        <RadioButtons
          name="energy-picker"
          options={ENERGY_OPTIONS}
          selected={field.value}
          onChange={(opt) => {
            field.onChange(opt);
          }}
        />
      )}
    />
  );
};
