import React from 'react';
import { HelpBox as HelpBoxComponent } from '@GDM/HelpBox';
import useTranslation from '@hooks/useTranslation';
import styles from './negative-prices.module.scss';

export const HelpBox = ({ onDismiss }: { onDismiss: () => void }) => {
  const { t } = useTranslation();

  return (
    <HelpBoxComponent className="mb-2" onDismiss={onDismiss} dismissible>
      <ul className={styles['help-list']}>
        <li>{t('sales_management.negative_price.help_box.point1')}</li>
      </ul>
      <ul className={styles['help-list']}>
        <li>{t('sales_management.negative_price.help_box.point2')}</li>
      </ul>
    </HelpBoxComponent>
  );
};
