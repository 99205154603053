import React from 'react';
import { RadioButtons } from '@GDM/forms';
import { energyOptions } from '@utils/constants/energyOptions';
import { Controller, useFormContext } from 'react-hook-form';
import { useFieldRules } from '../../utils/getFieldRules';
import { SimulationForm } from '../../utils/simulations.types';

export const EnergyInput: React.FC<{ disabled: boolean }> = ({ disabled }) => {
  const options = energyOptions.filter((opt) => !['all', 'other', 'hydro'].includes(opt.value));
  const form = useFormContext<SimulationForm>();
  const { fieldRules } = useFieldRules();
  const rules = fieldRules('installation_attributes.energy');

  return (
    <Controller
      control={form.control}
      name="installation_attributes.energy"
      rules={rules}
      render={({ field }) => (
        <div>
          <RadioButtons
            disabled={disabled}
            label="common.energy"
            size="lg"
            selected={field.value}
            onChange={(value) => field.onChange(value)}
            options={options}
            name="energy"
          />
        </div>
      )}
    />
  );
};
