import React from 'react';
import { AltContent } from '@GDM/AltContent';
import { Chart } from '@GDM/Chart';
import useDafr from '@hooks/requests/useDafr';
import useTranslation from '@hooks/useTranslation';
import { useDashboardMeter } from '@pages/DashboardMeter/useDashboardMeter';
import { MeterInvoice } from '@utils/types/meter-invoice';
import Highcharts from 'highcharts/highstock';
import round from 'lodash/round';
import { dateFormat } from '../helpers';
import { useContractCaps } from '../useContractCaps';
import { DafrChart } from './DafrChart';

export const AnnualCapChart: React.FC = () => {
  const { meter } = useDashboardMeter();
  const { invoices } = useContractCaps();
  const { t } = useTranslation();
  const { data: dafr, error: isDafrError } = useDafr(meter?.name);

  const filteredInvoices = invoices
    ?.filter((d: MeterInvoice) => d?.contract_type == 'ContractOa' || d?.contract_type == 'ContractCrEdf')
    .filter((d: MeterInvoice) => d?.production_annual && d?.start_date);

  const annual_caps = filteredInvoices?.map((d) => d?.annual_cap).filter((d) => d);
  const annual_cap =
    annual_caps && annual_caps.length > 0 && annual_caps[0] && annual_caps[0] > 0 ? annual_caps[0] : null;

  const startDates = filteredInvoices?.map((d: MeterInvoice) => d.start_date);
  const startDatesUniq = Array.from(new Set(startDates));
  const categories = startDatesUniq.sort().map((start_date: string) => dateFormat(start_date));

  const data = categories.map((category) => {
    const invoice = filteredInvoices?.find((d) => category == dateFormat(d.start_date));

    return invoice?.production_annual ? round(invoice.production_annual, 2) : 0;
  });

  const yAxisMax = annual_cap ? Math.max(...data, annual_cap) : null;

  const series: Highcharts.Options['series'] = [
    {
      name: t('common.contracts_caps'),
      type: 'spline',
      data: data,
      color: 'var(--primary-1)',
    },
  ];

  const options: Highcharts.Options = {
    xAxis: { categories },
    yAxis: {
      title: { text: 'kWh' },
      opposite: true,
      max: yAxisMax,
      plotLines: [
        {
          value: annual_cap || 0,
          dashStyle: 'ShortDash',
          width: 2,
        },
      ],
    },
  };

  const dafrExists = dafr && dafr.length > 0;
  const annualCapExists = data?.length > 0;

  return (
    <>
      {dafrExists && <DafrChart dafr={dafr} />}

      {!dafrExists && annualCapExists && <Chart series={series} options={options} hideLegend />}

      {!dafrExists && (!annualCapExists || isDafrError) && (
        <AltContent data-cy="no-dafr-info" label="monitoring.installation.no_dafr" />
      )}
    </>
  );
};
