import React, { useMemo } from 'react';
import { useRequest } from '@hooks/useRequest';
import { simulations_v2_books_path, simulations_v2_ppa_simulations_path } from '@utils/routes';
import { Simulation } from '@utils/types/simulation';
import { SimulationResponse, SimulationsContext } from './context.types';
import { simulationsContext } from './simulations.context';

export const SimulationsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const {
    data,
    loading: simulationsLoading,
    error: simulationsError,
  } = useRequest<SimulationResponse>(simulations_v2_ppa_simulations_path(), 'GET');
  const {
    data: bookSimulations,
    loading: bookSimulationsLoading,
    error: bookSimulationsError,
  } = useRequest<Simulation[]>(simulations_v2_books_path(), 'GET');

  const loading = simulationsLoading || bookSimulationsLoading;
  const error = simulationsError?.message || bookSimulationsError?.message;

  const contextValue = useMemo<SimulationsContext>(
    () => ({
      loading,
      error,
      bookSimulations,
      fullMerchantSimulations: data?.full_merchant_simulations ?? null,
      marketSimulations: data?.market_simulations ?? null,
    }),
    [bookSimulations, data?.full_merchant_simulations, data?.market_simulations, error, loading],
  );

  return <simulationsContext.Provider value={contextValue}>{children}</simulationsContext.Provider>;
};
