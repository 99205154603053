import React from 'react';
import Page from '@pages/Page';
import { User } from '@utils/types/user';
import { Header } from './Header';
import { SubscriptionForm } from './SubscriptionForm';
import { SubscriptionFormProvider } from './SubscriptionFormProvider';

const New: React.FC<{ user: User }> = ({ user }) => {
  return (
    <SubscriptionFormProvider>
      <Page
        titleIcon="UserPlus"
        title="subscriptions.new.title"
        user={user}
        pageActions={<Header />}
        layout="no-background"
        fixedHeightContainer
      >
        <SubscriptionForm />
      </Page>
    </SubscriptionFormProvider>
  );
};

export default New;
