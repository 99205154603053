import React, { useMemo, useState } from 'react';
import { useBooksContext } from '@context/BooksContext';
import { useInstallationsContext } from '@context/installations.context';
import { useUnavailabilitiesContext } from '@context/UnavailabilitiesContext';
import matchSorterInstanceStrict from '@utils/matchSorterInstanceStrict';
import { Book } from '@utils/types/book';
import { Installation } from '@utils/types/installation';
import { Unavailability } from '@utils/types/unavailability';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import { useForm } from 'react-hook-form';
import { unavailabilityFiltersContext } from './context';
import { UnavailabilitiesFiltersType } from './type/filters';

const filterUnavailabilities: (
  filters: UnavailabilitiesFiltersType,
  unavailabilities: Unavailability[],
  books: Book[] | null,
) => Unavailability[] = (filters, unavailabilities, books) => {
  if (!unavailabilities) return [];

  let clonedUnavailabilities = cloneDeep(unavailabilities);
  if (filters.installationName && filters.installationName.length > 0) {
    clonedUnavailabilities = matchSorterInstanceStrict(clonedUnavailabilities, filters.installationName, {
      keys: ['installation.name'],
    });
  }

  clonedUnavailabilities = clonedUnavailabilities.filter((unavailability) => {
    const { endDate, energy, event, startDate, status } = filters;

    const isOutsideDates =
      startDate &&
      endDate &&
      (dayjs(unavailability.start_date).toDate() > endDate || dayjs(unavailability.end_date).toDate() < startDate);

    const isTheWrongEnergyType = energy !== 'all' && unavailability.installation.energy !== energy;
    const isTheWrongEventType = event && unavailability.event_type !== event;
    const isTheWrongStatus = status && unavailability.status !== status;
    const isNotInBook =
      filters.bookUuid &&
      !books?.find((b) => b.uuid === filters.bookUuid)?.installation_names?.includes(unavailability.installation.name);

    if (isOutsideDates || isTheWrongEnergyType || isTheWrongEventType || isTheWrongStatus || isNotInBook) return false;

    return true;
  });

  return clonedUnavailabilities;
};

const filterInstallations: (
  filters: UnavailabilitiesFiltersType,
  installations: Installation[] | null,
  books: Book[] | null,
) => Installation[] = (filters, installations, books) => {
  if (!installations) return [];

  let clonedInstallations = cloneDeep(installations);
  if (filters.installationName && filters.installationName.length > 0) {
    clonedInstallations = matchSorterInstanceStrict(clonedInstallations, filters.installationName, {
      keys: ['installation.name'],
    });
  }

  clonedInstallations = clonedInstallations.filter((installation) => {
    if (filters.energy != 'all' && installation.energy != filters.energy) return false;
    if (
      filters.bookUuid &&
      !books?.find((b) => b.uuid === filters.bookUuid)?.installation_names?.includes(installation.name)
    )
      return false;

    return true;
  });

  return clonedInstallations;
};

export const UnavailabilityFiltersProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [chartVisible, setChartVisible] = useState(false);
  const { books } = useBooksContext();
  const { installations } = useInstallationsContext();
  const { unavailabilities } = useUnavailabilitiesContext();
  const form = useForm<UnavailabilitiesFiltersType>({
    defaultValues: {
      startDate: dayjs().subtract(3, 'month').toDate(),
      endDate: dayjs().add(3, 'month').toDate(),
      energy: 'all',
      event: null,
      status: null,
      bookUuid: null,
      installationName: null,
    },
  });
  const { watch } = form;
  const filters = watch();

  const filteredUnavailabilities = useMemo(
    () => filterUnavailabilities(filters, unavailabilities, books),
    [filters, unavailabilities, books],
  );

  const filteredInstallations = useMemo(
    () => filterInstallations(filters, installations, books),
    [filters, installations, books],
  );

  const toggleChart = () => setChartVisible((s) => !s);

  const contextValue = useMemo(
    () => ({ form, filteredUnavailabilities, filteredInstallations, chartVisible, toggleChart }),
    [chartVisible, filteredInstallations, filteredUnavailabilities, form],
  );

  return <unavailabilityFiltersContext.Provider value={contextValue}>{children}</unavailabilityFiltersContext.Provider>;
};
