import React, { useMemo } from 'react';
import { useConsumeContracts } from '@context/contracts.context';
import { Modal } from '@GDM/Modal';
import useTranslation from '@hooks/useTranslation';

type ContractConfirmDeleteModalProps = {
  id: string;
  isOpen: boolean;
  toggle: () => void;
  handleDelete: () => void;
  error?: string;
  isLoading?: boolean;
};

export const ContractConfirmDeleteModal: React.FC<ContractConfirmDeleteModalProps> = ({
  id,
  isOpen,
  toggle,
  handleDelete,
  error,
  isLoading,
}) => {
  const { contracts } = useConsumeContracts();
  const contract = useMemo(() => contracts.find((c) => c.id === id), [contracts, id]);
  const { t } = useTranslation();

  if (!contract) return null;

  return (
    <Modal
      data-cy="delete-contract-modal"
      isOpen={isOpen}
      toggle={toggle}
      header="sales_management.delete_contract"
      submitAction={handleDelete}
      saveButtonText="common.delete"
      error={error}
      isLoading={isLoading}
    >
      {t('sales_management.delete_contract_confirmation')} {!!contract.contract_nb && <b>{contract.contract_nb}</b>}
    </Modal>
  );
};
