import React from 'react';
import { Icon } from '@GDM/Icon';
import { ValueWithUnit } from '@GDM/ValueWithUnit';

export const Nowcast: React.FC<{ sum_realtime_portfolio?: number; real_time_ratio?: number }> = ({
  sum_realtime_portfolio,
  real_time_ratio,
}) => {
  if (sum_realtime_portfolio === undefined || real_time_ratio === undefined) return null;

  return (
    <>
      <Icon size={14} name="Zap" className="mb-1" />
      &nbsp;Nowcast&nbsp;
      <ValueWithUnit data-cy="sum-realtime" value={sum_realtime_portfolio} unit="kW" unstyled />
      &nbsp;<span data-cy="nowcast-realtime-ratio">({real_time_ratio?.toFixed(0) || 0}%)</span>
    </>
  );
};
