import React from 'react';
import { CellContext } from '@tanstack/react-table';
import { Contract } from '@utils/types/contract';
import dayjs from 'dayjs';

export const HorizonCell: React.FC<CellContext<Contract, unknown>> = ({ row: { original } }) => {
  const format = 'DD MMM YY';

  return (
    <div>
      {dayjs(original.start_date).format(format)} {'>'} {dayjs(original.end_date).format(format)}
    </div>
  );
};
