import React from 'react';
import { Select } from '@GDM/forms';
import { Col, Row } from '@GDM/layout';
import { useRequest } from '@hooks/useRequest';
import Page from '@pages/Page';
import { all_v2_users_path } from '@utils/routes';
import { Option } from '@utils/types/common-types';
import { User, UserInternal } from '@utils/types/user';
import { Links } from './Links';
import { PageActions } from './PageActions';
import { goToUser } from './utils';

/**
 * @prop `locale` {Locale}
 * @prop `users` `[id, email][]` an array containing the users id and email.
 */
export const Masquerade: React.FC<{ user: User }> = ({ user }) => {
  const { data: users, loading, error } = useRequest<UserInternal[]>(all_v2_users_path(), 'GET');
  const {
    data: adminUsers,
    loading: adminUsersLoading,
    error: adminUsersError,
  } = useRequest<UserInternal[]>(all_v2_users_path({ params: { admin_only: true } }), 'GET');

  const options: Option[] = !users ? [] : users.map(({ id, email }) => ({ label: email, value: id?.toString() }));
  const adminOptions: Option[] = !adminUsers
    ? []
    : adminUsers.map(({ id, email }) => ({ label: email, value: id?.toString() }));

  return (
    <Page
      title="use_sub_client.title"
      user={user}
      titleIcon="Users"
      error={error?.code || error?.message || (adminUsersError ? 'Admin users not loaded. Please reload.' : null)}
      isLoading={loading}
      layout="no-background"
      pageActions={<PageActions />}
      header={
        <Row>
          <Col xl={3}>
            <Select
              name="select-client"
              classNamePrefix="select-client"
              className="mb-3"
              label="use_sub_client.select_client"
              options={options}
              isDisabled={loading}
              onChange={(option) => goToUser(option?.value)}
              size="lg"
            />
          </Col>
          {!adminUsersError && (
            <Col xl={3}>
              <Select
                name="select-admin"
                classNamePrefix="select-admin"
                className="mb-3"
                label="use_sub_client.select_admin_user"
                options={adminOptions}
                isDisabled={adminUsersLoading}
                onChange={(option) => goToUser(option?.value)}
                size="lg"
              />
            </Col>
          )}
        </Row>
      }
    >
      {users && <Links users={users} />}
    </Page>
  );
};
