import React from 'react';
import { useUser } from '@context/User.context';
import { Chart } from '@GDM/Chart';
import useTranslation from '@hooks/useTranslation';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import { useSelectedYear } from '@pages/Simulations/Simulation/utils/selectedYear.context';
import { FullMerchant } from '@pages/Simulations/Simulation/utils/simulation.types';
import { formatNumber } from '@utils/formatters';
import { useCurrency } from '@utils/string';
import styles from './charts.module.scss';

export const YearlyAnalysisChart: React.FC = () => {
  const { t } = useTranslation();
  const { locale } = useUser();
  const project = useProjectContext();
  const { selectedYear } = useSelectedYear();
  const merchantDeviationsPerYear = project.full_merchant?.merchant_deviations_per_year || {};
  const currency = useCurrency();

  if (!selectedYear) return null;

  const currentYear: FullMerchant['merchant_deviations_per_year'][number] =
    Object.values(merchantDeviationsPerYear)[selectedYear as unknown as number];

  const localVsBaseloadYear = currentYear.loc_vs_base;
  const nationalVsBaseloadYear = currentYear.nat_vs_base;
  const forwardPrice = currentYear.forward_price;
  const localVsNationalYear = currentYear.loc_vs_nat;
  const options: Highcharts.Options = {
    chart: { type: 'waterfall' },
    title: { text: undefined },
    plotOptions: { waterfall: { grouping: false, shadow: false, borderWidth: 0 } },
    xAxis: { type: 'category' },
    yAxis: {
      title: { text: `${currency}/MWh` },
      min: Math.min(forwardPrice - 35, forwardPrice + nationalVsBaseloadYear.q25 + localVsNationalYear.q25 - 10),
    },
    tooltip: { pointFormatter: undefined, shared: false, pointFormat: '<b>{point.y:,.2f}</b> €/Mwh' },
    legend: { enabled: false },
  };

  const negativeChartColor = getComputedStyle(document.documentElement).getPropertyValue('--chart-red');
  const positiveChartColor = getComputedStyle(document.documentElement).getPropertyValue('--chart-grey');
  const series: Highcharts.Options['series'] = [
    {
      type: 'waterfall',
      color: negativeChartColor,
      upColor: positiveChartColor,
      opacity: 0.5,
      data: [
        { name: t('pricing.project.baseload'), y: forwardPrice },
        { name: t('pricing.project.nat_vs_bl'), y: nationalVsBaseloadYear.q25 },
        {
          name: t('pricing.project.local_vs_nat'),
          color: nationalVsBaseloadYear.q50 > 0 ? positiveChartColor : negativeChartColor,
          y: -nationalVsBaseloadYear.q25 + nationalVsBaseloadYear.q75 + localVsBaseloadYear.q75,
        },
        { name: t('pricing.project.local_capture_price'), isSum: true },
      ],
      dashStyle: 'Dot',
    },
    {
      type: 'waterfall',
      color: negativeChartColor,
      upColor: positiveChartColor,
      opacity: 0.5,
      data: [
        { name: t('pricing.project.baseload'), y: forwardPrice },
        { name: t('pricing.project.nat_vs_bl'), y: nationalVsBaseloadYear.q25 },
        { name: t('pricing.project.local_vs_nat'), y: localVsNationalYear.q25 },
        { name: t('pricing.project.local_capture_price'), isSum: true },
      ],
      dashStyle: 'Dot',
    },
    {
      type: 'waterfall',
      color: negativeChartColor,
      upColor: positiveChartColor,
      opacity: 0.5,
      data: [
        { name: t('pricing.project.baseload'), y: forwardPrice },
        { name: t('pricing.project.nat_vs_bl'), y: nationalVsBaseloadYear.q75 },
        { name: t('pricing.project.local_vs_nat'), y: localVsNationalYear.q75 },
        { name: t('pricing.project.local_capture_price'), isSum: true },
      ],
      dashStyle: 'Dot',
    },
    {
      type: 'waterfall',
      color: negativeChartColor,
      upColor: positiveChartColor,
      opacity: 0.9,
      data: [
        { name: t('pricing.project.baseload'), y: forwardPrice },
        { name: t('pricing.project.nat_vs_bl'), y: nationalVsBaseloadYear.q50 },
        { name: t('pricing.project.local_vs_nat'), y: localVsNationalYear.q50 },
        { name: t('pricing.project.local_capture_price'), isSum: true },
      ],
      dataLabels: {
        enabled: true,
        formatter: function () {
          return formatNumber(this.y, locale) + ` ${currency}/MWh`;
        },
        style: { fontWeight: 'bold' },
      },
      dashStyle: 'Solid',
      lineColor: positiveChartColor,
    },
  ];

  return (
    <div className={styles.chart}>
      <Chart chartType="waterfall" options={options} series={series} />
    </div>
  );
};
