import React from 'react';
import { DatePicker } from '@GDM/DatePicker';
import { useFieldRules } from '@pages/Simulations/NewSimulation/utils/getFieldRules';
import { SimulationForm } from '@pages/Simulations/NewSimulation/utils/simulations.types';
import { Controller, useFormContext } from 'react-hook-form';
import styles from '../../../new-simulation.module.scss';

export const StartMonthInput: React.FC<{ index: number }> = ({ index }) => {
  const { control } = useFormContext<SimulationForm>();
  const { fieldRules } = useFieldRules();
  const rules = fieldRules('ppa_wind_bounds_attributes.0.debut_mois_contrainte');

  return (
    <Controller
      control={control}
      name={`ppa_wind_bounds_attributes.${index}.debut_mois_contrainte`}
      rules={rules}
      render={({ field, fieldState }) => (
        <div className={styles['constraint-input-container']}>
          <DatePicker
            hasError={!!fieldState.error}
            onChange={field.onChange}
            selected={field.value}
            dateFormat="MMM"
            showHeader={false}
            picks="months"
            label="pricing.project.start_month"
            size="lg"
          />
        </div>
      )}
    />
  );
};
