import React from 'react';
import { DatePicker } from '@GDM/DatePicker';
import { Text } from '@GDM/Text';
import useTranslation from '@hooks/useTranslation';
import { useFormContext } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { M0AggregForm } from './M0AggregFormModal';

export const MonthInput: React.FC = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<M0AggregForm>();
  const { t } = useTranslation();

  return (
    <Controller
      name="date"
      control={control}
      rules={{ required: true }}
      render={({ field, fieldState }) => (
        <>
          <DatePicker
            label="common.month"
            selected={field.value}
            hasError={!!fieldState.error}
            onChange={(date) => field.onChange(date)}
            picks="months"
            data-cy="m0-date-input"
          />

          {errors?.date && <Text type="danger">{t('common.is_required_dynamic', { key: t('common.month') })}</Text>}
        </>
      )}
    />
  );
};
