import React, { useMemo } from 'react';
import { useConsumeContracts } from '@context/contracts.context';
import { Select } from '@GDM/forms';
import { futureMarketSorter } from '@hooks/requests/useMarketFutures';
import useTranslation from '@hooks/useTranslation';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Option } from '@utils/types/common-types';
import { MarketFuture } from '@utils/types/market_future';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import { Controller, useFormContext } from 'react-hook-form';
import { ContractsFiltersType } from './ContractFilters';

export const MarketFutureFilter: React.FC = () => {
  const { t } = useTranslation();
  const form = useFormContext<ContractsFiltersType>();
  const { contracts, allContracts, filterContracts } = useConsumeContracts();
  const value = form.watch('market_future_id');

  const PERIOD_OPTIONS = useMemo(() => {
    let userMarketFutures: MarketFuture[] = [];
    const allMarketFutures =
      allContracts
        ?.flatMap((c) => c.contract_sub_periods.map((csp) => csp?.market_future))
        .filter((c): c is MarketFuture => c !== null) || [];
    const marketFutureInContracts = allMarketFutures
      .filter((mf) => contracts?.some((c) => c.contract_sub_periods.some((csp) => csp.market_future?.id === mf?.id)))
      .filter((c): c is MarketFuture => c !== null);
    const allOptions: Option[] = [];
    const isSpot = contracts?.some((c) => c.contract_sub_periods.some((csp) => csp.price_type === 'spot'));
    const spotOption: Option = { label: t('sales_management.price.spot'), value: 'SPOT' };
    // ensure uniqueness of market futures
    userMarketFutures = uniqWith(marketFutureInContracts, isEqual).sort(futureMarketSorter);

    // selected options must always be in the list of options (to avoid selected option disappearing)
    if (value && value !== 'SPOT' && !userMarketFutures.find((o) => o.id === value)) {
      const marketFuture = allMarketFutures.find((o) => o.id === value);
      if (marketFuture) userMarketFutures.push(marketFuture);

      userMarketFutures.sort(futureMarketSorter);
    } else if (value === 'SPOT' || isSpot) {
      allOptions.unshift(spotOption);
    }

    allOptions.push(...userMarketFutures.map((mf) => ({ label: mf.name, value: mf.id })).sort(sortOptionsByLabelAsc));

    return allOptions;
  }, [contracts, allContracts, value, t]);

  return (
    <Controller
      name="market_future_id"
      control={form.control}
      render={({ field }) => (
        <Select
          {...field}
          classNamePrefix="contracts-filters-marketfuture-select"
          placeholder={t('common.market_future')}
          isClearable
          selectedOption={field.value}
          options={PERIOD_OPTIONS}
          onChange={(opt) => {
            filterContracts({
              ...form.getValues(),
              market_future_id: opt?.value || null,
              last_selected_filter: 'market_future_id',
            });
            field.onChange(opt?.value);
            form.setValue('last_selected_filter', opt?.value ? 'market_future_id' : null);
          }}
        />
      )}
    />
  );
};
