import React from 'react';
import { LightDefinition } from '@GDM/LightDefinitionList';
import { ValueWithUnit } from '@GDM/ValueWithUnit';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import styles from './simulation-settings.module.scss';

export const SimulationSettingsBook: React.FC = () => {
  const project = useProjectContext();

  return (
    <>
      <LightDefinition
        className={styles.definition}
        label="common.total_power"
        value={<ValueWithUnit value={project.book?.total_power} unit="kW" unstyled />}
      />
      <LightDefinition
        className={styles.definition}
        label="pricing.project.n_installations"
        value={project.book?.installations.length}
      />
    </>
  );
};
