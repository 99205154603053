import React, { useMemo } from 'react';
import { useUser } from '@context/User.context';
import { useGenKey } from '@hooks/useGenKey';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import { specialUnitsMap } from './config';
import { getSpecialNumberParts } from './helpers/getSpecialNumberParts';
import { AcceptableUnits } from './value-with-unit';
import styles from './value-with-unit.module.scss';

export const ValueWithUnit: React.FC<{
  value?: number | null;
  unit?: AcceptableUnits;
  unstyled?: boolean;
  valueVariant?: 'primary-1' | 'primary-2' | 'secondary' | null;
  className?: string;
  'data-cy'?: string;
}> = ({ unit, value, unstyled, valueVariant, className, 'data-cy': dataCy }) => {
  const { locale } = useUser();
  const { t } = useTranslation();
  const { getKey } = useGenKey();

  const parts = useMemo(() => getSpecialNumberParts({ locale, unit, value }), [locale, unit, value]);

  if ((!value && value !== 0) || isNaN(value)) {
    return (
      <div className={classNames(styles['value-with-unit'], unstyled && styles.unstyled)} data-cy={dataCy}>
        <span className={styles.value}>--</span>
        <span className={styles.unit}>{t((unit && specialUnitsMap.get(unit)?.default) || unit)}</span>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        styles['value-with-unit'],
        valueVariant && styles[valueVariant],
        unstyled && styles.unstyled,
        className,
      )}
      data-cy={dataCy}
    >
      {parts?.map(({ type, value }, i) => (
        <span
          key={getKey(parts[i])}
          className={classNames({
            [styles.value]: type === 'number' || type === 'compact',
            [styles.unit]: type === 'unit' || type === 'currency',
            [styles['minus-sign']]: type === 'minusSign',
            [styles['last-space']]: i === parts.length - 2 && type === 'literal',
          })}
          data-cy="value"
        >
          {t(value)}
        </span>
      ))}
      {(!parts || !parts.length) && (
        <div className={classNames(styles['value-with-unit'], unstyled && styles.unstyled)} data-cy={dataCy}>
          <span className={styles.value}>0</span>
          <span className={styles.unit}>{t((unit && specialUnitsMap.get(unit)?.default) || unit)}</span>
        </div>
      )}
    </div>
  );
};
