import React from 'react';
import { Icon } from '@GDM/Icon';
import getDeviationMarkerColor from '@utils/maps/getDeviationMarkerColor';
import { Installation } from '@utils/types/installation';
import { Point, divIcon } from 'leaflet';
import { renderToString } from 'react-dom/server';
import { Marker } from 'react-leaflet';

export const MapMarker: React.FC<React.PropsWithChildren<{ installation: Installation }>> = ({
  installation,
  children,
}) => {
  return (
    <Marker
      icon={divIcon({
        className: null as unknown as undefined,
        html: renderToString(
          <Icon name="MapPin" size={18} color={getDeviationMarkerColor(installation.average_q50)} />,
        ),
        popupAnchor: new Point(0, -20),
        iconAnchor: new Point(12.5, 20),
      })}
      position={[installation.latitude, installation.longitude]}
      key={installation.name}
    >
      {children}
    </Marker>
  );
};
