import React, { useContext, useEffect } from 'react';
import localeContext from '@context/locale.context';
import { Alert } from '@GDM/Alert';
import { Spinner } from '@GDM/Spinner';
import { contractTypeList } from '@utils/constants/contractTypesList';
import { formatSystemDate } from '@utils/formatters';
import dayjs from 'dayjs';
import filtersContext from './filtersContext';
import InvoicesGeneratedChart from './InvoicesGeneratedChart';
import InvoicesGeneratedTable from './InvoicesGeneratedTable';
import { MetricsFilters } from './MetricsFilters';
import useFilters from './utils/hooks/useFilters';
import useMetrics, { UseMetricsParams } from './utils/hooks/useMetrics';

export const Metrics: React.FC = () => {
  /** STATE */
  const [startDate, setStartDate] = React.useState<Date | null>(dayjs().startOf('year').toDate());
  const [endDate, setEndDate] = React.useState<Date | null>(dayjs().startOf('month').toDate());
  const [params, setParams] = React.useState<UseMetricsParams | null>(null);
  const locale = useContext(localeContext);
  const { data, loading, error } = useMetrics(params);
  const filters = useFilters(locale);
  const handleDateChange = (dates: [Date | null, Date | null]) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
  };

  useEffect(() => {
    if (startDate && endDate) {
      setParams({
        start_date: formatSystemDate(startDate),
        end_date: formatSystemDate(endDate),
        contract_types: filters.contracts.selected
          ? [filters.contracts.selected]
          : contractTypeList.FR.map((c) => c.value),
      });
    }
  }, [startDate, endDate, filters.contracts.selected]);

  /** RENDER */
  if (error) {
    return <Alert variant="danger" label="errors.unknown_error" className="px-3" />;
  }

  return (
    <filtersContext.Provider value={filters}>
      <MetricsFilters startDate={startDate} endDate={endDate} onDateChange={handleDateChange} />

      {loading && !data ? (
        <div className="ta:center">
          <Spinner />
        </div>
      ) : (
        <>
          {filters.view.selected === 'chart' && <InvoicesGeneratedChart metrics={data} />}

          {filters.view.selected === 'table' && <InvoicesGeneratedTable metrics={data} />}
        </>
      )}
    </filtersContext.Provider>
  );
};
