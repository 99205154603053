import React from 'react';
import useTranslation from '@hooks/useTranslation';
import { MeterInvoice } from '@utils/types/meter-invoice';

export const InstallationInfoRecipientCc: React.FC<{
  invoice: MeterInvoice;
}> = ({ invoice }) => {
  const { t } = useTranslation();

  return (
    <div className="pl-3">
      <div className="d-flex">
        <div className="mr-2">
          <b>{t('sales_management.invoice_recipient')}</b>
        </div>
        <div>{invoice.email_recipient.length > 0 ? invoice.email_recipient.join(', ') : t('common.none')}</div>
      </div>

      <div className="d-flex mt-1">
        <div className="mr-2">
          <b>{t('sales_management.cc')}</b>
        </div>
        <div>
          {invoice.email_cc.map((cc) => (
            <div key={cc}>{cc}</div>
          ))}
        </div>
      </div>
    </div>
  );
};
