import React from 'react';
import { useConsumeContracts } from '@context/contracts.context';
import { useDynamicOptions } from '@GDM/Filters';
import { Select } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Option } from '@utils/types/common-types';
import { Contract } from '@utils/types/contract';
import { ContractSubType } from '@utils/types/contract_sub_type';
import { Controller, useFormContext } from 'react-hook-form';
import { ContractsFiltersType } from './ContractFilters';

const getOptions = (contracts: Contract[]) => {
  const contractSubtypes = Array.from(
    new Set(contracts?.map((c) => c.sub_type).filter((c): c is ContractSubType => !!c) || []),
  );

  const options: Option<ContractSubType>[] = contractSubtypes
    .map((contractSubtype) => ({ label: contractSubtype.toUpperCase(), value: contractSubtype }))
    .sort(sortOptionsByLabelAsc);

  return options;
};

export const ContractSubTypeFilters: React.FC = () => {
  const form = useFormContext<ContractsFiltersType>();
  const { t } = useTranslation();
  const { filterContracts, contracts, allContracts } = useConsumeContracts();

  const options = useDynamicOptions(getOptions, 'sub_type', contracts, allContracts);

  return (
    <Controller
      name="sub_type"
      control={form.control}
      render={({ field }) => (
        <>
          <Select
            {...field}
            classNamePrefix="contracts-filters-contractsubtype-select"
            placeholder={t('sales_management.sub_type')}
            options={options}
            selectedOption={field.value}
            isClearable
            onChange={(option) => {
              field.onChange(option?.value);
              filterContracts({
                ...form.getValues(),
                sub_type: option?.value ?? null,
                last_selected_filter: 'sub_type',
              });
              form.setValue('last_selected_filter', option?.value ? 'sub_type' : null);
            }}
            id="sub_type"
          />
        </>
      )}
    />
  );
};
