import React from 'react';
import { Select } from '@GDM/forms';
import { Text } from '@GDM/Text';
import useTranslation from '@hooks/useTranslation';
import { ExportFormData } from 'pages/Export/export.types';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

export const SampleSelect: React.FC = () => {
  const { t } = useTranslation();
  const { control, trigger } = useFormContext<ExportFormData>();

  const sampleOptions = [
    { label: t('common.units.dynamic.minutes', { count: '1' }), value: '1mn' },
    { label: t('common.units.dynamic.minutes', { count: '5' }), value: '5mn' },
    { label: t('common.units.dynamic.minutes', { count: '10' }), value: '10mn' },
    { label: t('common.units.dynamic.minutes', { count: '15' }), value: '15mn' },
    { label: t('common.units.dynamic.minutes', { count: '30' }), value: '30mn' },
    { label: t('common.units.dynamic.hour', { count: '1' }), value: 'hourly' },
    { label: t('common.units.dynamic.day', { count: '1' }), value: 'daily' },
    // Monthly is disabled for now, as there is more work to do on backend
    // { label: t('common.monthly'), value: 'monthly' },
  ];

  // fix a bug with react-select not updating value when it's updated from outside
  const sampleValue = useWatch({ control, name: 'sample' });

  return (
    <Controller
      name="sample"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
        <>
          <Select
            id="sample"
            options={sampleOptions}
            isMulti={false}
            selectedOption={sampleValue}
            onChange={async (option) => {
              field.onChange(option?.value);

              if (await trigger('measureTypes')) await trigger('measureTypes');
            }}
            label="monitoring.export.sampling"
            classNamePrefix="export-sample"
          />
          {(field.value === '1mn' || field.value === '5mn') && (
            <Text
              text={t('monitoring.export.sampling_minutes_warning', { count: field.value.charAt(0) })}
              size="sm"
              className="text-wrap"
            />
          )}
        </>
      )}
    />
  );
};
