import React from 'react';
import { Restricted } from '@components/Restricted';
import { useConsumeContracts } from '@context/contracts.context';
import { Button } from '@GDM/Button';
import { CellContext } from '@tanstack/react-table';
import { v2_contract_path } from '@utils/routes';
import { Contract } from '@utils/types/contract';

export const ActionsCell: React.FC<CellContext<Contract, unknown>> = ({ row: { original } }) => {
  const { setSelectedContract, setSelectedToDeleteId } = useConsumeContracts();

  return (
    <>
      <Button
        floating
        data-cy="view-contract"
        onClick={() => {
          setSelectedContract?.(original);
          window.location.hash = original.id;
        }}
        icon="Eye"
      />
      <Restricted permissions={['display:edit_salesfocus']}>
        <Button
          floating
          data-cy="edit-contract"
          className="ml-2"
          href={`${v2_contract_path(original.id)}/edit`}
          icon="Edit3"
        />

        <Button
          floating
          variant="secondary"
          data-cy="delete-contract"
          className="ml-2"
          onClick={() => setSelectedToDeleteId(original.id)}
          icon="Trash2"
        />
      </Restricted>
    </>
  );
};
