import React, { useEffect, useRef } from 'react';
import { Card } from '@GDM/Card';
import { Chart } from '@GDM/Chart';
import useTranslation from '@hooks/useTranslation';
import { getSymbol } from '@utils/currency/getSymbol';
import { Currency } from '@utils/types/currency';
import Highcharts from 'highcharts';
import round from 'lodash/round';
import { Card as CardLegacy, CardBody as CardBodyLegacy } from 'reactstrap';

type MarketChartProps = {
  title: string;
  isM0Estimated: boolean;
  chartValues: {
    m0: number | null;
    m1: number | null;
    base: number | null;
    average_sales_price: number | null;
  };
  printable?: boolean;
  currency: Currency;
};

const MarketChart: React.FC<MarketChartProps> = ({ title, isM0Estimated, chartValues, printable, currency }) => {
  const ref = useRef<{
    chart: Highcharts.Chart;
    container: React.RefObject<HTMLDivElement>;
  }>(null);
  const { t } = useTranslation();
  const series_name_m0 = isM0Estimated ? t('sales_management.m0_estimation') : 'M0';
  const series_name_m1 = t('sales_management.valo_spot');

  const data = [];
  const categories = [];

  if (chartValues.m0 !== null) {
    data.push({ name: series_name_m0, y: round(chartValues.m0, 2) });
    categories.push(series_name_m0);
  }

  if (chartValues.base !== null) {
    data.push({ name: t('sales_management.market_value'), y: round(chartValues.base, 2) });
    categories.push(t('sales_management.market_value'));
  }
  if (chartValues.m1 !== null) {
    data.push({ name: series_name_m1, y: round(chartValues.m1, 2) });
    categories.push(series_name_m1);
  }
  if (chartValues.average_sales_price !== null) {
    data.push({ name: t('sales_management.average_sale_price'), y: round(chartValues.average_sales_price, 2) });
    categories.push(t('sales_management.average_sale_price'));
  }

  const options: Highcharts.Options = {
    title: { text: title },
    chart: {
      animation: false,
      type: 'bar',
      height: 250,
    },
    plotOptions: {
      series: {
        animation: false,
        opacity: 0.95,
      },
      bar: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    tooltip: {
      formatter: function () {
        return `${this.x}: ${Highcharts.numberFormat(this.y, 1)} ${getSymbol(currency)}/MWh`;
      },
    },
    xAxis: {
      categories,
    },
    yAxis: {
      title: { text: null },
    },
  };

  const series: Highcharts.Options['series'] = [
    {
      name: 'Price',
      type: 'bar',
      colorByPoint: true,
      showInLegend: false,
      data,
    },
  ];

  useEffect(() => {
    if (ref.current && ref.current.chart) {
      ref.current.chart.reflow();
    }
  }, [chartValues]);

  if (printable) {
    return (
      <CardLegacy>
        <CardBodyLegacy>
          <Chart options={options} ref={ref} series={series} hideLegend />
        </CardBodyLegacy>
      </CardLegacy>
    );
  }

  return (
    <Card>
      <div style={{ width: '100%' }}>
        <Chart options={options} ref={ref} series={series} hideLegend />
      </div>
    </Card>
  );
};

export default MarketChart;
