import React from 'react';
import { useRequest } from '@hooks/useRequest';
import Page from '@pages/Page';
import { internal_subscription_path } from '@utils/routes';
import { Subscription } from '@utils/types/subscription';
import { User } from '@utils/types/user';
import { Header } from './Header';
import { SubscriptionForm } from './SubscriptionForm';
import { SubscriptionFormProvider } from './SubscriptionFormProvider';

const Edit: React.FC<{
  user: User;
  subscriptionUuid: string;
}> = ({ user, subscriptionUuid }) => {
  const { data, loading, error } = useRequest<Subscription>(internal_subscription_path(subscriptionUuid), 'GET');

  return (
    <SubscriptionFormProvider defaultValues={data}>
      <Page
        titleIcon="Edit3"
        title="subscriptions.edit.title"
        user={user}
        isLoading={loading}
        error={error?.code}
        subtitle={!error && data ? data.email : null}
        pageActions={<Header />}
        layout="no-background"
        fixedHeightContainer
      >
        <SubscriptionForm />
      </Page>
    </SubscriptionFormProvider>
  );
};

export default Edit;
