import React, { useContext, useRef } from 'react';
import { useResizeObserver } from '@hooks/useResizeObserver';
import classNames from 'classnames';
import { FiltersUiStateContext } from './filters.context';
import styles from './filters.module.scss';

export const FilterContainer: React.FC<
  React.PropsWithChildren<{
    size?: 'fit' | 'small' | 'datepicker' | 'select';
    'data-cy'?: string;
  }>
> = ({ children, size, 'data-cy': dataCy }) => {
  const { showAllFilters, onChildrenResize } = useContext(FiltersUiStateContext);
  const ref = useRef<HTMLDivElement>(null);
  const firstRender = useRef(true);

  useResizeObserver(() => {
    if (!firstRender.current) {
      onChildrenResize();
    } else {
      firstRender.current = false;
    }
  }, ref);

  return (
    <div
      data-cy={dataCy}
      className={classNames(
        styles['filter-container'],
        size && styles[`size-${size}`],
        'mr-2',
        'mb-3',
        !showAllFilters && styles.hide,
      )}
      ref={ref}
    >
      {children}
    </div>
  );
};
