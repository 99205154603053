import React from 'react';
import { ProgressStepper, Step } from '@GDM/forms';
import { BillingDetails, ExtraInfo, GeneralInfo, PricingParams, UsageLimits } from './sections';
import { useSubscriptionForm } from './useSubscriptionForm';

export const SubscriptionForm: React.FC = () => {
  const { readonly } = useSubscriptionForm();

  const steps: Record<string, Step> = {
    general_info: { title: 'subscriptions.form.general_info.title', progress: 0, component: <GeneralInfo /> },
    pricing_params: { title: 'subscriptions.form.pricing_params.title', progress: 0, component: <PricingParams /> },
    usage_limits: { title: 'subscriptions.form.usage_limits.title', progress: 0, component: <UsageLimits /> },
    billing_details: { title: 'subscriptions.form.billing_details.title', progress: 0, component: <BillingDetails /> },
    extra_info: { title: 'subscriptions.form.extra_info.title', progress: 0, component: <ExtraInfo /> },
  };

  return <ProgressStepper showNav={!readonly} className="h-100" steps={steps} containerMaxWidth={60} />;
};
