import React from 'react';
import { Definition, DefinitionList } from '@GDM/DefinitionList';
import { Meter } from '@utils/types/meter';
import { ClientIdDefinition } from './ClientIdDefinition';
import { CommissioningDateDefinition } from './CommissioningDateDefinition';
import { CoordinatesDefinition } from './CoordinatesDefinition';
import { ErpCodeDefinition } from './ErpCodeDefinition/ErpCodeDefinition';
import { ExpectedPrDefinition } from './ExpectedPrDefinition';
import { GridConnectionDefinition } from './GridConnectionDefinition/GridConnectionDefinition';
import { GridDefinition } from './GridDefinition/GridDefinition';
import { InvoicingTypeDefinition } from './InvoicingTypeDefinition';
import { PowerDefinition } from './PowerDefinition';
import { ReactiveInstructionsDefinition } from './ReactiveInstructionsDefinition';
import { SiretDefinition } from './SiretDefinition/SiretDefinition';

const MeterInfo: React.FC<{ meter: Meter; isDummy?: boolean }> = ({ meter, isDummy }) => {
  const energyTypeToLabel: Record<string, string> = {
    solar: 'common.pv.solar',
    wind: 'common.wind.eolian',
    hydro: 'common.hydro.short',
  };

  const meterType = meter?.installation?.energy ? energyTypeToLabel[meter?.installation.energy] : 'common.unknown';
  const { grid_connection_type } = meter.installation || {};
  const installationName = meter.installation?.name || '';

  const owner = meter.installation?.owner_long_name || meter.installation?.owner_short_name || '';
  const ownerCompanyNumber = meter.installation?.owner_company_number
    ? `(${meter.installation?.owner_company_number})`
    : '';

  return (
    <DefinitionList>
      <Definition label="common.type" value={meterType} data-cy="type-definition" />

      <PowerDefinition meter={meter} />

      <CoordinatesDefinition meter={meter} />

      {meter?.installation?.energy === 'solar' && <ExpectedPrDefinition meter={meter} />}

      <ReactiveInstructionsDefinition meter={meter} />

      <ClientIdDefinition meter={meter} />

      {!!meter.facturation_type && isDummy && <InvoicingTypeDefinition meter={meter} />}

      <GridDefinition meter={meter} />

      <GridConnectionDefinition installationName={installationName} gridConnectionType={grid_connection_type} />

      <CommissioningDateDefinition meter={meter} />

      <ErpCodeDefinition meter={meter} />

      <SiretDefinition meter={meter} />

      <Definition label="common.owner" value={`${owner} ${ownerCompanyNumber}`} />
    </DefinitionList>
  );
};

export default MeterInfo;
