import React, { InputHTMLAttributes } from 'react';
import { Input } from '@GDM/forms';
import styles from './date-picker.module.scss';

export const TimePicker: React.FC<InputHTMLAttributes<HTMLTimeElement> & { onChange?: (e: string) => void }> = ({
  value,
  onChange,
}) => {
  return (
    <Input
      className={styles['time-picker']}
      value={value}
      type="time"
      onChange={(e) => onChange?.(e.target.value)}
      autoComplete="off"
      full
    />
  );
};
