import React from 'react';
import { Input } from '@GDM/forms';
import { useInstallationField } from '@pages/Installations/Installation/hooks/useInstallationField';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import { Controller } from 'react-hook-form';

export const NumberInput = () => {
  const form = useInstallationForm();
  const { disabled, rules } = useInstallationField('numero');

  return (
    <Controller
      name="numero"
      control={form.control}
      rules={rules}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          value={field.value ?? ''}
          label="common.line_number"
          size="lg"
          full
          data-cy="meter-number"
          disabled={disabled}
          hasError={!!fieldState.error}
          errorMessage={fieldState.error?.message}
        />
      )}
    />
  );
};
