import React from 'react';
import { Modal } from '@GDM/Modal';
import { Text } from '@GDM/Text';

export const ConfirmationModal: React.FC<React.ComponentProps<typeof Modal>> = (props) => {
  return (
    <Modal {...props} saveButtonText="common.confirm">
      <Text text="sales_management.invoices.error_more_10_percent_diff" type="danger" multiline />
    </Modal>
  );
};
