import 'swagger-ui-react/swagger-ui.css';
import React, { useState } from 'react';
import { Restricted } from '@components/Restricted';
import { Select } from '@GDM/forms';
import { Col, Row } from '@GDM/layout';
import Page from '@pages/Page';
import { User } from '@utils/types/user';
import SwaggerUI from 'swagger-ui-react';

const Doc: React.FC<{ user: User }> = ({ user }) => {
  const [selected, setSelected] = useState<string | undefined>('/api-docs/v2/openapi.yaml');

  const options = [
    { value: '/api-docs/v2/openapi.yaml', label: 'Documentation' },
    { value: '/api-docs/v2/openapi_admin.yaml', label: 'Admin Documentation' },
  ];

  return (
    <Page
      user={user}
      title="sidebar.admin_settings.api_doc"
      header={
        user.admin && (
          <Row>
            <Col xl="4">
              <Select options={options} className="mb-4" onChange={(e) => setSelected(e?.value)} />
            </Col>
          </Row>
        )
      }
    >
      <Restricted permissions={['display:view_api']} bypassRights={Boolean(user.admin)}>
        <SwaggerUI url={selected} />
      </Restricted>
    </Page>
  );
};

export default Doc;
