import React from 'react';
import { DatePicker } from '@GDM/DatePicker';
import { ExportFormData } from 'pages/Export/export.types';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
export const ExportDatesInput: React.FC = () => {
  const { control } = useFormContext<ExportFormData>();

  const handleDatePickerChange =
    (field: ControllerRenderProps<ExportFormData, 'dates'>) => (dates: Date | null | [Date | null, Date | null]) => {
      if (Array.isArray(dates)) field.onChange({ startDate: dates?.[0], endDate: dates?.[1] });
    };

  return (
    <Controller
      name="dates"
      control={control}
      rules={{ required: true, validate: (value) => Boolean(value.startDate && value.endDate) }}
      render={({ field, fieldState }) => (
        <DatePicker
          startDate={field.value.startDate}
          endDate={field.value.endDate}
          label="common.period"
          onChange={handleDatePickerChange(field)}
          data-cy="export-daterange-input"
          hasError={Boolean(fieldState?.error)}
          selectsRange
        />
      )}
    />
  );
};
