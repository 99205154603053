import React from 'react';
import { useUser } from '@context/User.context';
import { Map } from '@GDM/Map';
import { COUNTRIES } from '@utils/constants/countries';
import { CountryDefinitions } from '@utils/types/countries';
import classNames from 'classnames';
import parentStyles from '../pricing-maps.module.scss';
import { GeoRasterData, PricingMapM0s } from '../utils/pricing-maps.types';
import styles from './pricing-map.module.scss';
import { PricingMapContent } from './PricingMapContent';

export const PricingMap: React.FC<{
  geoRaster: GeoRasterData;
  mask: GeoJSON.Feature;
  m0s: PricingMapM0s;
  setLatLng: (latLng: CountryDefinitions['coordinates']) => void;
  latLng: CountryDefinitions['coordinates'];
}> = ({ geoRaster, mask, m0s, setLatLng, latLng }) => {
  const user = useUser();
  const country = user?.geolocation[0];
  const coordinates = COUNTRIES[country].coordinates;

  return (
    <div className={classNames(styles['pricing-map'], parentStyles['content'])}>
      <Map
        zoom={5.5}
        hideLayers={['satellite']}
        center={coordinates.lat && coordinates.lng ? [coordinates.lat, coordinates.lng] : [0, 0]}
      >
        <PricingMapContent geoRaster={geoRaster} mask={mask} m0s={m0s} setLatLng={setLatLng} latLng={latLng} />
      </Map>
    </div>
  );
};
