import React from 'react';
import styles from './page.module.scss';

export const PageWrapper: React.FC<React.PropsWithChildren<{ fixedHeightContainer: boolean }>> = ({
  children,
  fixedHeightContainer,
}) => {
  if (fixedHeightContainer)
    return <div className={fixedHeightContainer && styles['fixed-height-container']}>{children}</div>;

  return <>{children}</>;
};
