import React, { useEffect, useMemo, useState } from 'react';
import { mandatesContext } from '@context/Mandates.context';
import { Badge } from '@GDM/Badge';
import { ERRORS_TO_REMAP } from '@GDM/FormErrors/errors-to-remap';
import { Form } from '@GDM/forms';
import { useGrids } from '@hooks/requests/useGrids';
import { useMandates } from '@hooks/requests/useMandates';
import { useInstallationFormQuery } from '@pages/Installations/Installation/useInstallationFormQuery';
import { v2_installations_path } from '@utils/routes';
import { User } from '@utils/types/user';
import { SubmitHandler, useForm } from 'react-hook-form';
import { COMMON_FIELDS } from './constants/common-fields';
import { FIELD_SECTIONS } from './constants/field-sections';
import { showMeterFormContext } from './context/showMeterForm.context';
import { useErrorsFromRequest } from './hooks/useErrorsFromRequest';
import { useFormDataProviders } from './hooks/useFormDataProviders';
import { useSteps } from './hooks/useSteps';
import { useStepsNavigation } from './hooks/useStepsNavigation';
import { useValuesFromRequest } from './hooks/useValuesFromRequest';
import {
  InstallationForm as InstallationFormType,
  type InstallationFormRequestBody,
  type StepKey,
} from './installation.types';
import { PageActions } from './PageActions';
import { useMeterFormQuery } from './useMeterFormQuery';
import { useOwnerFormQuery } from './useOwnerFormQuery';
import { getDefaultValues } from './utils/getDefaultValues';
import { submitFormStep, type MutateFunction } from './utils/submitFormStep';

type MutationMap = Record<StepKey, { create: MutateFunction; update: MutateFunction; isEdit: boolean }>;

const LOCALE_ERRORS_TO_REMAP: Record<string, string> = {
  ...ERRORS_TO_REMAP,
  taken: 'sales_management.form_errors.meter_taken',
};

export const InstallationForm: React.FC<{ user: User; name?: string }> = ({ user, name }) => {
  /**
   * `user` is not yet defined in `useUser` hook as this component is called before the `<Page />`
   *  component that will define the `user` context
   */
  const defaultValues = getDefaultValues(user);

  const [showMeterForm, setShowMeterForm] = useState(false);
  const showMeterFormValue = useMemo(() => ({ showMeterForm, setShowMeterForm }), [showMeterForm, setShowMeterForm]);

  const form = useForm<InstallationFormType>({
    mode: 'all',
    defaultValues,
    reValidateMode: 'onSubmit',
  });
  const ownerId = form.watch('owner_id');
  const [selectedStep, setSelectedStep] = useState<StepKey>('installation');

  const country = form.watch('country');
  const steps = useSteps(country);
  const stepKeys = Object.keys(steps) as StepKey[];
  const { watch, setValue } = form;
  const grids = useGrids();
  const dataProviders = useFormDataProviders(watch);

  const { handleNextStep, handlePreviousStep } = useStepsNavigation(
    setSelectedStep,
    selectedStep,
    steps,
    form.clearErrors,
  );

  const mandates = useMandates();

  const {
    query: installationQuery,
    createMutation: createInstallation,
    updateMutation: updateInstallation,
  } = useInstallationFormQuery({ name, step: selectedStep, goToNextStep: handleNextStep });

  const { createMutation: createMeter, updateMutation: updateMeter } = useMeterFormQuery({
    id: installationQuery.data?.meter?.id,
    goToNextStep: handleNextStep,
  });

  const { createMutation: createOwner } = useOwnerFormQuery({
    goToNextStep: handleNextStep,
    setFormValue: form.setValue,
  });

  if (installationQuery.data?.has_meter && installationQuery.data.has_meter !== showMeterForm) {
    setShowMeterForm(installationQuery.data.has_meter);
  }

  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (name === 'grid_id') {
        const selectedGrid = grids.data?.find(({ id }) => id === values.grid_id);
        if (dataProviders.length === 1) {
          setValue('data_provider_id', dataProviders[0].id);
        } else if (selectedGrid?.data_providers?.length === 1) {
          setValue('data_provider_id', selectedGrid.data_providers[0].id);
        } else {
          setValue('data_provider_id', null);
        }
      }

      if (name === 'via_data_provider' && values.via_data_provider === false) {
        setValue('data_provider_id', null);
        setValue('data_provider_number', '');
      }

      if (name === 'via_direct_connection' && values.via_direct_connection === false) {
        setValue('numero', '');
        setValue('meter_pass_address', null);
        setValue('code', null);
        setValue('key', null);
        setValue('ip', null);
        setValue('tcp_port', null);
        setValue('boitier_ip', false);
      }

      if (name === 'via_direct_connection' && values.via_direct_connection === true) {
        setValue('boitier_ip', true);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, setValue, dataProviders, grids.data]);

  const mutationMap: MutationMap = {
    installation: {
      create: createInstallation.mutate,
      update: updateInstallation.mutate,
      isEdit: !!(name || installationQuery.data?.name),
    },
    meter: {
      create: createMeter.mutate,
      update: updateMeter.mutate,
      isEdit: !!installationQuery.data?.meter?.id,
    },
    owner: {
      create: createOwner.mutate,
      update: updateInstallation.mutate,
      isEdit: ownerId !== 'new_owner',
    },
  };

  const values = useValuesFromRequest(installationQuery.data);
  const { formErrors, globalError } = useErrorsFromRequest<InstallationFormType>(
    [
      installationQuery.error,
      createInstallation.error,
      updateInstallation.error,
      createMeter.error,
      updateMeter.error,
      createOwner.error,
    ],
    user.locale,
    LOCALE_ERRORS_TO_REMAP,
  );

  const handleSubmit: SubmitHandler<InstallationFormType> = (values, event) => {
    const nativeEvent = event?.nativeEvent as React.BaseSyntheticEvent<SubmitEvent>['nativeEvent'] | undefined;
    const isDraft = nativeEvent?.submitter?.getAttribute('name') === 'draft';

    if (selectedStep === 'meter' && !showMeterForm) {
      return handleNextStep();
    }

    const { create, update, isEdit } = mutationMap[selectedStep];

    const internalValues: InstallationFormRequestBody = {
      ...values,
      status: isDraft ? 'draft' : undefined,
    };

    submitFormStep(internalValues, create, update, isEdit);
  };

  const isLoading = [
    createInstallation.isPending,
    updateInstallation.isPending,
    installationQuery.isLoading,
    createMeter.isPending,
    updateMeter.isPending,
    createOwner.isPending,
  ].some((loading) => loading);

  const isNotValidatedByOps = mutationMap.meter.isEdit && !installationQuery.data?.meter?.validated_by_ops;
  const isValidatedByOps = mutationMap.meter.isEdit && installationQuery.data?.meter?.validated_by_ops;
  const isMeterStep = selectedStep === 'meter';

  return (
    <mandatesContext.Provider value={mandates}>
      <showMeterFormContext.Provider value={showMeterFormValue}>
        <Form
          form={form}
          values={values}
          errors={formErrors}
          defaultValues={defaultValues}
          user={user}
          onSubmit={handleSubmit}
          steps={steps}
          commonFields={COMMON_FIELDS}
          fieldSections={FIELD_SECTIONS}
          backButtonHref={v2_installations_path()}
          isLoading={isLoading}
          pageProps={{
            title: name ? 'admin.installations.edit_installation' : 'admin.installations.add_installation',
            subtitle: (
              <div className="d-flex align-items-center">
                {installationQuery.data?.status === 'draft' && (
                  <Badge className="mr-2" label="common.draft" variant="danger" />
                )}
                {isNotValidatedByOps && isMeterStep && (
                  <Badge
                    className="mr-2"
                    label="admin.installations.meter_not_validated"
                    tooltip="admin.installations.meter_not_validated_tooltip"
                    variant="orange"
                  />
                )}
                {isValidatedByOps && isMeterStep && (
                  <Badge className="mr-2" label="admin.installations.meter_active" variant="primary-1" />
                )}
                {installationQuery.data?.name ?? name}
              </div>
            ),
            user: user,
            layout: 'no-background',
            isLoading: isLoading,
            error: globalError,
            useQueryClient: false,
            pageActions: (
              <PageActions
                onPreviousStep={handlePreviousStep}
                onNextStep={handleNextStep}
                isLoading={isLoading}
                isFirstStep={stepKeys.indexOf(selectedStep) === 0}
                isLastStep={stepKeys.indexOf(selectedStep) === stepKeys.length - 1}
              />
            ),
          }}
          countryKey="country"
          currentStep={selectedStep}
        />
      </showMeterFormContext.Provider>
    </mandatesContext.Provider>
  );
};
